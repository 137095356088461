
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/register.bilbogen.html";
      const src = "<form name=\"form\">\n  <div class=\"form-group clearfix\">\n    <div class=\"col-xs-12\">\n      <div class=\"input-group\" ng-class=\"\">\n        <input type=\"text\" class=\"form-control\" ng-model=\"modelVars.value\" placeholder=\"Skriv stelnr.\" required />\n        <span class=\"input-group-btn\">\n          <button class=\"btn btn-default\" type=\"button\" ng-disabled=\"form.cvr.$invalid\" ng-click=\"query()\">\n            Kør opslag\n          </button>\n        </span>\n      </div>\n    </div>\n  </div>\n</form>\n\n<form action=\"/api/application/getGenericPDF\" method=\"POST\" target=\"_blank\" name=\"test\" id=\"pdfgenericform\">\n  <input type=\"hidden\" name=\"type\" value=\"\" />\n  <input type=\"hidden\" name=\"values\" value=\"\" />\n  <input type=\"hidden\" name=\"params\" value=\"\" />\n  <input type=\"hidden\" name=\"downloadButtonState\" value=\"\" />\n  <input type=\"hidden\" name=\"id\" value=\"\" />\n</form>\n\n<hr />\n<div>\n  <div ng-show=\"motorregisterResult.type == 'success'\">\n    <a\n      class=\"btn btn-default pull-right\"\n      pdflink=\"\"\n      pdfform=\"pdfgenericform\"\n      params=\"Opslag i bilbogen\"\n      scope-element=\"motorregisterSections\"\n      >Hent som PDF</a\n    >\n\n    <div ng-repeat=\"section in motorregisterSections\">\n      <p><strong>{{section.title}}</strong></p>\n      <dl class=\"dl-horizontal\">\n        <div ng-repeat=\"line in section.elements\">\n          <dt>{{line.overrideText ? line.overrideText : line.key}}</dt>\n          <dd>{{line.value[0]}}&nbsp;</dd>\n        </div>\n      </dl>\n    </div>\n  </div>\n\n  <div ng-show=\"motorregisterResult.type == 'error'\" class=\"alert alert-warning\">{{motorregisterResult.message}}</div>\n  <div ng-show=\"motorregisterResult.type == 'wait'\" class=\"well\">\n    <i class=\"fa fa-spinner fa-spin\"></i> Forespørger bilbogen...\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      