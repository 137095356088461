
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/form/noitsoBox.html";
      const src = "<div ng-if=\"id\" class=\"alert alert-selvanmelder clearfix\" style=\"text-align:left; margin-top: 1.5em; line-height:2em;\">\n  Kreditværdighed: <span class=\"{{noitsoColor()}}\">{{ danishNoitsoStatus() }}</span>\n  <div class=\"pull-right\">\n    <button ng-if=\"!currentRow.noitsoTransactionId && noitsoInitializeStatus !== 'loading'\" class=\"btn btn-sm btn-primary\" type=\"button\" ng-click=\"initializeNoitso()\">\n      <i class=\"fa fa-envelope-o\"></i> Afsend link\n    </button>\n    <button ng-if=\"noitsoInitializeStatus === 'loading'\" class=\"btn btn-sm btn-default\" disabled type=\"button\"><i class=\"fa fa-spin fa-spinner\"></i> Afsendt</button>\n    <button ng-if=\"currentRow.noitsoTransactionId && noitsoInitializeStatus === 'done'\" class=\"btn btn-sm btn-default\" disabled type=\"button\"><i class=\"fa fa-check\"></i> Afsendt</button>\n    <a ng-if=\"currentRow.noitsoTransactionId && userInfo.isSuperAdmin\" class=\"btn btn-sm btn-default\" style=\"color: #555555\" target=\"_blank\"\n       ng-href=\"https://creditboard.noitso.dk/da/credit-check/view/{{currentRow.noitsoTransactionId}}\"><i class=\"fa fa-external-link\"></i> Åbn</a>\n    <button ng-if=\"currentRow.noitsoTransactionId && userInfo.isSuperAdmin && false\" class=\"btn btn-sm btn-danger\" type=\"button\" ng-click=\"deleteNoitso()\">Delete</button>\n  </div>\n</div>";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      