import creditAnalysisTotalValueTemplate from './creditAnalysisTotalValue.html'
import { IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import { formatNumber } from '../functions/intlFormaters'

interface CreditAnalysisTotalValueScope extends IScope {
  data: any
  contract: any
  formatNumber: typeof formatNumber
  rammebeloebNum: number
  inklNum: number
  indenforRammen: boolean
}

export const creditAnalysisTotalValue = [
  function (): IDirective<CreditAnalysisTotalValueScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisTotalValueTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        contract: '=',
        buttonState: '=',
      },

      link: function ($scope: CreditAnalysisTotalValueScope, elm: JQLite, attrs: IAttributes) {
        $scope.formatNumber = formatNumber

        function update() {
          $scope.inklNum = ($scope.data?.totalValue ?? 0) + ($scope.contract?.hovedstol ?? 0)
          $scope.rammebeloebNum =
            $scope.data?.totalValueLimit == null
              ? $scope.contract?.samletEngagementBestyrelseGraense
              : $scope.data?.totalValueLimit
          $scope.indenforRammen = $scope.inklNum <= $scope.rammebeloebNum
        }

        $scope.$watch('data', update)
        $scope.$watch('contract', update)
      },
    }
  },
]
