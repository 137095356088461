import { errorCount } from '../functions/validationErrors'

export const formArticleCategoryCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount

    $scope.variableDefs = {
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      sort: {
        friendly: 'Sorteringstal',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      icon: {
        friendly: 'Ikon',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['article.icon']
        },
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Kategorien blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/article/edit/' + res)
      if ($scope.id !== undefined) {
        loadInsuranceCompany()
      }
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var loadInsuranceCompany = function () {
      $http.post('/api/articleCategory/get/' + $scope.id).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }
        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.deactivate = function () {
        $http.post('/api/articleCategory/deactivate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Kategorien blev deaktiveret.')
          loadInsuranceCompany()
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/articleCategory/activate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Kategorien blev genaktiveret.')
          loadInsuranceCompany()
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew) {
        if (saveAsNew) {
          $http.post('/api/articleCategory/add', $scope.vars).success(saveSuccess)
        } else {
          $http.post('/api/articleCategory/edit/' + $stateParams.id, $scope.vars).success(saveSuccess)
        }
      }
      loadInsuranceCompany()
    } else {
      $scope.vars = {}
      $scope.vars.row = {
        name: '',
        sort: 0,
      }

      $scope.save = function () {
        $http.post('/api/articleCategory/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
