import errorTemplate from '../templates/modals/error.html'

export const mainCtrl = [
  '$state',
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$modal',
  '$interop',
  function ($state, $scope, $rootScope, $http, $stateParams, $modal, $interop) {
    $scope.$state = $state
    $rootScope.$on('httperror', function (e, msg) {
      $modal({ template: errorTemplate, show: true, content: msg })
    })

    $rootScope.currentForm = undefined
    $rootScope.currentStatus = 0

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (!$rootScope.keepAlertAfterStateChange) {
        $rootScope.globalAlert = false
      }
      $rootScope.keepAlertAfterStateChange = false
    })

    $rootScope.setGlobalAlert = function (msg) {
      $rootScope.globalAlert = msg
      $rootScope.keepAlertAfterStateChange = true
    }

    $rootScope.setKeepAlert = function (val) {
      $rootScope.keepAlertAfterStateChange = val
    }

    $rootScope.globalAlert = false
    $rootScope.keepAlertAfterStateChange = false

    $rootScope.$interop = $interop
  },
]
