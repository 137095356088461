import type { IAttributes, IDirective, IScope } from "angular";

interface ClickOnAttrs extends IAttributes {
  clickOn: string
}

export function clickOn(): IDirective<IScope, JQLite, ClickOnAttrs> {
  return {
    link(scope: IScope, elm: JQLite, attrs: ClickOnAttrs) {
      scope.$on(attrs.clickOn, function () {
        //We can't trigger submit immediately, or we get $digest already in progress error :-[ (because ng-submit does an $apply of its own)
        setTimeout(function () {
          elm[0].click()
        })
      })
    },
  }
}
