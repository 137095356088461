import { dateToDanish } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'
import { cvrLookupMappingsCompanyPage } from '../functions/cvrcprLookupMappings'

export const formCompanyCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$upload',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $upload, $interop) {
    $scope.setWarning = function (warning, warningField) {
      $scope[warningField] = warning
    }

    $scope.config = cvrLookupMappingsCompanyPage
    $scope.errorCount = errorCount
    $scope.cityVars = {}
    $scope.variableDefs = {
      cvr: {
        friendly: 'CVR-nr.',
        datatype: 'cvr',
        vartype: 'model',
        required: true,
      },
      website: {
        friendly: 'Hjemmeside',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      address: {
        friendly: 'Adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      zipcode: {
        friendly: 'Postnr',
        datatype: 'postnr',
        vartype: 'model',
        required: true,
      },
      mail: {
        friendly: 'E-mail adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      phone: {
        friendly: 'Telefon',
        datatype: 'digits',
        vartype: 'model',
        required: true,
      },

      fax: {
        friendly: 'Fax',
        datatype: 'digits',
        vartype: 'model',
        required: false,
      },

      afregningsmail: {
        friendly: 'Afregningsmail',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      foqusCompanyCode: {
        friendly: 'Foqus Finance, Firmakode',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['firstlease'],
        },
      },
      foqusDepartmentCode: {
        friendly: 'Foqus Finance, Afdelingskode',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['firstlease'],
        },
      },
      hasAccess: {
        friendly: 'Adgang til forhandlersystem',
        required: true,
      },
      enableDepositum: {
        friendly: 'Aktivér depositum på forhandler',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      stiftelsesprovisionFlexSplitMax: {
        friendly: 'Firmaspecifikt max. for flex/split stiftelsesprovision',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      lokalmarginal: {
        friendly: 'Firmaspecifik lokalmarginal',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      visKontonummerFaktura: {
        friendly: 'Vis kontonummer på faktura',
        required: true,
      },
      visKollegersAnsoegninger: {
        friendly: 'Vis kollegers kladder',
        required: true,
      },
      selvbevillingAktiv: {
        friendly: 'Vis kollegers kladder',
        required: true,
      },
      lockAnviser2: {
        friendly: 'Lås sekundær tilbagekøber for forhandler',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.secondaryRepurchaser'],
        },
      },
      forhandlerlogoLilleMaxBreddeCm: {
        friendly: 'Lille forhandlerlogo, max. bredde (cm)',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      forhandlerlogoLilleMaxHoejdeCm: {
        friendly: 'Lille forhandlerlogo, max. højde (cm)',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      forhandlerlogoStorMaxBreddeCm: {
        friendly: 'Stort forhandlerlogo, max. bredde (cm)',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      forhandlerlogoStorMaxHoejdeCm: {
        friendly: 'Stort forhandlerlogo, max. højde (cm)',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      forhandlerlogoCenter: {
        friendly: 'Centrér forhandlerlogo',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      receiveNewsletters: {
        friendly: 'Modtag nyhedsbreve',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      forhandlerUdenEkstraMaanedRegAfgift: {
        friendly: 'Uden ekstra måned reg. afgift',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      forhandlerMedDigitalUnderskrift: {
        friendly: 'Aktivér digital underskrift',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      tilbudsmodtagelsesunderskrift: {
        friendly: 'Underskriftsfelt på tilbud',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.tilbudsmodtagelsesunderskrift'],
        },
      },
      forhandlerIsLeasinggiver: {
        friendly: 'Forhandler som leasinggiver',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.forhandlerIsLeasinggiver'],
        },
      },
      kommentar: {
        friendly: 'Kommentar',
        vartype: 'model',
        required: true,
      },

      koerebogDemoUsername: {
        friendly: 'Kørebog Demo brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      koerebogDemoPassword: {
        friendly: 'Kørebog Demo adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      asgUsername: {
        friendly: 'ASG brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
      asgPassword: {
        friendly: 'ASG adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
      godkendtKautionist: {
        friendly: 'Godkend forhandler som kautionist',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.godkendtKautionist'],
        },
      },
      godkendtAnviser: {
        friendly: 'Godkend forhandler som anviser',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.godkendtAnviser'],
        },
      },
      'tilladSeparatOpkraevningForhandler' : {
        friendly: 'Tillad separat opkrævning af dokumentgebyr og panthaverdeklaration',
        datatype : 'boolean',
        vartype: 'model',
        required : true,
        fieldConditions: {
          requiredFeature: ['company.tilladSeparatOpkraevningForhandler'],
        },
      },
      forhandlerTilladPrivatLeasing: {
        friendly: 'Godkend forhandler til privatleasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.forhandlerTilladPrivatLeasing'],
        },
      },
      enableRoadsideAssistance: {
        friendly: 'Tilbyd vejhjælp via SOS Dansk Autohjælp',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['roadsideAssistance'],
        },
      },
      visRente: {
        friendly: 'Vis rente',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      bilinfoUsername: {
        friendly: 'Bilinfo brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      bilinfoPassword: {
        friendly: 'Bilinfo password',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      findLeasingApiToken: {
        friendly: 'Find Leasing API token',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      tilladAlternativLeverandoer: {
        friendly: 'Tillad brug af alternativ leverandør',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.tilladAlternativLeverandoer'],
        },
      },
      extraXMLFields: {
        friendly: 'extraXMLFields',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.extraXMLFields'],
        },
      },
      enableService: {
        friendly: 'Aktivér service på forhandler',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.enableService'],
        },
      },
      digitalSignatureToProd: {
        friendly: 'Send til',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.digitalSignatureToProd'],
        },
      },
      digitalSignatureOnSend: {
        friendly: '(Digital) underskrift ved',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.digitalSignatureOnSend'],
        },
      },
      administrationsaftale: {
        friendly: 'Administrationsafale',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.administrationsaftale'],
        },
      },
      enableSummary: {
        friendly: 'Aktivér opsummering på forhandler',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.enableSummary'],
        },
      },
      enableSaleAndLeaseBack: {
        friendly: 'Aktivér sale and lease back på forhandler',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.enableSaleAndLeaseBack'],
        },
      },
      whitelabel: {
        friendly: 'Whitelabel-samarbejde',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.whitelabel'],
        },
      },
      defaultAnviser: {
        vartype: 'model',
        datatype: 'count',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.defaultAnviser'],
        },
      },
      defaultAnviser2: {
        vartype: 'model',
        datatype: 'count',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.secondaryRepurchaser'],
        },
      },
      defaultProductId: {
        vartype: 'model',
        datatype: 'count',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.defaultProductId'],
        },
      },
      dealerRepurchaserWithoutSignature: {
        vartype: 'model',
        datatype: 'boolean',
        required: true,
        fieldConditions: {
          requiredFeature: ['company.dealerRepurchaserWithoutSignature'],
        },
      },
    }

    $scope.options = {
      defaultAnviser: [
        { value: 0, option: 'Leasingtager' },
        { value: 1, option: 'Forhandler' },
        { value: 3, option: 'Anden (erhverv)' },
      ],
      defaultAnviser2: [
        { value: 0, option: 'Ingen' },
        { value: 1, option: 'Forhandler' },
        { value: 2, option: 'Anden (erhverv)' },
      ],
      lockAnviser2: [
        { value: false, option: 'Nej' },
        { value: true, option: 'Ja' },
      ],
      digitalSignatureToProd: [
        { value: false, option: 'Test' },
        { value: true, option: 'Produktion' },
      ],
      digitalSignatureOnSend: [
        { value: false, option: 'Godkendelse' },
        { value: true, option: 'Afsendelse' },
      ],
    }

    $http.post('/api/product/getAll').success(function (res) {
      $scope.products = res
      $scope.productsWithNone = [{ id: 0, name: 'Intet valgt' }].concat($scope.products)
    })

    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Forhandleren blev gemt.')
      if ($scope.id === undefined) {
        $location.url('/controlpanel/company/edit/' + res)
      } else {
        $scope.setKeepAlert(false)
      }
    }
    $scope.selectLogo = function ($files) {
      // Only accept one file
      $scope.selectedLogo = $files[0]
    }

    $scope.deleteLogo = function () {
      $http.post('/api/company/deleteLogo/' + $scope.id).success(function (res) {
        loadCompany()
      })
    }

    $scope.setActiveId($stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10))

    $scope.setActiveUserId(undefined)

    $scope.modelVars = {}
    $interop.specific.formCompanyCtrl.getModelVars($http).then((v) => {
      //Check if nothing else has replaced it
      if (Object.entries($scope.modelVars).length === 0) {
        $scope.modelVars = v
        $scope.modelVars.companyGroupId = undefined
      }

      $http.post('/api/companyGroup/getAllMains').success(function (res) {
        $scope.companyGroups = res
        if ($scope.modelVars.companyGroupId === undefined) {
          $scope.modelVars.companyGroupId = res[0].id
        }
      })
    })

    $http.post('/api/companyGroup/getAllCampaigns').success(function (res) {
      $scope.campaignCompanyGroups = res
      //angular.forEach(res, function(obj) {
      //obj.dateRange = (obj.start !== null && obj.end !== null) ? '(' + dateToDanish(new Date(obj.start)) + '-' + dateToDanish(new Date(obj.end)) + ')' : '';
      //})
    })

    if ($interop.specific.formCompanyCtrl.extraSetup) {
      $interop.specific.formCompanyCtrl.extraSetup($http, $scope)
    }

    var loadCompany = function () {
      $http.post('/api/company/get/' + $scope.id).success(function (res) {
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }

        $scope.tags = res.tags
        $scope.hasLogo = res.hasLogo
        $scope.selectedProducts = res.products
        $scope.selectedCampaignCompanyGroups = res.campaignCompanyGroups
        $scope.newestSavedForApproval = res.newestSavedForApproval
          ? dateToDanish(new Date(res.newestSavedForApproval))
          : 'Ingen sager afsendt'
        $scope.formIsLoaded = true
      })
    }
    $scope.filterActive = function (xs) {
      if (!!xs && !!$scope.selectedProducts) {
        return xs.filter(function (x) {
          return $scope.selectedProducts[x.id] || x.id == 0
        })
      }
    }
    if ($scope.id !== undefined) {
      $scope.save = function () {
        if ($scope.selectedLogo) {
          $upload.upload({
            url: '/api/company/uploadLogo/' + $scope.id,
            file: $scope.selectedLogo,
          })
        }

        $http
          .post('/api/company/edit/' + $scope.id, {
            row: $scope.modelVars,
            products: $scope.selectedProducts,
            campaignCompanyGroups: $scope.selectedCampaignCompanyGroups,
          })
          .success(saveSuccess)
      }
      loadCompany()
      $scope.deactivate = function () {
        $http.post('/api/company/deactivate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Forhandleren blev deaktiveret.')
          loadCompany()
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/company/activate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Forhandleren blev genaktiveret.')
          loadCompany()
          $scope.loadAll()
        })
      }
    } else {
      $scope.selectedProducts = {}
      $scope.selectedCampaignCompanyGroups = {}
      $scope.save = function () {
        $http
          .post('/api/company/add', { row: $scope.modelVars, products: $scope.selectedProducts })
          .success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
