export const registerTinglysningCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.options = {}
    $scope.modelVars = { value: '' }
    $scope.query = function () {
      $scope.tinglysningResult = {
        type: 'wait',
      }
      $http.post('/api/tinglysningLookup/' + $scope.modelVars.value).success(function (res) {
        $scope.tinglysningResult = res
      })
    }
  },
]
