
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/creditAnalysis/creditAnalysisSidebarHead.html";
      const src = "<small>\n  <i\n    ng-class=\"{'fa': true, 'fa-circle': true, 'text-warning': approval.state == 'requested', 'text-success': approval.state == 'approved', 'text-danger': approval.state == 'declined'}\"\n  ></i>\n  {{title}}\n</small>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      