import modalSettingsTemplate from '../templates/modals/settings.html'
export const headerCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$modal',
  '$interop',
  function ($scope, $rootScope, $http, $state, $modal, $interop) {
    $scope.$state = $state
    const userLinks = $interop.specific.headerCtrl.userLinks
    const alternateAccounts = $interop.specific.headerCtrl.alternateAccounts

    //
    $scope.hasAlternateAccounts = () => {
      return alternateAccounts && $rootScope.userInfo && Object.entries($rootScope.userInfo.alternateAccounts).length
    }

    function loadUserInfo(callback) {
      $http.post('/api/getUserInfo', []).then(function (response) {
        $rootScope.userInfo = response.data
        $rootScope.$broadcast('userInfoLoaded')

        $scope.dropdown = [
          {
            text: 'Brugerindstillinger',
            click: 'settings()',
          },
          {
            divider: true,
          },
        ]
        if (userLinks && $rootScope.userInfo.links.length > 0) {
          angular.forEach($rootScope.userInfo.links, function (link) {
            $scope.dropdown.push(link)
          })
          $scope.dropdown.push({
            divider: true,
          })
        }

        if (alternateAccounts) {
          for (const user in $rootScope.userInfo.alternateAccounts) {
            $scope.dropdown.push({
              text: $rootScope.userInfo.alternateAccounts[user],
              click: function () {
                $scope.useAlternateAccount(user)
              },
            })
          }
        }

        $scope.dropdown.push({
          text: 'Log ud',
          click: 'logout()',
        })
        if (callback !== undefined) {
          callback()
        }
      })
    }
    $rootScope.loadUserInfo = loadUserInfo
    loadUserInfo()

    var settingsModal = $modal({ template: modalSettingsTemplate, show: false })

    $scope.settings = function () {
      settingsModal.$promise.then(settingsModal.show)
    }

    $scope.useAlternateAccount = function (id) {
      const loc = window.location
      var url = `${loc.origin}/logon/impersonate/${id}`
      window.location.replace(url)
    }

    $scope.logout = function () {
      $http.post('/api/logout', []).then(function () {
        // redirect til login
        var url = window.location.href.split('/').slice(0, 3).join('/') + '/logon'
        window.location.replace(url)
      })
    }
  },
]
