import creditAnalysisSidebarTemplate from './creditAnalysisSidebar.html'
import { element, IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import angular from 'angular'

interface CreditAnalysisSidebarScope extends IScope {
  positionFixed: boolean | undefined
}

const SCROLL_OFFSET = 235

export const creditAnalysisSidebar = [
  '$window',
  '$timeout',
  function ($window: Window, $timeout: ITimeoutService): IDirective<CreditAnalysisSidebarScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisSidebarTemplate,
      scope: {
        approval: '=',
        contract: '=',
      },

      link: function ($scope: CreditAnalysisSidebarScope, elm: JQLite, attrs: IAttributes) {
        const onWindowEvent = function () {
          $timeout(function () {
            const belowScrollOffset =
              (document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset) >= SCROLL_OFFSET

            const elmHeightLessThanWindowHeight =
              elm[0].getElementsByClassName('credit-analysis-sidebar')[0].clientHeight < $window.innerHeight

            $scope.positionFixed = belowScrollOffset && elmHeightLessThanWindowHeight
          })
        }
        angular.element($window).bind('scroll', onWindowEvent)
        angular.element($window).bind('resize', onWindowEvent)
      },
    }
  },
]
