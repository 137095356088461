import angular, {
  IHttpInterceptor,
  IHttpProvider,
  IHttpResponse,
  IPromise,
  IQService,
  IRequestConfig,
  IRootScopeService,
} from 'angular'

export const myHttpInterceptorConfig = [
  '$httpProvider',
  function myHttpInterceptorConfig($httpProvider: IHttpProvider) {
    $httpProvider.interceptors.push('myHttpInterceptor')
  },
]

export const myHttpInterceptor = [
  '$q',
  '$rootScope',
  function ($q: IQService, $rootScope: IRootScopeService): IHttpInterceptor {
    return {
      request(config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> {
        const [, firstPart] = location.pathname.split('/', 2)
        if (['firstlease', 'comfort', 'lindholm'].includes(firstPart) && location.hostname === 'localhost') {
          if (config.url.startsWith('/')) {
            config.url = `/${firstPart}${config.url}`
          }
        }

        return config
      },
      response<T>(response: IHttpResponse<T>): IPromise<IHttpResponse<T>> | angular.IHttpResponse<T> {
        if (response.headers()['content-type'] === 'application/json; charset=utf-8') {
          if (!response) return $q.reject(response)
        }
        return response
      },
      responseError<T>(rejection: any): IPromise<IHttpResponse<T>> | IHttpResponse<T> {
        if (!('config' in rejection && 'status' in rejection)) {
          return $q.reject(rejection)
        }

        const response: IHttpResponse<T> = rejection

        let pathname = response.config.url

        // do something on error
        //console.log(response.config.url)
        if (
          response.status > 0 &&
          pathname !== '/api/getCity' &&
          pathname.indexOf('/api/application/accept/') !== 0 &&
          pathname.indexOf('/api/application/dowjones/json/') !== 0 &&
          pathname.indexOf('/api/casehub/case/') !== 0 &&
          pathname.indexOf('/api/nav/getContractNo/') !== 0
        ) {
          if ((response.data as any).error) {
            const error = (response.data as any).error
            if (error.exception) {
              $rootScope.$broadcast('httperror', error.exception.description)
            } else {
              const baseError = `An internal error occurred: ${error.message}`
              const withExceptionId = error.id ? `${baseError}.\nException ID: ${error.id}` : baseError
              $rootScope.$broadcast('httperror', withExceptionId)
            }
          } else {
            $rootScope.$broadcast('httperror', response.data)
          }
        }
        return $q.reject(response)
      },
    }
  },
]
