import { errorCount } from '../functions/validationErrors'
export const formGpsProviderCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount

    $scope.variableDefs = {
      name: {
        friendly: 'Firmakode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      caption: {
        friendly: 'Firmanavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      gpsEnhedPrisUdenKoerebog: {
        friendly: 'Startomkostning',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gpsEnhedAbonnementUdenKoerebog: {
        friendly: 'Abonnement pr. måned',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gpsEnhedPrisMedKoerebog: {
        friendly: 'Startomkostning',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gpsEnhedAbonnementMedKoerebog: {
        friendly: 'Abonnement pr. måned',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('GPS-leverandøren blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/gpsProvider/edit/' + res)
      if ($scope.id !== undefined) {
      }
    }

    $scope.modelVars = {}
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var loadGpsProvider = function () {
      $http.post('/api/gpsProvider/get/' + $scope.id).success(function (res) {
        for (var i in res) {
          $scope.modelVars[i] = res[i]
        }

        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.deactivate = function () {
        $http.post('/api/gpsProvider/deactivate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('GPS-leverandøren blev deaktiveret.')
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/gpsProvider/activate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('GPS-leverandøren blev genaktiveret.')
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew) {
        if (saveAsNew) {
          $http.post('/api/gpsProvider/add', $scope.modelVars).success(saveSuccess)
        } else {
          $http.post('/api/gpsProvider/edit/' + $stateParams.id, $scope.modelVars).success(saveSuccess)
        }
      }
      loadGpsProvider()
    } else {
      $scope.modelVars = {
        name: '',
        gpsEnhedPris: 0,
        gpsEnhedAbonnement: 0,
      }

      $scope.save = function () {
        $http.post('/api/gpsProvider/add', $scope.modelVars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
