
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/angularstrap/alternativeDropdown.html";
      const src = "<ul tabindex=\"-1\" class=\"dropdown-menu\" role=\"menu\">\n  <li role=\"presentation\" ng-class=\"{divider: item.divider}\" ng-repeat=\"item in content\">\n    <a\n      role=\"menuitem\"\n      tabindex=\"-1\"\n      pdflink=\"{{item.pdflink}}\"\n      params=\"{{item.params}}\"\n      download-button-state=\"{{item.downloadButtonState}}\"\n      ng-if=\"!item.divider && item.pdflink\"\n      ng-bind=\"item.text\"\n    ></a>\n    <a\n      role=\"menuitem\"\n      tabindex=\"-1\"\n      ng-href=\"{{item.href}}\"\n      ng-if=\"!item.divider && item.href\"\n      target=\"{{item.target}}\"\n      ng-bind=\"item.text\"\n      ng-click=\"$eval(item.click) === false ? $event.stopPropagation() : $hide()\"\n    ></a>\n    <a\n      role=\"menuitem\"\n      tabindex=\"-1\"\n      href=\"javascript:void(0)\"\n      ng-if=\"!item.divider && item.click && !item.href\"\n      ng-click=\"$eval(item.click) === false ? $event.stopPropagation() : $hide()\"\n      ng-bind=\"item.text\"\n    ></a>\n  </li>\n</ul>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      