import { danishLocaleNumber } from '../functions/numbers'
import { dateToYMD } from '../functions/dates'

export const reportSummaryCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  '$timeout',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $state, $timeout, $interop) {
    $scope.modelVars = {
      requestType: '',
      cvrs: '',
      cprs: '',
      vins: '',
    }
    $scope.loading = false

    $scope.summary = null
    $scope.query = null

    $scope.renderAmount = function (value) {
      return danishLocaleNumber(Math.ceil(value))
    }
    $scope.engine = new $interop.ComputationEngine()

    $scope.variableDefs = {
      cvrs: {
        friendly: 'CVR-numre',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      cprs: {
        friendly: 'CPR-numre',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      vins: {
        friendly: 'Stelnumre',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      dueDate: {
        friendly: 'Forfaldsdato',
        datatype: 'date',
        vartype: 'model',
        required: true,
      },
    }
    $scope.split = function (s) {
      if (!s) return []
      return s
        .replace(/\s/g, '')
        .split(',')
        .filter((x) => x != '')
    }

    $scope.dateToYMD = function (date) {
      if (date) {
        return dateToYMD(date)
      } else {
        return ''
      }
    }

    $scope.getSummary = function () {
      $scope.summary = null
      $scope.loading = true
      var reqURL = $scope.modelVars.requestType
      var cvrs = $scope.split($scope.modelVars.cvrs).map((x) => 'cvr=' + x)
      var cprs = $scope.split($scope.modelVars.cprs).map((x) => 'cpr=' + x)
      var vins = $scope.split($scope.modelVars.vins).map((x) => 'vin=' + x)
      var reqLoad =
        $scope.modelVars.requestType === 'dealer'
          ? cvrs.join('&')
          : $scope.modelVars.requestType === 'customer'
          ? cvrs.concat(cprs).join('&')
          : vins.join('&')
      reqLoad += '&dueDate=' + $scope.dateToYMD($scope.modelVars.dueDate)
      $scope.query = reqLoad.replaceAll('&', ', ').replaceAll('=', ': ').toUpperCase()
      $http
        .post('/api/xlsx/summary/' + reqURL + '?' + reqLoad)
        .success(function (res) {
          $timeout(function () {
            console.log(res)
            $scope.loading = false
            $scope.summary = res
          })
        })
        .error(function () {
          $timeout(function () {
            $scope.loading = false
          })
        })
    }

    $scope.options = {}
    $scope.options.requestType = [
      { value: '', option: 'Vælg' },
      { value: 'dealer', option: 'Forhandler' },
      { value: 'customer', option: 'Kunde' },
      { value: 'asset', option: 'Køretøj' },
    ]
  },
]
