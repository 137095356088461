
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/helpmenu.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>\n    Hjælp <a class=\"small pull-right\" href=\"/help\"><i class=\"fa fa-long-arrow-left\"></i> Tilbage til emneoversigten</a>\n  </h3>\n</div>\n\n<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <h3></h3>\n\n    <ul class=\"nav nav-pills nav-stacked\">\n      <li\n        ng-repeat=\"item in list\"\n        ng-class=\"{'active' : activeCategoryId == item.id,  'deactivated': item.deactivated}\"\n      >\n        <a ng-href=\"/help/show/{{item.id}}/{{articleList[item.id][0].id}}\">{{item.name}}</a>\n        <ul\n          ng-show=\"activeCategoryId == item.id && articleList[item.id] !== undefined && (articleList[item.id]).length\"\n        >\n          <li\n            ng-repeat=\"articleItem in articleList[item.id]\"\n            ng-class=\"{'active' : activeId == articleItem.id,  'deactivated': articleItem.deactivated}\"\n          >\n            <a href=\"/help/show/{{item.id}}/{{articleItem.id}}\">{{articleItem.title}}</a>\n          </li>\n        </ul>\n      </li>\n    </ul>\n    <hr />\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      