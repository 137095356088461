import { errorCount } from '../functions/validationErrors'
export const formPrtCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  function ($scope, $rootScope, $http) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}
    $scope.vars = {}
    $scope.vars.interestPeriods = []

    $scope.options = {
      half: [
        { value: 1, option: '1. halvår' },
        { value: 2, option: '2. halvår' },
      ],
    }

    $scope.variableDefs = {
      year: {
        friendly: 'Årstal',
        vartype: 'model',
        datatype: 'digits',
        required: true,
      },
      half: {
        friendly: 'Halvår',
        vartype: 'model',
        datatype: 'count',
        required: true,
      },
      interest: {
        friendly: 'Registreringsafgift',
        vartype: 'model',
        datatype: 'percent',
        required: true,
      },
    }

    $scope.yearHalfUnique = function () {
      var xs = $scope.vars.interestPeriods.map((x) => x.year.toString() + '-' + x.half.toString())

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index
      }
      return xs.filter(onlyUnique).length === xs.length
    }

    $http.post('/api/commonConfig/getPrt').success(function (res) {
      res.forEach((item) =>
        $scope.vars.interestPeriods.push({ year: item.year, half: item.half, interest: item.interest }),
      )
      $scope.formIsLoaded = true
    })

    $scope.addInterestPeriod = function (key) {
      if (key !== undefined) {
        var arrTop = $scope.vars.interestPeriods.slice(key, key + 1).pop()
      } else {
        var arrTop = $scope.vars.interestPeriods.slice(-1).pop()
      }
      var tempDate = new Date()
      var temp = {
        year: tempDate.getFullYear(),
        half: 1,
        interest: 0,
      }
      if (arrTop !== undefined) {
        if (arrTop.half === 2) {
          temp.year = arrTop.year + 1
          temp.half = 1
          temp.interest = arrTop.interest
        } else {
          temp.year = arrTop.year
          temp.half = 2
          temp.interest = arrTop.interest
        }
      }
      if (key !== undefined) {
        $scope.vars.interestPeriods.splice(key + 1, 0, temp)
      } else {
        $scope.vars.interestPeriods.push(temp)
      }
    }
    $scope.removeInterestPeriod = function (key) {
      $scope.vars.interestPeriods.splice(key, 1)
    }

    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Rentesatser for registreringsafgift blev gemt')

      $scope.setKeepAlert(false)
    }

    $scope.save = function () {
      $http.post('/api/commonConfig/savePrt', $scope.vars.interestPeriods).success(saveSuccess)
    }
  },
]
