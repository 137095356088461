
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/digitalSignatureRecipients/companyModal.html";
      const src = "<div class=\"modal-dialog modal-wide\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n      <h4 class=\"modal-title\">Tegningsberettigede til Digital Underskrift</h4>\n    </div>\n    <div class=\"modal-body clearfix\">\n      <div>\n        <div\n          ng-repeat=\"cvr in cvrs\"\n          ptb-company\n          cvr=\"cvr\"\n          persons=\"persons[cvr]\"\n          cvr-metadata=\"cvrMetadata[cvr]\"\n          contract-persons=\"contractPersons\"\n        ></div>\n      </div>\n      <button\n        type=\"button\"\n        class=\"btn btn-success pull-right\"\n        ng-disabled=\"anyErrors()\"\n        ng-click=\"saveSignerCache(); $hide()\"\n      >\n        Gem\n      </button>\n      <button type=\"button\" class=\"btn btn-default pull-left\" data-dismiss=\"modal\" ng-click=\"$hide()\">Tilbage</button>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      