import { REGISTRERINGSNUMMER_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import angular, { IAttributes, IDirective, INgModelController, IScope } from 'angular'
import { ControllerFromStrOrUndef, makeDirective } from '@/shared/util/directiveHelpers'
import { InjectObj } from '@/shared/util/injectTypes'

export const nrKontonr = makeDirective({
  require: 'ngModel',
  link(inject, { ctrl, elem }) {
    ctrl.$parsers.unshift(function (viewValue) {
      viewValue = (viewValue + '').toUpperCase()
      if (REGISTRERINGSNUMMER_REGEXP.test(viewValue) || viewValue == '') {
        // it is valid
        ctrl.$setValidity('registreringsnummer', true)
        return viewValue
      } else {
        ctrl.$setValidity('registreringsnummer', false)
        return undefined
      }
    })
    elem.bind('blur', function () {
      rerenderViewValue(ctrl.$modelValue, ctrl)
    })
  },
})
