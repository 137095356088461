import { IAttributes, IDirective, INgModelController, IScope } from "angular";

interface ValidConditionAttrs extends IAttributes {
  ngValidCondition: string
}

export function ngValidCondition(): IDirective<IScope, JQLite, ValidConditionAttrs, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: ValidConditionAttrs, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      scope.$watch(
        function () {
          return scope.$eval(attrs.ngValidCondition)
        },
        function (currentValue) {
          ctrl.$setValidity('validCondition', currentValue)
        },
      )
    },
  }
}
