
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/popoverFormErrors.html";
      const src = "<div class=\"popover\" style=\"width: 300px\">\n  <div class=\"arrow\"></div>\n  <h3 class=\"popover-title\">\n    <button type=\"button\" class=\"close\" ng-click=\"$hide()\"><span aria-hidden=\"true\">&times;</span></button>\n    Fejl i indtastning\n  </h3>\n  <div class=\"popover-content\">\n    <ul style=\"margin-bottom: 0px; padding-left: 20px\">\n      <li ng-repeat=\"field in errorList(form, this)\">\n        <small>{{ variableDefs[field] ? $interop.friendlyFromDef(variableDefs[field], engine) || field : field}}</small>\n      </li>\n    </ul>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      