export function Broadcaster() {
  this._listeners = []
}

Broadcaster.prototype.register = function (listener) {
  this._listeners.push(listener)

  return function () {
    for (var i in this._listeners) {
      if (this._listeners[i] == listener) {
        this._listeners.splice(i, 1)
        break
      }
    }
  }.bind(this)
}

Broadcaster.prototype.broadcast = function (a) {
  for (var i in this._listeners) {
    this._listeners[i](a)
  }
}
