import { errorCount } from '../functions/validationErrors'
import angular from 'angular'
export const formInterestAdjustmentCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  function ($scope, $rootScope, $http) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}

    $scope.variableDefs = {
      interestAdjustment: {
        friendly: 'Renteændring for alle prisgrupper',
        vartype: 'model',
        datatype: 'percent',
        required: true,
      },
      firmInterest: {
        friendly: 'Fastrente for alle prisgrupper',
        vartype: 'model',
        datatype: 'percent',
        required: true,
      },
    }

    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Renteændring blev gennemført.')

      $scope.setKeepAlert(false)
    }

    $scope.saveInterestAdjustment = function () {
      $http
        .post('/api/interestAdjustment/edit', { interestAdjustment: $scope.modelVars.interestAdjustment })
        .success(saveSuccess)
    }

    $scope.saveFirmInterest = function () {
      $http.post('/api/firmInterest/edit', { firmInterest: $scope.modelVars.firmInterest }).success(saveSuccess)
    }
  },
]
