import { dateToDanish } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'
import angular from 'angular'
import newsletterConfirmTemplate from '../templates/modals/newsletterConfirm.html'

export const formNewsletterCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$window',
  '$modal',
  function ($scope, $rootScope, $http, $stateParams, $location, $window, $modal) {
    $scope.renderTimestamp = function (timestamp) {
      return dateToDanish(new Date(timestamp))
    }

    $scope.showNews = function (item) {
      return !item.deactivated && item.published !== null
    }

    $http.post('/api/news/getAll').success(function (res) {
      $scope.news = res
    })
    $scope.selectedNews = {}

    $scope.errorCount = errorCount

    $scope.variableDefs = {
      subject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      content: {
        friendly: 'Indhold',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      summary: {
        friendly: 'Opsummering',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Nyhedsbrevet blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/newsletter/edit/' + res)
      if ($scope.id !== undefined) {
        loadNewsletter()
      }
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var loadNewsletter = function () {
      $http.post('/api/newsletter/get/' + $scope.id).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }
        $scope.selectedNews = res.news
        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.deactivate = function () {
        $http.post('/api/newsletter/deactivate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev deaktiveret.')
          loadNewsletter()
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/newsletter/activate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev genaktiveret.')
          loadNewsletter()
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew) {
        if (saveAsNew) {
          $http.post('/api/newsletter/add', $scope.vars).success(saveSuccess)
        } else {
          $http.post('/api/newsletter/edit/' + $stateParams.id, $scope.vars).success(saveSuccess)
        }
      }
      loadNewsletter()

      $scope.preview = function () {
        $http.post('/api/newsletter/edit/' + $stateParams.id, $scope.vars).success(function () {
          $window.open('/api/newsletter/preview/' + $stateParams.id, '_blank')
        })
      }
      $scope.sendTestStatus = 0
      $scope.sendTest = function () {
        $scope.sendTestStatus = 1
        $http.post('/api/newsletter/sendTest/' + $stateParams.id, $scope.vars).success(function () {
          $scope.sendTestStatus = 2
        })
      }
      $scope.sendStatus = 0
      $scope.send = function () {
        var subscope = $scope.$new(true)
        subscope.self = subscope
        subscope.updateCompanyReceiveNewsletterStatus = false
        subscope.sendToCompanyId = {}
        $http.post('/api/newsletter/simulate/' + $stateParams.id, $scope.vars).success(function (res) {
          subscope.res = res
          for (var i in res) {
            subscope.sendToCompanyId[res[i].id] = true
          }
        })

        $modal({ template: newsletterConfirmTemplate, show: true, scope: subscope })
        subscope.send = function ($hide) {
          $hide()
          $scope.sendStatus = 1
          var temp = angular.copy($scope.vars)
          temp.sendToCompanyId = subscope.sendToCompanyId
          temp.updateCompanyReceiveNewsletterStatus = subscope.updateCompanyReceiveNewsletterStatus
          $http
            .post('/api/newsletter/send/' + $stateParams.id, temp)
            .success(function () {
              $scope.sendStatus = 2
            })
            .error(function () {
              $scope.sendStatus = 0
            })
        }
      }
    } else {
      $scope.vars = {
        news: $scope.selectedNews,
      }
      $scope.vars.row = {
        subject: '',
        content: '',
      }

      $scope.save = function () {
        $http.post('/api/newsletter/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
