import type {
  IAttributes,
  IController,
  IDirective,
  IDirectiveFactory,
  IFormController,
  INgModelController,
  Injectable,
  IScope
} from 'angular'
import type { InjectDescription, InjectObj, InjectTypesMap } from '@/shared/util/injectTypes'

export interface MapControllers {
  form: IFormController
  ngModel: INgModelController
}

export type ControllerFromStrOrUndef<Ctrl extends keyof MapControllers | undefined> = [Ctrl] extends [undefined]
  ? IController | undefined
  : MapControllers[Ctrl & keyof MapControllers]

//Modeled on Vue's prop stuff
export type ScopeType<T> = { new (...args: any[]): T & {} }

export interface OneWayStrBinding {
  type: 'oneWayStr' | 'dom'
}

export interface BidirectionalBinding {
  type: 'twoWay' | 'bidirectional'
  shallow?: boolean
}

export interface OnewayBinding {
  type: 'oneWay'
  shallow?: boolean
}

export interface FunctionBinding {
  type: 'function'
}

export type Binding = OneWayStrBinding | BidirectionalBinding | OnewayBinding | FunctionBinding

export interface ScopeDescription<T> {
  type: ScopeType<T>
  attr?: string
  binding: Binding | Binding['type']
  optional?: boolean
  //TODO: Validate and add default later
  //default?: T
}

export type InScope<Scope> = { [Key in keyof Scope]: ScopeDescription<Scope[Key]> }

export interface DescribeType<Tpe> {}
export function assignScope<AssignScope extends object>(): DescribeType<AssignScope> {
  return {}
}

export function attrs<Attrs extends object>(): DescribeType<Attrs> {
  return {}
}

export interface DirectiveDescription<
  Inject extends InjectDescription,
  InScopeType extends { [name: string]: unknown },
  AssignScope,
  Attrs,
  RequiredController extends keyof MapControllers | undefined,
  Elem extends JQLite,
> {
  inject?: Inject
  require?: RequiredController
  scope?: InScope<InScopeType> | boolean
  assignScope?: DescribeType<AssignScope>
  attrs?: DescribeType<Attrs>
  restrict?: ('element' | 'attribute' | 'class' | 'comment')[]
  template?: string
  linkAssign?(
    inject: InjectObj<Inject>,
    args: {
      scope: AssignScope & InScopeType & IScope
      attrs: Attrs
      iAttributes: IAttributes
      ctrl: ControllerFromStrOrUndef<RequiredController>
      elem: Elem
    },
  ): Partial<AssignScope>
  link?(
    inject: InjectObj<Inject>,
    args: {
      scope: AssignScope & InScopeType & IScope
      attrs: Attrs
      iAttributes: IAttributes
      ctrl: ControllerFromStrOrUndef<RequiredController>
      elem: Elem
    },
  ): void
  extraInjectProps?: Omit<
    IDirective<
      AssignScope & InScopeType & IScope,
      Elem,
      Attrs & IAttributes,
      ControllerFromStrOrUndef<RequiredController> & IController
    >,
    'require' | 'link' | 'scope' | 'restrict' | 'template'
  >
}

export function makeDirective<
  Inject extends InjectDescription,
  InScopeType extends { [name: string]: unknown },
  AssignScope,
  Attrs,
  RequiredController extends keyof MapControllers | undefined,
  Elem extends JQLite,
>(
  directiveDescription: DirectiveDescription<Inject, InScopeType, AssignScope, Attrs, RequiredController, Elem>,
): Injectable<
  IDirectiveFactory<
    AssignScope & InScopeType & IScope,
    Elem,
    Attrs & IAttributes,
    ControllerFromStrOrUndef<RequiredController> & IController
  >
> {
  const injectDescription = directiveDescription.inject
  const injectArr = injectDescription ? Object.values(injectDescription) : []

  const restrict = (directiveDescription.restrict ?? ['element', 'attribute'])
    .map((r) => {
      switch (r) {
        case 'element':
          return 'E'
        case 'attribute':
          return 'A'
        case 'class':
          return 'C'
        case 'comment':
          return 'M'
      }
    })
    .join('')

  let scope: boolean | Record<string, any>
  if (typeof directiveDescription.scope !== 'boolean') {
    scope = {}
    for (const scopeKey in directiveDescription.scope) {
      const scopeDesc = directiveDescription.scope[scopeKey]

      let firstPart
      let optionalPart = scopeDesc.optional ? '?' : ''
      let shallowPart = ''

      switch (typeof scopeDesc.binding === 'string' ? scopeDesc.binding : scopeDesc.binding.type) {
        case 'oneWayStr':
        case 'dom':
          firstPart = '@'
          break
        case 'bidirectional':
        case 'twoWay':
          firstPart = '='
          break
        case 'oneWay':
          firstPart = '<'
          break
        case 'function':
          firstPart = '&'
          break
      }

      if (typeof scopeDesc.binding === 'object' && 'shallow' in scopeDesc.binding && scopeDesc.binding.shallow) {
        shallowPart = '*'
      }

      scope[scopeKey] = `${firstPart}${shallowPart}${optionalPart}${scopeDesc.attr ?? ''}`
    }
  } else {
    scope = directiveDescription.scope
  }

  return [
    ...injectArr,
    function (
      ...injects
    ): IDirective<
      AssignScope & InScopeType & IScope,
      Elem,
      Attrs & IAttributes,
      ControllerFromStrOrUndef<RequiredController> & IController
    > {
      const injectObj = {} as InjectObj<Inject>
      for (const injectObjKey in injectDescription) {
        if (injectDescription.hasOwnProperty(injectObjKey)) {
          injectObj[injectObjKey] = injects[injectArr.indexOf(injectDescription[injectObjKey])]
        }
      }

      return {
        restrict,
        template: directiveDescription.template,
        require: directiveDescription.require,
        scope,
        link(scope, elem, attrs, controller) {
          if (directiveDescription.require && !controller) {
            throw new Error('Controller was undefined when it should not be')
          }

          const argsObj = {
            scope,
            attrs,
            iAttributes: attrs,
            ctrl: controller as ControllerFromStrOrUndef<RequiredController>,
            elem,
          }

          if (directiveDescription.linkAssign) {
            Object.assign(scope, directiveDescription.linkAssign(injectObj, argsObj))
          }

          if (directiveDescription.link) {
            directiveDescription.link(injectObj, argsObj)
          }
        },
        ...(directiveDescription.extraInjectProps ?? {}),
      }
    },
  ]
}
