import { assignScope, makeDirective, type ScopeType } from '@/shared/util/directiveHelpers'
import { danishLocaleNumber } from '@/shared/functions/numbers'
import creditAnalysisNetIncomeTemplate from './creditAnalysisNetIncome.html'
export const creditAnalysisNetIncome = makeDirective({
  template: creditAnalysisNetIncomeTemplate,
  scope: {
    values: {
      type: Object as unknown as ScopeType<{
        netIncomeInterest: number
        netIncomeInsurance: number
        netIncomeAdministrationFee: number
        netIncomePVFees: number
        netIncomeTotal: number
        netIncomeTotalPerMonth: number
      }>,
      binding: 'twoWay',
    },
  },
  assignScope: assignScope<{
    netIncomeInterest: number
    netIncomeInsurance: number
    administrationsgebyr: number
    netIncomeAdministrationFee: number
    netIncomePVFees: number
    netIncomeTotal: number
    netIncomeTotalPerMonth: number
    renderStaticAmount: (n: number) => string
  }>(),
  link({}, { scope }) {
    scope.$watch('values', () => {
      scope.netIncomeInterest = scope.values.netIncomeInterest
      scope.netIncomeInsurance = scope.values.netIncomeInsurance
      scope.netIncomeAdministrationFee = scope.values.netIncomeAdministrationFee
      scope.netIncomePVFees = scope.values.netIncomePVFees
      scope.netIncomeTotal = scope.values.netIncomeTotal
      scope.netIncomeTotalPerMonth = scope.values.netIncomeTotalPerMonth
    })

    scope.renderStaticAmount = function (value) {
      return 'kr ' + danishLocaleNumber(String(Math.ceil(value)))
    }
  },
})
