import creditAnalysisOwnersTemplate from './creditAnalysisOwners.html'
import { IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import { formatPercent } from '../functions/intlFormaters'

interface CreditAnalysisOwnersScope extends IScope {
  formatPercent: typeof formatPercent
}

export const creditAnalysisOwners = [
  function (): IDirective<CreditAnalysisOwnersScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisOwnersTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        addOwner: '=',
        buttonState: '=',
      },

      link: function ($scope: CreditAnalysisOwnersScope, elm: JQLite, attrs: IAttributes) {
        $scope.formatPercent = formatPercent
      },
    }
  },
]
