import { errorCount } from '../functions/validationErrors'
import { IHttpResponse, type IHttpService, type ILocationService, IScope } from 'angular'

interface FormServiceLevelScope extends IScope {
  loadAll: () => void
  setGlobalAlert: (s: string) => void
  setKeepAlert: (b: boolean) => void
  id: number | undefined
  setActiveId: (id: number | undefined) => void

  formIsLoaded: boolean

  deactivate: () => void
  activate: () => void
  save: (saveAsNew: boolean) => void


  errorCount: typeof errorCount,
  variableDefs: {[k: string]: computationEngine.VariableDef},
  modelVars: any
}

export const formServiceLevelCtrl = [
  '$scope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope: FormServiceLevelScope, $http: IHttpService, $stateParams: {id?: string}, $location: ILocationService) {
    $scope.errorCount = errorCount

    $scope.variableDefs = {
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      pricePerKm: {
        friendly: 'Pris pr. km',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
    }
    var saveSuccess = function (res: IHttpResponse<string>) {
      $scope.loadAll()
      $scope.setGlobalAlert('Serviceaftalen blev gemt.')
      if (parseInt(res.data, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/serviceLevel/edit/' + res.data)
      if ($scope.id !== undefined) {
      }
    }
    console.log('A')

    $scope.modelVars = {}
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var loadServiceLevel = function () {
      console.log('B')
      $http.post<any[]>('/api/serviceLevel/get/' + $scope.id, {}).then(function (res) {
        for (var i in res.data) {
          $scope.modelVars[i] = res.data[i]
        }
        console.log('C')

        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      console.log('D')
      $scope.deactivate = function () {
        console.log('E')
        $http.post('/api/serviceLevel/deactivate/' + $scope.id, {}).then(function () {
          console.log('F')
          $scope.setGlobalAlert('Serviceaftalen blev deaktiveret.')
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        console.log('G')
        $http.post('/api/serviceLevel/activate/' + $scope.id, {}).then(function () {
          console.log('H')
          $scope.setGlobalAlert('Serviceaftalen blev genaktiveret.')
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew: boolean) {
        console.log('I')
        if (saveAsNew) {
          $http.post<string>('/api/serviceLevel/add', $scope.modelVars).then(saveSuccess)
        } else {
          $http.post<string>('/api/serviceLevel/edit/' + $stateParams.id, $scope.modelVars).then(saveSuccess)
        }
      }
      loadServiceLevel()
    } else {
      console.log('J')
      $scope.modelVars = {
        name: '',
        pricePerKm: 0,
      }

      $scope.save = function () {
        $http.post<string>('/api/serviceLevel/add', $scope.modelVars).then(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
