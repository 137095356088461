import { validCpr } from '../functions/cpr'
import { personCallback } from '../functions/cvrcprLookupMappings'
import { IHttpService, INgModelController, IScope } from "angular";

interface CprAttrs extends IScope {
  config: string
}

export const ngCpr = [
  '$http',
  function ($http: IHttpService) {
    return {
      require: 'ngModel',

      link(scope: IScope, elm: JQLite, attrs: CprAttrs, ctrl: INgModelController) {
        const config = scope.$eval(attrs.config)
        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = viewValue + ''

          var valid = validCpr(viewValue)
          if (valid) {
            // it is valid
            ctrl.$setValidity('cpr', true)
            if (config) {
              $http.post(`/api/registration/personAddress/${viewValue}`, {}).then(function (res) {
                personCallback(scope, res.data, config)
              })
            }
            return viewValue
          } else if (viewValue == '') {
            ctrl.$setValidity('cpr', true)
            return ''
          } else {
            ctrl.$setValidity('cpr', false)
            return undefined
          }
        })
      },
    }
  },
]
