
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/newDMRContract.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Ny DMR-kontrakt</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-info\">\n          Bilen skal genvurderes, og afgiftsvurderingen indtastes. Rentesats skal justeres, hvis referencerenten er\n          ændret, og kontrakten er med variabel rente.\n        </div>\n\n        <form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n          <div ng-autofield=\"loebetid\"></div>\n          <div ng-autofield=\"ydelse\"></div>\n          <div ng-autofield=\"rentesats\"></div>\n          <div ng-autofield=\"vurderetRegAfgift\"></div>\n          <div ng-autofield=\"fakturaprisExMoms\"></div>\n\n          <button class=\"btn btn-primary pull-right\"><i class=\"fa fa-star\"></i> &nbsp; Opret beregning</button>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      