import { errorCount } from '../functions/validationErrors'
export const headerUserSettingsCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$modal',
  function ($scope, $rootScope, $http, $state, $modal) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}

    $scope.variableDefs = {
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      mail: {
        friendly: 'E-mail adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      phone: {
        friendly: 'Telefon',
        datatype: 'digits',
        vartype: 'model',
        required: true,
      },
    }

    function loadForm() {
      $rootScope.loadUserInfo(function () {
        $scope.modelVars.name = $scope.userInfo.name
        $scope.modelVars.mail = $scope.userInfo.mail
        $scope.modelVars.phone = $scope.userInfo.phone

        $scope.formIsLoaded = true
      })
    }

    loadForm()

    $scope.save = function () {
      $http.post('/api/setUserInfo', $scope.modelVars).success(function () {
        $scope.successMsg = 'Informationerne blev opdateret'
        loadForm()
      })
    }
  },
]
