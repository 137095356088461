export const PERCENTAGE_REGEXP = /^\-?\d+(,\d{1,4})?$/
export const INTEGER_REGEXP = /^\-?\d+$/
export const DIGITS_REGEXP = /^\d+$/
export const LEASINGKONTRAKTNR_REGEXP = /^6\d{5}$/
export const YEAR_REGEXP = /^\d{4}$/
export const POSTNR_REGEXP = /^\d{4}$/
export const TELEFON_REGEXP = /^\d{8}$/
export const CVR_REGEXP = /^\d{8}$/
export const CPR_REGEXP = /^\d{10}$/
export const REGISTRERINGSNUMMER_REGEXP = /^[\da-zæøåA-ZÆØÅ ]{1,7}$/
export const STELNUMMER_REGEXP = /^([\da-np-zA-NP-Z]{17})$/
export const STELNUMMER_UDSTYR_REGEXP = /^([\da-np-zA-NP-Z]+)$/
export const BANKREGNR_REGEXP = /^\d{4}$/
export const BANKKONTONR_REGEXP = /^\d{7,11}$/
