import { errorCount } from '../functions/validationErrors'
export const formPenneoCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  function ($scope, $rootScope, $http) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}

    $scope.variableDefs = {
      penneoReminderInterval: {
        friendly: 'Dage mellem påmindelser',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      penneoEmailSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      penneoReminderEmailSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      penneoCompletedEmailSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      penneoEmailText: {
        friendly: 'Penneo - Udsendelse af kontrakt',
        datatype: 'text',
        vartype: 'model',
      },
      penneoReminderEmailText: {
        friendly: 'Penneo - Reminder',
        datatype: 'text',
        vartype: 'model',
      },
      penneoCompletedEmailText: {
        friendly: 'Penneo - Underskrift modtaget',
        datatype: 'text',
        vartype: 'model',
      },
    }
    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Mailskabeloner blev gemt.')

      $scope.setKeepAlert(false)
    }

    $scope.save = function () {
      $http.post('/api/commonConfig/edit', $scope.modelVars).success(saveSuccess)
    }
    $http.post('/api/commonConfig/get').success(function (res) {
      $scope.modelVars = res
      $scope.formIsLoaded = true
    })
  },
]
