
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/company.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>Stamdata og brugere</h3>\n</div>\n\n<ul class=\"nav nav-tabs\">\n  <li ng-class=\"{'active' : $state.includes('root.company.user')}\"><a href=\"/company/user\">Brugere</a></li>\n  <li ng-if=\"userInfo.isSuperAdmin\" ng-class=\"{'active' : $state.includes('root.company.edit')}\">\n    <a href=\"/company/edit\">Stamdata</a>\n  </li>\n</ul>\n\n<div ui-view></div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      