import ultimateOwnersTemplate from '../templates/ultimate-owners.html'

export const dkkapUltimateOwners = [
  '$http',
  '$rootScope',
  function ($http, $rootScope) {
    return {
      restrict: 'A',
      template: ultimateOwnersTemplate,
      scope: {
        applicationId: '=',
        modelVars: '=',
      },
      link: function ($scope, elm, attrs) {
        $scope.ownerships = []
        $scope.currentCvr = null
        $scope.knownCPRs = {}
        $scope.typedOwners = []
        $scope.ownershipRequireId = {}

        var ownersCPRKeys = [
          { cpr: 'ejerCpr1', name: 'ejerNavn1' },
          { cpr: 'ejerCpr2', name: 'ejerNavn2' },
          { cpr: 'ejerCpr3', name: 'ejerNavn3' },
          { cpr: 'ejerCpr4', name: 'ejerNavn4' },
        ]
        var knownCPRKeys = [
          { cpr: 'cpr', name: 'navn' },
          { cpr: 'cpr2', name: 'navn2' },
          { cpr: 'anviserCpr', name: 'anviserNavn' },
          { cpr: 'kautionistCpr', name: 'kautionistNavn' },
          { cpr: 'kautionist2Cpr', name: 'kautionist2Navn' },
        ].concat(ownersCPRKeys)

        function getRequestObject() {
          return {
            cvr: $scope.modelVars.cvr,
            name: $scope.modelVars.navn,
            applicationId: $scope.applicationId ? $scope.applicationId : null,
          }
        }

        function getKnownCPRs() {
          var out = {}
          for (var i in knownCPRKeys) {
            var cprKey = knownCPRKeys[i].cpr
            var nameKey = knownCPRKeys[i].name
            if ($scope.modelVars[cprKey] && $scope.modelVars[nameKey] && !out[$scope.modelVars[nameKey]]) {
              out[$scope.modelVars[nameKey]] = $scope.modelVars[cprKey]
            }
          }

          return out
        }
        /*
                  function loadApplicationOwnershipsCB() {
                    if ($scope.applicationId) {
                      $http.post('/api/ownership/getOwnerships/' + $scope.applicationId + '/0').success(function(res) {
                        for (var i in res) {
                          $scope.ownershipRequireId[getOwnershipKey(res[i])] = res[i].requireIdOverride
                        }
                      })
                    }
                  }
            */
        function loadCvrOwnershipsCB(force) {
          if ($scope.modelVars.cvr == $scope.currentCvr && !force) return
          if ($scope.modelVars.cvr) {
            $scope.currentCvr = $scope.modelVars.cvr
            $scope.ownerships = []
            $http.post('/api/ownership/getOwnershipsForCvr', getRequestObject()).success(function (res) {
              $scope.ownerships = res
              for (var i in res) {
                var key = getOwnershipKey(res[i])
                $scope.ownershipRequireId[key] = res[i].requireIdOverride
              }
              updateTypedOwners()
            })
          }
        }

        function updateTypedOwners() {
          // de personer som ikke er nævnt i "ownerships"-array
          var isAutomatic = {}
          for (var i in $scope.ownerships) {
            isAutomatic[$scope.ownerships[i].name] = true
          }
          var out = []
          for (var i in ownersCPRKeys) {
            var o = ownersCPRKeys[i]
            //console.log(o, $scope.modelVars[o.name])
            if ($scope.modelVars[o.name]) {
              var temp = {
                cprCvr: $scope.modelVars[o.cpr] ? $scope.modelVars[o.cpr] : null,
                name: $scope.modelVars[o.name],
                automatic: false,
                requireId: true,
                isCompany: $scope.modelVars[o.cpr] && $scope.modelVars[o.cpr].length == 8,
              }
              //console.log($scope.modelVars[o.name], isAutomatic)
              if (!isAutomatic[$scope.modelVars[o.name]]) {
                out.push(temp)
              }
              var key = getOwnershipKey(temp)
              if ($scope.ownershipRequireId[key] === undefined) {
                $scope.ownershipRequireId[key] = true
              }
            }
          }
          $scope.typedOwners = out
        }

        function getTypedOwnerNames() {
          var out = []
          for (var i in ownersCPRKeys) {
            var o = ownersCPRKeys[i]
            out.push($scope.modelVars[o.cpr])
            out.push($scope.modelVars[o.name])
          }
          return out
        }

        function getOwnershipKey(ownership) {
          return JSON.stringify({ cpr: ownership.cprCvr, name: ownership.name })
        }

        function updateRequireIdOverride(name, value) {
          if ($scope.modelVars.cvr) {
            $http.post('/api/ownership/updateRequireIdOverride', {
              cvr: $scope.modelVars.cvr,
              name: name,
              requireIdOverride: value,
            })
          }
        }

        $scope.updateRequireIdOverride = updateRequireIdOverride
        $scope.getOwnershipKey = getOwnershipKey

        $scope.$watch(
          function () {
            return JSON.stringify(getKnownCPRs())
          },
          function (res) {
            $scope.knownCPRs = getKnownCPRs()
          },
        )

        $scope.$watch(function () {
          return JSON.stringify(getTypedOwnerNames())
        }, updateTypedOwners)

        //$scope.$watch('applicationId', loadApplicationOwnershipsCB)
        $scope.$watch('modelVars.cvr', function () {
          loadCvrOwnershipsCB()
        })
        $scope.$on('formCtrl.save.success', function () {
          loadCvrOwnershipsCB(true)
        })
      },
    }
  },
]
