import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngUppercase(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = (viewValue + '').toUpperCase()

        // it is valid
        ctrl.$setValidity('uppercase', true)
        return viewValue
      })
      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
