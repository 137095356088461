import { errorCount } from '../functions/validationErrors'
export const headerUserPasswordCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$modal',
  function ($scope, $rootScope, $http, $state, $modal) {
    $scope.errorCount = errorCount
    $scope.password = ''
    $scope.repeatPassword = ''
    $scope.formIsLoaded = true

    $scope.save = function () {
      $scope.successMsg = ''
      $scope.errorMsg = ''
      if ($scope.password == '') {
        $scope.errorMsg = 'Du skal taste et kodeord.'
      } else {
        $http.post('/api/setPassword', { password: $scope.password }).success(function () {
          $scope.successMsg = 'Dit kodeord blev ændret'
          $scope.password = ''
          $scope.repeatPassword = ''
        })
      }
    }
  },
]
