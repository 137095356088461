import { errorCount } from '../functions/validationErrors'
import angular from 'angular'
export const formSelvanmelderCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$q',
  '$interop',
  function ($scope, $rootScope, $http, $q, $interop) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}
    $scope.cityVars = {}
    $scope.products = []

    $scope.options = $interop.specific.formSelvanmelderCtrl.options

    $scope.variableDefs = {
      selvanmelderDemobil: {
        friendly: 'Demobil',
        vartype: 'model',
        required: true,
      },
      selvanmelderLavHovedstol: {
        friendly: 'Lav hovedstol',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['dk'],
        },
      },
      selvanmelderLavHovedstolGraense: {
        friendly: 'Lav hovedstol er følgende og under',
        vartype: 'model',
        datatype: 'amount',
        required: true,
        fieldConditions: {
          requiredModule: ['dk'],
        },
      },
      selvanmelderLavLoebetid: {
        friendly: 'Lav løbetid',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['dk'],
        },
      },
      selvanmelderLavLoebetidGraense: {
        friendly: 'Lav løbetid er følgende og under',
        vartype: 'model',
        datatype: 'count',
        required: true,
        fieldConditions: {
          requiredModule: ['dk'],
        },
      },
    }

    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Selvanmelder indstillinger blev gemt.')

      $scope.setKeepAlert(false)
    }

    $scope.save = function () {
      $http.post('/api/selvanmelder/edit', $scope.modelVars).success(saveSuccess)
    }

    var commonConfigP = $http.post('/api/commonConfig/get')
    var productsP = $http.post('/api/product/getAll')

    $q.all([commonConfigP, productsP]).then(function (pair) {
      var commonConfig = pair[0].data
      var products = pair[1].data

      var temp = {
        selvanmelderProdukt: {},
      }

      //Some of these values in commonConfig might not exist depending on the instance. That's fine
      temp.selvanmelderDemobil = commonConfig.selvanmelderDemobil
      temp.selvanmelderLavHovedstol = commonConfig.selvanmelderLavHovedstol
      temp.selvanmelderLavHovedstolGraense = commonConfig.selvanmelderLavHovedstolGraense
      temp.selvanmelderLavLoebetid = commonConfig.selvanmelderLavLoebetid
      temp.selvanmelderLavLoebetidGraense = commonConfig.selvanmelderLavLoebetidGraense

      angular.forEach(products, function (product) {
        if (product.isLeasing) {
          temp.selvanmelderProdukt[product.id] = product.selvanmelderProdukt
        }
      })

      $scope.products = products
      $scope.modelVars = temp
    })
  },
]
