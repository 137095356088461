import { type IHttpService, type ILocationService, type IRootScopeService, type IScope } from 'angular'
import { type IStateService } from 'ui-router'

interface ControlpanelServiceLevelScope extends IScope {
  addurl: string
  editurl: string
  loadAll: () => void
  list: any[]
  setActiveId: (id: number) => void
  activeId: number
}

export const controlpanelServiceLevelCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$location',
  function (
    $scope: ControlpanelServiceLevelScope,
    $rootScope: IRootScopeService & { common: any },
    $http: IHttpService,
    $state: IStateService,
    $location: ILocationService,
  ) {
    $scope.addurl = '/controlpanel/serviceLevel/add'
    $scope.editurl = '/controlpanel/serviceLevel/edit'
    $scope.loadAll = function () {
      $http.post<any[]>('/api/serviceLevel/getAll', {}).then(function (res) {
        $scope.list = res.data
        //console.log($scope.list)
        if ($state.is('root.controlpanel.serviceLevel.index')) {
          if ($scope.list.length > 0) {
            $location.url('/controlpanel/serviceLevel/edit/' + $scope.list[0].id)
          } else {
            $location.url('/controlpanel/serviceLevel/add')
          }
        }
      })
    }

    $scope.loadAll()

    $scope.setActiveId = function (id) {
      $scope.activeId = id
    }
    $rootScope.common = {}
  },
]
