
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/serviceLevel.html";
      const src = "<h3></h3>\n<form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n  <fieldset>\n    <legend>{{id === undefined ? \"Opret\" : \"Ret\"}} Serviceaftale</legend>\n\n    <div ng-autofield=\"name\"></div>\n    <div ng-autofield=\"pricePerKm\"></div>\n  </fieldset>\n  <hr />\n\n  <a ng-click=\"deactivate()\" class=\"btn btn-danger pull-left\" ng-show=\"id !== undefined && !modelVars.deactivated\">\n    <i class=\"fa fa-trash-o\"></i> &nbsp; Deaktivér serviceaftale\n  </a>\n  <a ng-click=\"activate()\" class=\"btn btn-success pull-left\" ng-show=\"id !== undefined && modelVars.deactivated\">\n    <i class=\"fa fa-star-o\"></i> &nbsp; Genaktivér serviceaftale\n  </a>\n\n  <div class=\"btn-group pull-right\">\n    <a class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\"\n      ><i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl</a\n    >\n    <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" ng-click=\"save()\"\n      ><i class=\"fa fa-save\"></i> &nbsp; Gem</a\n    >\n  </div>\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      