import { BANKREGNR_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes, INgModelController, IScope } from "angular";

export function ngBankRegnr() {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl: INgModelController) {
      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = (viewValue + '').toUpperCase()
        if (BANKREGNR_REGEXP.test(viewValue) || viewValue == '') {
          // it is valid
          ctrl.$setValidity('bankRegnr', true)
          return viewValue
        } else {
          ctrl.$setValidity('bankRegnr', false)
          return undefined
        }
      })
      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
