export const helpMenuCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $http.post('/api/article/getActiveUserArticles').success(function (res) {
      $scope.list = res.categories
      $scope.articleList = res.articles
    })

    $scope.setActiveCategoryId = function (id) {
      $scope.categoryId = id
      $scope.activeCategoryId = id
      // do nothing -- til brug for companyGroup niveau
    }
    $scope.setActiveId = function (id) {
      $scope.id = id
      $scope.activeId = id
    }
  },
]
