
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/editInfotext.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Ret feltbeskrivelse ({{variableDefs[field].friendly}})</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-success\" ng-show=\"successMsg\" ng-bind=\"successMsg\"></div>\n\n        <form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n          <textarea class=\"form-control\" ng-model=\"description\" placeholder=\"Kort vejledning til feltet\"></textarea>\n          <hr />\n          <a class=\"btn btn-primary pull-right\" ng-click=\"setInfotext(field, description); $hide()\"\n            ><i class=\"fa fa-check\"></i> &nbsp; Gem</a\n          >\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      