import { Formula } from '@/computationengine/formula'
import { getDieselAddon, getPeriodicTax } from '@/computationengine/PeriodicTax'
import type { ComputationEngineLogger } from '@/computationengine/logger'

function getYearDate(year: number) {
  const date = new Date()
  date.setFullYear(year, 0, 1)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

function getFoersteBetalingDefaultValue(isLeasingProduct: boolean, d?: Date) {
  if (d === undefined) {
    d = new Date()
  }
  let year = d.getFullYear()
  let month = d.getMonth()
  if (d.getDate() <= 10 || isLeasingProduct) {
    month += 1
  } else {
    month += 2
  }
  if (month >= 12) {
    month = month % 12
    year++
  }
  return new Date(year, month, 1, 0, 0, 0, 0)
}

function nextFirstOfMonth(d?: Date) {
  if (d === undefined) {
    d = new Date()
  }
  let year = d.getFullYear()
  let month = d.getMonth()
  if (d.getDate() == 1) {
    return d
  }
  month += 1
  if (month >= 12) {
    month = month % 12
    year++
  }
  return new Date(year, month, 1, 0, 0, 0, 0)
}

function firstOfMonth(d?: Date) {
  if (d === undefined) {
    d = new Date()
  }
  const year = d.getFullYear()
  const month = d.getMonth()
  return new Date(year, month, 1, 0, 0, 0, 0)
}

const CPR_REGEXP = /^\d{10}$/

function cprBirthday(viewValue: string) {
  const valid = CPR_REGEXP.test(viewValue)
  if (valid) {
    const day = parseInt(viewValue.substring(0, 2), 10)
    const month = parseInt(viewValue.substring(2, 4), 10)
    const year = parseInt(viewValue.substring(4, 6), 10)
    const datetest = new Date()
    datetest.setFullYear(1900 + year, month - 1, day)
    const valid1 =
      datetest.getFullYear() == 1900 + year && datetest.getMonth() == month - 1 && datetest.getDate() == day
    if (valid1) {
      return datetest
    }
  }
  return new Date()
}

// noinspection UnnecessaryLocalVariableJS,JSUnusedGlobalSymbols
export function createFunctions(
  logger: ComputationEngineLogger,
  serverVars?: computationEngine.ServerVars,
  getUserId?: () => number | undefined,
  isSuperAdmin?: () => boolean | undefined,
  splitInsuranceCompanyId?: () => number | undefined,
  getHasAutoItCase?: () => boolean
) {
  const d = new Date()
  const today = new Date(d.getFullYear(), d.getMonth(), d.getDate())

  return {
    bstart(loebetid: number, alderMdr: number) {
      if (alderMdr >= 36) return loebetid
      else {
        return Math.max(0, Math.min(loebetid, 36 - alderMdr))
      }
    },
    bslut(loebetid: number, alderMdr: number) {
      return loebetid - this.bstart(loebetid, alderMdr)
    },
    validateEmail(email: string) {
      if (email === undefined) return true

      const parts = (email + '').split('@')
      return parts.length == 2 && parts[0].length > 0 && parts[1].length > 0
    },
    getForsikringAarligPraemie(forsikringPosition: number) {
      const temp = serverVars.forsikringAarligPraemie[forsikringPosition]
      if (temp === undefined) {
        return 0
      } else {
        return temp
      }
    },
    getGpsEnhedAbonnement(firmakode: string, medKoerebog: boolean) {
      const base = serverVars.gpsEnhedAbonnement[firmakode]
      return base && base[String(medKoerebog)]
    },
    getGpsEnhedPris(firmakode: string, medKoerebog: boolean) {
      const base = serverVars.gpsEnhedPris[firmakode]
      return base && base[String(medKoerebog)]
    },
    getGpsFirmanavn(firmakode: string) {
      return serverVars.gpsFirmanavn[firmakode]
    },
    //For non core applications that only have one variant
    maxRentesats(companyGroupId: number, productId: number) {
      if (serverVars.rentesats !== undefined) {
        if (serverVars.rentesats[companyGroupId] !== undefined) {
          if (serverVars.rentesats[companyGroupId][productId] !== undefined) {
            const maxAge = Math.max.apply(
              null,
              Object.keys(serverVars.rentesats[companyGroupId][productId]) as any as number[]
            )
            return serverVars.rentesats[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    maxRentesatsAge(companyGroupId: number, productId: number) {
      if (serverVars.rentesatsAge !== undefined) {
        if (serverVars.rentesatsAge[companyGroupId] !== undefined) {
          if (serverVars.rentesatsAge[companyGroupId][productId] !== undefined) {
            const maxAge = Math.max.apply(
              null,
              Object.keys(serverVars.rentesatsAge[companyGroupId][productId]) as any as number[]
            )
            return serverVars.rentesatsAge[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    maxRentesatsPrice(companyGroupId: number, productId: number) {
      if (serverVars.rentesatsPrice !== undefined) {
        if (serverVars.rentesatsPrice[companyGroupId] !== undefined) {
          if (serverVars.rentesatsPrice[companyGroupId][productId] !== undefined) {
            const maxAge = Math.max.apply(
              null,
              Object.keys(serverVars.rentesatsPrice[companyGroupId][productId]) as any as number[]
            )
            return serverVars.rentesatsPrice[companyGroupId][productId][maxAge]
          }
        }
      }
      return NaN
    },
    if<A>(condition: boolean | number | string, a: A, b: A) {
      return condition === 1 || condition === '1' || condition === true ? a : b
    },
    isUndefined<A>(a: A | undefined) {
      return a === undefined
    },
    getUserId() {
      if (getUserId !== undefined) return getUserId()
      return undefined
    },
    isSuperAdmin() {
      if (isSuperAdmin !== undefined) return isSuperAdmin()
      return false
    },
    splitInsuranceCompanyId() {
      if (splitInsuranceCompanyId !== undefined) {
        return splitInsuranceCompanyId()
      }
      return null
    },
    getToday() {
      return today
    },
    getYear(d: Date) {
      return d.getFullYear()
    },
    getDay(d: Date) {
      return d.getDate()
    },
    getDefaultProductId() {
      const userId = getUserId && getUserId()
      return userId && serverVars.defaultProductId[userId]
    },
    getHasAutoItCase() {
      return (getHasAutoItCase ? getHasAutoItCase() : false) ?? false
    },
    getFirstOfCurrentYear() {
      return getYearDate(new Date().getFullYear())
    },
    getFoersteBetalingDefaultValue: getFoersteBetalingDefaultValue,
    nextFirstOfMonth: nextFirstOfMonth,
    firstOfMonth: firstOfMonth,
    getForsikringPosition(insuranceCompanyId: number, arg1: any, arg2: any, arg3: any, arg4: any, arg5: any) {
      const keys = Object.keys(serverVars.forsikringMaxPris ?? {}).sort(
        (a, b) => Number(a) - Number(b)
      )

      const coreKeys = [
        'forsikringPosInsuranceCompanyId',
        'forsikringPosVarevogn',
        'forsikringPosEjIndregistreret',
        'forsikringPosGlas',
        'forsikringPosFossil',
        'forsikringMaxPris'
      ]
      const kroneKeys = [
        'forsikringPosInsuranceCompanyId',
        'forsikringPosEjIndregistreret',
        'forsikringPosGlas',
        'forsikringMaxPris',
        'forsikringPosMin96Mdr'
      ]
      const perbKeys = ['forsikringPosInsuranceCompanyId', 'forsikringMaxPris']

      if (coreKeys.every((k) => serverVars.hasOwnProperty(k))) {
        const varevogn = arg1
        const ejIndregistreret = arg2
        const glas = arg3
        const fossil = arg4
        // noinspection UnnecessaryLocalVariableJS
        const maxpris = arg5

        for (const i of keys) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            varevogn === serverVars.forsikringPosVarevogn[i] &&
            ejIndregistreret === serverVars.forsikringPosEjIndregistreret[i] &&
            glas === serverVars.forsikringPosGlas[i] &&
            fossil === serverVars.forsikringPosFossil[i] &&
            (maxpris || 0) <= serverVars.forsikringMaxPris[i]
          ) {
            return i
          }
        }
      } else if (kroneKeys.every((k) => serverVars.hasOwnProperty(k))) {
        const ejIndregistreret = arg1
        const glas = arg2
        const maxpris = arg3
        // noinspection UnnecessaryLocalVariableJS
        const min96mdr = arg4

        for (const i of keys) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            ejIndregistreret === serverVars.forsikringPosEjIndregistreret[i] &&
            glas === serverVars.forsikringPosGlas[i] &&
            (maxpris || 0) <= serverVars.forsikringMaxPris[i] &&
            min96mdr === serverVars.forsikringPosMin96Mdr[i]
          ) {
            return i
          }
        }
      } else if (perbKeys.every((k) => serverVars.hasOwnProperty(k))) {
        // noinspection UnnecessaryLocalVariableJS
        const maxpris = arg1

        for (const i in serverVars.forsikringMaxPris) {
          if (
            insuranceCompanyId === serverVars.forsikringPosInsuranceCompanyId[i] &&
            maxpris <= serverVars.forsikringMaxPris[i]
          )
            return i
        }
      } else if (Object.keys(serverVars).length > 0) {
        const res = {
          found: {
            core: Object.fromEntries(coreKeys.map((k) => [k, serverVars.hasOwnProperty(k)])),
            krone: Object.fromEntries(kroneKeys.map((k) => [k, serverVars.hasOwnProperty(k)])),
            perb: Object.fromEntries(perbKeys.map((k) => [k, serverVars.hasOwnProperty(k)]))
          },
          value: {
            core: Object.fromEntries(coreKeys.map((k) => [k, serverVars[k]])),
            krone: Object.fromEntries(kroneKeys.map((k) => [k, serverVars[k]])),
            perb: Object.fromEntries(perbKeys.map((k) => [k, serverVars[k]]))
          },
          sv: { ...serverVars }
        }
        logger.info(res)
        throw new Error('Don\'t know what instance implementation to use for getForsikringPosition')
      }

      return undefined
    },
    getDepreciationMatrixPct(depreciationMatrixId: number, annualMileage: number, months: number) {
      if (serverVars.depreciationMatrixPct && serverVars.depreciationMatrixPct[depreciationMatrixId]) {
        // we assume that keys are found in sorted order
        const keys = Object.keys(serverVars.depreciationMatrixPct[depreciationMatrixId]).sort(
          (a, b) => Number(a) - Number(b)
        )

        // we also assume that the sorted key sequence corresponds to a sequence of increasing annualMileage and months
        for (const i of keys) {
          //console.log('reached item ' + i + ", " + serverVars.depreciationMatrixAnnualMileage[depreciationMatrixId][i] + ", " + serverVars.depreciationMatrixMonths[depreciationMatrixId][i])
          if (
            annualMileage <= serverVars.depreciationMatrixAnnualMileage[depreciationMatrixId][i] &&
            months <= serverVars.depreciationMatrixMonths[depreciationMatrixId][i]
          ) {
            return serverVars.depreciationMatrixPct[depreciationMatrixId][i]
          }
        }
      }

      return 0.0
    },

    getCarGarantiePosition(
      product: number,
      nyBil: boolean,
      forlaengelse: boolean,
      bilAlder: number,
      forsikringsperiode: number,
      kilometerstand: number,
      hestekraefter: number,
      koersel: number,
      fabriksgaranti: boolean
    ) {
      const keys = Object.keys(serverVars.carGarantiePosProduct ?? {}).sort(
        (a, b) => Number(a) - Number(b)
      )

      for (const i of keys) {
        if (
          product === serverVars.carGarantiePosProduct[i] &&
          nyBil === serverVars.carGarantiePosNyBil[i] &&
          forlaengelse === serverVars.carGarantiePosForlaengelse[i] &&
          bilAlder <= serverVars.carGarantiePosMaxAlder[i] &&
          forsikringsperiode === serverVars.carGarantiePosForsikringsperiode[i] &&
          kilometerstand <= serverVars.carGarantiePosMaxKm[i] &&
          (hestekraefter <= serverVars.carGarantiePosMaxHK[i] || serverVars.carGarantiePosMaxHK[i] == null) &&
          (koersel <= serverVars.carGarantiePosMaxKoersel[i] || serverVars.carGarantiePosMaxKoersel[i] == null) &&
          (fabriksgaranti === serverVars.carGarantiePosFabriksgaranti[i] ||
            serverVars.carGarantiePosFabriksgaranti[i] == null)
        ) {
          return i
        }
      }

      return undefined
    },
    ceil: Math.ceil,
    floor: Math.floor,
    round: Math.round,
    roundWithMode(value: number, mode: 0 | 1 | 2) {
      switch (mode) {
        case 0:
          return value
        case 1:
          return Math.ceil(value)
        case 2:
          return Math.round(value)
      }
    },
    min: Math.min,
    max: Math.max,
    existingCompanyGroupId(a: any, b: any) {
      return serverVars.maxLoebetid !== undefined && serverVars.maxLoebetid[a] !== undefined ? a : b
    },
    datecmp(a: any, b: any) {
      if (a instanceof Date && b instanceof Date) {
        return a.getTime() - b.getTime()
      } else {
        return 0
      }
    },
    fv: Formula.FV,
    pmt: Formula.PMT,
    pv: Formula.PV,
    nper: Formula.NPER,
    rate: Formula.RATE,
    cumipmt: Formula.CUMIPMT,
    effect: Formula.EFFECT,
    cprBirthday: cprBirthday,
    monthsSince(d1: Date | null | undefined, d2: Date | null | undefined) {
      if ((d1 as any) === 0) {
        // 0 er fallbackværdi i serverVars. Denne skal sørge for at monthsSince ikke får nogen værdi
        return undefined
      }
      if (d1 === undefined || d1 === null) {
        return 0
      }
      if (d2 === undefined || d2 === null) {
        d2 = new Date()
      }

      // vi udregner antal måneder i forhold til hvis bilen havde været indreg. pr. d. 1. i indreg. måneden.
      // dateOffset er det der skal trækkes fra slutdatoen for at dette giver mening
      const dateOffset = d1.getDate() - 1

      // der lægges 1 dag til slutdatoen, da vi udregner antal måneder fra d1 kl 0.00 til *og med* d2, dvs. faktisk d2+1 kl. 0.00].
      const d1p = new Date(d1)
      d1p.setDate(1)
      const d2p = new Date(d2)
      d2p.setDate(d2.getDate() - dateOffset + 1)

      const m1 = d1p.getMonth() + 12 * d1p.getFullYear()
      const m2 = d2p.getMonth() + 12 * d2p.getFullYear()
      // noinspection UnnecessaryLocalVariableJS
      const diff = m2 - m1
      return diff
    },
    isWithinDays(date: Date | null | undefined, days: number) {
      if (date) {
        const temp = new Date()
        temp.setDate(temp.getDate() + days)

        return date.getTime() <= temp.getTime()
      }
      return false
    },
    addMonths(date: Date | undefined, months: number | undefined) {
      if (date === undefined) {
        date = new Date()
      }

      if (months === undefined) {
        return undefined
      }

      const temp = new Date(date)
      temp.setMonth(temp.getMonth() + months)
      temp.setDate(temp.getDate() - 1)
      return temp
    },
    addDays(date: Date | undefined, days: number | undefined) {
      if (date === undefined) {
        date = new Date()
      }
      if (days === undefined) {
        return undefined
      }

      const temp = new Date(date)
      temp.setDate(temp.getDate() + days)
      return temp
    },
    ultimoMonth(d: Date) {
      const out = new Date(d)
      out.setMonth(d.getMonth() + 1)
      out.setDate(0)
      return out
    },
    cprMod11(cpr: string | undefined | null) {
      if (cpr === undefined || cpr === null) {
        return false
      }

      const idx = (i: number) => {
        return Number(cpr[i])
      }

      // noinspection UnnecessaryLocalVariableJS,PointlessArithmeticExpressionJS
      const out =
        cpr.length == 10 &&
        (4 * idx(0) +
          3 * idx(1) +
          2 * idx(2) +
          7 * idx(3) +
          6 * idx(4) +
          5 * idx(5) +
          4 * idx(6) +
          3 * idx(7) +
          2 * idx(8) +
          1 * idx(9)) %
        11 ==
        0
      return out
    },
    strlen(str: any) {
      if (typeof str === 'string') {
        return str.length
      }
      return 0
    },
    udskudtRegAfgiftPct(alderMdr: number, loebetidWrite: number) {
      return (
        Math.max(0, Math.min(3, alderMdr + loebetidWrite) - Math.max(0, alderMdr)) * 0.02 +
        Math.max(0, Math.min(36, alderMdr + loebetidWrite) - Math.max(3, alderMdr)) * 0.01 +
        Math.max(0, alderMdr + loebetidWrite - Math.max(36, alderMdr)) * 0.005
      )
    },
    regAfgiftForbrugt(alderMdr: number, loebetid: number, vurderetRegAfgift: number) {
      return this.udskudtRegAfgiftPct(alderMdr, loebetid) * vurderetRegAfgift
    },
    regAfgiftRest(alderMdr: number, loebetid: number, vurderetRegAfgift: number) {
      return vurderetRegAfgift - this.regAfgiftForbrugt(alderMdr, loebetid, vurderetRegAfgift)
    },
    regAfgiftRente(alderMdr: number, loebetid: number, vurderetRegAfgift: number, renteRegAfgiftPct: number) {
      return (this.regAfgiftRest(alderMdr, loebetid, vurderetRegAfgift) * renteRegAfgiftPct * loebetid) / 12
    },
    regAfgiftForudbetaling(alderMdr: number, loebetid: number, vurderetRegAfgift: number, renteRegAfgiftPct: number) {
      return (
        this.regAfgiftForbrugt(alderMdr, loebetid, vurderetRegAfgift) +
        this.regAfgiftRente(alderMdr, loebetid, vurderetRegAfgift, renteRegAfgiftPct)
      )
    },
    getRenteRegAfgiftPct(leveringsdatoHalfYear: boolean) {
      if (serverVars.renteRegAfgiftPct) {
        const entriesArr = Object.entries(serverVars.renteRegAfgiftPct)
        entriesArr.sort((a, b) => (a[0] as any as number) - (b[0] as any as number))
        const entries = Object.fromEntries(entriesArr)
        return entries[String(leveringsdatoHalfYear)] ?? (entriesArr.at(-1) as [string, number])[1]
      } else {
        return 0
      }
    },
    beskatningsgrundlagMiljoe(currentDate?: Date) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 2.5
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 3.5
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 4.5
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 6
      } else {
        return 7
      }
    },
    beskatningsgrundlagLav(currentDate?: Date) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 0.245
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 0.24
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 0.235
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 0.23
      } else {
        return 0.225
      }
    },
    beskatningsgrundlagHoej(currentDate?: Date) {
      currentDate ??= new Date() //For support for PerB
      if (currentDate < new Date(2022, 0, 1)) {
        return 0.205
      } else if (currentDate < new Date(2023, 0, 1)) {
        return 0.21
      } else if (currentDate < new Date(2024, 0, 1)) {
        return 0.215
      } else if (currentDate < new Date(2025, 0, 1)) {
        return 0.22
      } else {
        return 0.225
      }
    },
    getPeriodicTax: getPeriodicTax,
    getDieselAddon: getDieselAddon,
    halfYear(date?: Date) {
      if (!!date) {
        return date.getFullYear().toString() + (date.getMonth() <= 5 ? '1' : '2')
      } else {
        date = new Date()
        return date.getFullYear().toString() + (date.getMonth() <= 5 ? '1' : '2')
      }
    },
    getProductPriceLevel(companyGroupIdFinal: number, productId: number, price: number) {
      const levels = (serverVars.productPriceLevels[companyGroupIdFinal] ?? {})[productId] ?? []
      const sortedLevels = [...levels].sort((a, b) => a.value - b.value)

      while (sortedLevels.length) {
        const level = sortedLevels.pop()
        if (price >= level.value) {
          return level.key
        }
      }

      return undefined
    }
  }
}