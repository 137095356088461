
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/form/offer/attachments.html";
      const src = "<hr ng-if=\"materials.length > 0\" />\n<h4 ng-if=\"materials.length > 0\" class=\"form-subheader\">Vedlagte filer</h4>\n\n<div class=\"row\">\n  <div class=\"col-sm-6 col-md-4\" ng-repeat=\"m in materials\">\n    <div class=\"thumbnail\">\n      <a\n        ng-show=\"modelVars.status == 0 || userInfo.isSuperAdmin\"\n        ng-click=\"deleteMaterial(m)\"\n        class=\"btn btn-xs btn-default\"\n        style=\"position: absolute; right: 20px; top: 5px\"\n        ><i class=\"fa fa-times\"></i\n      ></a>\n\n      <a ng-href=\"{{ m.url }}\" target=\"_blank\">\n        <img ng-src=\"{{ m.thumbUrl }}\" holder-fix alt=\"...\" style=\"height: auto !important\" />\n      </a>\n      <span class=\"caption\"> {{ m.filename }} </span>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      