import { errorCount } from '../functions/validationErrors'

export const formCommonConfigCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$interop',
  function ($scope, $rootScope, $http, $interop) {
    $scope.debug = function (form) {
      console.log(JSON.stringify(form))
    }

    $scope.errorCount = errorCount
    $scope.modelVars = {}
    $scope.cityVars = {}

    const partOfMonthlyPaymentOptions = [
      {
        value: false,
        option: 'Tillæg til ydelse',
      },
      {
        value: true,
        option: 'Inkl. i ydelse',
      },
    ]

    $scope.options = {
      gpsFirmaKode: [],
      forsikringFaellespoliceSelskab: [],
      forsikringFaellespolicePrisregel: [
        {
          value: 0,
          option: 'Fakturapris ex. moms',
        },
        {
          value: 1,
          option: 'Beskatningsgrundlag for alder <= 6md, herefter totalpris + vurderet reg. afgift',
        },
        {
          value: 2,
          option: 'Fakturapris inkl. moms'
        }
      ],
      forsikringFaellespoliceBenytBiltype: [
        {
          value: 0,
          option: 'Benyt ikke biltype (personbil el. varevogn)',
        },
        {
          value: 1,
          option: 'Benyt biltype (personbil el. varevogn)',
        },
      ],
      forsikringFaellespoliceBenytFossil: [
        {
          value: 0,
          option: 'Benyt ikke drivmiddel (fossil/elektrisk)',
        },
        {
          value: 1,
          option: 'Benyt drivmiddel (fossil/elektrisk)',
        },
      ],
      forsikringFaellespoliceFriskade: [
        {
          value: false,
          option: 'Nej',
        },
        {
          value: true,
          option: 'Ja',
        },
      ],
      depreciationMatrixId: [],
      roadsideAssistanceIsPartOfMonthlyPayment: partOfMonthlyPaymentOptions,
      serviceIsPartOfMonthlyPaymentOperating: partOfMonthlyPaymentOptions,
      serviceIsPartOfMonthlyPaymentFinancial: partOfMonthlyPaymentOptions,
      leasinggiverBetalerNummerpladerDefault: [
        { value: 0, option: 'Forhandler' },
        { value: 1, option: $interop.prettyModuleName },
        { value: 2, option: 'Opkræves separat' },
      ],
    }

    // Remove second option for FirstLease
    if ($interop.module === 'firstlease') {
      $scope.options.leasinggiverBetalerNummerpladerDefault.splice(1, 1)
    }

    $http.post('/api/insuranceCompany/getAll').success(function (res) {
      angular.forEach(res, function (o) {
        $scope.options.forsikringFaellespoliceSelskab.push({
          value: o.id,
          option: o.name,
        })
      })
    })

    $http.post('/api/gpsProvider/getAll').success(function (res) {
      angular.forEach(res, function (o) {
        $scope.options.gpsFirmaKode.push({ value: o.name, option: o.caption })
      })
    })

    if ($interop.module === 'krone') {
      $http.post('/api/depreciationMatrix/getAll').success(function (res) {
        angular.forEach(res, function (o) {
          $scope.options.depreciationMatrixId.push({
            value: o.id,
            option: o.name,
          })
        })
      })
    }

    $scope.variableDefs = {
      cvr: {
        friendly: 'CVR-nr.',
        datatype: 'cvr',
        vartype: 'model',
        required: true,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      address: {
        friendly: 'Adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      zipcode: {
        friendly: 'Postnr',
        datatype: 'postnr',
        vartype: 'model',
        required: true,
      },
      mail: {
        friendly: 'E-mail adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      notificationMail: {
        friendly: 'E-mail adresse til notifikationer',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      phone: {
        friendly: 'Telefon',
        datatype: 'digits',
        vartype: 'model',
        required: true,
      },
      web: {
        friendly: 'Hjemmeside',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: ['commonConfig.web'],
      },
      notificationPhone: {
        friendly: 'SMS-notifikationstelefon',
        datatype: 'digits',
        vartype: 'model',
        required: true,
      },

      hovedstolBestyrelseGraense: {
        friendly: 'Hovedstol, bestyrelsesgodkendelsesgrænse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      samletEngagementBestyrelseGraense: {
        friendly: 'Samlet engagement, bestyrelsesgodkendelsesgrænse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.samletEngagementBestyrelseGraense'],
        },
      },
      standardVedligeholdelse: {
        friendly: 'Est. vedligeholdelse pr. md.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      standardBraendstof: {
        friendly: 'Est. brændstof pr. md.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      standardEjerudgift: {
        friendly: 'Est. ejerudgift pr. md.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      blankoBeloebMax: {
        friendly: 'Max. blankobeløb',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      blankoPctMax: {
        friendly: 'Max. blankopct',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      externalName: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      externalMail: {
        friendly: 'E-mail adresse',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      bankkontoBank: {
        friendly: 'Bank',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      bankkontoKontoNr: {
        friendly: 'Kontonr.',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      bankkontoRegNr: {
        friendly: 'Reg. nr.',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      bankkontoIban: {
        friendly: 'IBAN nr.',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['commonConfig.bankkontoIban'],
        },
      },
      bankkontoSwift: {
        friendly: 'SWIFT nr.',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['commonConfig.bankkontoSwift'],
        },
      },
      tysklandMomsPct: {
        friendly: 'Momsprocent, Tyskland',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['germanComparisonPrice'],
        },
      },
      euroKurs: {
        friendly: 'Eurokurs DKK/EUR',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['germanComparisonPrice'],
        },
      },
      nummerpladerDefault: {
        friendly: 'Nummerplader',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      roadsideAssistanceCompanyName: {
        friendly: 'Vejhjælp firmanavn',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['roadsideAssistance'],
        },
      },
      roadsideAssistanceCompanyCvr: {
        friendly: 'Vejhjælp CVR nummer',
        datatype: 'cvr',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['roadsideAssistance'],
        },
      },
      roadsideAssistancePrice: {
        friendly: 'Vejhjælp pris pr. md.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['roadsideAssistance'],
        },
      },
      roadsideAssistanceIsPartOfMonthlyPayment: {
        friendly: '',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['roadsideAssistance'],
        },
      },
      serviceIsPartOfMonthlyPaymentOperating: {
        friendly: 'Inkl. eller tillæg til ydelse? (Operationel)',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.configurableServiceLocation'],
        },
      },
      serviceIsPartOfMonthlyPaymentFinancial: {
        friendly: 'Inkl. eller tillæg til ydelse? (Finansiel)',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.configurableServiceLocation'],
        },
      },
      dueDateExtraDays: {
        friendly: 'Betalingsfrist, ekstra dage',
        datatype: 'count',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.dueDateExtraDays'],
        },
      },
      thirdPartyDataAccessors: {
        friendly: 'Tredjepartsfirmaer med dataadgang',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.thirdPartyDataAccessors'],
        },
      },
      nummerpladerOmregistrering: {
        friendly: 'Nummerplader omregistrering',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.nummerpladerOmregistrering'],
        },
      },
      nummerpladerAvance: {
        friendly: 'Nummerplader avance',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.nummerpladerAvance'],
        },
      },
      nummerpladerAvanceOmregistrering: {
        friendly: 'Nummerplader omregistrering avance',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.nummerpladerAvanceOmregistrering'],
        },
      },
      leasinggiverBetalerNummerpladerDefault: {
        friendly: 'Leasinggiver betaler nummerplader',
        datatype: 'count',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.leasinggiverBetalerNummerpladerDefault'],
        },
      },
      expirationDays: {
        friendly: 'Antal dage til udløb af tilbud',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      inactiveCompanyDays: {
        friendly: 'Antal dage uden nye sager, inaktive forhandlere',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      kladdearkivDageStandard: {
        friendly: 'Antal dage til kladdearkiv',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      deklarationForsikring: {
        friendly: 'Deklaration, forsikring',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      deklarationForsikringForlaengelse: {
        friendly: 'Deklaration, forsikring, forlængelse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.deklarationForsikringForlaengelse'],
        },
      },
      pledgeDeclarationMonthlyCost: {
        friendly: 'Månedlig omkostning, panthaverdeklaration',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.pledgeDeclarationMonthlyCost'],
        },
      },
      afgiftFast: {
        friendly: 'Afgift, fast',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      afgiftPct: {
        friendly: 'Afgift, pct.',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      renteRegAfgiftPct: {
        friendly: 'Rente, ikke betalt reg. afgift',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.renteRegAfgiftPct']
        },
      },
      foersteYdelsePctMax: {
        friendly: 'Max. første ydelse ved forudbetalt reg. afgift',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      aaopMax: {
        friendly: 'Max. ÅOP',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.aaopMax'],
        },
      },
      foersteYdelseIngenRegAfgiftPctMax: {
        friendly: 'Max. første ydelse ved ingen reg. afgift',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      gebyrRestance: {
        friendly: 'Rykkerbrev, ved ydelses- og forsikringsrestance',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrAfvisningPBS: {
        friendly: 'Afvist/tilbageført Betalingsservice',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrAflysningEjendomsforbehold: {
        friendly: 'Aflysning af ejendomsforbehold',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrFIKort: {
        friendly: 'FI-kort gebyr (hvis ej Betalingsservice)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrNoteringUdlaeg: {
        friendly: 'Notering af udlæg',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrBevilgetHenstand: {
        friendly: 'Bevilget henstand eller bortfald af ydelse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrArkivTimepris: {
        friendly: 'Fremfinding af arkivmateriale pr. time ',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrArkivMin: {
        friendly: 'Fremfinding af arkivmateriale min.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrIkkeMeddeltAdresseaendring: {
        friendly: 'Ikke meddelt adresseændring',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrAendringForsikring: {
        friendly: 'Ændring af forsikring, herunder forsikringsskift',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrMorarenteReferencesatsPct: {
        friendly: 'Morarente referencesats',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      gebyrOpsigelsePctMin: {
        friendly: 'Min. pct. ved opsigelse',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      gebyrOpsigelseBeloebMin: {
        friendly: 'Min. beløb ved opsigelse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrSplitBoedeRykker1: {
        friendly: 'Split, gebyr ved bøde, rykker 1',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrSplitBoedeRykker2: {
        friendly: 'Split, gebyr ved bøde, rykker 2',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gpsEnhedPris: {
        friendly: 'GPS-enhedspris',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gpsEnhedAbonnement: {
        friendly: 'GPS-enhed abonnement',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },

      maxLoebetidPrivatleasing: {
        friendly: 'Max. løbetid, privatleasing',
        vartype: 'model',
        datatype: 'count',
        require: true,
      },

      firmabilbeskatningLavPct: {
        friendly: 'Beskatningsprocent, basis',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      firmabilbeskatningLavBeloeb: {
        friendly: 'Beløbsgrænse for basis',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forsikringFaellespoliceGpsGraense: {
        friendly: 'Krav om GPS ved bilens pris ex. moms større end',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forsikringAarligPraemieStilstandFaktor: {
        friendly: 'Årlig præmie, stilstandsfaktor',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      firmabilbeskatningHoejPct: {
        friendly: 'Beaskatningsprocent, resten',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      groenEjerAfgiftTillaegPct: {
        friendly: 'Tillægsprocent til beskatning af grøn ejerafgift',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      marginalSkatteprocentForslag: {
        friendly: 'Forslag til marginal skatteprocent',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      anvisningsprovisionMax: {
        friendly: 'Max. anvisningsprovision (Krone)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },

      forsikringFaellespoliceSelskab: {
        friendly: 'Fællespolice, forsikringsselskab',
        vartype: 'model',
      },
      forsikringFaellespoliceNummer: {
        friendly: 'Fællespolice, kaskopolicenummer',
        datatype: 'text',
        vartype: 'model',
      },

      forsikringFaellespolicePrisregel: {
        friendly: 'Fællespolice, prisregel',
        vartype: 'model',
      },
      forsikringFaellespoliceBenytBiltype: {
        friendly: 'Fællespolice, benyt biltype (personbil/varevogn)',
        vartype: 'model',
      },
      forsikringFaellespoliceBenytFossil: {
        friendly: 'Fællespolice, benyt drivmiddel (fossil/elektrisk)',
        vartype: 'model',
      },
      forsikringSelvrisikoGlas: {
        friendly: 'Fællespolice, selvrisiko glas',
        vartype: 'model',
        datatype: 'amount',
        fieldConditions: {
          requiredFeature: ['commonConfig.forsikringSelvrisikoGlas'],
        },
      },
      forsikringFaellespoliceFriskade: {
        friendly: 'Fællespolice, tilbyd glasdækning',
        vartype: 'model',
      },
      forsikringAldersgraenseAar: {
        friendly: 'Min. alder, fællespolice',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      forsikringSkadesfriAar: {
        friendly: 'Min. skadesfri år, fællespolice',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      forsikringFriskadePraemie: {
        friendly: 'Ekstra præmie for friskadedækning',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forsikringFriskadeSelvrisikoForrude: {
        friendly: 'Selvrisiko for udskiftning af forrude v. friskade',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      fastrenteGraenseMaaneder: {
        friendly: 'Fast rente, max. måneder til levering',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltBilTotalpris: {
        friendly: 'Bil, totalpris',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltBilUdbetaling: {
        friendly: 'Bil, udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltBilLoebetid: {
        friendly: 'Bil, løbetid',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltCampingTotalpris: {
        friendly: 'Camping, totalpris',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltCampingUdbetaling: {
        friendly: 'Camping, udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forhandlerskiltCampingLoebetid: {
        friendly: 'Camping, løbetid',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      leasingkontraktEjerCvr: {
        friendly: 'Leasingkontrakt ejer, CVR',
        datatype: 'cvr',
        vartype: 'model',
        required: true,
      },
      leasingkontraktEjerNavn: {
        friendly: 'Leasingkontrakt ejer, Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      leasingkontraktEjerRentesats: {
        friendly: 'Leasingkontrakt ejer, Rentesats',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      sommerbilRegAfgiftTillaeg: {
        friendly: 'Sommerbil, md. reg. afgift tillæg',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      sommerbilIndregistreringGebyr: {
        friendly: 'Sommerbil, indregistreringsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      selvbevillingCamping: {
        friendly: 'Selvbevilling, camping aktiv',
        vartype: 'model',
      },
      selvbevillingCampingAlderMin: {
        friendly: 'Kundens alder (min.)',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingEkstraudstyrPctAfUdbetalingMax: {
        friendly: 'Ekstraudstyrs andel af udbetaling (max.)',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingLavUdbetalingPct: {
        friendly: 'Lav udbetaling, pct. grænse',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingLavUdbetalingTotalprisMax: {
        friendly: 'Max. totalpris, lav udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingHoejUdbetalingTotalprisMax: {
        friendly: 'Max. totalpris, høj udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingYdelsePctAfRaadighedsbeloebMax: {
        friendly: 'Max. ydelse pct. af rådighedsbeløb',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      selvbevillingCampingAaopMax: {
        friendly: 'Max. ÅOP',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      knaekleasingGebyr: {
        friendly: 'Knækleasingsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      helpIsActive: {
        friendly: 'Hjælpemenu er aktiv',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },

      gebyrSplitKorrektion: {
        friendly: 'Split, korrektionsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },

      maanedsdagSplit: {
        friendly: 'Split, månedsdag',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      splitEgenForsikringGrundgebyr: {
        friendly: 'Split, egen forsikring, grundgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      splitEgenForsikringMaanedsgebyr: {
        friendly: 'Split, egen forsikring, månedsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      splitKraevetBesparelse: {
        friendly: 'Krævet besparelse pr. md før advarsel',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      splitAndelPrivatAdvarelse: {
        friendly: 'Andel af privatandel før advarsel',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      gebyrKroneGenberegning: {
        friendly: 'Genberegningsgebyr, Krone',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrOmregNummerplader: {
        friendly: 'Gebyr, omreg. numerplader',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      rykkerPrivat: {
        friendly: 'Rykker, privat',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      rykkerErhverv1: {
        friendly: 'Rykker 1, erhverv',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      rykkerErhverv2: {
        friendly: 'Rykker 2, erhverv',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      rykkerErhverv3: {
        friendly: 'Rykker 3, erhverv',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrManglendeEjerafgift: {
        friendly: 'Manglende ejerafgift, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrBestillingNummerplade3: {
        friendly: 'Bestilling 3. nummerplade, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrBestillingNyRegAttest: {
        friendly: 'Bestilling ny reg. attest, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrOphaevelse: {
        friendly: 'Ophævelse, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrManglendeNETS: {
        friendly: 'Manglende NETS, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrAnnullering: {
        friendly: 'Annullering, gebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      ekstraMaanedLoebetidMin: {
        friendly: 'Medregn ej, hvis løbetiden er under',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      ekstraMaanedMdMax: {
        friendly: 'Maxbeløb pr. måned',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      ekstraMaanedTotalMax: {
        friendly: ' Totalbeløb for hele løbetiden kan max. udgøre ',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      etuForsikringDefault: {
        friendly: 'ETU forsikring standard',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      synAdvarselDage: {
        friendly: 'Advarsel, antal dage før syn',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      koerebogDemoEndpoint: {
        friendly: 'Kørebog Demo endpoint',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      penneoFolderId: {
        friendly: 'Penneo mappe ID',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['penneo.folderId'],
        },
      },
      penneoEndpoint: {
        friendly: 'Penneo endpoint',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      penneoUsername: {
        friendly: 'Penneo brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      penneoPassword: {
        friendly: 'Penneo adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      navEndpoint: {
        friendly: 'NAV endpoint',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      navUsername: {
        friendly: 'NAV brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      navPassword: {
        friendly: 'NAV adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      dowjonesEndpoint: {
        friendly: 'Dow Jones endpoint',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      dowjonesUsername: {
        friendly: 'Dow Jones brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      dowjonesPassword: {
        friendly: 'Dow Jones adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      anslaaetForsikringPrAarMin: {
        friendly: 'Anslået forsikring pr. år, min.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['splitleasing'],
        },
      },
      anslaaetGroenEjerafgiftPrAarMin: {
        friendly: 'Anslået grøn ejerafgift pr. år, min.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['splitleasing'],
        },
      },
      anslaaetServicePrAarMin: {
        friendly: 'Anslået Service pr. år, min.',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['splitleasing'],
        },
      },
      anslaaetServiceKronePrKm: {
        friendly: 'Anslået Service kr pr. km',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['splitleasing'],
        },
      },
      anslaaetKmPrLiterBraendstofMin: {
        friendly: 'Anslået km pr. liter brændstof, min.',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['splitleasing'],
        },
      },
      anslaaetDieselPris: {
        friendly: 'Anslået kr pr. liter diesel',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['propellantPrice'],
        },
      },
      anslaaetBenzinPris: {
        friendly: 'Anslået kr pr. liter benzin',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['propellantPrice'],
        },
      },
      anslaaetElPris: {
        friendly: 'Anslået kr pr. kWh',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['propellantPrice'],
        },
      },
      anslaaetHybridPris: {
        friendly: 'Anslået kr pr. liter elektroner i omløb :) <- Hybrid',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['propellantPrice'],
        },
      },
      anslaaetHybridPluginPris: {
        friendly: 'Anslået kr pr. liter elektroner i omløb :) <- Hybrid plugin',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['propellantPrice'],
        },
      },
      kronekapitalServer: {
        friendly: 'Krone Kapital endpoint',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      kronekapitalUsername: {
        friendly: 'Krone Kapital brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      kronekapitalPassword: {
        friendly: 'Krone Kapital adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      asgUsername: {
        friendly: 'ASG brugernavn',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
      asgPassword: {
        friendly: 'ASG adgangskode',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['asg'],
        },
      },
      experianCaller: {
        friendly: 'Experian Caller',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      experianInstanceId: {
        friendly: 'Experian Instance Id',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      experianUsername: {
        friendly: 'Experian Username',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      experianPassword: {
        friendly: 'Experian Password',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      gatewayApiToken: {
        friendly: 'Gateway API token',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      customerInfoLinkUrl: {
        friendly: 'URL skabelon',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },

      kilometerstandMaxVejl: {
        friendly: 'Max. kilometerstand',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      kilometerstandMaxVejlLavFY: {
        friendly: `Max. kilometerstand v/lav ${$interop.terms.downPayment} m.m.`,
        datatype: 'count',
        vartype: 'model',
        required: true,
      },

      provisionMaxPctVejl: {
        friendly: `Max. anvisningsprovision i pct. af ${$interop.terms.downPayment}`,
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },

      afviklingMinPctVejl: {
        friendly: 'Min. afviklingsprocent',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },

      maxKoerselPrAarMaxVejl: {
        friendly: 'Max. kørsel pr. år',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },

      afviklingMinPctVejlHoejKm: {
        friendly: 'Min. afviklingsprocent v/høj kørsel pr. år',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },

      foersteYdelseMinPctVejl: {
        friendly: 'Min. første ydelse',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      beskatningsgrundlagBilMin: {
        friendly: 'Min. beskatningsgrundlag',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      afviklingBeloebMin: {
        friendly: 'Min. afvikling, leasing',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      beskaffenhedStandStandard: {
        friendly: 'Beskaffenhed og stand, standard',
        datatype: 'text',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.beskaffenhedStandStandard'],
        },
      },
      overkoerselKmPrisMin: {
        friendly: 'Min. overkørsel pris pr. km',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.overkoerselKmPrisMin'],
        },
      },
      underkoerselKmPris: {
        friendly: 'Tilbagebetaling af underkørsel, kr pr. km',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.underkoerselKmPris'],
        },
      },
      carExaminationFee: {
        friendly: 'Undersøgelse af bil ved tilbagelevering',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.carExaminationFee'],
        },
      },
      insuranceAdmFee: {
        friendly: 'Gebyr for forsikringsadministration',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.insuranceAdmFee'],
        },
      },
      missingServiceFee: {
        friendly: 'Manglende serviceoverholdelse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.missingServiceFee'],
        },
      },
      highMileageDeposit: {
        friendly: 'Gebyr ved over 25% overkørsel',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.highMileageDeposit'],
        },
      },
      registrationExaminationFee: {
        friendly: 'Undersøgelse af forsikring eller reg. forhold',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.registrationExaminationFee'],
        },
      },
      kontraktSkaeringsnr: {
        friendly: 'Skæringsnummer for kontrakter',
        datatype: 'digits',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.kontraktSkaeringsnr'],
        },
      },
      paymentDays: {
        friendly: 'Antal betalingsdage ved salg af bil',
        datatype: 'count',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.paymentDays'],
        },
      },
      varmePrKvmPrMaaned: {
        friendly: 'Varme i kWh pr. kvm. pr. måned',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      voksenElforbrugPrMaaned: {
        friendly: 'Elforbrug for en voksen i kWh pr. måned',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      kWhPris: {
        friendly: 'Pris for 1 kWh.',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      barnElforbrugPrMaaned: {
        friendly: 'Elforbrug for et barn i kWh pr. måned',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      vandforbrugPrMaaned: {
        friendly: 'Vandforbrug i m3 pr. måned',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      forsikring: {
        friendly: 'Forsikring pr. måned',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      abonnementer: {
        friendly: 'Abonnementer pr. måned',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      extraXMLFieldsSchema: {
        friendly: 'Kommentar',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.extraXMLFieldsSchema'],
        },
      },
      commonConfigNummerplader: {
        vartype: 'server',
        datatype: 'amount',
        fieldConditions: {
          requiredFeature: ['commonConfig.commonConfigNummerplader'],
        },
      },
      vandPris: {
        friendly: 'Vandpris pr. m3',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredModule: ['perb'],
        },
      },
      additionalExpeditionsFee: {
        friendly: 'Øvrige nødvendige ekspeditioner, pr. påbegyndt time',
        vartype: 'model',
        datatype: 'amount',
        required: true,
        fieldConditions: {
          requiredFeature: ['commonConfig.additionalExpeditionsFee'],
        },
      },
      depreciationMatrixIdCarNew: {
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['depreciationMatrix.byVehicleType'],
        },
      },
      depreciationMatrixIdCarUsed: {
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['depreciationMatrix.byVehicleType'],
        },
      },
      depreciationMatrixIdVanNew: {
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['depreciationMatrix.byVehicleType'],
        },
      },
      depreciationMatrixIdVanUsed: {
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['depreciationMatrix.byVehicleType'],
        },
      },
      deliveryDateMonthOffset: {
        vartype: 'model',
        datatype: 'count',
        friendly: 'Forskudt leveringsdato, antal måneder',
        fieldConditions: {
          requiredFeature: ['commonConfig.deliveryDateMonthOffset'],
        },
      },
      sectorCodeWarningList: {
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['commonConfig.sectorCodeWarningList']
        }
      },
      sectorCodeEdiList: {
        vartype: 'model',
        fieldConditions: {
          requiredModule: ['krone'],
        }
      },
      vehicleTypeEdiList: {
        vartype: 'model',
        fieldConditions: {
          requiredModule: ['krone'],
        }
      }
    }

    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Fælles priser blev gemt.')

      $scope.setKeepAlert(false)
    }

    $scope.save = function () {
      $http.post('/api/commonConfig/edit', $scope.modelVars).success(saveSuccess)
    }
    $http.post('/api/commonConfig/get').success(function (res) {
      $scope.modelVars = res
      $scope.formIsLoaded = true
    })
  },
]
