import { PERCENTAGE_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

interface DecimalAttrs extends IAttributes {
  ngDecimal: number
}

export function ngDecimal(): IDirective<IScope, JQLite, DecimalAttrs, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: DecimalAttrs, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        if (PERCENTAGE_REGEXP.test(viewValue) || viewValue == '') {
          ctrl.$setValidity('decimal', true)
          return parseFloat(viewValue.replace(',', '.'))
        } else {
          ctrl.$setValidity('decimal', false)
          return undefined
        }
      })
      ctrl.$formatters.push(function (modelValue) {
        if (modelValue === null || (modelValue * 1 === modelValue)) {
          ctrl.$setValidity('decimal', true)
        } else {
          ctrl.$setValidity('decimal', false)
        }

        return (modelValue * 1).toFixed(attrs.ngDecimal).replace('.', ',')
      })

      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
