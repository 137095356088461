
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/attachFile.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Vedlæg fil</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-success\" ng-show=\"successMsg\" ng-bind=\"successMsg\"></div>\n        <div class=\"alert alert-danger\" ng-show=\"errorMsg\" ng-bind=\"errorMsg\"></div>\n        <form class=\"form-horizontal\" method=\"post\" enctype=\"multipart/form-data\">\n          <div class=\"form-group\">\n            <div class=\"col-xs-3 control-label\">Vælg fil:</div>\n            <div class=\"col-xs-9\">\n              <input type=\"file\" ng-file-select=\"onMaterialSelect($files);\" class=\"form-control\" ng-multiple=\"true\" />\n            </div>\n          </div>\n        </form>\n      </div>\n\n      <div class=\"modal-footer\">\n        <button class=\"btn btn-primary\" ng-click=\"uploadMaterial()\">\n          <i class=\"fa fa-upload\"></i> &nbsp;Upload fil\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      