import { CVR_REGEXP, CPR_REGEXP } from '../regexes'

export function cprBirthday(viewValue: string) {
  var valid = CPR_REGEXP.test(viewValue)
  if (valid) {
    var day = parseInt(viewValue.substring(0, 2), 10)
    var month = parseInt(viewValue.substring(2, 4), 10)
    var year = parseInt(viewValue.substring(4, 6), 10)
    var datetest = new Date()
    datetest.setFullYear(1900 + year, month - 1, day)
    var valid1 = datetest.getFullYear() == 1900 + year && datetest.getMonth() == month - 1 && datetest.getDate() == day
    if (valid1) {
      return datetest
    }
  }
  return new Date()
}

export function validCpr(viewValue: string) {
  var valid = CPR_REGEXP.test(viewValue)
  if (valid) {
    var day = parseInt(viewValue.substring(0, 2), 10)
    var month = parseInt(viewValue.substring(2, 4), 10)
    var year = parseInt(viewValue.substring(4, 6), 10)
    var datetest = new Date()
    datetest.setFullYear(1900 + year, month - 1, day)
    var valid1 = datetest.getFullYear() == 1900 + year && datetest.getMonth() == month - 1 && datetest.getDate() == day

    datetest.setFullYear(2000 + year, month - 1, day)
    var valid2 = datetest.getFullYear() == 2000 + year && datetest.getMonth() == month - 1 && datetest.getDate() == day
    valid = valid1 || valid2
  }
  return valid
}
