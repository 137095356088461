export const companyCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.setActiveId = function (id) {
      // overrule med current companyId
      $scope.id = $scope.userInfo.companyId
    }
    $scope.setActiveUserId = function (id) {
      // do nothing -- bruges ikke i Stamdata tab
    }

    $scope.loadAll = function () {
      // do nothing -- bruges ikke i Stamdata tab
    }
  },
]
