import creditAnalysisMonthioTemplate from './creditAnalysisMonthio.html'
import { IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import { ButtonState, SaveUserInputOwner } from './creditAnalysisTypes'

interface CreditAnalysisMonthioScope extends IScope {
  create: () => Promise<void>
  createAndRefresh: () => Promise<void>
  validateAndCreate: () => Promise<void>
  validateCreateAndRefresh: () => Promise<void>
  contract: any
  buttonState: { create: ButtonState; refresh: ButtonState }
}

export const creditAnalysisMonthio = [
  function (): IDirective<CreditAnalysisMonthioScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisMonthioTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        buttonState: '=',
        contract: '=',
      },

      link: function ($scope: CreditAnalysisMonthioScope, elm: JQLite, attrs: IAttributes) {
        $scope.validateAndCreate = async function () {
          const valid = $scope.contract.navn.length !== 0 && $scope.contract.mail.length !== 0
          if (valid) {
            return await $scope.create()
          } else {
            $scope.buttonState.create = 'error'
          }
        }
        $scope.validateCreateAndRefresh = async function () {
          const valid = $scope.contract.navn.length !== 0 && $scope.contract.mail.length !== 0
          if (valid) {
            return await $scope.createAndRefresh()
          } else {
            $scope.buttonState.create = 'error'
          }
        }
      },
    }
  },
]
