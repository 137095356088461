
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/help.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>Hjælp</h3>\n</div>\n\n<div class=\"row\">\n  <div class=\"col-sm-4\" ng-repeat=\"col in cols\">\n    <div class=\"list-group\" ng-repeat=\"cat in col\">\n      <a class=\"list-group-item active\"> {{cat.name}} </a>\n      <a ng-href=\"/help/show/{{cat.id}}/{{item.id}}\" class=\"list-group-item\" ng-repeat=\"item in articles[cat.id]\"\n        >{{item.title}}</a\n      >\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      