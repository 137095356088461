import { dateToYMD } from '../functions/dates'
import { IAttributes, IDirective, IScope } from 'angular'

interface PdflinkScope extends IScope {
  engine: computationEngine.ComputationEngine<any>
  modelVars: any
  id: number
}

interface PdflinkAttrs extends IAttributes {
  pdfform?: string
  pdflinkeval?: string
  pdflink: string
  params: string
  downloadButtonState: string
  applicationId?: string
}

export function pdflink(): IDirective<PdflinkScope, JQLite, PdflinkAttrs> {
  return {
    link(scope: PdflinkScope, element: JQLite, attrs: PdflinkAttrs) {
      element.bind('click', function (event) {
        const form = document.getElementById(attrs.pdfform ?? 'pdfform')
        if (!form || !(form instanceof HTMLFormElement)) {
          throw new Error(`Could not find element with id ${attrs.pdfform ?? 'pdfform'} or it was not a form`)
        }

        const valueElem = (name: string): (Element | RadioNodeList) & { value: string } => {
          const item = form.elements.namedItem(name)
          if (!item || !('value' in item)) {
            throw new Error(`Invalid form element ${name}`)
          }

          return item
        }

        valueElem('type').value = attrs.pdflinkeval ? scope.$eval(attrs.pdflinkeval) : attrs.pdflink
        valueElem('params').value = attrs.params
        valueElem('downloadButtonState').value = attrs.downloadButtonState
        valueElem('id').value = attrs.applicationId ? scope.$eval(attrs.applicationId) : scope.id

        if (attrs.scopeElement !== undefined) {
          valueElem('values').value = JSON.stringify(scope.$eval(attrs.scopeElement))
        } else {
          if (scope.engine !== undefined) {
            var temp = scope.engine.getValues()
            for (var v in temp) {
              var d = temp[v]
              temp[v] = d instanceof Date ? dateToYMD(d) : d
            }
            valueElem('values').value = JSON.stringify(temp)
          } else {
            valueElem('values').value = JSON.stringify(scope.modelVars)
          }
        }
        form.submit()
      })
    },
  }
}
