import { assignScope, makeDirective, type ScopeType } from '@/shared/util/directiveHelpers'
import indexActionsTemplate from './indexActions.html'
import errorTemplate from '@/shared/templates/modals/error.html'
import warningWithCallback from '@/shared/templates/modals/warningWithCallback.html'
import type { IScope } from 'angular'
import type { CarleasingInterop } from '@/shared/carleasingInterop'
import type { UserInfo } from '@/shared/util/injectTypes'

type ImportResult = { id?: number; error?: string }[]

export const indexActions = makeDirective({
  inject: {
    $state: '$state',
    $modal: '$modal',
    $upload: '$upload',
    $rootScope: '$rootScope',
    $interop: '$interop'
  },
  template: indexActionsTemplate,
  scope: {
    userInfo: {
      type: Object as unknown as ScopeType<UserInfo>,
      binding: 'twoWay',
    },
  },
  assignScope: assignScope<{
    $interop: CarleasingInterop
    uploading: boolean
    uploadAsg(file: File): void
    uploadNordania(file: File, regTax: boolean): void
    uploadLegacyExcel(file: File): void
    showCreateLead(): void
  }>(),
  linkAssign({ $state, $modal, $upload, $rootScope, $interop }, { scope }) {
    return {
      $interop,
      uploading: false,
      uploadAsg(file) {
        var uploadUrl = '/api/firstlease/asg/upload/carTax'
        scope.uploading = true

        $upload
          .upload<ImportResult>({
            url: uploadUrl,
            file: file,
          })
          .error(() => $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af ASG-sag' }))
          .success((data, status) => {
            if (status != 200 || data.some((d) => d.error)) {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af ASG-sag' })
            } else {
              $rootScope.setGlobalAlert('ASG-sag blev indlæst')
              if (data[0] && data[0].id) {
                $state.go('root.application.edit', { id: data[0].id })
              }
            }
          })
      },

      uploadNordania(file) {
        var uploadUrl = '/api/firstlease/nordania/upload/sale'
        scope.uploading = true

        function renderStaticPct(value: number) {
          return (value * 100).toFixed(4).replace('.', ',') + ' %'
        }

        $upload
          .upload<
            ImportResult & { isCurrentPrtRate: boolean; prtRate: { interest: number; year: number; half: number } }[]
          >({
            url: uploadUrl,
            file: file,
          })
          .error(() => $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Nordania-sag' }))
          .success((data, status) => {
            if (status != 200 || data.some((d) => d.error)) {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Nordania-sag' })
            } else {
              if (!data[0].isCurrentPrtRate) {
                var subscope = scope.$new(true) as IScope & { content: string; callback(): void }
                subscope.content =
                  'Skats rentesats for restafgift er en anden end den aktive pr. dags dato. ' +
                  `Den indlæste sag er beregnet ud fra en rente på ${renderStaticPct(data[0].prtRate.interest)} (${
                    data[0].prtRate.year
                  }, ${data[0].prtRate.half}. halvår). ` +
                  'Forvent derfor en anden ydelse.'
                subscope.callback = function () {
                  $rootScope.setGlobalAlert('Nordania-sag blev indlæst')
                  if (data[0] && data[0].id) {
                    $state.go('root.application.edit', { id: data[0].id })
                    // reloadTable()
                  }
                }

                $modal({ template: warningWithCallback, show: true, scope: subscope })
              } else {
                $rootScope.setGlobalAlert('Nordania-sag blev indlæst')
                if (data[0] && data[0].id) {
                  $state.go('root.application.edit', { id: data[0].id })
                  // reloadTable()
                }
              }
            }
          })
      },

      uploadLegacyExcel(file) {
        var uploadUrl = `/api/${$interop.module}/legacy/upload/excel`
        scope.uploading = true

        $upload
          .upload<ImportResult>({
            url: uploadUrl,
            file: file,
          })
          .error((data) => {
            if (data.matchError) {
              var modalScope = scope.$new(true) as IScope & {
                message: string
                matchError: { [key: string]: string }
                missingError: string[]
                negativeError: string[]
                $interop: CarleasingInterop
                objectKeys: typeof Object.keys
              }
              modalScope.message = data.message
              modalScope.matchError = data.matchError
              modalScope.missingError = data.missingError
              modalScope.negativeError = data.negativeError
              modalScope.$interop = $interop
              modalScope.objectKeys = Object.keys

              const externalImportErrorTemplate = `
              <div class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" ng-click="$hide()">&times;</button>
                      <h4 class="modal-title">Fejl ved indlæsning af Excel-sag</h4>
                    </div>
                    <div class="modal-body">
                      <div class="alert alert-warning">{{message}}</div>
              
                      <h3 ng-if="missingError">Manglende felter</h3>
              
                      <ul ng-if="missingError">
                        <li ng-repeat="field in missingError">{{field}}</li>
                      </ul>
              
                      <hr ng-if="objectKeys(matchError).length" />
              
                      <h3 ng-if="objectKeys(matchError).length">Differencer</h3>
              
                      <p ng-if="objectKeys(matchError).length">
                        Foretag rettelser i opsætningen hos {{$interop.prettyModuleName}}, for at undgå disse differencer:
                      </p>
              
                      <table class="table retrofittable" ng-if="objectKeys(matchError).length">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Afsendt værdi</th>
                            <th>Krone værdi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr ng-repeat="field in objectKeys(matchError)">
                            <td><b>{{field}}</b></td>
                            <td>{{matchError[field].expected}}</td>
                            <td>{{matchError[field].obtained}}</td>
                          </tr>
                        </tbody>
                      </table>
                      
                      <hr ng-if="negativeError" />

                      <h3 ng-if="negativeError">TODO</h3>
                      
                      <ul ng-if="negativeError">
                        <li ng-repeat="field in negativeError">{{field}}</li>
                      </ul>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" ng-click="$hide()">Ok</button>
                    </div>
                  </div>
                </div>
              </div>`

              $modal({
                template: externalImportErrorTemplate,
                show: true,
                scope: modalScope,
              })
            } else {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Excel-sag' })
            }
          })
          .success((data, status) => {
            if (status != 200 || data.some((d) => d.error)) {
              $modal({ template: errorTemplate, show: true, content: 'Fejl ved indlæsning af Excel-sag' })
            } else {
              $rootScope.setGlobalAlert('Excel-sag blev indlæst')
              if (data[0] && data[0].id) {
                $state.go('root.application.edit', { id: data[0].id })
                // reloadTable()
              }
            }
          })
      },

      showCreateLead() {
        var modalScope = scope.$new()
        $modal({
          template: '<div class="modal" tabindex="-1" role="dialog" create-lead $hide="$hide()"/>',
          show: true,
          scope: modalScope,
        })
      },
    }
  },
})
