
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/forms/gpsProvider.html";
      const src = "<h3></h3>\n<form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n  <fieldset>\n    <legend>{{id === undefined ? \"Opret\" : \"Ret\"}} GPS-leverandør</legend>\n\n    <div ng-autofield=\"name\"></div>\n    <div ng-autofield=\"caption\"></div>\n    <h4>Uden kørebog</h4>\n    <div ng-autofield=\"gpsEnhedPrisUdenKoerebog\"></div>\n    <div ng-autofield=\"gpsEnhedAbonnementUdenKoerebog\"></div>\n    <h4>Med kørebog</h4>\n    <div ng-autofield=\"gpsEnhedPrisMedKoerebog\"></div>\n    <div ng-autofield=\"gpsEnhedAbonnementMedKoerebog\"></div>\n  </fieldset>\n  <hr />\n\n  <div class=\"btn-group pull-right\">\n    <a class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\"\n      ><i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl</a\n    >\n    <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" ng-click=\"save()\"\n      ><i class=\"fa fa-save\"></i> &nbsp; Gem</a\n    >\n  </div>\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      