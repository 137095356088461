import { errorCount } from '../functions/validationErrors'
export const formMailTemplatesCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$interop',
  function ($scope, $rootScope, $http, $interop) {
    $scope.errorCount = errorCount
    $scope.modelVars = {}
    $scope.cityVars = {}

    $scope.hasAllFieldConditions = function (def) {
      return (def.fieldConditions?.requiredFeature ?? []).every((c) => $scope.$interop.features.includes(c))
    }

    $scope.variableDefs = {
      mailTemplateCreateSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateReceivedSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateApprovalSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasFinancing'],
        },
      },
      mailTemplateApprovalLeasingSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.approvalLeasing'],
        },
      },
      mailTemplateAutoApprovalSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasAutoApproval'],
        },
      },
      mailTemplateConditionedApprovalSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateDeclineSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateForgotPasswordSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateContractSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateOfferSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateUploadNotification: {
        friendly: 'Notifikation ved upload af fil til sag',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.uploadNotification'],
        },
      },
      mailTemplateUploadNotificationSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.uploadNotification'],
        },
      },
      mailTemplateMaterialRequestSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateRequestResidualValueSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateGuarantorDistributorSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateCreateDistributorSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateExternalSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasExternal'],
        },
      },
      mailTemplatePrincipalAmountWarningSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateCreate: {
        friendly: `Oprettelse (mail til ${$interop.prettyModuleName})`,
        datatype: 'text',
        vartype: 'model',
      },
      smsTemplateCreate: {
        friendly: `Oprettelse (sms til ${$interop.prettyModuleName})`,
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateReceived: {
        friendly: 'Modtagelse (mail til sælger)',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateApproval: {
        friendly: 'Godkendelse af finansiering (mail til sælger)',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasFinancing'],
        },
      },
      mailTemplateApprovalLeasing: {
        friendly: 'Godkendelse af leasing (mail til sælger)',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.approvalLeasing'],
        },
      },
      mailTemplateAutoApproval: {
        friendly: 'Godkendelse (AUTO - mail til sælger)',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasAutoApproval'],
        },
      },
      mailTemplateConditionedApproval: {
        friendly: 'Betinget godkendelse (mail til sælger)',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateDecline: {
        friendly: 'Afvisning (mail til sælger)',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateForgotPassword: {
        friendly: 'Glemt adgangskode',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateContract: {
        friendly: 'Materialemail',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateOffer: {
        friendly: 'Tilbudsmail',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateMaterialRequest: {
        friendly: 'Materialeforespørgsel',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateRequestResidualValue: {
        friendly: 'Forespørgsel fra kunde vedr. manglende restværdi',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateGuarantorDistributor: {
        friendly: 'Anviser køber (forhandler)',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateCreateDistributor: {
        friendly: 'Forhandleroprettelse',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateExternal: {
        friendly: 'Eksternt håndteret sag',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasExternal'],
        },
      },
      mailTemplatePrincipalAmountWarning: {
        friendly: 'Advarselsmail vedr. sag med stor hovedstol',
        datatype: 'text',
        vartype: 'model',
      },
      mailTemplateCustomerInfoLink: {
        friendly: 'Mail ved udsendelse af kundelink',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasLegacyCustomerLink'],
        },
      },
      mailTemplateCustomerInfoLink2: {
        friendly: 'Mail ved udsendelse af kundelink for meddebitor',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.mailTemplateCustomerInfoLink2', 'hasLegacyCustomerLink'],
        },
      },
      mailTemplateCustomerInfoLinkSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasLegacyCustomerLink'],
        },
      },
      mailTemplateCustomerInfoLink2Subject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.mailTemplateCustomerInfoLink2Subject', 'hasLegacyCustomerLink'],
        },
      },
      mailTemplateCustomerInfoLinkResponse: {
        friendly: 'Mail ved kundens udfyldelse af kundelink',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasLegacyCustomerLink'],
        },
      },
      mailTemplateCustomerInfoLinkResponseSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasLegacyCustomerLink'],
        },
      },
      smsTemplateCustomerInfoLink: {
        friendly: 'SMS ved udsendelse af kundelink',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['hasLegacyCustomerLink'],
        },
      },
      smsTemplateCustomerInfoLink2: {
        friendly: 'SMS ved udsendelse af kundelink for meddebitor',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.smsTemplateCustomerInfoLink2', 'hasLegacyCustomerLink'],
        },
      },
      mailTemplatePenneoCompletionNotificationSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.penneoCompletionNotification'],
        },
      },
      mailTemplatePenneoCompletionNotification: {
        friendly: 'Mail notifikation ved alle penneounderskrifter',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.penneoCompletionNotification'],
        },
      },
      mailTemplateVirksomhedspantAdvarsel: {
        friendly: 'Advarsel om virksomhedspant',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.companyPledgeWarning'],
        },
      },
      mailTemplateVirksomhedspantAdvarselSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['mailTemplate.companyPledgeWarning'],
        },
      },
      mailTemplateLeadCreated: {
        friendly: 'Lead oprettet',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['leads'],
        },
      },
      mailTemplateLeadCreatedSubject: {
        friendly: 'Emne',
        datatype: 'text',
        vartype: 'model',
        fieldConditions: {
          requiredFeature: ['leads'],
        },
      },
    }
    var saveSuccess = function (res) {
      $scope.setGlobalAlert('Mailskabeloner blev gemt.')

      $scope.setKeepAlert(false)
    }

    $scope.save = function () {
      $http.post('/api/commonConfig/edit', $scope.modelVars).success(saveSuccess)
    }
    $http.post('/api/commonConfig/get').success(function (res) {
      $scope.modelVars = res
      $scope.formIsLoaded = true
    })
  },
]
