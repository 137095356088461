
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/forms/interestAdjustment.html";
      const src = "<h3>Renteændring</h3>\n\n<form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n  <div class=\"clearfix\">\n    <div ng-autofield=\"interestAdjustment\"></div>\n    <a\n      ng-if=\"$interop.module !== 'perb'\"\n      class=\"btn btn-primary pull-right\"\n      ng-show=\"!form.$invalid || !formIsLoaded\"\n      ng-click=\"saveInterestAdjustment()\"\n    >\n      <i class=\"fa fa-save\"></i> &nbsp; Gem\n    </a>\n  </div>\n  <hr />\n  <div ng-if=\"$interop.module !== 'perb'\" class=\"clearfix\">\n    <div ng-autofield=\"firmInterest\"></div>\n    <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" ng-click=\"saveFirmInterest()\"\n      ><i class=\"fa fa-save\"></i> &nbsp; Gem</a\n    >\n  </div>\n  <a\n    ng-if=\"$interop.module === 'perb'\"\n    class=\"btn btn-primary pull-right\"\n    ng-show=\"!form.$invalid || !formIsLoaded\"\n    ng-click=\"saveInterestAdjustment()\"\n  >\n    <i class=\"fa fa-save\"></i> &nbsp; Gem\n  </a>\n  <a ng-if=\"$interop.module === 'perb'\" class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\">\n    <i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl\n  </a>\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      