import { POSTNR_REGEXP } from '../regexes'
import { IAttributes, IScope } from 'angular'
import { assignScope, attrs, makeDirective } from '@/shared/util/directiveHelpers'

interface PostnrScope extends IScope {
  cityVars: { [postnr: string]: string }
}

interface PostnrAttrs extends IAttributes {
  ngPostnr: string
}

export const ngPostnr = makeDirective({
  inject: {
    $http: '$http',
  },
  require: 'ngModel',
  assignScope: assignScope<{ cityVars: { [postnr: string]: string } }>(),
  scope: false,
  attrs: attrs<{ ngPostnr: string }>(),
  link({ $http }, { attrs, scope, ctrl }) {
    ctrl.$formatters.push(function (modelValue) {
      if (Number.isSafeInteger(modelValue)) {
        $http.post<string>('/api/getCity', modelValue).then(function (res) {
          if (typeof scope.cityVars !== 'undefined') {
            scope.cityVars[attrs.ngPostnr] = res.data
          }
        })
      }
      return modelValue
    })

    ctrl.$parsers.unshift(function (viewValue) {
      viewValue = viewValue + ''
      if (POSTNR_REGEXP.test(viewValue)) {
        // it is valid
        ctrl.$setValidity('postnr', true)
        var temp = parseInt(viewValue, 10)
        if (Number.isSafeInteger(temp)) {
          $http.post<string>('/api/getCity', temp).then(
            function (res) {
              if (typeof scope.cityVars !== 'undefined') {
                scope.cityVars[attrs.ngPostnr] = res.data
              }
            },
            function (err) {
              ctrl.$setValidity('postnr', false)
              if (typeof scope.cityVars !== 'undefined') {
                scope.cityVars[attrs.ngPostnr] = 'Ukendt postnr.'
              }
            },
          )
        } else {
          ctrl.$setValidity('postnr', false)
          if (typeof scope.cityVars !== 'undefined') {
            scope.cityVars[attrs.ngPostnr] = 'Ukendt postnr.'
          }
        }

        return temp
      } else {
        if (typeof scope.cityVars !== 'undefined') {
          if (viewValue == '') {
            ctrl.$setValidity('postnr', true)
            scope.cityVars[attrs.ngPostnr] = ''
            return null
          } else {
            ctrl.$setValidity('postnr', false)
            scope.cityVars[attrs.ngPostnr] = ''
            return undefined
          }
        }
        else {
          return undefined
        }
      }
    })
  },
})
