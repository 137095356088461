import creditAnalysisSidebarHeadTemplate from './creditAnalysisSidebarHead.html'
import { IAttributes, IDirective, IScope } from 'angular'

export const creditAnalysisSidebarHead = [
  function (): IDirective<IScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisSidebarHeadTemplate,
      scope: {
        title: '@',
        approval: '=',
      },

      link: function ($scope: {}, elm: JQLite, attrs: IAttributes) {},
    }
  },
]
