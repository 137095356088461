import modalTemplate from '@/shared/templates/digitalSignatureRecipients/companyModal.html'
import { assignScope, makeDirective, ScopeType } from '@/shared/util/directiveHelpers'

export interface Person {
  name: string
  cprCvr: string
  mail: string
}

export interface SaveReq {
  persons: { [cvr: string]: Array<Person> }
  cvrs: Array<string>
}

export interface CvrMetadata {
  name: string
  cvr: string
  powerToBind: string
  roles: Array<Role>
}

export interface Role {
  name: string
  role: string
  mail: string
}

interface PTBModalLoad {
  cvrs: Array<string>
  cvrMetadata: { [cvr: string]: CvrMetadata }
  persons: { [cvr: string]: Array<Person> }
  contractPersons: Array<Person>
}

export const ptbModal = makeDirective({
  inject: {
    $http: '$http'
  },
  restrict: ['attribute'],
  template: modalTemplate,
  scope: {
    aftalenr: {
      type: Number,
      binding: 'twoWay'
    },
    applicationId: {
      type: Number,
      binding: 'twoWay'
    },
    engine: {
      type: Object as unknown as ScopeType<computationEngine.ComputationEngine<any>>,
      binding: 'twoWay'
    },
    whenDone: {
      type: Function as unknown as ScopeType<() => void>,
      binding: 'twoWay'
    },
    persons: {
      type: Object as unknown as ScopeType<{ [cvr: string]: Array<Person> }>,
      binding: 'twoWay'
    },
    onPersonsChange: {
      type: Function as unknown as ScopeType<(persons: { [cvr: string]: Array<Person> }) => void>,
      binding: 'twoWay'
    },
    $hide: {
      type: Function,
      binding: 'function'
    }
  },
  assignScope: assignScope<{
    saveSignerCache: () => void
    anyErrors: () => boolean

    // liste af companies.
    cvrs: string[]
    cvrMetadata: { [cvr: string]: CvrMetadata }
    contractPersons: Person[]
  }>(),
  linkAssign({ $http }, { scope }) {
    return {
      cvrs: [],
      cvrMetadata: {},
      contractPersons: [],

      saveSignerCache() {
        return $http
          .post('/api/ptb/saveSignerCache ', {
            ...(<SaveReq>{ persons: scope.persons, cvrs: scope.cvrs })
          })
          .then(() => {
            scope.onPersonsChange(scope.persons)
            scope.whenDone()
          })
      },

      anyErrors() {
        for (const cvr of scope.cvrs) {
          if (scope.persons[cvr].length == 0) {
            return true
          }
          for (const person of scope.persons[cvr]) {
            if (person.name.length == 0 || person.mail.length == 0) {
              return true
            }
          }
        }
        return false
      }
    }
  },
  link({ $http }, { scope }) {
    if (scope.applicationId) {
      $http.get<PTBModalLoad>('/api/ptb/lookupByApplicationId/' + scope.applicationId).then(function(data) {
        const res = data.data
        scope.cvrs = res.cvrs
        if (scope.cvrs.length !== 0) {
          scope.persons = res.persons
          scope.contractPersons = res.contractPersons
          scope.cvrMetadata = res.cvrMetadata
        }
      })
    }
  }
})
