import { IDirective, IParseService, IScope } from 'angular'

export const selectOnFocus = [
  '$parse',
  function ($parse: IParseService): IDirective {
    return {
      restrict: 'A',
      link(scope: IScope, elem: JQLite) {
        elem.bind('focus', function () {
          const e = elem[0]
          if (e instanceof HTMLInputElement || e instanceof HTMLTextAreaElement) {
            e.select()
          }
          return false
        })
      },
    }
  },
]
