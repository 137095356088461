import creditAnalysisTemplate from './creditAnalysis.html'
import { IAttributes, IDirective, IScope } from 'angular'

import type {
  ApprovalInfo,
  ApprovalSection,
  ApprovalState,
  ButtonState,
  GetAnalysis,
  GetAnalysisContract,
  GetAnalysisCreditro,
  GetAnalysisCvr,
  GetAnalysisExistingCustomer,
  GetAnalysisMonthio,
  GetAnalysisOwners,
  GetAnalysisRKI,
  SaveUserInput,
  SelectOption,
  SetApproval,
  UserTimestamp,
} from './creditAnalysisTypes'

function mkApprovalInfo(): ApprovalInfo {
  return {
    state: 'draft',
    description: '',
    lastModified: null,
    canApprove: true,
    dirty: false,
  }
}

function mkUserInput(): SaveUserInput & { ownerContactIdx: number } {
  return {
    creditRating: {
      deviatesFromCreditPolicy: null,
      annualReportsDescription: '',
      vehicleDescription: '',
      contractDescription: '',
      conclusion: '',
      fleetDescription: '',
      fleetConclusion: '',
    },
    kyc: {
      description: '',
      descriptionAddendum: '',
      ordinaryCustomerAcquisition: null,
      ordinaryCustomerAcquisitionDescription: '',
      customerRelations: null,
      customerRelationsDescription: '',
      lineOfBusiness: null,
      lineOfBusinessDescription: '',
      lineOfBusinessDetails: null,
      lineOfBusinessDetailsDescription: '',
      vehicleCustomerFit: null,
      vehicleCustomerFitDescription: '',
      contractCustomerFit: null,
      contractCustomerFitDescription: '',
      attentionMoneyLaundering: null,
      attentionMoneyLaunderingDescription: '',
      attentionTerrorFinancing: null,
      attentionTerrorFinancingDescription: '',
      otherComments: null,
      otherCommentsDescription: ''
    },
    owners: [],
    ownerContactIdx: 0,
  }
}

interface CreditAnalysisScope extends IScope {
  applicationId: number
  userPermissions: {
    canApproveDisposableIncome: boolean
    canApproveContract: boolean
    canApproveKyc: boolean
  }
  loading: boolean
  //
  userInputDirty: boolean

  setUserInputDirty: () => void
  options: {
    yesNoNull: Array<SelectOption>
  }
  saveUserInput: () => Promise<void>
  saveApproval: (section: ApprovalSection) => (state: ApprovalState) => Promise<void>
  //
  lastModified: null | UserTimestamp
  userInput: null | (SaveUserInput & { ownerContactIdx: number })
  approval: null | {
    disposableIncome: ApprovalInfo
    creditRating: ApprovalInfo
    kyc: ApprovalInfo
  }
  apiData: {
    monthio: GetAnalysisMonthio | null
    rki: GetAnalysisRKI | null
    existingCustomer: GetAnalysisExistingCustomer | null
    newestContract: GetAnalysisContract | null
    lockedContract: GetAnalysisContract | null
    cvr: GetAnalysisCvr | null
    owners: GetAnalysisOwners | null
    creditro: GetAnalysisCreditro | null
  }
  buttonState: { [k: string]: { create?: ButtonState; refresh?: ButtonState } }
  contract: any
  validateOwners: () => boolean
  validateApproval: (section: ApprovalSection) => boolean
  getCustomerType: () => 'company' | 'person' | null
  log: (x: any) => void
  create: (section: any) => Promise<void>
  refresh: (section: any) => Promise<void>
  createAndRefresh: (section: any) => void
  stringify: (x: any) => string
  addOwner: (name: string) => void
}

function applyGetAnalysisToScope($scope: CreditAnalysisScope, data: GetAnalysis, initialLoad: boolean) {
  const { approval: approvalDI } = data.disposableIncome
  const { approval: approvalCR, ...userInputCR } = data.creditRating
  const { approval: approvalKYC, ...userInputKYC } = data.kyc

  const formIsDirty =
    $scope.userInputDirty ||
    ($scope.approval?.disposableIncome.dirty ?? false) ||
    ($scope.approval?.creditRating.dirty ?? false) ||
    ($scope.approval?.kyc.dirty ?? false)

  // Detect if there is a change in approval status:
  const approvalDIStateChanged = ($scope.approval?.disposableIncome.state ?? 'draft') !== approvalDI?.state
  const approvalCRStateChanged = ($scope.approval?.creditRating.state ?? 'draft') !== approvalCR?.state
  const approvalKYCStateChanged = ($scope.approval?.kyc.state ?? 'draft') !== approvalKYC?.state

  const inputAndApprovalRefresh =
    initialLoad || !formIsDirty || approvalDIStateChanged || approvalCRStateChanged || approvalKYCStateChanged

  function undefinedIfM1(n: number): number | undefined {
    if (n === -1) {
      return undefined
    } else {
      return n
    }
  }

  // refresh user input if this is the initial load OR it is not dirty:
  if (inputAndApprovalRefresh) {
    $scope.lastModified = data.lastModified
    $scope.userInput = {
      creditRating: userInputCR,
      kyc: userInputKYC,
      owners: data.inputOwners,
      ownerContactIdx: undefinedIfM1(data.inputOwners.findIndex((v) => v.contact)) ?? 0,
    }
    if ($scope.userInput.owners.length === 0) {
      $scope.userInput.owners.push({ name: '', email: '', contact: false })
    }

    // TODO Approval permissions is not contained yet in the GET response
    $scope.approval = {
      disposableIncome: { canApprove: true, dirty: false, ...approvalDI },
      creditRating: { canApprove: true, dirty: false, ...approvalCR },
      kyc: { canApprove: true, dirty: false, ...approvalKYC },
    }
  }

  // always reload API data!

  // disposableIncome	monthio
  $scope.apiData.monthio = data.monthio
  // creditRating	rki
  $scope.apiData.rki = data.rki
  // creditRating	existingCustomer
  $scope.apiData.existingCustomer = data.existingCustomer
  // creditRating	contract
  $scope.apiData.newestContract = data.newestContract
  $scope.apiData.lockedContract = data.lockedContract
  // creditRating	cvr
  $scope.apiData.cvr = data.cvr
  // creditRating	owners
  $scope.apiData.owners = data.owners
  // kyc	creditro
  $scope.apiData.creditro = data.creditro

  $scope.contract = (data.lockedContract ?? data.newestContract).json
}

async function loadData(
  $scope: CreditAnalysisScope,
  $http: ng.IHttpService,
  $timeout: ng.ITimeoutService,
  initialLoad: boolean,
) {
  if (initialLoad) {
    $scope.loading = true
  }
  const res = await $http.get<'null' | GetAnalysis>(`/api/creditAnalysis/${$scope.applicationId}`)
  if (res.status === 200) {
    return await new Promise((resolve) => {
      $timeout(function () {
        if (res.status == 200) {
          if (initialLoad) {
            $scope.loading = false
          }

          if (res.data != 'null') {
            applyGetAnalysisToScope($scope, res.data, initialLoad)
          } else if (initialLoad) {
            $scope.userInput = mkUserInput()

            $scope.approval = {
              disposableIncome: mkApprovalInfo(),
              creditRating: mkApprovalInfo(),
              kyc: mkApprovalInfo(),
            }
          }
          resolve({})
        }
      })
    })
  } else {
    throw new Error('Failed to load data')
  }
}

export const creditAnalysis = [
  '$timeout',
  '$http',
  function (
    $timeout: ng.ITimeoutService,
    $http: ng.IHttpService,
  ): IDirective<CreditAnalysisScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisTemplate,
      scope: { applicationId: '=', userPermissions: '=' },

      link: function ($scope: CreditAnalysisScope, elm: JQLite, attrs: IAttributes) {
        $scope.log = function (x: any) {
          console.log(x)
        }
        $scope.stringify = (a: any) => {
          return JSON.stringify(a)
        }
        $scope.loading = true
        $scope.userInput = null
        $scope.userInputDirty = false
        $scope.setUserInputDirty = function () {
          $scope.userInputDirty = true
        }
        $scope.apiData = {
          monthio: null,
          rki: null,
          existingCustomer: null,
          newestContract: null,
          lockedContract: null,
          cvr: null,
          owners: null,
          creditro: null,
        }

        $scope.buttonState = {}

        function makeButtonState(name: string) {
          $scope.buttonState[name] = { create: undefined, refresh: undefined }
        }

        makeButtonState('monthio')
        makeButtonState('rki')
        makeButtonState('existingCustomer')
        makeButtonState('owners')
        makeButtonState('cvr')
        makeButtonState('creditro')

        $scope.getCustomerType = function () {
          const b = ($scope.apiData.lockedContract ?? $scope.apiData.newestContract)?.json?.kundetype
          if (b === true) return 'company'
          if (b === false) return 'person'
          return null
        }

        $scope.options = {
          yesNoNull: [
            { value: null, option: '-', isNull: true },
            { value: true, option: 'Ja', isNull: false },
            { value: false, option: 'Nej', isNull: false },
          ],
        }

        $scope.saveUserInput = async function () {
          if (!$scope.approval) return
          if (!$scope.userInput) return

          const postBody: SaveUserInput = {
            creditRating: $scope.userInput.creditRating,
            kyc: $scope.userInput.kyc,
            owners: $scope.userInput.owners,
          }

          if (postBody.owners[$scope.userInput.ownerContactIdx]) {
            postBody.owners[$scope.userInput.ownerContactIdx].contact = true
          }

          const res = await $http.post(`/api/creditAnalysis/${$scope.applicationId}`, postBody)
          if (res.status == 200) {
            await loadData($scope, $http, $timeout, false)
            $scope.userInputDirty = false
            return
          } else {
            throw Error('Update failed')
          }
        }

        $scope.saveApproval = function (section: ApprovalSection) {
          return async function (state: ApprovalState) {
            await $scope.saveUserInput()
            const setApprovalBody: SetApproval = {
              state,
              description: ($scope.approval && $scope.approval[section].description) ?? '',
              lamports: {
                section: section as any,
                monthio: $scope.apiData.monthio?.lamport ?? null,
                rki: $scope.apiData.rki?.lamport ?? null,
                existingCustomer: $scope.apiData.existingCustomer?.lamport ?? null,
                contract: $scope.apiData.newestContract?.lamport ?? null,
                cvr: $scope.apiData.cvr?.lamport ?? null,
                owners: $scope.apiData.owners?.lamport ?? null,
                creditro: $scope.apiData.creditro?.lamport ?? null,
              },
            }

            const res = await $http.post(
              `/api/creditAnalysis/${$scope.applicationId}/approval/${section}`,
              setApprovalBody,
            )
            if (res.status == 200) {
              await loadData($scope, $http, $timeout, false)
              return
            } else {
              console.error('approval save failed', section, state)
              throw new Error('approval save failed')
            }
          }
        }

        async function handleEffects(section: string, button: 'create' | 'refresh', effects: string[]) {
          if (effects.includes('missingFields') || effects.includes('missingEffect')) {
            await $timeout(() => ($scope.buttonState[section][button] = 'error'))
          } else if (effects.includes('executedUpdate') || effects.includes('executedClear')) {
            await loadData($scope, $http, $timeout, false)
            await $timeout(() => ($scope.buttonState[section][button] = 'success'))
          } else if (effects.includes('alreadyExecuted') || effects.length === 0) {
            await $timeout(() => ($scope.buttonState[section][button] = 'success'))
          } else {
            //Unknown effect
            await $timeout(() => ($scope.buttonState[section][button] = 'error'))
          }
        }

        $scope.create = async function (x: string) {
          $scope.buttonState[x].create = 'loading'
          try {
            const res = await $http.post<{ effects: string[] }>(
              `/api/creditAnalysis/${$scope.applicationId}/${x}/create`,
              {},
            )
            if (res.status === 200) {
              await handleEffects(x, 'create', res.data.effects)
            } else {
              console.error(`Create ${x} failed`)
            }
          } catch (e) {
            $scope.buttonState[x].create = 'httperror'
          }
        }

        $scope.refresh = async function (x: string) {
          $scope.buttonState[x].refresh = 'loading'

          try {
            const res = await $http.post<{ effects: string[] }>(
              `/api/creditAnalysis/${$scope.applicationId}/${x}/refresh`,
              {},
            )
            if (res.status === 200) {
              await handleEffects(x, 'refresh', res.data.effects)
            } else {
              console.error(`Refresh ${x} failed`)
            }
          } catch (e) {
            $scope.buttonState[x].refresh = 'httperror'
          }
        }

        $scope.createAndRefresh = async function (x: string) {
          await $scope.create(x)
          if ($scope.buttonState[x].create !== 'success') {
            return
          }

          let wait = 500
          let totalTimeWaited = 0
          while (totalTimeWaited < 30000) {
            await $timeout(() => {}, wait, false)
            await $scope.refresh(x)

            let isPending
            let status
            switch (x) {
              case 'monthio':
                status = $scope.apiData.monthio?.status
                isPending = status === 'pre_request' || status === 'pending'
                break
              case 'creditro':
                status = $scope.apiData.creditro?.status
                isPending = status === 'pre_request' || status === 'pending'
                break
              case 'rki':
                const rkiData = $scope.apiData.rki?.data?.data?.creditlinkCaseByExternalId?.other?.at(0)?.badDebtStatus
                status = rkiData.enumCase
                isPending = status === 'PENDING'
                break
              case 'cvr':
                const cvrData = $scope.apiData.cvr?.data?.data?.creditlinkCaseByExternalId?.other?.at(0)?.cvrDataStatus
                status = cvrData?.enumCase
                isPending = status === 'PENDING'
                break
            }

            if (isPending) {
              $scope.buttonState[x].refresh = 'loading'
            }

            if ($scope.buttonState[x].refresh === 'success') {
              return
            } else {
              totalTimeWaited += wait

              if (wait < 2000) {
                wait *= 2
              }
            }
          }
        }

        $scope.addOwner = (name) => {
          const owners = $scope.userInput?.owners
          if (owners) {
            const firstEmptyOwner = owners.find((o) => o.name === '')
            if (firstEmptyOwner) {
              firstEmptyOwner.name = name
            } else {
              owners.push({ name, email: '', contact: false })
            }
            $scope.setUserInputDirty()
          }
        }

        function validateEmail(email: string | undefined) {
          if (email) {
            const input = document.createElement('input')
            input.type = 'email'
            input.required = true
            input.value = email

            return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(email)
          } else {
            return false
          }
        }

        $scope.validateOwners = function () {
          const nameValid = $scope.contract.navn.length
          const owners = $scope.userInput?.owners
          if (!owners) {
            return false
          }

          if ($scope.contract.kundetype) {
            const contact = owners.find((_o, i) => i === $scope.userInput?.ownerContactIdx)
            const ownerNamesValid = owners.every((o) => o.name.length)
            const contactEmailValid = validateEmail(contact?.email)
            const cvrValid = $scope.contract.cvr?.length === 8

            return ownerNamesValid && contactEmailValid && cvrValid && nameValid
          } else {
            const emailValid = validateEmail($scope.contract.mail)
            const cprValid = $scope.contract.cpr?.length === 10
            return nameValid && emailValid && cprValid
          }
        }

        $scope.validateApproval = (section: ApprovalSection) => {
          const userInput = $scope.userInput
          if (!userInput) {
            return false
          }

          switch (section) {
            case 'disposableIncome':
              return true
            case 'creditRating':
              const sharedCrValidation =
                userInput.creditRating.vehicleDescription !== '' &&
                userInput.creditRating.contractDescription !== '' &&
                userInput.creditRating.deviatesFromCreditPolicy !== null &&
                userInput.creditRating.conclusion !== ''

              const cvrValidation =
                !$scope.contract.kundetype ||
                (userInput.creditRating.annualReportsDescription !== '' && $scope.validateOwners())

              const bilstatistikValidation =
                !$scope.contract.kundetype ||
                (userInput.creditRating.fleetDescription !== '' && userInput.creditRating.fleetConclusion != '')

              return sharedCrValidation && cvrValidation && bilstatistikValidation

            case 'kyc':
              return (
                userInput.kyc.description !== '' &&
                userInput.kyc.descriptionAddendum !== '' &&
                userInput.kyc.ordinaryCustomerAcquisition !== null &&
                userInput.kyc.ordinaryCustomerAcquisitionDescription !== '' &&
                userInput.kyc.customerRelations !== null &&
                userInput.kyc.customerRelationsDescription !== '' &&
                userInput.kyc.lineOfBusiness !== null &&
                userInput.kyc.lineOfBusinessDescription !== '' &&
                userInput.kyc.lineOfBusinessDetails !== null &&
                userInput.kyc.lineOfBusinessDetailsDescription !== '' &&
                userInput.kyc.vehicleCustomerFit !== null &&
                userInput.kyc.vehicleCustomerFitDescription !== '' &&
                userInput.kyc.contractCustomerFit !== null &&
                userInput.kyc.contractCustomerFitDescription !== '' &&
                userInput.kyc.attentionMoneyLaundering !== null &&
                userInput.kyc.attentionMoneyLaunderingDescription !== '' &&
                userInput.kyc.attentionTerrorFinancing !== null &&
                userInput.kyc.attentionTerrorFinancingDescription !== '' &&
                userInput.kyc.otherComments !== null &&
                userInput.kyc.otherCommentsDescription !== ''
              )
            default:
              return false
          }
        }

        // on subsequent saves
        $scope.$on('formCtrl.save.success', function () {
          loadData($scope, $http, $timeout, false)
        })

        loadData($scope, $http, $timeout, true)
      },
    }
  },
]
