import { isEmpty } from './isEmpty'
import type { INgModelController } from 'angular'

export function rerenderViewValue(val: any, ctrl: INgModelController) {
  var viewValue = val
  if (!isEmpty(viewValue)) {
    //console.log('viewvalue', viewValue)
    for (var i in ctrl.$formatters) {
      viewValue = ctrl.$formatters[i](viewValue)
      //  console.log('viewvalue', viewValue, ctrl.$formatters[i])
    }
    //ctrl.$viewValue = viewValue;
    ctrl.$setViewValue(viewValue)
    ctrl.$render()
  } /*else {
        ctrl.$setViewValue('');
        ctrl.$render();
	  }*/
}
