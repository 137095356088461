import creditPurgingOfUnworthyTemplate from '../templates/disposableIncomeComputer.html'
import {
  IAttributes,
  IDirective,
  IHttpService,
  IPromise,
  IQService,
  IRootScopeService,
  IScope,
  ITimeoutService,
} from 'angular'
// @ts-ignore
import { danishLocaleNumber } from '../functions/numbers'
import { CarleasingInterop } from '../carleasingInterop'
interface KreditvaerdighedServerVars {}
interface KreditvaerdighedModelVars {}
interface DispESkat {
  pdfExists: boolean
  samtykkeUUID: string
  table: ESkatTable
  tableIsEmpty: boolean
  rawValues: RawValues
}

interface DispGaeldOplysninger {
  gaeldList: [Gaeld]
}

interface Gaeld {
  startDato: string
  regDato: string
  identifier: string
  gaeldsType: string
  rest: number
  rente: number
}

interface RawValues {
  validPeriod: string
  aarligSkatteOplysningEngangsudbetalingerBeloeb: number
  aarligSkatteOplysningPensionDagpengeStipendieBeloeb: number
  aarligSkatteOplysningLoenIndkomstBeloeb: number
  aarligSkatteOplysningPersonligIndkomstBeloeb: number
  aarligSkatteOplysningKapitalindkomstBeloeb: number
  aarligSkatteOplysningLigningsmaessigtFradragBeloeb: number
  aarligSkatteOplysningSkattepligtigIndkomstBeloeb: number
  aarligSkatteOplysningBeregnetSkatBeloeb: number
  aarligSkatteOplysningBundskatBeloeb: number
  aarligSkatteOplysningKommuneKirkeskatBeloeb: number
  aarligSkatteOplysningEjendomsvaerdiskatBeloeb: number
  aarligSkatteOplysningSamletAktieindkomstBeloeb: number
  aarligSkatteOplysningArbejdsmarkedsbidragBeloeb: number
}

interface ESkatTableSection {
  header: string
  values: ReadonlyArray<ESkatTableValue>
  remainder: ESkatTableValue | null
  result: ESkatTableValue
}
interface ESkatTableValue {
  value: number
  name: string
  flipSign: boolean
}

interface ESkatSubtable {
  tableSections: ReadonlyArray<ESkatTableSection>
  name: string
}

interface ESkatTable {
  validPeriod: string
  subtables: ReadonlyArray<ESkatSubtable>
}

interface PersonOplysBruttoIndkomst {
  aindkomstTilAMBidrag: number
  aindkomstIkkeTilAMBidrag: number
}
interface PersonOplysSkat {
  indeholdtAMBeloeb: number
  indeholdtASkatBeloeb: number
  atpBidragBeloeb: number
}
interface PersonOplysPension {
  pensionsandelBeloeb: number
}

interface BlanketOplysninger {
  bruttoIndkomst: PersonOplysBruttoIndkomst
  skat: PersonOplysSkat
  pension: PersonOplysPension
  nettoIndkomst: number
}

interface DispIndkomstOplysninger {
  cpr: string
  dimMonth: ReadonlyArray<string>
  dimCompany: ReadonlyArray<string>
  indkomstMonthCompany: Record<string, Record<string, BlanketOplysninger>>
  nettoindkomst: Record<string, number>
  bruttoindkomst: Record<string, number>
}

interface ESkat {
  dispESkat: DispESkat
  dispIndkomstOplysninger: DispIndkomstOplysninger
  dispGaeldOplysninger: DispGaeldOplysninger
}

interface DisposableIncomeComputerScope extends IScope {
  gaeldOplysninger: DispGaeldOplysninger
  getIndkomst: (indkomstOplysninger: DispIndkomstOplysninger, date: string, companyName: string) => number
  eSkatDatePrettyPrint: (x: string) => string
  indkomstOplysninger: DispIndkomstOplysninger
  eSkatPrettyPrint: (x: number) => string
  vocabulary: Map<string, string>
  eSkatIsLoading: boolean
  haftBilFoerToString: () => string
  boligTypeToString: () => string
  cancel: () => void
  realEngine: computationEngine.ComputationEngine<any>
  dummyEngine: DummyEngine
  data: any
  valuesJson: any
  save: () => IPromise<void>
  goToEditMode: () => void
  applicationId: number
  groups: ReadonlyArray<any>
  modelVars: KreditvaerdighedModelVars
  serverVars: KreditvaerdighedServerVars
  engine: any
  variableDefs: any
  editMode: Boolean
  renderAmount: (value: number) => string
  eSkatData: DispESkat

  $interop: CarleasingInterop
}

class DummyEngine {
  variableDefs: any
  values: any

  constructor(variableDefs: any, values: any) {
    this.variableDefs = variableDefs
    this.values = values
  }

  eval(identifier: any, fallback: any) {
    if (this.values[identifier] === undefined) {
      return fallback
    }
    return this.values[identifier]
  }

  getValues() {
    return this.values
  }
  resetComputation() {}

  clearExpressionCache() {}

  getVariableDefs() {
    return this.variableDefs
  }
}

export const disposableIncomeComputer = [
  '$http',
  '$rootScope',
  '$q',
  '$timeout',
  '$interop',
  function (
    $http: IHttpService,
    $rootScope: IRootScopeService,
    $q: IQService,
    $timeout: ITimeoutService,
    $interop: CarleasingInterop,
  ): IDirective<DisposableIncomeComputerScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditPurgingOfUnworthyTemplate,
      scope: {
        applicationId: '=',
        valuesJson: '=',
      },

      link: function ($scope: DisposableIncomeComputerScope, elm: JQLite, attrs: IAttributes) {
        $scope.serverVars = {}
        $scope.modelVars = {}
        $scope.editMode = false


        $scope.renderAmount = function (value) {
          return danishLocaleNumber(Math.ceil(value).toString())
        }

        const emptyFunction = function () {
          return undefined
        }

        $scope.goToEditMode = function () {
          $scope.engine = $scope.realEngine
          $scope.editMode = true
        }

        $scope.save = function () {
          return $http
            .post('/api/kreditvaerdighed/save', {
              applicationId: $scope.applicationId,
              modelVars: $scope.modelVars,
              computedValues: $scope.engine.getValues(),
            })
            .then(() => {
              load().then(() => {
                $timeout(() => {
                  $scope.engine = $scope.dummyEngine
                  $scope.editMode = false
                })
              })
            })
        }

        $scope.cancel = function () {
          $scope.engine = $scope.dummyEngine
          $scope.editMode = false
        }

        $scope.boligTypeToString = function () {
          if ($scope.valuesJson.boligType === 0) return 'Ejerbolig'
          if ($scope.valuesJson.boligType === 1) return 'Lejebolig'
          else return 'Andelsbolig'
        }
        $scope.haftBilFoerToString = function () {
          if ($scope.valuesJson.haftBilFoer === 0) return 'Ja'
          else return 'Nej'
        }

        $scope.getIndkomst = function (
          indkomstOplysninger: DispIndkomstOplysninger,
          date: string,
          companyName: string,
        ): number {
          const info = indkomstOplysninger.indkomstMonthCompany[date][companyName]
          return info.bruttoIndkomst.aindkomstTilAMBidrag + info.bruttoIndkomst.aindkomstIkkeTilAMBidrag
        }

        $scope.eSkatPrettyPrint = function (x: number | string): string {
          if (x !== undefined)
            return x
              .toString()
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          else return ''
        }
        $scope.eSkatDatePrettyPrint = function (x: string): string {
          const year = x.substr(0, 4)
          const month = x.substr(5, 2)
          let map = new Map([
            ['01', 'Jan'],
            ['02', 'Feb'],
            ['03', 'Mar'],
            ['04', 'Apr'],
            ['05', 'Maj'],
            ['06', 'Jun'],
            ['07', 'Jul'],
            ['08', 'Aug'],
            ['09', 'Sep'],
            ['10', 'Okt'],
            ['11', 'Nov'],
            ['12', 'Dec'],
          ])

          return year + ' ' + map.get(month)
        }

        function load() {
          $scope.eSkatIsLoading = true
          return $http.get<any>('/api/kreditvaerdighed/load/' + $scope.applicationId).then(function (data) {
            // Internally ComputationEngine calls 'setDefaultValues()' in its constructor.
            // Therefore, we initialize ComputationEngine before adding modelVars values

            $scope.variableDefs = data.data.varDefs
            if (data.data.eSkat !== undefined) {
              $scope.eSkatData = data.data.eSkat.dispESkat
              $scope.indkomstOplysninger = data.data.eSkat.dispIndkomstOplysninger
              $scope.gaeldOplysninger = data.data.eSkat.dispGaeldOplysninger
            }
            $scope.eSkatIsLoading = false

            $scope.realEngine = new $interop.ComputationEngine(
              $scope.modelVars,
              $scope.serverVars,
              emptyFunction,
              emptyFunction,
              emptyFunction,
              $scope.variableDefs,
            )

            $scope.engine = $scope.realEngine
            $scope.editMode = true

            if (data.data.disposableIncome !== undefined) {
              $scope.dummyEngine = new DummyEngine($scope.variableDefs, data.data.disposableIncome.computedValues)
              $scope.engine = $scope.dummyEngine
              $scope.editMode = false
            }

            if (data.data.disposableIncome !== undefined) {
              for (const val in data.data.disposableIncome.modelVars) {
                if (data.data.disposableIncome.modelVars[val] !== undefined) {
                  ;($scope.modelVars as any)[val] = data.data.disposableIncome.modelVars[val]
                } else {
                  ;($scope.modelVars as any)[val] = $scope.variableDefs[val].defaultValue
                }
              }
            }

            for (const val of data.data.modelVarFields) {
              ;($scope.serverVars as any)['application_' + val] = $scope.valuesJson[val]
            }
            for (const val in data.data.serverVars) {
              ;($scope.serverVars as any)[val] = data.data.serverVars[val]
            }

            $scope.groups = data.data.groups

            $scope.$watchCollection('modelVars', function (newVar, oldVar) {
              if ($scope.engine !== undefined) {
                $scope.engine.clearExpressionCache()
              }
            })
          })
        }

        $scope.$watch('applicationId', function (id) {
          if (id !== undefined) {
            load()
          }
        })

        $scope.$on('disposableIncome.save', function (event, argsOpt) {
          var args = argsOpt || {}
          $scope.save()
        })
      },
    }
  },
]
