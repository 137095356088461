
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/register.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>Registeropslag</h3>\n</div>\n\n<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <ul class=\"nav nav-pills nav-stacked\">\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.rki') === 0}\">\n        <a ng-href=\"/register/rki\">Experian RKI</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.dowjones') === 0}\">\n        <a ng-href=\"/register/dowjones\">Dow Jones</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.cvr') === 0}\">\n        <a ng-href=\"/register/cvr\">Det Centrale Virksomhedsregister (CVR)</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.dmr') === 0}\">\n        <a ng-href=\"/register/dmr\">DMR - Motorregistret</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.bilbogen') === 0}\">\n        <a ng-href=\"/register/bilbogen\">Bilbogen</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.tinglysning') === 0}\">\n        <a ng-href=\"/register/tinglysning\">Tingbogen</a>\n      </li>\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.register.synsrapport') === 0}\">\n        <a ng-href=\"/register/synsrapport\">Synsrapport</a>\n      </li>\n    </ul>\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      