import { createFunctions } from '@/computationengine/functions'
import { ComputationEngineLogger } from '@/computationengine/logger'

type JsepNode =
  | JsepLiteralNode
  | JsepBinaryExpressionNode
  | JsepUnaryExpressionNode
  | JsepCallExpressionNode
  | JsepIdentifierNode
  | JsepMemberExpressionNode

interface JsepNodeBase {
  type: string
}

interface JsepLiteralNode extends JsepNodeBase {
  type: 'Literal'
  value: computationEngine.Scalar
  raw: string
}

interface JsepBinaryExpressionNode extends JsepNodeBase {
  type: 'BinaryExpression' | 'LogicalExpression'
  left: JsepNode
  right: JsepNode
  operator: string
}

interface JsepUnaryExpressionNode extends JsepNodeBase {
  type: 'UnaryExpression'
  argument: JsepNode
  operator: string
}

interface JsepCallExpressionNode extends JsepNodeBase {
  type: 'CallExpression'
  callee: JsepNode
  arguments: JsepNode[]
}

interface JsepIdentifierNode extends JsepNodeBase {
  type: 'Identifier'
  name: string
}

interface JsepMemberExpressionNode extends JsepNodeBase {
  type: 'MemberExpression'
  object: JsepNode
  property: JsepNode
}

declare function jsep(expression: string): JsepNode

function toposort(nodes: string[], edges: [string, string][]) {
  let cursor = nodes.length,
    sorted: string[] = new Array(cursor),
    visited: Record<string, boolean> = {},
    i = cursor

  while (i--) {
    if (!visited[i]) visit(nodes[i], i, [])
  }

  return sorted

  function visit(node: string, i: number, predecessors: string[]) {
    if (predecessors.indexOf(node) >= 0) {
      throw new Error('Cyclic dependency: ' + JSON.stringify(node))
    }

    if (!~nodes.indexOf(node)) {
      throw new Error(
        'Found unknown node. Make sure to provided all involved nodes. Unknown node: ' + JSON.stringify(node)
      )
    }

    if (visited[i]) return
    visited[i] = true

    // outgoing edges
    const outgoing = edges.filter(function(edge) {
      return edge[0] === node
    })
    if ((i = outgoing.length)) {
      const preds = predecessors.concat(node)
      do {
        const child = outgoing[--i][1]
        visit(child, nodes.indexOf(child), preds)
      } while (i)
    }

    sorted[--cursor] = node
  }
}

function uniqueNodes(arr: [string, string][]) {
  const res = []
  for (let i = 0, len = arr.length; i < len; i++) {
    const edge = arr[i]
    if (res.indexOf(edge[0]) < 0) res.push(edge[0])
    if (res.indexOf(edge[1]) < 0) res.push(edge[1])
  }
  return res
}

interface ComputationEngineOptions<VariableDefs extends { [name: string]: computationEngine.VariableDef } = {}> {
  modelVars?: Partial<{ [Property in keyof VariableDefs]: computationEngine.Scalar }>
  serverVars?: computationEngine.ServerVars
  functionImpls?: {
    getUserId?: () => number | undefined
    isSuperAdmin?: () => boolean | undefined
    splitInsuranceCompanyId?: () => number | undefined
    getHasAutoItCase?: () => boolean
  }
  customVariableDefs?: VariableDefs

  keepModelvars?: boolean
  cachedExpressionIdentifiers?: { [k: string]: JsepNode }
  logger?: ComputationEngineLogger
}

const optionsArr: (keyof ComputationEngineOptions)[] = [
  'modelVars',
  'serverVars',
  'functionImpls',
  'customVariableDefs'
]

export class ComputationEngine2<VariableDefs extends { [name: string]: computationEngine.VariableDef } = {}> {
  readonly #modelVars: Partial<{ [Property in keyof VariableDefs]: computationEngine.Scalar }>
  readonly serverVars?: computationEngine.ServerVars

  #reportTypeErrors = true
  readonly variableDefs: VariableDefs
  #expressionCache: Record<string, computationEngine.Scalar> = {}

  readonly #functions: Record<string, Function>
  readonly #logger: ComputationEngineLogger

  constructor(
    modelVarsOrOptions?:
      | Partial<{ [Property in keyof VariableDefs]: computationEngine.Scalar }>
      | ComputationEngineOptions<VariableDefs>,
    serverVars?: computationEngine.ServerVars,
    getUserId?: () => number | undefined,
    isSuperAdmin?: () => boolean | undefined,
    splitInsuranceCompanyId?: () => number | undefined,
    customVariableDefs?: VariableDefs,
    getHasAutoItCase?: () => boolean
  ) {
    if (modelVarsOrOptions && optionsArr.some((k) => k in modelVarsOrOptions)) {
      if (serverVars || getUserId || splitInsuranceCompanyId || customVariableDefs || getHasAutoItCase) {
        throw new Error('Can\'t use both options object, and normal parameters when constructing ComputationEngine')
      }

      const options = modelVarsOrOptions as ComputationEngineOptions<VariableDefs>
      this.#modelVars =
        (options.modelVars as Partial<{ [Property in keyof VariableDefs]: computationEngine.Scalar }>) ?? {}
      this.serverVars = options.serverVars
      this.variableDefs = (options.customVariableDefs ?? {}) as VariableDefs

      this.#logger = options.logger ?? ComputationEngineLogger.fromConsole()

      this.#functions = createFunctions(
        this.#logger,
        options.serverVars,
        options.functionImpls?.getUserId,
        options.functionImpls?.isSuperAdmin,
        options.functionImpls?.splitInsuranceCompanyId,
        options.functionImpls?.getHasAutoItCase,
      )

      if (!options.keepModelvars) {
        this.#setDefaultValues()
      }

      if (options.cachedExpressionIdentifiers) {
        this.#cachedExpressionIdentifiers = options.cachedExpressionIdentifiers
      }
    } else {
      this.#modelVars =
        (modelVarsOrOptions as Partial<{ [Property in keyof VariableDefs]: computationEngine.Scalar }>) ?? {}
      this.serverVars = serverVars
      this.variableDefs = (customVariableDefs ?? {}) as VariableDefs
      this.#logger = ComputationEngineLogger.fromConsole()
      this.#functions = createFunctions(this.#logger, serverVars, getUserId, isSuperAdmin, splitInsuranceCompanyId, getHasAutoItCase)

      this.#setDefaultValues()
    }
  }

  #cachedResetOrdering: string[] | null = null

  get resetOrdering(): string[] {
    if (this.#cachedResetOrdering) {
      return this.#cachedResetOrdering
    }

    const resetDependencies: [string, string][] = []
    for (const varName in this.variableDefs) {
      const varDef = this.variableDefs[varName]
      if (varDef.reset) {
        for (const i in varDef.reset) {
          resetDependencies.push([varDef.reset[i], varName])
        }
      }
    }

    this.#cachedResetOrdering = toposort(uniqueNodes(resetDependencies), resetDependencies).filter((x) => {
      return this.variableDefs[x].reset !== undefined
    })

    return this.#cachedResetOrdering
  }

  // For backwards compatibility
  getVariableDefs() {
    return this.variableDefs
  }

  setErrorReporting(args: { typeErrors?: boolean }) {
    this.#reportTypeErrors = args.typeErrors ?? this.#reportTypeErrors
  }

  #cachedExpressionIdentifiers: { [k: string]: JsepNode } | null = null

  get expressionIdentifiers(): { [k: string]: JsepNode } {
    if (this.#cachedExpressionIdentifiers) {
      return this.#cachedExpressionIdentifiers
    }

    const cache: { [k: string]: JsepNode } = {}

    for (const identifier in this.variableDefs) {
      const varDef = this.variableDefs[identifier]
      if (varDef.vartype === 'expression') {
        cache[identifier] = jsep(varDef.expression as computationEngine.Expr)
      }

      for (const subIdentifier in varDef) {
        if (subIdentifier.endsWith('Expr')) {
          const exprName = `${identifier}.${subIdentifier.substring(0, subIdentifier.length - 4)}`
          cache[exprName] = jsep(varDef[subIdentifier])
        }
      }
    }
    this.#cachedExpressionIdentifiers = cache

    return cache
  }

  #cachedNodes: { [k: string]: JsepNode } = {}

  #nodeFromStr(str: computationEngine.Expr) {
    const exprIdents = this.expressionIdentifiers

    if (exprIdents[str]) {
      return exprIdents[str]
    } else if (this.#cachedNodes[str]) {
      return this.#cachedNodes[str]
    } else {
      const node = jsep(str)
      this.#cachedNodes[str] = node
      return node
    }
  }

  //Type here is very hacky
  eval<A = computationEngine.Scalar>(str: computationEngine.Expr, fallback?: A): A {
    try {
      const temp = this.#evalNode(this.#nodeFromStr(str))
      let out
      if (temp == temp) {
        // NaN should never come out of computation engine!!
        out = temp
      } else {
        out = undefined
      }
      if (out === undefined) {
        return fallback as A
      }
      return out as A
    } catch (error) {
      this.#logger.error('ERROR:' + str + ';', error)
      return fallback as A
    }
  }

  evalUnsafe(str: computationEngine.Expr) {
    try {
      return this.#evalNode(this.#nodeFromStr(str))
    } catch (error) {
      return 'ERROR:' + str + ';' + error
    }
  }

  #setDefaultValues(justInitialized?: { [Property in keyof VariableDefs]: never[] }) {
    for (const i in this.#modelVars) {
      delete this.#modelVars[i]
    }

    const exprIdents = this.expressionIdentifiers

    for (const i in this.variableDefs) {
      const varDef = this.variableDefs[i]
      if (varDef.vartype === 'model') {
        const defaultNode: JsepNode | undefined = exprIdents[`${i}.default`]

        this.#modelVars[i] = varDef.defaultValue ?? defaultNode !== undefined ? this.#getLiteralValue(defaultNode) : undefined

        if (justInitialized !== undefined) {
          justInitialized[i] = []
        }
      }
    }
  }

  resetComputation(justInitialized?: { [Property in keyof VariableDefs]: never[] }) {
    this.#expressionCache = {}
    this.#setDefaultValues()

    const exprIdents = this.expressionIdentifiers

    for (const i in this.variableDefs) {
      const varDef = this.variableDefs[i]
      if (varDef.vartype === 'model') {
        const defaultNode: JsepNode | undefined = exprIdents[`${i}.default`]
        if (defaultNode !== undefined) {
          this.#modelVars[i] = this.#evalNode(defaultNode)

          if (justInitialized !== undefined) {
            justInitialized[i] = []
          }
        }
      }
    }
    this.#expressionCache = {}
  }

  resetUndefinedComputations() {
    this.#expressionCache = {}

    const exprIdents = this.expressionIdentifiers

    for (const i in this.variableDefs) {
      const varDef = this.variableDefs[i]
      if (varDef.vartype === 'model') {
        const defaultNode: JsepNode | undefined = exprIdents[`${i}.default`]
        if (defaultNode !== undefined && this.#modelVars[i] === undefined) {
          this.#modelVars[i] = this.#evalNode(defaultNode)
        }
      }
    }

    this.#expressionCache = {}
  }

  clearExpressionCache() {
    this.#expressionCache = {}
  }

  getValues() {
    const out: { [Property in keyof VariableDefs]: computationEngine.Scalar } = {} as {
      [Property in keyof VariableDefs]: computationEngine.Scalar
    }
    for (const identifier in this.variableDefs) {
      out[identifier] = this.#getValue(identifier)
      for (const subIdentifier in this.variableDefs[identifier]) {
        if (subIdentifier.endsWith('Expr')) {
          const exprName = `${identifier}.${subIdentifier.substring(0, subIdentifier.length - 4)}`
          out[exprName as keyof VariableDefs] = this.#getValue(exprName)
        }
      }
    }
    return out
  }

  #getExpressionValue(rawIdentifier: string) {
    if (this.#expressionCache[rawIdentifier] !== undefined) {
      return this.#expressionCache[rawIdentifier]
    }

    const exprIdents = this.expressionIdentifiers
    if (exprIdents[rawIdentifier]) {
      const ret = this.#evalNode(exprIdents[rawIdentifier])
      this.#expressionCache[rawIdentifier] = ret as computationEngine.Scalar
      return ret
    } else {

      const arrIdentifier = rawIdentifier.split('.', 2)
      const identifier = arrIdentifier[0]
      const varDef = this.variableDefs[identifier]

      if (arrIdentifier.length === 1 && varDef.vartype !== 'expression') {
        throw new Error(`Tried to evaluate ${rawIdentifier}, but it has type ${varDef.vartype}`)
      }

      throw new Error(`Could not find expression ${rawIdentifier}`)
    }
  }

  #getValue(identifier: string) {
    if (identifier.includes('.')) {
      return this.#getExpressionValue(identifier)
    }

    const varDef = this.variableDefs[identifier]
    if (varDef === undefined) {
      throw `Variabel med navn ${identifier} kunne ikke findes`
    }
    const expectedTypes = this.#dataTypeToTypes(varDef.datatype, varDef.required)

    switch (varDef.vartype) {
      case 'server':
        let temp = this.serverVars[identifier]

        if (temp === undefined) {
          let fallback = varDef.fallbackValue
          if (varDef.fallbackExpr !== undefined) {
            fallback = this.#getExpressionValue(`${identifier}.fallback`)
          }

          if (fallback === undefined) {
            fallback = expectedTypes.length ? this.#valueZero(fallback, expectedTypes[0]) : 0
          }

          if (Object.keys(this.serverVars).length > 0) {
            this.#logger.warn(
              `expected server variable ${identifier} not found, even though serverVars object is non-empty. Returning fallback value ${fallback}. This may break stuff`
            )
          }

          this.#assertSpecificType(fallback, expectedTypes, null, identifier + '.fallback', null)
          return fallback
        }
        const lookupBy = varDef.lookupBy
        if (Array.isArray(lookupBy)) {
          const lookupByValues = []
          for (const lookupId of lookupBy) {
            const value = this.#getValue(lookupId)
            temp = temp[value]
            lookupByValues.push(value)
            if (temp === undefined) {
              let fallback = varDef.fallbackValue
              if (varDef.fallbackExpr !== undefined) {
                fallback = this.#getExpressionValue(`${identifier}.fallback`)
              }

              if (fallback === undefined) {
                const lookupStr = lookupByValues.map(v => `[${v}]`).join('')
                this.#logger.warn(
                  `expected server variable ${identifier}${lookupStr} not found. Returning fallback value ${fallback}. This may break stuff`
                )

                fallback = expectedTypes.length ? this.#valueZero(fallback, expectedTypes[0]) : undefined
              }

              this.#assertSpecificType(fallback, expectedTypes, null, identifier + '.fallback', null)
              return fallback
            }
          }
        }
        return temp

      case 'model':
        this.#assertSpecificType(this.#modelVars[identifier], expectedTypes, null, identifier, null)
        return this.#modelVars[identifier]

      case 'expression':
        const res = this.#getExpressionValue(identifier)
        this.#assertSpecificType(res, expectedTypes, null, identifier, null)
        return res

      default:
        throw `Variabel med navn ${identifier} har ikke variabeltype`
    }
  }

  #getFunc(identifier: string) {
    if (this.#functions[identifier] !== undefined) {
      return this.#functions[identifier]
    }
    throw `Funktion med navn ${identifier} kunne ikke findes`
  }

  #getLiteralValue(node: JsepNode) {
    if (node === undefined) return undefined
    if (node.type === 'Literal') {
      return node.value
    }
    return undefined
  }

  #valueZero(value: any, tpe?: string | typeof Date) {
    if (!tpe) {
      tpe = typeof value
    }

    switch (tpe) {
      case 'undefined':
      case 'object':
      case 'function':
      case 'symbol':
        if (typeof tpe !== 'undefined') {
          this.#logger.error(`Found value ${value} with unexpected type ${typeof value}`)
        }
        return undefined
      case 'boolean':
        return false
      case 'number':
        return 0
      case 'string':
        return ''
      case 'bigint':
        return BigInt(0)
      default:
        if (this.#reportTypeErrors) {
          this.#logger.error(`Don't know what default value to give to type ${tpe}`)
        }
        return 0
    }
  }

  #pprintNode(node: JsepNode | null): string {
    if (!node) {
      return ''
    }

    switch (node.type) {
      case 'Literal':
        return node.raw
      case 'BinaryExpression':
      case 'LogicalExpression':
        return `${this.#pprintNode(node.left)} ${node.operator} ${this.#pprintNode(node.right)}`
      case 'UnaryExpression':
        return `${node.operator}${this.#pprintNode(node.argument)}`
      case 'CallExpression':
        return `${(node.callee as JsepIdentifierNode).name}(${node.arguments
          .map((v) => this.#pprintNode(v))
          .join(', ')})`
      case 'Identifier':
        return node.name
      case 'MemberExpression':
        return `${(node.object as JsepIdentifierNode).name}.${(node.property as JsepIdentifierNode).name}`
      default:
        return JSON.stringify(node)
    }
  }

  #operatorTypes(operator: string) {
    switch (operator) {
      case '||':
        return ['boolean', 'number'] //Grumble grumble
      case '&&':
        return ['boolean', 'number'] //Grumble grumble
      case '==':
      case '!=':
        return []
      case '+':
      case '-':
      case '*':
      case '/':
      case '%':
        return ['number']
      case '>':
        return ['number', 'boolean'] //Grumble grumble
      case '>=':
      case '<':
      case '<=':
        return ['number']
      case '!':
        return ['boolean', 'number']
      default:
        this.#logger.error(`Unsupported operator: ${operator}`)
        return []
    }
  }

  #dataTypeToTypes(datatype: computationEngine.VarDefDataType | undefined, varDefRequired?: boolean) {
    if (!datatype || (datatype as string) == '') {
      return []
    }

    const required = Boolean(varDefRequired)
    const res = []
    switch (datatype) {
      case 'amount':
      case 'percent':
        res.push('number')
        break
      case 'date':
        res.push(Date)
        break
      case 'year':
      case 'count':
        res.push('number')
        break
      case 'boolean':
        res.push('boolean')
        break
      case 'text':
        res.push('string')
        break
      case 'decimal':
      case 'singledecimal':
        res.push('number')
        break
      case 'cvr':
      case 'cpr':
      case 'ucwords':
        res.push('string')
        break
      case 'postnr':
      case 'telefon':
        res.push('number')
        break
      case 'cpr-cvr':
      case 'ucwords-brand':
      case 'bank-regnr':
      case 'bank-kontonr':
      case 'registreringsnummer':
      case 'stelnummer':
      case 'uppercase':
        res.push('string')
        break
      case 'digits':
      case 'leasingkontraktnr':
      case 'enum':
        res.push('number')
        break
      default:
        this.#logger.warn(`Unknown datatype: ${datatype}`)
    }

    if (res.length) {
      res.push('undefined') // Modelvars can often be undefined if they are invalid
      if (!required) {
        res.push('null')
      }
    }

    return res
  }

  #assertTypeError(cond: boolean, error: () => string) {
    if (!cond && this.#reportTypeErrors) {
      this.#logger.error(error())
    }
  }

  #assertSpecificType(
    toType: any,
    expectedTypes: (string | typeof Date)[],
    vNode: JsepNode | null,
    fullExpression: string,
    node: JsepNode | null
  ) {
    if (expectedTypes.length === 0) {
      return
    }

    const tpe = typeof toType
    const hasExpectedType =
      expectedTypes.includes(tpe) ||
      (toType === null && expectedTypes.includes('null')) ||
      (tpe === 'object' && expectedTypes.some((t) => typeof t !== 'string' && toType instanceof t))

    let prettyTypeName: string | undefined
    if (toType === null) {
      prettyTypeName = 'null'
    }
    if (toType === undefined) {
      prettyTypeName = 'undefined'
    }
    if (!prettyTypeName && Symbol.toStringTag) {
      prettyTypeName = toType[Symbol.toStringTag]
    }
    if (!prettyTypeName && toType && toType.constructor) {
      prettyTypeName = toType.constructor.name
    }

    this.#assertTypeError(
      hasExpectedType,
      () => `Found value ${toType} from "${this.#pprintNode(vNode)}" in ${fullExpression} from "${this.#pprintNode(
        node
      )}" with type ${typeof toType}(${prettyTypeName}), but expected types ${expectedTypes}`
    )
  }

  #evalNode(node: JsepNode): computationEngine.Scalar | undefined {
    if (node === undefined) return undefined

    switch (node.type) {
      case 'Literal':
        return node.value
      case 'BinaryExpression':
      case 'LogicalExpression':
        // We type these as any, as trying to get TypeScript to cooperate will probably be a bit too hard
        let leftV: any = this.#evalNode(node.left)
        let rightV: any = this.#evalNode(node.right)

        if (typeof leftV === 'undefined' && typeof rightV === 'undefined') {
          return undefined
        } else if (typeof leftV === 'undefined') {
          leftV = this.#valueZero(rightV)
        } else if (typeof rightV === 'undefined') {
          rightV = this.#valueZero(leftV)
        }

        const expectedTypes = this.#operatorTypes(node.operator)
        this.#assertSpecificType(leftV, expectedTypes, node.left, `${leftV}${node.operator}${rightV}`, node)
        this.#assertSpecificType(rightV, expectedTypes, node.right, `${leftV}${node.operator}${rightV}`, node)

        const assertSameType = () => {
          this.#assertTypeError(
            typeof leftV === typeof rightV || leftV === null || rightV === null,
            () => `Left and right have different types. Left=${typeof leftV}(${this.#pprintNode(
              node.left
            )}), Right=${typeof rightV}(${this.#pprintNode(node.right)}) in ${this.#pprintNode(
              node
            )}. This is probably a bug`
          )
        }

        switch (node.operator) {
          case '||':
            return leftV || rightV
          case '&&':
            return leftV && rightV
          case '==':
            assertSameType()
            // noinspection EqualityComparisonWithCoercionJS
            return leftV == rightV
          case '!=':
            assertSameType()
            // noinspection EqualityComparisonWithCoercionJS
            return leftV != rightV
          case '+':
            return leftV + rightV
          case '-':
            return leftV - rightV
          case '*':
            return leftV * rightV
          case '/':
            return leftV / rightV
          case '%':
            return leftV % rightV
          case '>':
            return leftV > rightV
          case '>=':
            return leftV >= rightV
          case '<':
            return leftV < rightV
          case '<=':
            return leftV <= rightV
          default:
            throw new Error('Unsupported operator: ' + node.operator)
        }
      case 'UnaryExpression':
        const argV = this.#evalNode(node.argument)

        const expectedUnaryTypes = this.#operatorTypes(node.operator)
        this.#assertSpecificType(argV, expectedUnaryTypes, node.argument, `${node.operator}${argV}`, node)

        switch (node.operator) {
          case '!':
            if (typeof argV === 'undefined') {
              return true
            }
            return !argV
          case '+':
            if (typeof argV === 'undefined') {
              return 0
            }
            return +argV
          case '-':
            if (typeof argV === 'undefined') {
              return 0
            }
            return -argV
          default:
            throw new Error('Unsupported operator: ' + node.operator)
        }
      case 'CallExpression':
        if (node.callee.type !== 'Identifier') throw 'Funktionsnavn skal være identifier'

        /*if (node.callee.name === 'if') {
            var condition = evalNode(node.arguments[0]);
            if (condition === 1 || condition === '1' || condition === true)  {
                return evalNode(node.arguments[1])
            } else {
                return evalNode(node.arguments[2])
            }
        }*/

        const values = node.arguments.map((v) => this.#evalNode(v))
        const functionsAllowingUndefined = [
          'validateEmail',
          'isUndefined',
          'monthsSince',
          'addMonths',
          'addDays',
          'cprMod11',
          'if',
          'existingCompanyGroupId'
        ]

        if (functionsAllowingUndefined.includes(node.callee.name) || values.every((v) => v !== undefined)) {
          return this.#getFunc(node.callee.name).apply(this.#functions, values)
        } else {
          //this.#logger.log(node.callee.name, values)
          return undefined
        }

      //return getFunc(node.callee.name).apply(self, values)

      case 'Identifier':
        if (node.name === 'Infinity') return Infinity
        return this.#getValue(node.name)
      case 'MemberExpression':
        if (node.object.type !== 'Identifier') throw 'Ikke lovlig MemberExpression'
        if (node.property.type !== 'Identifier') throw 'Ikke lovlig MemberExpression'
        return this.#getValue(node.object.name + '.' + node.property.name)
      default:
        throw `Node type ${(node as JsepNodeBase).type} not handled`
    }
  }
}
