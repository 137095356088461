
      require('/home/soren/Programming/carleasing/applications/js/src/shared/templates/angularstrap/alternativeDropdown.html');
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/form/offer/showmodeToolbar.html";
      const src = "<div class=\"pull-left\" style=\"line-height: 0\">\n  <a\n    href\n    class=\"btn btn-default btn-xs\"\n    ng-show=\"status === 2 && currentRow.approvalStatus === true\"\n    ng-click=\"mailContractModal()\"\n    >Send materiale</a\n  >\n  <a href class=\"btn btn-default btn-xs\" ng-click=\"mailMaterialRequestModal()\" ng-show=\"userInfo.isSuperAdmin\"\n    >Anmod info</a\n  >\n\n  <div class=\"btn-group\" ng-show=\"userInfo.isSuperAdmin && status >= 1\">\n    <a\n      ng-href=\"/api/application/bulkPDF/{{id}}?{{registerButtonQueryString()}}\"\n      class=\"btn btn-default btn-xs\"\n      target=\"_blank\"\n      >Udskriv opslag</a\n    >\n    <a\n      href\n      class=\"btn btn-default btn-xs\"\n      bs-dropdown=\"registerButtonDropdown()\"\n      data-html=\"true\"\n      data-placement=\"bottom-right\"\n      data-template-url=\"shared/templates/angularstrap/alternativeDropdown.html\"\n      ng-click=\"$event.stopPropagation()\"\n      ><i class=\"fa fa-caret-down\"></i\n    ></a>\n  </div>\n</div>\n&nbsp;\n<a\n  href\n  class=\"btn btn-default btn-xs pull-right\"\n  ng-class=\"{'text-danger' : form.stiftelsesprovisionRegWrite.$invalid || form.rentesatsRegWrite.$invalid}\"\n  ng-click=\"self.showmode=1\"\n  >FAV</a\n>\n<a href class=\"btn btn-default btn-xs pull-right\" ng-click=\"self.showmode=3\" ng-show=\"userInfo.isSuperAdmin\">Maskinrum</a>\n<a href=\"\" class=\"btn btn-xs btn-default pull-right\" ng-click=\"openAttachFileModal($event)\" ng-show=\"userInfo.isSuperAdmin\">\n  <i class=\"fa fa-paperclip\"></i>&nbsp; Vedlæg fil\n</a>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      