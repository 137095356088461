import { dateToYMD } from '../functions/dates'

export const reportAdjustedFeesCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.modelVars = {
      month: new Date(),
    }

    $scope.$watch('modelVars.month', function (v) {
      const v2 = new Date(v)
      v2.setDate(1)
      $scope.modelVars.period = dateToYMD(v2)
    })
  },
]
