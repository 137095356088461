import { STELNUMMER_REGEXP, STELNUMMER_UDSTYR_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { CarleasingInterop } from "@/shared/carleasingInterop";
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

interface StelnummerScope extends IScope{
  modelVars: {
    productId: number
  }
}

export const ngStelnummer = [
  '$interop',
  function ($interop: CarleasingInterop): IDirective<StelnummerScope, JQLite, IAttributes, INgModelController> {
    return {
      require: 'ngModel',
      link(scope: StelnummerScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
        if (!ctrl) {
          throw new Error("Controller not present")
        }

        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = (viewValue + '').toUpperCase()
          const pattern =
            $interop.module !== 'perb' && scope.modelVars.productId === 11
              ? STELNUMMER_UDSTYR_REGEXP
              : STELNUMMER_REGEXP

          if (pattern.test(viewValue)) {
            // it is valid
            ctrl.$setValidity('stelnummer', true)
            return viewValue
          } else if (viewValue === '') {
            ctrl.$setValidity('stelnummer', true)
            return ''
          } else {
            ctrl.$setValidity('stelnummer', false)
            return undefined
          }
        })
        elm.bind('blur', function () {
          rerenderViewValue(ctrl.$modelValue, ctrl)
          //scope.$digest();
        })
      },
    }
  },
]
