type LookupMappingKeys = 'name' | 'zipcode' | 'phone' | 'address' | 'mail'

type LookupMapping = {
  name?: string
  zipcode?: string
  phone?: string
  address?: string
  mail?: string
} & { [x: string]: string }

type LookupConfig = {
  mapping: LookupMapping
  errormsg?: string
}

type LookupMappings = { [cprCvrFieldName: string]: LookupConfig }

export const cprLookupMappings: LookupMappings = {
  kautionistCpr: {
    mapping: {
      name: 'kautionistNavn',
      address: 'kautionistAdresse',
      zipcode: 'kautionistPostnr',
    },
    errormsg: 'kautionistCprWarning',
  },
  kautionist2Cpr: {
    mapping: {
      name: 'kautionist2Navn',
      address: 'kautionist2Adresse',
      zipcode: 'kautionist2Postnr',
    },
    errormsg: 'kautionist2CprWarning',
  },
  anviserCpr: {
    mapping: {
      name: 'anviserNavn',
      address: 'anviserAdresse',
      zipcode: 'anviserPostnr',
    },
    errormsg: 'anviserCprWarning',
  },
  cpr: {
    mapping: {
      name: 'navn',
      zipcode: 'postnr',
      phone: 'tlf',
      address: 'adresse',
      mail: 'mail',
    },
    errormsg: 'cprWarning',
  },
  cpr2: {
    mapping: {
      name: 'navn2',
      zipcode: 'postnr2',
      phone: 'tlf2',
      address: 'adresse2',
      mail: 'mail2',
    },
    errormsg: 'cpr2Warning',
  },
}
export const cvrLookupMappings: LookupMappings = {
  cvr: {
    mapping: {
      name: 'navn',
      zipcode: 'postnr',
      address: 'adresse',
    },
    errormsg: 'cvrWarning',
  },
  kautionistCvr: {
    mapping: {
      name: 'kautionistNavn',
      address: 'kautionistAdresse',
      zipcode: 'kautionistPostnr',
    },
    errormsg: 'kautionistCvrWarning',
  },
  kautionist2Cvr: {
    mapping: {
      name: 'kautionist2Navn',
      address: 'kautionist2Adresse',
      zipcode: 'kautionist2Postnr',
    },
    errormsg: 'kautionist2CvrWarning',
  },
  anviserCvr: {
    mapping: {
      name: 'anviserNavn',
      zipcode: 'anviserPostnr',
      address: 'anviserAdresse',
    },
    errormsg: 'anviserCvrWarning',
  },
  anviser2Cvr: {
    mapping: {
      name: 'anviser2Navn',
      zipcode: 'anviser2Postnr',
      address: 'anviser2Adresse',
    },
    errormsg: 'anviser2CvrWarning',
  },
  alternativLeverandoerCVR: {
    mapping: {
      name: 'alternativLeverandoerFirmanavn',
      zipcode: 'alternativLeverandoerAdresse',
      address: 'alternativLeverandoerPostnr',
      phone: 'alternativLeverandoerTlf',
      mail: 'alternativLeverandoerMail',
    },
  },
}

export const cvrLookupMappingsCompanyPage: LookupMappings = {
  cvrCompanyPage: {
    mapping: {
      name: 'name',
      address: 'address',
      zipcode: 'zipcode',
    },
    errormsg: 'cvrWarning',
  },
}

export function finalCallback(scope: any, responseObj: any, config: LookupConfig) {
  const mapping = config.mapping

  for (const field in mapping) {
    let v = responseObj[field]
    if (typeof v === 'string') {
      v = v.substring(0, 50)
    }
    if (field in mapping) {
      scope.modelVars[mapping[field]] = v
    }
  }
  scope.setWarning(responseObj.errormsg, config.errormsg)
}

export function personCallback(scope: any, responseObj: any, config: LookupConfig) {
  if (!responseObj.isValid) {
    scope.setWarning('Personen kunne ikke findes.', config.errormsg)
  } else {
    finalCallback(scope, responseObj, config)
  }
}
export function companyCallback(scope: any, responseObj: any, config: LookupConfig) {
  if (!responseObj.isValid) {
    scope.setWarning('Virksomheden kunne ikke findes.', config.errormsg)
  } else {
    // preprocess obj
    if (!!responseObj.zipcode) {
      responseObj.zipcode = parseInt(responseObj.zipcode)
    }
    finalCallback(scope, responseObj, config)
  }
}
