import { IAttributes, IDirective, IScope } from 'angular'

interface EnterAttrs extends IAttributes {
  ngEnter: string
}

export function ngEnter(): IDirective<IScope, JQLite, EnterAttrs> {
  return {
    link(scope: IScope, element: JQLite, attrs: EnterAttrs) {
      element.bind('keydown keypress', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.ngEnter, { event: event })
          })

          event.preventDefault()
        }
      })
    },
  }
}
