
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/acceptComment.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Tilføj evt. kommentar til godkendelsesmail</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-danger\" ng-if=\"synAdvarsel\">\n          Køretøjet skal synes inden {{renderDate(dmrSynSenestDato)}}\n        </div>\n\n        <div class=\"alert alert-success\" ng-show=\"successMsg\" ng-bind=\"successMsg\"></div>\n\n        <form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n          <textarea ng-model=\"comment\" class=\"form-control\" rows=\"8\"></textarea>\n\n          <button\n            class=\"btn btn-primary pull-right\"\n            ng-click=\"digitalSignature(acceptWrapper(true, comment, true, false, self.selvanmelder, $hide))\"\n          >\n            <i class=\"fa fa-send\"></i> &nbsp; Godkend\n          </button>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      