import { assignScope, makeDirective } from '@/shared/util/directiveHelpers'
import holcoBoxTemplate from './holcoBox.html'
import { INgModelController } from 'angular'

interface PriceIdentified {
  id: number
  name: string
  price: number
}

interface CalcualteResult {
  packages: (PriceIdentified | null)[]
  additional: (PriceIdentified | null)[]
  servicePlanPrice: number
}

export const holcoBox = makeDirective({
  template: holcoBoxTemplate,
  inject: {
    http: '$http',
    $timeout: '$timeout',
  },
  scope: false,
  assignScope: assignScope<{
    engine: computationEngine.ComputationEngine<any>
    modelVars: {
      useApplicationHolco: boolean
      status: number
      service: number
    }
    holcoLoading: boolean
    form: { [name: string]: INgModelController }
  }>(),
  link({ http, $timeout }, { scope }) {
    //https://www.freecodecamp.org/news/javascript-debounce-example/
    function debounce<Args extends any[]>(f: (...args: Args) => void, timeout: number) {
      let timer: number
      return (...args: Args) => {
        clearTimeout(timer)
        timer = setTimeout(() => f(...args), timeout) as unknown as number
      }
    }

    const computeService = //Delay until expression cache cleared
      debounce(() => {
        $timeout(() => {
          if (scope.engine.eval('useHolco') && scope.modelVars.status === 0) {
            if (
              scope.engine.eval('holcoBrandId') &&
              scope.engine.eval('holcoModelId') &&
              scope.engine.eval('holcoEngineId')
            ) {
              scope.holcoLoading = true
              http.post<CalcualteResult>('/api/holco/calculate', scope.engine.getValues()).then((res) =>
                $timeout(() => {
                  scope.modelVars.service = Math.ceil(res.data.servicePlanPrice * 0.8)
                  scope.holcoLoading = false

                  if (scope.form.service) {
                    scope.form.service.$setValidity('holcoError', res.data.servicePlanPrice > 0)
                  }
                }),
              )
            } else {
              scope.modelVars.service = 0
              if (scope.form.service) {
                scope.form.service.$setValidity('holcoError', false)
              }
            }
          }
        })
      }, 500)

    ;[
      'modelVars.useApplicationHolco',
      'modelVars.status',
      'modelVars.loebetid',
      'modelVars.maxKoerselPrAar',
      'modelVars.nyBil',
      'modelVars.indregistreretFoersteGang',
      'modelVars.kilometerstand',
      'modelVars.dbiNo',
    ].forEach((w) => {
      scope.$watch(w, () => {
        computeService()
      })
    })
  },
})
