
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/kroneError.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Fejl ved eksport til Krone Kapital</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-warning\">{{message}}</div>\n\n        <h3 ng-if=\"missingError\">Manglende felter</h3>\n\n        <ul ng-if=\"missingError\">\n          <li ng-repeat=\"field in missingError\">{{field}}</li>\n        </ul>\n\n        <hr />\n\n        <h3 ng-if=\"matchError\">Differencer</h3>\n\n        <p ng-if=\"matchError\">\n          Foretag rettelser enten i opsætningen hos {{$interop.prettyModuleName}} eller hos Krone Kapital, for at undgå disse differencer:\n        </p>\n\n        <table class=\"table retrofittable\" ng-if=\"matchError\">\n          <thead>\n            <tr>\n              <th></th>\n              <th>Afsendt værdi</th>\n              <th>Krone værdi</th>\n            </tr>\n          </thead>\n          <tbody>\n            <tr ng-repeat=\"field in objectKeys(matchError)\">\n              <td><b>{{field}}</b></td>\n              <td>{{matchError[field].expected}}</td>\n              <td>{{matchError[field].obtained}}</td>\n            </tr>\n          </tbody>\n        </table>\n\n        <hr ng-if=\"negativeError\" />\n\n        <h3 ng-if=\"negativeError\">TODO</h3>\n\n        <ul ng-if=\"negativeError\">\n          <li ng-repeat=\"field in negativeError\">{{field}}</li>\n        </ul>\n      </div>\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default\" ng-click=\"$hide()\">Ok</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      