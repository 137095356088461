
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/controlpanel.reports.html";
      const src = "<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <h3></h3>\n\n    <ul ng-if=\"$interop.module !== 'perb'\" class=\"nav nav-pills nav-stacked\">\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.controlpanel.report.summary') === 0}\">\n        <a href=\"/controlpanel/report/summary\">Aktive kontrakter</a>\n      </li>\n    </ul>\n    <ul ng-if=\"$interop.module === 'perb'\" class=\"nav nav-pills nav-stacked\">\n      <li ng-class=\"{'active' : $state.current.name.indexOf('root.controlpanel.report.adjustedFees') === 0}\">\n        <a href=\"/controlpanel/report/adjustedFees\">Ændrede gebyrer</a>\n      </li>\n      <li>\n        <a href=\"/api/xlsx/flexStatus\" target=\"_blank\">Leasingperioder i DMR og NAV</a>\n      </li>\n    </ul>\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      