import { INTEGER_REGEXP } from '../regexes'
import { danishLocaleNumber } from '../functions/numbers'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngInteger(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = (viewValue + '').replace(/\./g, '')
        if (INTEGER_REGEXP.test(viewValue) || viewValue == '') {
          // it is valid
          ctrl.$setValidity('integer', true)
          if (viewValue == '') {
            return null
          } else {
            return parseInt(viewValue, 10)
          }
        } else {
          // it is invalid, return undefined (no model update)
          ctrl.$setValidity('integer', false)
          return undefined
        }
      })

      ctrl.$formatters.push(function (modelValue) {
        var temp = danishLocaleNumber(modelValue)
        if (modelValue === null || (+modelValue === modelValue && !(modelValue % 1))) {
          ctrl.$setValidity('integer', true)
        } else {
          ctrl.$setValidity('integer', false)
        }
        return temp
      })

      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
        //scope.$digest();
      })
    },
  }
}
