import { ICompileService, IScope } from 'angular'

export const ngCompileTest = [
  '$compile',
  function ($compile: ICompileService) {
    return {
      restrict: 'A',
      replace: true,
      scope: false,
      link(scope: IScope, elem: JQLite) {
        var out =
          '<input name="leveringsdato" type="text" ng-model="modelVars.leveringsdato" class="form-control text-right" placeholder="" ng-disabled="formIsDisabled" required="" bs-datepicker="" data-placement="bottom-right" data-date-format="dd.MM.yyyy" data-start-week="1" data-lang="da" data-start-view="2" data-autoclose="true" data-min-date="today" ng-autostuff="variableDefs.leveringsdato">'
        elem.html(out)
        $compile(elem.contents())(scope)
      },
    }
  },
]
