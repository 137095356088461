// https://www.retsinformation.dk/eli/lta/2021/1147

type ByFuel = {
  gte: number
  lt: number
  van: number
  car: number
  dieselAddon: number
}

type ByCO2 = {
  gt: number
  lte: number
  fee: number
  dieselAddon: number
}

const tFuelConsumptionDiesel: ByFuel[] = [
  { gte: 50.0, lt: Infinity, van: 330, car: 330, dieselAddon: 0 },
  { gte: 44.4, lt: 50.0, van: 330, car: 370, dieselAddon: 0 },
  { gte: 40.0, lt: 44.4, van: 330, car: 390, dieselAddon: 0 },
  { gte: 36.4, lt: 40.0, van: 330, car: 410, dieselAddon: 0 },
  { gte: 33.3, lt: 36.4, van: 330, car: 430, dieselAddon: 0 },
  { gte: 28.6, lt: 33.3, van: 330, car: 460, dieselAddon: 0 },
  { gte: 25.0, lt: 28.6, van: 330, car: 500, dieselAddon: 0 },
  { gte: 22.2, lt: 25.0, van: 330, car: 540, dieselAddon: 0 },
  { gte: 20.0, lt: 22.2, van: 330, car: 580, dieselAddon: 0 },
  { gte: 18.2, lt: 20.0, van: 640, car: 890, dieselAddon: 0 },
  { gte: 16.7, lt: 18.2, van: 940, car: 1190, dieselAddon: 0 },
  { gte: 15.4, lt: 16.7, van: 1260, car: 1510, dieselAddon: 0 },
  { gte: 14.3, lt: 15.4, van: 1570, car: 1820, dieselAddon: 0 },
  { gte: 13.3, lt: 14.3, van: 1870, car: 2120, dieselAddon: 0 },
  { gte: 12.5, lt: 13.3, van: 2180, car: 2430, dieselAddon: 0 },
  { gte: 11.8, lt: 12.5, van: 2480, car: 2730, dieselAddon: 0 },
  { gte: 11.1, lt: 11.8, van: 2790, car: 3040, dieselAddon: 0 },
  { gte: 10.5, lt: 11.1, van: 3100, car: 3350, dieselAddon: 0 },
  { gte: 10.0, lt: 10.5, van: 3410, car: 3660, dieselAddon: 0 },
  { gte: 9.1, lt: 10.0, van: 4010, car: 4260, dieselAddon: 0 },
  { gte: 8.3, lt: 9.1, van: 4650, car: 4900, dieselAddon: 0 },
  { gte: 7.7, lt: 8.3, van: 5260, car: 5510, dieselAddon: 0 },
  { gte: 7.1, lt: 7.7, van: 5870, car: 6120, dieselAddon: 0 },
  { gte: 6.7, lt: 7.1, van: 6480, car: 6730, dieselAddon: 0 },
  { gte: 6.3, lt: 6.7, van: 7110, car: 7360, dieselAddon: 0 },
  { gte: 5.9, lt: 6.3, van: 7720, car: 7970, dieselAddon: 0 },
  { gte: 5.6, lt: 5.9, van: 8330, car: 8580, dieselAddon: 0 },
  { gte: 5.3, lt: 5.6, van: 8970, car: 9220, dieselAddon: 0 },
  { gte: 5.0, lt: 5.3, van: 9580, car: 9830, dieselAddon: 0 },
  { gte: 4.8, lt: 5.0, van: 10190, car: 10440, dieselAddon: 0 },
  { gte: 4.5, lt: 4.8, van: 10800, car: 11050, dieselAddon: 0 },
  { gte: -Infinity, lt: 4.5, van: 11430, car: 11680, dieselAddon: 0 },
]

const tFuelConsumptionNonDiesel: ByFuel[] = [
  { gte: 56.3, lt: Infinity, van: 0, car: 330, dieselAddon: 130 },
  { gte: 50.0, lt: 56.3, van: 0, car: 370, dieselAddon: 130 },
  { gte: 45.0, lt: 50.0, van: 0, car: 390, dieselAddon: 130 },
  { gte: 41.0, lt: 45.0, van: 0, car: 410, dieselAddon: 130 },
  { gte: 37.6, lt: 41.0, van: 0, car: 430, dieselAddon: 130 },
  { gte: 32.1, lt: 37.6, van: 0, car: 460, dieselAddon: 130 },
  { gte: 28.1, lt: 32.1, van: 0, car: 500, dieselAddon: 610 },
  { gte: 25.0, lt: 28.1, van: 0, car: 540, dieselAddon: 1090 },
  { gte: 22.5, lt: 25.0, van: 330, car: 580, dieselAddon: 1180 },
  { gte: 20.5, lt: 22.5, van: 640, car: 890, dieselAddon: 1300 },
  { gte: 18.8, lt: 20.5, van: 940, car: 1190, dieselAddon: 1400 },
  { gte: 17.3, lt: 18.8, van: 1260, car: 1510, dieselAddon: 1510 },
  { gte: 16.1, lt: 17.3, van: 1570, car: 1820, dieselAddon: 1620 },
  { gte: 15.0, lt: 16.1, van: 1870, car: 2120, dieselAddon: 1740 },
  { gte: 14.1, lt: 15.0, van: 2180, car: 2430, dieselAddon: 1870 },
  { gte: 13.2, lt: 14.1, van: 2480, car: 2730, dieselAddon: 1990 },
  { gte: 12.5, lt: 13.2, van: 2790, car: 3040, dieselAddon: 2120 },
  { gte: 11.9, lt: 12.5, van: 3100, car: 3350, dieselAddon: 2220 },
  { gte: 11.3, lt: 11.9, van: 3410, car: 3660, dieselAddon: 2330 },
  { gte: 10.2, lt: 11.3, van: 4010, car: 4260, dieselAddon: 2580 },
  { gte: 9.4, lt: 10.2, van: 4650, car: 4900, dieselAddon: 2790 },
  { gte: 8.7, lt: 9.4, van: 5260, car: 5510, dieselAddon: 3010 },
  { gte: 8.1, lt: 8.7, van: 5870, car: 6120, dieselAddon: 3270 },
  { gte: 7.5, lt: 8.1, van: 6480, car: 6730, dieselAddon: 3460 },
  { gte: 7.0, lt: 7.5, van: 7110, car: 7360, dieselAddon: 3680 },
  { gte: 6.6, lt: 7.0, van: 7720, car: 7970, dieselAddon: 3950 },
  { gte: 6.2, lt: 6.6, van: 8330, car: 8580, dieselAddon: 4160 },
  { gte: 5.9, lt: 6.2, van: 8970, car: 9220, dieselAddon: 4380 },
  { gte: 5.6, lt: 5.9, van: 9580, car: 9830, dieselAddon: 4640 },
  { gte: 5.4, lt: 5.6, van: 10190, car: 10440, dieselAddon: 4870 },
  { gte: 5.1, lt: 5.4, van: 10800, car: 11050, dieselAddon: 5170 },
  { gte: -Infinity, lt: 5.1, van: 11430, car: 11680, dieselAddon: 5410 },
]

const tCO2Emission: ByCO2[] = [
  { gt: -Infinity, lte: 58, fee: 330, dieselAddon: 160 },
  { gt: 58, lte: 65, fee: 370, dieselAddon: 160 },
  { gt: 65, lte: 73, fee: 390, dieselAddon: 160 },
  { gt: 73, lte: 80, fee: 410, dieselAddon: 160 },
  { gt: 80, lte: 87, fee: 430, dieselAddon: 160 },
  { gt: 87, lte: 102, fee: 460, dieselAddon: 160 },
  { gt: 102, lte: 116, fee: 500, dieselAddon: 740 },
  { gt: 116, lte: 131, fee: 540, dieselAddon: 1320 },
  { gt: 131, lte: 145, fee: 580, dieselAddon: 1420 },
  { gt: 145, lte: 160, fee: 890, dieselAddon: 1570 },
  { gt: 160, lte: 174, fee: 1190, dieselAddon: 1690 },
  { gt: 174, lte: 189, fee: 1510, dieselAddon: 1820 },
  { gt: 189, lte: 203, fee: 1820, dieselAddon: 1960 },
  { gt: 203, lte: 218, fee: 2120, dieselAddon: 2100 },
  { gt: 218, lte: 232, fee: 2430, dieselAddon: 2260 },
  { gt: 232, lte: 246, fee: 2730, dieselAddon: 2400 },
  { gt: 246, lte: 262, fee: 3040, dieselAddon: 2560 },
  { gt: 262, lte: 277, fee: 3350, dieselAddon: 2680 },
  { gt: 277, lte: 290, fee: 3660, dieselAddon: 2810 },
  { gt: 290, lte: 319, fee: 4260, dieselAddon: 3110 },
  { gt: 319, lte: 350, fee: 4900, dieselAddon: 3370 },
  { gt: 350, lte: 377, fee: 5510, dieselAddon: 3630 },
  { gt: 377, lte: 409, fee: 6120, dieselAddon: 3950 },
  { gt: 409, lte: 433, fee: 6730, dieselAddon: 4180 },
  { gt: 433, lte: 461, fee: 7360, dieselAddon: 4440 },
  { gt: 461, lte: 492, fee: 7970, dieselAddon: 4770 },
  { gt: 492, lte: 519, fee: 8580, dieselAddon: 5020 },
  { gt: 519, lte: 548, fee: 9220, dieselAddon: 5290 },
  { gt: 548, lte: 581, fee: 9830, dieselAddon: 5600 },
  { gt: 581, lte: 605, fee: 10440, dieselAddon: 5880 },
  { gt: 605, lte: 645, fee: 11050, dieselAddon: 6240 },
  { gt: 645, lte: Infinity, fee: 11680, dieselAddon: 6530 },
]

const tReg: { [y: number]: number } = {
  2022: 3.0 / 100,
  2023: 9.7 / 100,
  2024: 16.8 / 100,
  2025: 24.4 / 100,
  2026: 36.9 / 100,
}

const tRegMin = 2022
const tRegMax = 2026

function fuelPred(v: number) {
  return (x: ByFuel) => x.gte <= v && v < x.lt
}

function co2Pred(v: number) {
  return (x: ByCO2) => x.gt < v && v <= x.lte
}

type VehicleType = 'van' | 'car'

export function getPeriodicTax(
  currentDate: Date,
  firstRegDate: Date,
  isDiesel: boolean,
  vehicleType: VehicleType,
  fuelConsumption: number,
  co2Emission: number,
) {
  const cutOffCO2Emission = new Date(2021, 7 - 1, 1)
  if (firstRegDate < cutOffCO2Emission) {
    const t = isDiesel ? tFuelConsumptionDiesel : tFuelConsumptionNonDiesel
    const x = t.find(fuelPred(fuelConsumption))
    const out = x && x[vehicleType]
    return out
  } else {
    const y = currentDate.getFullYear()
    const reg = y < tRegMin ? 0.0 : y > tRegMax ? tReg[tRegMax] : tReg[y]
    const x = tCO2Emission.find(co2Pred(co2Emission))
    const out = x && x.fee
    return out && Math.ceil((out * (1 + reg)) / 10) * 10
  }
}

export function getDieselAddon(firstRegDate: Date, isDiesel: boolean, fuelConsumption: number, co2Emission: number) {
  // according to the computation here
  // https://skat.dk/data.aspx?oid=54460
  // the diesel addon is not regulated annually

  const cutOffCO2Emission = new Date(2021, 7 - 1, 1)
  if (firstRegDate < cutOffCO2Emission) {
    const t = isDiesel ? tFuelConsumptionDiesel : tFuelConsumptionNonDiesel
    const x = t.find(fuelPred(fuelConsumption))
    return (isDiesel && x?.dieselAddon) || 0
  } else {
    const x = tCO2Emission.find(co2Pred(co2Emission))
    return (isDiesel && x?.dieselAddon) || 0
  }
}
