export const companyUserCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.addurl = '/company/user/add'
    $scope.editurl = '/company/user/edit'

    $scope.loadAll = function () {
      $http.post('/api/user/getAll').success(function (res) {
        $scope.list = res
        //console.log($scope.list)
        if ($state.is('root.company.user.index')) {
          if ($scope.list.length > 0) {
            $location.url('/company/user/edit/' + $scope.list[0].id)
          } else {
            $location.url('/company/user/add')
          }
        }
      })
    }

    $scope.loadAll()

    $scope.setActiveId = function (id) {
      // do nothing -- til brug for companyGroup niveau
    }
    $scope.setActiveUserId = function (id) {
      $scope.activeUserId = id
      $scope.userId = id
    }
    $rootScope.common = {}
  },
]
