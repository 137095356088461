
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/register.tinglysning.html";
      const src = "<form name=\"form\">\n  <div class=\"form-group clearfix\">\n    <div class=\"col-xs-12\">\n      <div class=\"input-group\" ng-class=\"\">\n        <input\n          type=\"text\"\n          class=\"form-control\"\n          ng-model=\"modelVars.value\"\n          placeholder=\"Skriv adresse og postnr.\"\n          required\n        />\n        <span class=\"input-group-btn\">\n          <button class=\"btn btn-default\" type=\"button\" ng-disabled=\"form.cvr.$invalid\" ng-click=\"query()\">\n            Kør opslag\n          </button>\n        </span>\n      </div>\n    </div>\n  </div>\n</form>\n\n<form\n  ng-if=\"$interop.module === 'krone' || $interop.module === 'perb'\"\n  action=\"/api/application/getTinglysningPDF\"\n  method=\"POST\"\n  target=\"_blank\"\n  name=\"test\"\n  id=\"pdfgenericform\"\n>\n  <input type=\"hidden\" name=\"type\" value=\"\" />\n  <input type=\"hidden\" name=\"values\" value=\"\" />\n  <input type=\"hidden\" name=\"params\" value=\"\" />\n  <input type=\"hidden\" name=\"downloadButtonState\" value=\"\" />\n  <input type=\"hidden\" name=\"id\" value=\"\" />\n</form>\n\n<hr />\n<div>\n  <div ng-show=\"tinglysningResult.result\">\n    <a\n      class=\"btn btn-default pull-right\"\n      pdflink=\"\"\n      pdfform=\"pdfgenericform\"\n      params=\"Tinglysningsopslag\"\n      scope-element=\"modelVars.value\"\n      >Hent som PDF</a\n    >\n\n    <div ng-repeat=\"section in tinglysningResult.result\">\n      <p><strong>{{section.searchStr}}</strong></p>\n      <dl class=\"dl-horizontal\">\n        <div>\n          <dt>Resultat</dt>\n          <dd>{{section.alertMessage}}</dd>\n        </div>\n      </dl>\n    </div>\n  </div>\n\n  <div ng-show=\"tinglysningResult.type == 'error'\" class=\"alert alert-warning\">{{tinglysningResult.message}}</div>\n  <div ng-show=\"tinglysningResult.type == 'wait'\" class=\"well\">\n    <i class=\"fa fa-spinner fa-spin\"></i> Forespørger tingbogen...\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      