import { DIGITS_REGEXP } from '../regexes'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngDigits(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = (viewValue + '').replace(/\./g, '')
        if (DIGITS_REGEXP.test(viewValue)) {
          // it is valid
          ctrl.$setValidity('digits', true)
          return parseInt(viewValue, 10)
        } else if (viewValue == '') {
          ctrl.$setValidity('digits', true)
          return null
        } else {
          ctrl.$setValidity('digits', false)
          return undefined
        }
      })
    },
  }
}
