
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/creditAnalysis/creditAnalysisHeader.html";
      const src = "<div ng-class=\"{'credit-analysis-header': true, 'fixed': positionFixed}\">\n  <div class=\"save-button-container\">\n    <span>\n      <small class=\"text-muted\" ng-if=\"lastModified\">\n        Senest gemt af:<br />\n        <b>{{lastModified.historicalUserName}}, {{renderTimestamp(lastModified.timestamp)}}</b>\n      </small>\n    </span>\n    <span>\n      <button\n        type=\"button\"\n        ng-disabled=\"!userInputDirty\"\n        ng-class=\"{'btn': true, 'btn-primary': true}\"\n        ng-click=\"save()\"\n      >\n        <i ng-class=\"{'fa':true, 'fa-save': !saving, 'fa-spin': saving, 'fa-spinner': saving}\"></i> Gem\n      </button>\n    </span>\n  </div>\n  <h3>\n    <i class=\"fa fa-drivers-license-o text-muted icon\"></i>{{' '}} Bevillingsbrev: {{contract.navn}}\n    ({{contract.kundetype ? 'CVR: ' + contract.cvr : 'CPR: ' + contract.cpr}})\n  </h3>\n  <p class=\"text-muted\">\n    <b>\n      {{contract.maerkeModel}} · Reg. nr. {{contract.registreringsnummer}} · Stelnr. {{contract.stelnummer}} ·\n      {{contract.nyBil ? 'Ny bil' : 'Brugt bil'}} · {{contract.foqusContractProfile}}\n    </b>\n  </p>\n  <hr />\n</div>\n<div ng-class=\"{'credit-analysis-header-placeholder': positionFixed}\"></div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      