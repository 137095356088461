
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/kroneTransferConfirm.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Sagen har allerede et Krone Kapital kontraktnr. {{kroneContractNo}} </h4>\n      </div>\n      <div class=\"modal-body\">\n        <p>\n          <a href=\"https://forhandler.kronekapital.dk/application/{{kroneId}}\" target=\"_blank\">Link til den nuværende sag i Krone Connect</a>\n        </p>\n\n        <p>\n        Vil du overføre sagen igen? Husk at kontakte Krone Kapital vedr. sletning af den gamle kontrakt.\n        </p>\n\n      </div>\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-primary\" ng-click=\"$hide(); transferToKroneApiCall()\">Send</button>\n      </div>\n    </div>\n  </div>\n</div>";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      