
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/controlpanel.depreciationModel.html";
      const src = "<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <h3></h3>\n    <p>\n      <a href=\"{{addurl}}\" class=\"btn-default btn-sm\"><i class=\"fa fa-plus-circle\"></i> Ny restværdimodel</a>\n    </p>\n    <ul class=\"nav nav-pills nav-stacked\">\n      <li ng-repeat=\"item in list\" ng-class=\"{'active' : activeId == item.id}\">\n        <a ng-href=\"{{editurl}}/{{item.id}}\">{{item.name}}</a>\n      </li>\n    </ul>\n    <hr />\n    <a href=\"{{addurl}}\" class=\"btn-default btn-sm\"><i class=\"fa fa-plus-circle\"></i> Ny restværdimodel</a>\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      