import type { IAttributes, ICompileService, IDirective, IScope } from 'angular'

interface CompileAttrs extends IAttributes {
  compile: string
}

export const compile = [
  '$compile',
  function ($compile: ICompileService): IDirective<IScope, JQLite, CompileAttrs> {
    return {
      link(scope: IScope, element: JQLite, attrs: CompileAttrs) {
        scope.$watch(
          function (scope) {
            // watch the 'compile' expression for changes
            return scope.$eval(attrs.compile)
          },
          function (value) {
            // when the 'compile' expression changes
            // assign it into the current DOM
            element.html(value)

            // compile the new DOM and link it to the current
            // scope.
            // NOTE: we only compile .childNodes so that
            // we don't get into infinite loop compiling ourselves
            $compile(element.contents())(scope)
          },
        )
      },
    }
  },
]
