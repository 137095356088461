import type { IScope } from 'angular'

// Types here might very well be incorrect
interface FormScope extends IScope {
  modelVars: {
    userId: number

    kundetype?: boolean
    cvr: string
    cpr: string
    navn: string
    adresse: string
    postnr: number

    cpr2: string
    navn2: string
    adresse2: string
    postnr2: number

    anviserForhandler: boolean | number
    anviserCpr: string
    anviserCvr: string
    anviserNavn: string
    anviserAdresse: string
    anviserPostnr: number

    anviser2: number
    anviser2Cvr: string
    anviser2Navn: string
    anviser2Adresse: string
    anviser2Postnr: number

    meddebitor: boolean

    kautionist: number
    kautionistCpr: string
    kautionistCvr: string
    kautionistNavn: string
    kautionistAdresse: string
    kautionistPostnr: number

    kautionist2: number
    kautionist2Cpr: string
    kautionist2Cvr: string
    kautionist2Navn: string
    kautionist2Adresse: string
    kautionist2Postnr: number

    ejerNavn1: string
    ejerCpr1: string
    ejerNavn2: string
    ejerCpr2: string
    ejerNavn3: string
    ejerCpr3: string
    ejerNavn4: string
    ejerCpr4: string
  }

  userLookup: {
    [userId: number]: {
      company: {
        cvr: string
        name: string
        address: string
        zipcode: number
      }
    }
  }

  roles: { [s: string]: Role }
  stakeholders: { [s: string]: Stakeholder }
}

interface Stakeholder {
  roles: string[]
  name: string
  number: string | undefined
  identifier: string
  address: string
  zipcode: number
}

interface Entity {
  identifier: string
  name: string
  address: string
  zipcode: number
}

type Role = Entity

var USE_ANGULAR_EQUALS = true
var ROLE_EXPRESSIONS = {
  Leasingtager: getLeasingtager,

  'Aftaletager 2': function (scope: FormScope): Entity | undefined {
    var v = scope.modelVars
    if (v.meddebitor) {
      return {
        identifier: v.cpr2,
        name: v.navn2,
        address: v.adresse2,
        zipcode: v.postnr2,
      }
    }
  },

  Anviser: function (scope: FormScope): Entity | undefined{
    var v = scope.modelVars

    if (v.anviserForhandler === true || v.anviserForhandler === 1) {
      return getForhandler(scope)
    }
    if (v.anviserForhandler === false || v.anviserForhandler === 0) {
      return getLeasingtager(scope)
    }
    if (v.anviserForhandler === 2 || v.anviserForhandler === 3) {
      return {
        identifier: v.anviserForhandler === 2 ? v.anviserCpr : v.anviserCvr,
        name: v.anviserNavn,
        address: v.anviserAdresse,
        zipcode: v.anviserPostnr,
      }
    }
  },

  'Anviser 2': function (scope: FormScope): Entity | undefined {
    var v = scope.modelVars

    if (v.anviser2 === 1) {
      return getForhandler(scope)
    } else if (v.anviser2 === 2) {
      return {
        identifier: v.anviser2Cvr,
        name: v.anviser2Navn,
        address: v.anviser2Adresse,
        zipcode: v.anviser2Postnr,
      }
    }
  },

  Kautionist: function (scope: FormScope): Entity | undefined {
    var v = scope.modelVars

    if (v.kautionist === 1) {
      return getForhandler(scope)
    } else if (v.kautionist === 2 || v.kautionist === 3) {
      return {
        identifier: v.kautionist === 2 ? v.kautionistCpr : v.kautionistCvr,
        name: v.kautionistNavn,
        address: v.kautionistAdresse,
        zipcode: v.kautionistPostnr,
      }
    }
  },

  'Kautionist 2': function (scope: FormScope): Entity | undefined {
    var v = scope.modelVars

    if (v.kautionist2 === 1 || v.kautionist2 === 2) {
      return {
        identifier: v.kautionist2 === 1 ? v.kautionist2Cpr : v.kautionist2Cvr,
        name: v.kautionist2Navn,
        address: v.kautionist2Adresse,
        zipcode: v.kautionist2Postnr,
      }
    }
  },

  'Ultimativ ejer 1': getUltimativEjer('1'),

  'Ultimativ ejer 2': getUltimativEjer('2'),

  'Ultimativ ejer 3': getUltimativEjer('3'),

  'Ultimativ ejer 4': getUltimativEjer('4'),
}

/**
 * Function which adds watches for each possible role on a contract.
 * The listener for the watches will maintain the structures:
 * - scope.roles
 * - scope.stakeholders
 *
 * @param scope     The scope of formCtrl
 * @param watches   Array reference. All watches are collected here for management (recreation) by formCtrl
 */
export function addRoleWatchers(scope: FormScope, watches: (() => void)[]) {
  console.log('adding role watchers')
  scope.roles = {}
  scope.stakeholders = {}

  for (const role in ROLE_EXPRESSIONS) {
    const exprFn = ROLE_EXPRESSIONS[role as keyof typeof ROLE_EXPRESSIONS]
    watches.push(
      scope.$watch(
        filterValidIdentifer(exprFn),
        getRoleListener(role),
        USE_ANGULAR_EQUALS,
      ),
    )
  }
}

function filterValidIdentifer(exprFn: (scope: FormScope) => Entity | undefined) {
  return function (scope: IScope): Entity {
    const out = exprFn(scope as FormScope)
    if (out && out.identifier && (out.identifier.length === 8 || out.identifier.length === 10)) {
      return out
    }
    return undefined as any as Entity
  }
}

function getRoleListener(role: string) {
  return function (val: Role, oldVal: Role, scope: IScope) {
    //if (val === oldVal) return; // ignore if called on initialization
    const typedScope = scope as FormScope

    if (!val) {
      delete typedScope.roles[role]
    } else {
      typedScope.roles[role] = val
    }

    typedScope.stakeholders = rolesToStakeholders(typedScope.roles)
  }
}

function rolesToStakeholders(roles: { [role: string]: Role }) {
  const stakeholders: { [identifier: string]: Stakeholder } = {}
  for (const role in ROLE_EXPRESSIONS) {
    if (roles[role]) {
      const roleObj = roles[role]
      if (stakeholders[roleObj.identifier]) {
        stakeholders[roleObj.identifier].roles.push(role)
      } else {
        stakeholders[roleObj.identifier] = {
          roles: [role],
          name: roleObj.name,
          number: formatIdentifier(roleObj.identifier),
          identifier: roleObj.identifier,
          address: roleObj.address,
          zipcode: roleObj.zipcode,
        }
      }
    }
  }
  return stakeholders
}

// HELPERS

function getLeasingtager(scope: FormScope): Entity {
  const v = scope.modelVars
  return {
    identifier: v.kundetype === true ? v.cvr : v.cpr,
    name: v.navn,
    address: v.adresse,
    zipcode: v.postnr,
  }
}

function getForhandler(scope: FormScope): Entity | undefined {
  const temp = scope.userLookup[scope.modelVars.userId]
  if (temp) {
    const c = temp.company
    return {
      identifier: c.cvr,
      name: c.name,
      address: c.address,
      zipcode: c.zipcode,
    }
  }
}

function getUltimativEjer(no: '1' | '2' | '3' | '4') {
  return function (scope: FormScope): Entity {
    const v = scope.modelVars
    return {
      identifier: (v as any)['ejerCpr' + no],
      name: (v as any)['ejerNavn' + no],
      address: '',
      zipcode: 0,
    }
  }
}

function formatIdentifier(identifier: string) {
  if (identifier.length === 8) {
    return 'CVR: ' + identifier
  } else if (identifier.length === 10) {
    return 'CPR: ' + identifier.substring(0, 6) + '-' + identifier.substring(6)
  }
}
