import { CVR_REGEXP } from '../regexes'

export const registerCvrCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.options = {}
    $scope.modelVars = { cvr: '' }

    $scope.query = function () {
      var cvr = $scope.modelVars.cvr

      if (CVR_REGEXP.test(cvr)) {
        $scope.cvrResult = {
          register: 'CVR',
          type: 'wait',
        }

        $http.post('/api/CVRLookup/' + cvr).success(function (res) {
          $scope.cvrResult = res
        })
      } else {
        $scope.cvrResult = {}
      }
    }
  },
]
