import { rerenderViewValue } from '../functions/rerenderViewValue'
import { ucwordsBrand } from '../functions/ucwords'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

interface UcwordsbrandAttrs extends IAttributes {
  ngUcwordsBrand?: number
}

export function ngUcwordsBrand(): IDirective<IScope, JQLite, UcwordsbrandAttrs, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: UcwordsbrandAttrs, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      var minLength = 0
      if (attrs.ngUcwordsBrand !== undefined && attrs.ngUcwordsBrand > 0) {
        minLength = attrs.ngUcwordsBrand
      }
      var status = 0
      scope.$watch('modelVars.status', function (s) {
        status = s as number
        validateViewValue(ctrl.$viewValue)
      })
      scope.$watch(attrs.ngModel, function () {
        validateViewValue(ctrl.$viewValue)
      })

      var validateViewValue = function (viewValue: string) {
        if (status > 0 && (viewValue === undefined || viewValue.length < minLength)) {
          ctrl.$setValidity('ucwordsBrand', false)
        } else {
          ctrl.$setValidity('ucwordsBrand', true)
        }
        return ucwordsBrand(viewValue === undefined ? '' : viewValue + '')
      }

      ctrl.$parsers.unshift(validateViewValue)
      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
