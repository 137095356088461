
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/applicationExternalLiabilities.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"close($hide)\">&times;</button>\n        <h4 class=\"modal-title\">Der er pant i vognen</h4>\n      </div>\n      <div class=\"modal-body\">\n        <p>{{message}}</p>\n      </div>\n      <div class=\"modal-footer clearfix\">\n        <a class=\"btn btn-default pull-right\" ng-click=\"close($hide)\">Luk</a>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      