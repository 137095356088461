export interface ComputationEngineLogger {
  info(...args: any[]): void

  warn(...args: any[]): void

  error(...args: any[]): void
}

export const ComputationEngineLogger = {
  fromConsole(): ComputationEngineLogger {
    return {
      info(...args: any[]): void {
        console.info(...args)
      },
      warn(...args: any[]): void {
        console.warn(...args)
      },
      error(...args: any[]): void {
        console.error(...args)
      }
    }
  }
}