import angular from 'angular'
export const seasonAdminCtrl = [
  '$scope',
  '$stateParams',
  '$timeout',
  '$http',
  function ($scope, $stateParams, $timeout, $http) {
    $scope.self = $scope
    $scope.seasonNote = ''
    $scope.seasonNoteDirty = false
    $scope.seasonNoteLoading = true
    $scope.applicationRow = null
    $scope.contractRes = null
    $scope.products = {}

    $scope.reloadData = function () {
      $scope.applicationRow = null
      $scope.contractRows = null

      $http.post('/api/application/get', { id: parseInt($stateParams.id, 10) }).success(function (res) {
        $timeout(function () {
          $scope.applicationRow = res
          if (!$scope.seasonNoteDirty) {
            $scope.seasonNote = res.modelVars.seasonNote
            $scope.seasonNoteLoading = false
          }
        })
      })

      $http.post('/api/season/contracts/' + $stateParams.id).success(function (res) {
        $timeout(function () {
          for (var key in res) {
            if (res[key].valuesJson) {
              res[key].values = JSON.parse(res[key].valuesJson)
            }
          }
          $scope.contractRes = res
        })
      })
    }

    function convertDates(obj) {
      var copy = angular.extend({}, obj)
      for (var i in copy) {
        if (copy[i] instanceof Date) {
          copy[i] = copy[i].getTime()
        }
      }
      return copy
    }

    $scope.create = function (seasonContract) {
      $http.post('/api/season/create/' + $stateParams.id, convertDates(seasonContract)).success(function (res) {
        $timeout(function () {
          $scope.reloadData()
        })
      })
    }

    $scope.update = function (contractId, seasonContract) {
      $http.post('/api/season/update/' + contractId, convertDates(seasonContract)).success(function (res) {
        $timeout(function () {
          $scope.reloadData()
        })
      })
    }

    $scope.saveNote = function () {
      $http.post('/api/season/saveNote/' + $stateParams.id, { seasonNote: $scope.seasonNote }).success(function (res) {
        $timeout(function () {
          $scope.seasonNoteDirty = false
        })
      })
    }

    $scope.reloadData()

    $http.post('/api/product/getAll').success(function (res) {
      for (var i in res) {
        $scope.products[res[i].id] = res[i]
      }
    })

    // paramId er givet
    // hent sæsonansøgning
    // hent sæsonkontrakter
  },
]
