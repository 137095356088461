
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/rejectComment.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Begrundelse for afvisning</h4>\n      </div>\n      <div class=\"modal-body clearfix\" ng-if=\"$interop.features.includes('rejectComment.canIncludeComment')\">\n        <textarea ng-model=\"begrundelseAfvisning\" class=\"form-control\" rows=\"8\"></textarea>\n      </div>\n      <div ng-if=\"$interop.features.includes('rejectComment.canIncludeComment')\" class=\"modal-footer\">\n        <button\n          type=\"button\"\n          class=\"btn btn-danger pull-right\"\n          ng-click=\"rejectWithComment(begrundelseAfvisning); $hide()\"\n        >\n          Afvis\n        </button>\n      </div>\n      <div ng-if=\"!$interop.features.includes('rejectComment.canIncludeComment')\" class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-danger pull-right\" ng-click=\"reject(); $hide()\">Afvis</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      