import personTemplate from '@/shared/templates/digitalSignatureRecipients/person.html'
import { Person } from './companyModal'
import { assignScope, makeDirective, ScopeType } from '@/shared/util/directiveHelpers'

export const ptbPerson = makeDirective({
  restrict: ['attribute'],
  template: personTemplate,
  scope: {
    modelVars: {
      type: Object as unknown as ScopeType<Person>,
      binding: 'twoWay',
    },
    engine: {
      type: Object as unknown as ScopeType<computationEngine.ComputationEngine<any>>,
      binding: 'twoWay',
    },
    removeHandler: {
      type: Function as unknown as ScopeType<() => void>,
      binding: 'function',
      optional: true
    },
    supportRemove: {
      type: Boolean,
      binding: 'twoWay',
      optional: true
    }
  },
  assignScope: assignScope<{
    mailDanger(): boolean
    cprCvrDanger(): boolean
    nameDanger(): boolean
    variableDefs: {
      name: computationEngine.VariableDef
      cprCvr: computationEngine.VariableDef
      mail: computationEngine.VariableDef
    }
  }>(),
  linkAssign({}, { scope }) {
    const modelVarType: 'model' = 'model'
    const textDataType: computationEngine.VarDefDataType = 'text'

    return {
      variableDefs: {
        name: {
          friendly: 'Navn',
          datatype: textDataType,
          vartype: modelVarType,
          required: false,
        },
        cprCvr: {
          friendly: 'CPR/CVR',
          datatype: textDataType,
          vartype: modelVarType,
          required: false,
        },
        mail: {
          friendly: 'Mail',
          datatype: textDataType,
          vartype: modelVarType,
          required: false,
        },
      },

      nameDanger() {
        return scope.modelVars.name.length == 0
      },

      cprCvrDanger() {
        return !(
          scope.modelVars.cprCvr.length == 10 ||
          scope.modelVars.cprCvr.length == 8 ||
          scope.modelVars.cprCvr.length == 0
        )
      },
      mailDanger() {
        return scope.modelVars.mail.length == 0
      },
    }
  },
})
