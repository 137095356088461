
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/newsletterConfirm.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Bekræft afsendelse</h4>\n      </div>\n      <div class=\"modal-body\" ng-if=\"res.length > 0\">\n        <b>Hvilke forhandlere skal modtage nyhedsbrevet?</b>\n        <label\n          ><input type=\"checkbox\" ng-model=\"self.updateCompanyReceiveNewsletterStatus\" /> Fravalgte forhandlere skal\n          ikke fremover modtage nyhedsbreve</label\n        >\n        <hr />\n        <ul>\n          <li ng-repeat=\"company in res | orderBy : 'name'\">\n            <label\n              ><input type=\"checkbox\" ng-model=\"sendToCompanyId[company.id]\" /> {{company.name}}\n              ({{company.mail}})</label\n            >\n          </li>\n        </ul>\n      </div>\n      <div class=\"modal-body\" ng-if=\"res.length == 0\">\n        <b\n          >Nyhedsbrevet vil ikke blive sendt til nogen forhandlere. De mulige forhandlere har allerede modtaget en\n          kopi.</b\n        >\n      </div>\n\n      <div class=\"modal-footer clearfix\" ng-if=\"res.length > 0\">\n        <button type=\"button\" class=\"btn btn-default pull-left\" ng-click=\"$hide()\">Annullér</button>\n        <a class=\"btn btn-success pull-right\" ng-click=\"send($hide)\">Afsend</a>\n      </div>\n      <div class=\"modal-footer clearfix\" ng-if=\"res.length == 0\">\n        <button type=\"button\" class=\"btn btn-default pull-right\" ng-click=\"$hide()\">Ok</button>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      