import creditAnalysisContractTemplate from './creditAnalysisContract.html'
import { IAttributes, IDirective, IScope } from 'angular'
import { formatNumber, formatDate, formatPercent } from '../functions/intlFormaters'

interface CreditAnalysisContractScope extends IScope {
  newestContract: any
  lockedContract: any | null
  contract: any
  formatDate: typeof formatDate
  formatNumber: typeof formatNumber
  formatPercent: typeof formatPercent
}

export const creditAnalysisContract = [
  function (): IDirective<CreditAnalysisContractScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisContractTemplate,
      scope: {
        newestContract: '=',
        lockedContract: '=',
      },

      link: function ($scope: CreditAnalysisContractScope, elm: JQLite, attrs: IAttributes) {
        function update() {
          $scope.contract = $scope.lockedContract ?? $scope.newestContract
        }
        update()
        $scope.$watch('lockedContract', update)
        $scope.$watch('newestContract', update)

        $scope.formatDate = formatDate
        $scope.formatNumber = formatNumber
        $scope.formatPercent = formatPercent
      },
    }
  },
]
