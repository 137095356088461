import template from '../templates/codeMirror.html'
export const codeMirror = [
  '$timeout',
  function ($timeout) {
    return {
      replace: true,
      template: template,
      scope: {
        value: '=',
        tags: '=',
      },
      link: function (scope, element, attrs, ctrl) {
        var textarea = element.find('textarea')[0]
        scope.self = scope

        function completeAfter(cm, pred) {
          var cur = cm.getCursor()
          if (!pred || pred())
            setTimeout(function () {
              if (!cm.state.completionActive) cm.showHint({ completeSingle: false })
            }, 100)
          return CodeMirror.Pass
        }

        function completeIfAfterLt(cm) {
          return completeAfter(cm, function () {
            var cur = cm.getCursor()
            return cm.getRange(CodeMirror.Pos(cur.line, cur.ch - 1), cur) == '<'
          })
        }

        function completeIfInTag(cm) {
          return completeAfter(cm, function () {
            var tok = cm.getTokenAt(cm.getCursor())
            if (
              tok.type == 'string' &&
              (!/['"]/.test(tok.string.charAt(tok.string.length - 1)) || tok.string.length == 1)
            )
              return false
            var inner = CodeMirror.innerMode(cm.getMode(), tok.state).state
            return inner.tagName
          })
        }

        var unwatch = scope.$watch('self.tags', function (newValue, oldValue) {
          if (newValue !== undefined) {
            var myCodeMirror = CodeMirror.fromTextArea(textarea, {
              mode: 'xml',
              lineNumbers: true,
              extraKeys: {
                "'<'": completeAfter,
                "'/'": completeIfAfterLt,
                "' '": completeIfInTag,
                "'='": completeIfInTag,
                'Ctrl-Space': 'autocomplete',
              },
              matchBrackets: true,
              theme: 'tomorrow-night-eighties',
              hintOptions: { schemaInfo: scope.tags },
            })
            // Set the codemirror value to the scope
            myCodeMirror.on('change', function (e) {
              $timeout(function () {
                scope.value = myCodeMirror.getValue()
              })
            })
            unwatch()
          }
        })
      },
    }
  },
]
