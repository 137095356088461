import { errorCount } from '../functions/validationErrors'
import angular from 'angular'

export const formInsuranceCostsCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $interop) {
    $scope.vars = {}
    $scope.errorCount = errorCount

    $scope.options = {
      insuranceCompanyId: [],
      kind: $interop.specific.formInsuranceCostsCtrl.kind,
      carGarantieProduct: [
        { value: 0, option: 'Basic 100' },
        { value: 1, option: 'Comfort 100' },
        { value: 2, option: 'Comfort Plus 100' },
        { value: 3, option: 'Comfort Plus 150' },
      ],
      nyBil: [
        { value: false, option: 'Nej' },
        { value: true, option: 'Ja' },
      ],
    }

    $http.post('/api/insuranceCompany/getAll').success(function (res) {
      angular.forEach(res, function (o) {
        $scope.options.insuranceCompanyId.push({ value: o.id, option: o.name })
      })
    })

    $scope.variableDefs = {
      policenr: {
        friendly: 'Policenr',
        vartype: 'model',
        required: false,
      },
      aftalenr: {
        friendly: 'Aftalenr',
        vartype: 'model',
        datatype: 'count',
        required: false,
      },
      insuranceCompanyId: {
        friendly: 'Forsikringsselskab',
        vartype: 'model',
        required: true,
      },
      kind: {
        friendly: 'Type',
        vartype: 'model',
        required: true,
      },
      maxPris: {
        friendly: 'Max pris',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      aarligPraemie: {
        friendly: 'Årlig præmie',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      selvrisiko: {
        friendly: 'Selvrisiko',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      forsikringsperiode: {
        friendly: 'Forsikringsperiode',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      maxAlder: {
        friendly: 'Maks bilalder',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      maxKmStand: {
        friendly: 'Maks kilometerstand',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      maxKoersel: {
        friendly: 'Maks kørsel',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
      maxHK: {
        friendly: 'Maks hestekræfter',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
      nyBil: {
        friendly: 'Ny bil?',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      product: {
        friendly: 'Produkt',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      fabriksgaranti: {
        friendly: 'Fabriksgaranti',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
      forlaengelse: {
        friendly: 'Forlængelse?',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      praemie: {
        friendly: 'Årlig præmie',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      cost: {
        friendly: 'Forsikring, årlig præmie, faktisk omkostning',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['insuranceCosts.cost']
        }
      }
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Forsikringstrin blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
    }
    $scope.addInsuranceCost = function (key) {
      if (key !== undefined) {
        var arrTop = $scope.vars.insuranceCosts.slice(key, key + 1).pop()
      } else {
        var arrTop = $scope.vars.insuranceCosts.slice(-1).pop()
      }
      var temp = {
        kind: 0,
        maxPris: 0,
        aarligPraemie: 0,
        selvrisiko: 0,
        cost: 0
      }
      if (arrTop !== undefined) {
        if (arrTop.aftalenr) {
          if (key !== undefined) {
            temp.aftalenr = null
          } else {
            temp.aftalenr = arrTop.aftalenr + 1
          }
        } else {
          temp.aftalenr = null
        }
        temp.insuranceCompanyId = arrTop.insuranceCompanyId
        temp.kind = arrTop.kind
        temp.maxPris = arrTop.maxPris
        temp.aarligPraemie = arrTop.aarligPraemie
        temp.selvrisiko = arrTop.selvrisiko
        temp.cost = arrTop.cost
      }
      if (key !== undefined) {
        $scope.vars.insuranceCosts.splice(key, 0, temp)
      } else {
        $scope.vars.insuranceCosts.push(temp)
      }
    }
    $scope.removeInsuranceCost = function (key) {
      $scope.vars.insuranceCosts.splice(key, 1)
    }
    $scope.addCarGarantie = function (key) {
      let arrTop
      if (key !== undefined) {
        arrTop = $scope.vars.carGarantie.slice(key, key + 1).pop()
      } else {
        arrTop = $scope.vars.carGarantie.slice(-1).pop()
      }
      var temp = {
        forsikringsperiode: 0,
        maxAlder: 0,
        maxKmStand: 0,
        maxKoersel: 0,
        maxHK: 0,
        fabriksgaranti: 0,
        praemie: 0,
      }
      if (arrTop !== undefined) {
        temp.product = arrTop.product
        temp.forsikringsperiode = arrTop.forsikringsperiode
        temp.maxAlder = arrTop.maxAlder
        temp.maxKmStand = arrTop.maxKmStand
        temp.maxKoersel = arrTop.maxKoersel
        temp.maxHK = arrTop.maxHK
        temp.nyBil = arrTop.nyBil
        temp.fabriksgaranti = arrTop.fabriksgaranti
        temp.forlaengelse = arrTop.forlaengelse
        temp.praemie = arrTop.praemie
      }
      if (key !== undefined) {
        $scope.vars.carGarantie.splice(key, 0, temp)
      } else {
        $scope.vars.carGarantie.push(temp)
      }
    }
    $scope.removeCarGarantie = function (key) {
      $scope.vars.carGarantie.splice(key, 1)
    }

    $scope.modelVars = {} // dummy

    $scope.saveCarGarantie = function () {
      $http.post('/api/commonConfig/saveCarGarantie', $scope.vars.carGarantie).success(saveSuccess)
    }

    $scope.saveInsuranceCosts = function () {
      $http.post('/api/commonConfig/saveInsuranceCosts', $scope.vars.insuranceCosts).success(saveSuccess)
    }
    $scope.loadAll = function () {
      $scope.vars.insuranceCosts = []
      $scope.vars.carGarantie = []

      if ($interop.features.includes('carGarantie')) {
        $http.post('/api/commonConfig/getCarGarantie').success(function (res) {
          $scope.vars.carGarantie = res
          $scope.formIsLoaded = true
        })
      }

      $http.post('/api/commonConfig/getInsuranceCosts').success(function (res) {
        $scope.vars.insuranceCosts = res
        $scope.formIsLoaded = true
      })
    }
    $scope.loadAll()
  },
]
