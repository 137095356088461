import { CVR_REGEXP, CPR_REGEXP } from '../regexes'
import { validCpr } from '../functions/cpr'

export const ngCprCvr = [
  '$http',
  '$q',
  function ($http, $q) {
    return {
      require: 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = viewValue + ''

          if (CVR_REGEXP.test(viewValue) || CPR_REGEXP.test(viewValue)) {
            switch (attrs.name) {
              case 'ejerCpr1':
                scope.modelVars.ejerNavn1Status = 'loading'
                break
              case 'ejerCpr2':
                scope.modelVars.ejerNavn2Status = 'loading'
                break
              case 'ejerCpr3':
                scope.modelVars.ejerNavn3Status = 'loading'
                break
              case 'ejerCpr4':
                scope.modelVars.ejerNavn4Status = 'loading'
                break
            }
            scope['canceller' + attrs.name] = $q.defer()
            //console.log('defining defer', scope['canceller' + attrs.name])
            $http({
              method: 'POST',
              url: CVR_REGEXP.test(viewValue)
                ? '/api/registration/companyAddress/' + viewValue
                : '/api/registration/personAddress/' + viewValue,
              timeout: scope['canceller' + attrs.name].promise,
              data: {},
            }).success(function (obj) {
              switch (attrs.name) {
                case 'ejerCpr1':
                  if (obj.isValid) {
                    scope.modelVars.ejerNavn1 = obj.name
                  }
                  scope.modelVars.ejerNavn1Status = obj.isValid ? 'success' : 'failure'
                  break
                case 'ejerCpr2':
                  if (obj.isValid) {
                    scope.modelVars.ejerNavn2 = obj.name
                  }
                  scope.modelVars.ejerNavn2Status = obj.isValid ? 'success' : 'failure'
                  break
                case 'ejerCpr3':
                  if (obj.isValid) {
                    scope.modelVars.ejerNavn3 = obj.name
                  }
                  scope.modelVars.ejerNavn3Status = obj.isValid ? 'success' : 'failure'
                  break
                case 'ejerCpr4':
                  if (obj.isValid) {
                    scope.modelVars.ejerNavn4 = obj.name
                  }
                  scope.modelVars.ejerNavn4Status = obj.isValid ? 'success' : 'failure'
                  break
              }
            })
            ctrl.$setValidity('cprcvr', true)
            return viewValue
          } else {
            switch (attrs.name) {
              case 'ejerCpr1':
                scope.modelVars.ejerNavn1Status = ''
                break
              case 'ejerCpr2':
                scope.modelVars.ejerNavn2Status = ''
                break
              case 'ejerCpr3':
                scope.modelVars.ejerNavn3Status = ''
                break
              case 'ejerCpr4':
                scope.modelVars.ejerNavn4Status = ''
                break
            }
            if (scope['canceller' + attrs.name] !== undefined) {
              //console.log(scope['canceller' + attrs.name])
              scope['canceller' + attrs.name].resolve()
            }
            if (validCpr(viewValue)) {
              // it is valid

              ctrl.$setValidity('cprcvr', true)
              return viewValue
            } else if (viewValue == '') {
              ctrl.$setValidity('cprcvr', true)
              return null
            } else {
              ctrl.$setValidity('cprcvr', false)
              return undefined
            }
          }
        })
      },
    }
  },
]
