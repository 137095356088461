import { dateToDanish } from '../functions/dates'
export const newsItemCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.setActiveMonth($stateParams.month === undefined ? undefined : parseInt($stateParams.month, 10))
    $scope.setActiveId($stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10))

    $scope.renderTimestamp = function (timestamp) {
      return dateToDanish(new Date(timestamp))
    }
    $http.post('/api/news/get/' + $scope.id).success(function (res) {
      $scope.row = res.row
    })
  },
]
