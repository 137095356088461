import { assignScope, makeDirective, ScopeType } from '@/shared/util/directiveHelpers'
import noitsoBoxTemplate from './noitsoBox.html'
import { IHttpResponse } from 'angular'

export const noitsoBox = makeDirective({
  template: noitsoBoxTemplate,
  inject: {
    $http: '$http',
    $timeout: '$timeout',
    $rootScope: '$rootScope',
  },
  scope: {
    id: {
      binding: 'twoWay',
      type: Number,
    },
    currentRow: {
      binding: 'twoWay',
      type: Object as ScopeType<unknown> as ScopeType<{
        noitsoTransactionId?: string | null
        noitsoCaseUrl?: string | null
      }>,
    },
  },
  assignScope: assignScope<{
    noitsoStatus: string
    noitsoInitializeStatus: 'done' | 'loading'

    noitsoColor(): string
    danishNoitsoStatus(): string

    initializeNoitso(): Promise<void>
    getNoitsoStatus(): Promise<void>
    deleteNoitso(): Promise<void>
  }>(),
  linkAssign({ $http, $timeout, $rootScope }, { scope }) {
    function handleNoitsoError(e: any) {
      if (!('config' in e && 'status' in e) || e.status !== 400) {
        throw e
      }

      const response: IHttpResponse<{
        noitsoError: {
          type: string | null
          title: string | null
          message: string | null
          validationErrors: [{ error: string; propertyName: string }]
        }
      }> = e

      const error = response.data.noitsoError

      $timeout(() => {
        $rootScope.$broadcast('httperror', `Noitso error:
Title: ${error.title}
Message: ${error.message}
ValidationErrors: ${error.validationErrors.map((e) => `${e.propertyName}: ${e.error}`).join('\n')}`)
      })

    }

    return {
      noitsoInitializeStatus: 'done' as 'done',

      danishNoitsoStatus() {
        switch (scope.noitsoStatus) {
          case 'NOT_CREATED':
            return 'Ej oprettet'
          case 'AWAIT_CUSTOMER':
            return 'Afventer kundehandling'
          case 'AWAIT_LESSOR':
            return 'Afventer kreditgodkendelse'
          case 'DECLINE':
            return 'Afvist'
          case 'ACCEPT':
            return 'Godkendt'
          default:
            return scope.noitsoStatus
        }
      },

      noitsoColor() {
        switch (scope.noitsoStatus) {
          case 'NOT_CREATED':
            return 'text-muted'
          case 'AWAIT_CUSTOMER':
          case 'AWAIT_LESSOR':
            return 'text-warning'
          case 'DECLINE':
            return 'text-error'
          case 'ACCEPT':
            return 'text-success'
          default:
            return ''
        }
      },

      async initializeNoitso() {
        if (typeof scope.id !== 'undefined') {
          scope.noitsoInitializeStatus = 'loading'
          try {
            const res = await $http.post<{ transactionId: string; caseUrl: string }>(
              `/api/experiancreditboard/initialize/${scope.id}`,
              undefined,
            )
            await $timeout(() => {
              scope.noitsoStatus = 'AWAIT_LESSOR'
              scope.currentRow.noitsoTransactionId = res.data.transactionId
              scope.currentRow.noitsoCaseUrl = res.data.caseUrl
              scope.noitsoInitializeStatus = 'done'
            })
          } catch (e) {
            handleNoitsoError(e)
          }
        }
      },

      async getNoitsoStatus() {
        if (typeof scope.id !== 'undefined') {
          try {
            const res = await $http.get<{ status: string }>(`/api/experiancreditboard/status/${scope.id}`)
            await $timeout(() => {
              scope.noitsoStatus = res.data.status
            })
          } catch (e) {
            handleNoitsoError(e)
          }
        }
      },

      async deleteNoitso() {
        if (typeof scope.id !== 'undefined') {
          scope.noitsoInitializeStatus = 'loading'
          await $http.delete(`/api/experiancreditboard/${scope.id}`)
          await $timeout(() => {
            scope.noitsoStatus = 'NOT_CREATED'
            delete scope.currentRow.noitsoTransactionId
            delete scope.currentRow.noitsoCaseUrl
            scope.noitsoInitializeStatus = 'done'
          })
        }
      },
    }
  },
  link({ $http, $timeout }, { scope }) {
    scope.$watch('id', () => scope.getNoitsoStatus())
  },
})
