import creditAnalysisCreditroTemplate from './creditAnalysisCreditro.html'
import { IAttributes, IDirective, IScope } from 'angular'
import type { ButtonState, SaveUserInputOwner } from './creditAnalysisTypes'

interface CreditAnalysisCreditroScope extends IScope {
  owners: SaveUserInputOwner[]
  create: () => Promise<void>
  createAndRefresh: () => Promise<void>
  validateAndCreate: () => Promise<void>
  validateCreateAndRefresh: () => Promise<void>
  validateOwners: () => boolean
  contract: any
  buttonState: { create: ButtonState; refresh: ButtonState }
}

export const creditAnalysisCreditro = [
  function (): IDirective<CreditAnalysisCreditroScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisCreditroTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        owners: '=',
        contract: '=',
        buttonState: '=',
        validateOwners: '=',
      },

      link: function ($scope: CreditAnalysisCreditroScope, elm: JQLite, attrs: IAttributes) {
        $scope.validateAndCreate = async function () {
          const valid = $scope.validateOwners()
          if (valid) {
            return await $scope.create()
          } else {
            $scope.buttonState.create = 'error'
          }
        }
        $scope.validateCreateAndRefresh = async function () {
          const valid = $scope.validateOwners()
          if (valid) {
            return await $scope.createAndRefresh()
          } else {
            $scope.buttonState.create = 'error'
          }
        }
      },
    }
  },
]
