
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/index/indexActions.html";
      const src = "<button ng-if=\"$interop.module === 'firstlease' && userInfo.isSuperAdmin\" ng-file-select=\"uploadAsg($files);\" class=\"btn btn-default\">\n  <i class=\"fa fa-upload\"></i> &nbsp; ASG\n</button>\n<button ng-if=\"$interop.module === 'firstlease' && userInfo.isSuperAdmin\" ng-file-select=\"uploadNordania($files);\" class=\"btn btn-default\">\n  <i class=\"fa fa-upload\"></i> &nbsp; Nordania\n</button>\n<button ng-if=\"['lindholm', 'nordic'].includes($interop.module) && userInfo.isSuperAdmin\" ng-file-select=\"uploadLegacyExcel($files);\" class=\"btn btn-default\">\n  <i class=\"fa fa-upload\"></i> &nbsp; Import Excel\n</button>\n<a ng-if=\"userInfo.isSuperAdmin\" href=\"/application\" class=\"btn btn-default\">\n  <i class=\"fa fa-star\"></i> &nbsp; Ny beregning\n</a>\n<button ng-if=\"$interop.module === 'firstlease'\" class=\"btn btn-default\" type=\"button\" ng-click=\"showCreateLead()\">\n  <i class=\"fa fa-star\"></i> &nbsp; Opret lead\n</button>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      