import { assignScope, attrs, makeDirective } from '@/shared/util/directiveHelpers'
import holcoTupleTemplate from './holcoTuple.html'

interface Identified {
  id: number
  name: string
}

export const holcoTuple = makeDirective({
  template: holcoTupleTemplate,
  inject: {
    http: '$http',
    $timeout: '$timeout',
  },
  scope: false,
  attrs: attrs<{
    holcoTuple: 'Application' | 'DepreciationModel'
  }>(),
  assignScope: assignScope<{
    engine?: computationEngine.ComputationEngine<any>
    modelVars: {
      holcoApplicationBrandId?: number | null
      holcoApplicationModelId?: number | null
      holcoApplicationEngineId?: number | null
      holcoDepreciationModelBrandId?: number | null
      holcoDepreciationModelModelId?: number | null
      holcoDepreciationModelEngineId?: number | null
    }
    options: { [name: string]: { value: computationEngine.Scalar | null | undefined; option: string }[] }
    noneOptions: { [name: string]: string }
  }>(),
  link({ http, $timeout }, { scope, attrs }) {
    const prefix = attrs.holcoTuple
    scope.holcoBoxPrefix = prefix

    if (!scope.noneOptions) {
      scope.noneOptions = {}
    }

    const noneOption = 'Vælg'
    const loadingOption = 'Indlæser...'

    scope.noneOptions.holcoApplicationBrandId = noneOption
    scope.noneOptions.holcoDepreciationModelBrandId = noneOption
    scope.noneOptions.holcoApplicationModelId = noneOption
    scope.noneOptions.holcoDepreciationModelModelId = noneOption
    scope.noneOptions.holcoApplicationEngineId = noneOption
    scope.noneOptions.holcoDepreciationModelEngineId = noneOption

    scope.options.holcoApplicationBrandId = []
    scope.options.holcoDepreciationModelBrandId = []
    scope.options.holcoApplicationModelId = []
    scope.options.holcoDepreciationModelModelId = []
    scope.options.holcoApplicationEngineId = []
    scope.options.holcoDepreciationModelEngineId = []

    http.get<Identified[]>(`/api/holco/brands`).then((res) =>
      $timeout(() => {
        scope.options.holcoApplicationBrandId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
        scope.options.holcoDepreciationModelBrandId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
      }),
    )

    scope.$watch(
      () => (scope.engine ? scope.engine.eval('holcoBrandId') : scope.modelVars[`holco${prefix}BrandId`]),
      async (brandId, oldBrandId) => {
        if (oldBrandId !== undefined && brandId !== oldBrandId) {
          scope.modelVars[`holco${prefix}EngineId`] = null
          scope.modelVars[`holco${prefix}ModelId`] = null
        }

        scope.options.holcoApplicationModelId = []
        scope.options.holcoDepreciationModelModelId = []
        if (brandId === undefined || brandId === null) {
          return
        }

        scope.noneOptions.holcoApplicationModelId = loadingOption
        scope.noneOptions.holcoDepreciationModelModelId = loadingOption

        const res = await http.get<Identified[]>(`/api/holco/brands/${brandId}/models`)
        await $timeout(() => {
          scope.options.holcoApplicationModelId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
          scope.options.holcoDepreciationModelModelId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
          scope.noneOptions.holcoApplicationModelId = noneOption
          scope.noneOptions.holcoDepreciationModelModelId = noneOption
        })
      },
    )

    scope.$watch(
      () => (scope.engine ? scope.engine.eval('holcoBrandId') : scope.modelVars[`holco${prefix}BrandId`]),
      async (brandId) => {
        scope.options.holcoApplicationEngineId = []
        scope.options.holcoDepreciationModelEngineId = []
        if (brandId === undefined || brandId === null) {
          return
        }

        scope.noneOptions.holcoApplicationEngineId = loadingOption
        scope.noneOptions.holcoDepreciationModelEngineId = loadingOption

        const res = await http.get<Identified[]>(`/api/holco/brands/${brandId}/engines`)
        await $timeout(() => {
          scope.options.holcoApplicationEngineId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
          scope.options.holcoDepreciationModelEngineId = res.data.map((ided) => ({ value: ided.id, option: ided.name }))
          scope.noneOptions.holcoApplicationEngineId = noneOption
          scope.noneOptions.holcoDepreciationModelEngineId = noneOption
        })
      },
    )
  },
})
