
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/inspect.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <div class=\"pull-right\">\n    <a class=\"btn btn-default\" href=\"/application/{{id}}\"><i class=\"fa fa-arrow-left\"></i> &nbsp; Tilbage</a>\n  </div>\n  <h3>Inspicér beregningsmotor</h3>\n</div>\n\n<div class=\"well\">\n  <div class=\"row\">\n    <div class=\"col-xs-5\">\n      <select class=\"form-control\" ng-model=\"left\" ng-options=\"o.value as o.option for o in options.left\"></select>\n    </div>\n    <div class=\"col-xs-5\">\n      <select class=\"form-control\" ng-model=\"right\" ng-options=\"o.value as o.option for o in options.right\"></select>\n    </div>\n    <div class=\"col-xs-2\">\n      <button class=\"btn btn-default\" ng-click=\"doComparison()\">Sammenlign</button>\n    </div>\n  </div>\n</div>\n\n<div ng-show=\"comparisonActive\">\n  <h4>Ændringer i beregning</h4>\n  <div compile=\"visual\"></div>\n  <div ng-show=\"left != 'locked' && right != 'locked'\">\n    <h4>Ændringer i beregner</h4>\n    <div compile=\"visual2\"></div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      