// S�t over p� �rsv�lger som standard i datov�lgeren!
export const datepickerConfig = [
  '$provide',
  function ($provide) {
    $provide.decorator('$datepicker', [
      '$delegate',
      function ($delegate) {
        return function (element, controller, config) {
          var $datepicker = $delegate(element, controller, config)

          var oldhide = $datepicker.hide
          $datepicker.hide = function (blur) {
            $datepicker.setMode(2)
            return oldhide(blur)
          }
          return $datepicker
        }
      },
    ])
  },
]
