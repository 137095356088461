import { dateToDanish } from '../functions/dates'
export const newsCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.addurl = '/news/show'

    $scope.renderTimestamp = function (timestamp) {
      return dateToDanish(new Date(timestamp))
    }

    $scope.loadAll = function () {
      $http.post('/api/news/getActiveUserNews').success(function (res) {
        $scope.list = res.newsMonths
        $scope.newsList = res.news

        if ($scope.activeMonth === undefined && $scope.list.length > 0) {
          // gå ind i første måned.
          $location.url('/news/show/' + $scope.list[0].id + '/' + $scope.newsList[$scope.list[0].id][0].id)
        }
      })
    }

    $scope.$on('$stateChangeSuccess', function (e, toState) {
      if (
        toState.name === 'root.news.index' &&
        $scope.list !== undefined &&
        $scope.list[0] !== undefined &&
        $scope.newsList[$scope.list[0].id][0] !== undefined
      ) {
        // gå ind i første måned.
        $location.url('/news/show/' + $scope.list[0].id + '/' + $scope.newsList[$scope.list[0].id][0].id)
      }
    })

    $scope.loadAll()

    $scope.setActiveMonth = function (id) {
      $scope.month = id
      $scope.activeMonth = id
      // do nothing -- til brug for companyGroup niveau
    }
    $scope.setActiveId = function (id) {
      $scope.id = id
      $scope.activeId = id
    }
    $rootScope.common = {}
  },
]
