
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/browserWarningKoerebog.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Vi anbefaler Google Chrome</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-danger\" ng-bind=\"alert\" ng-show=\"alert\"></div>\n        Vi anbefaler at du skifter browser - kørebogen virker bedst med Google Chrome.\n      </div>\n      <div class=\"modal-footer\">\n        <a\n          class=\"btn btn-default\"\n          ng-href=\"https://app.ecofleet.com/seeme/loginService.php?referer=https://app.ecofleet.com/seeme/redirector.php&username={{userInfo.koerebogDemoUsername}}&password={{userInfo.koerebogDemoPassword}}\"\n          target=\"_blank\"\n          >Ok, fortsæt</a\n        >\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      