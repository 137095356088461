import { errorCount } from '../functions/validationErrors'
export const formNewsCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $http.post('/api/companyGroup/getAllCampaigns').success(function (res) {
      $scope.campaignCompanyGroups = res
    })

    $http.post('/api/product/getAll').success(function (res) {
      $scope.products = res
    })

    $scope.selectedProducts = {}
    $scope.selectedCampaignCompanyGroups = {}
    $scope.errorCount = errorCount

    $scope.variableDefs = {
      title: {
        friendly: 'Titel',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      content: {
        friendly: 'Indhold',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      endDate: {
        friendly: 'Slutdato',
        datatype: 'date',
        vartype: 'model',
        required: true,
      },
      notifyUsers: {
        friendly: 'Vis på brugeres startside',
        vartype: 'model',
        required: true,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Nyheden blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/news/edit/' + res)
      if ($scope.id !== undefined) {
        loadNews()
      }
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var loadNews = function () {
      $http.post('/api/news/get/' + $scope.id).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }

        $scope.selectedCampaignCompanyGroups = res.campaignCompanyGroups
        $scope.selectedProducts = res.products

        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.deactivate = function () {
        $http.post('/api/news/deactivate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev deaktiveret.')
          loadNews()
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/news/activate/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev genaktiveret.')
          loadNews()
          $scope.loadAll()
        })
      }
      $scope.unpublish = function () {
        $http.post('/api/news/unpublish/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev konverteret til kladde.')
          loadNews()
          $scope.loadAll()
        })
      }
      $scope.publish = function () {
        $http.post('/api/news/publish/' + $scope.id).success(function (res) {
          $scope.setGlobalAlert('Nyheden blev publiceret.')
          loadNews()
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew) {
        if (saveAsNew) {
          $http.post('/api/news/add', $scope.vars).success(saveSuccess)
        } else {
          $http.post('/api/news/edit/' + $stateParams.id, $scope.vars).success(saveSuccess)
        }
      }
      loadNews()
    } else {
      $scope.vars = {
        campaignCompanyGroups: $scope.selectedCampaignCompanyGroups,
        products: $scope.selectedProducts,
      }
      $scope.vars.row = {
        title: '',
        content: '',
        notifyUsers: false,
      }

      $scope.save = function () {
        $http.post('/api/news/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
