export function dateToYMD(date: Date): string {
  var d = date.getDate()
  var m = date.getMonth() + 1
  var y = date.getFullYear()
  return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
}
export function dateToDanish(date: Date, withTime?: boolean): string {
  if (date === undefined || !(date instanceof Date)) return ''

  var d = date.getDate()
  var m = date.getMonth() + 1
  var y = date.getFullYear()
  var dt = (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y

  var hr = date.getHours()
  var mi = date.getMinutes()
  var tm = (hr <= 9 ? '0' + hr : hr) + '.' + (mi <= 9 ? '0' + mi : mi)

  if (withTime) {
    return dt + ' ' + tm
  } else {
    return dt
  }
}
