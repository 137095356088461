import warningDeleteTemplate from '@/shared/templates/modals/warningDelete.html'
import applicationConditionTemplate from '@/shared/templates/modals/applicationCondition.html'
import applicationReasonTemplate from '@/shared/templates/modals/applicationReason.html'

import { assignScope, makeDirective } from '@/shared/util/directiveHelpers'
import type { IDeferred, IPromise, IScope } from 'angular'

import indexTemplate from './index.html'

//Incorporated from (These instance's files can be deleted after testing):
//comfort
//fact
//nordic
//firstlease (not actions)
//lindholm (not actions)

interface TableState {
  orderBy?: string
  reverse?: boolean
  hide?: { [k: string]: boolean }
  hideNewsNotifications?: boolean
}

interface UserInfo {
  externalName: string
  tableState: string
  id: number
  companyId: number
  isSuperAdmin: boolean
  systemCompanyName: string
  creditAnalysis: {
    canApproveContract: boolean
    canApproveDisposableIncome: boolean
    canApproveKyc: boolean
  }
}

interface User {
  id: number
  name: string
  deactivated: boolean
}

interface Company {
  id: number
  name: string
}

type ApprovalState = 'draft' | 'requested' | 'approved' | 'declined'

interface Application {
  statusKey: string
  externalPartner: string | null
  externalContractNo: string | null
  id: number
  user: string
  company: string
  product: string
  productId: number
  maerkeModel: string
  navn: string
  lastModifiedRaw: string
  approvalStatus: boolean | null
  aftalenr: string | null
  status: number
  statusText: string
  initials: string
  archive: boolean
  betingelseKunde: string
  betingelseSaelger: string
  stelnummer: string
  registreringsnummer: string
  reviewEmployee: string | null
  reviewEmployeeName: string | null
  reviewEmployeePhone: number | null
  initialsEmployeeMail: string
  initialsEmployeePhone: string
  initialsEmployeeName: string
  approvalEmployee: string | null
  declineEmployee: string | null
  reviewStartedRaw: string | null
  begrundelseAfvisning: string
  adminmode: boolean
  useLoebetidWrite: boolean
  loebetidWrite: number
  ydelseWrite: number
  udloebsdato: string
  customerInfoLinkStatus: number
  kundetype: boolean
  leasingtype: boolean
  creditAnalysisDisposableIncomeStatus: ApprovalState
  creditAnalysisCreditRatingStatus: ApprovalState
  creditAnalysisKycStatus: ApprovalState
  betalingFoersteGangRaw: string
  leveringsdatoFinalRaw: string | null
}

interface ApplicationGroup {
  statusKey: string
  total: number
  intervalFrom?: number
  intervalTo?: number
  rows: Application[]
}

interface NewsNotification {
}

export const indexDir = makeDirective({
  template: indexTemplate,
  inject: {
    $http: '$http',
    $q: '$q',
    $rootScope: '$rootScope',
    $timeout: '$timeout',
    $modal: '$modal',
    $interop: '$interop'
  },
  assignScope: assignScope<{
    userInfo?: UserInfo
    userId: number
    self: {
      userId: number
    }
    getContractDropdown(
      a: Application,
      $hide: () => void
    ): { text: string; click: () => void; href: string; target: string }[] | undefined
    applicationGroupInfo(group: ApplicationGroup): string
    applications: ApplicationGroup[]
    applicationsLoaded: boolean
    emptyResult: boolean
    reloadTable(): void

    newsNotifications: NewsNotification[]
    newsNotificationHidden: { [id: number]: boolean }
    hideNewsNotification(id: number): void
    showNewsNotification(news: { id: number }): boolean

    tableState: TableState
    persistTableState(): void
    toggleNewsNotifications(): void
    setOrder(field: string): void
    setHide(key: string): void

    creditAnalysisFilter: string
    creditAnalysisFilterOptions: { value: string; option: string; canSee: boolean }[]
    searchString: string
    currentPage: number
    perPage: number
    pageCount: number
    pages: number[]

    setPage(n: number): void

    userLookup: { [id: string]: { user: User; company: Company } }
    users: { id: number; name: string; company: string }[]

    onUserIdChange(): void
    onCreditAnalysisFilterChange(): void

    seeInitialsInfo(name: string, email: string, phone: string): { text: string; href: string; disabled?: boolean }[]
    delete(id: number): void
    condition(a: Application): void
    reason(a: Application): void

    creditAnalysisInfo(a: Application): { applicable: boolean; description: string; status: string }[]
    creditAnalysisCount(a: Application): number
    creditAnalysisTotal(a: Application): number
    creditAnalysisTooltip(a: Application): string
    creditAnalysisColor(a: Application): string

    addMonthsToDate(date: Date | string | null, months: number): Date | null
    showDate(date: Date | string | null): string | null
    showDatetime(date: Date | string | null): string | null
  }>(),
  linkAssign({ $http, $modal, $q, $rootScope, $interop }, { scope }) {
    let currentReload: IDeferred<any> | undefined = undefined

    return {
      $interop,
      userInfo: $rootScope.userInfo,
      self: scope,
      getContractDropdown(a, $hide) {
        if (a.approvalStatus === true) {
          return [
            {
              text: 'Hent kontrakt MED kundekopi',
              click: $hide,
              href: '/api/application/contract/' + a.id + '/1',
              target: '_blank'
            },
            {
              text: 'Hent kontrakt UDEN kundekopi',
              click: $hide,
              href: '/api/application/contract/' + a.id + '/0',
              target: '_blank'
            }
          ]
        } else {
          return undefined
        }
      },

      applicationGroupInfo(group: ApplicationGroup) {
        const statusTypes: { [k: string]: string } = {
          '0': 'Kladder',
          '1': 'Afventer godkendelse',
          '2': 'Afslåede',
          '3': 'Godkendte de sidste 8 dage',
          '4': 'Godkendt tidligere',
          '5': 'Under behandling af mig',
          '6': 'Kladdearkiv',
          '7': scope.userInfo ? `Sendt til ${scope.userInfo.externalName}` : 'Sendt til ekstern',
          '8': 'Under behandling af andre',
          '9': 'Arkiveret'
        }

        const from = group.intervalFrom
        const to = group.intervalTo

        return `${statusTypes[group.statusKey]} (${group.total}${
          from && to
            ? from != 1 || to != group.total
              ? `, nr. ${from != to ? `${from}-${to}` : from} på denne side`
              : ', alle vises'
            : ''
        })`
      },

      applications: [],
      reloadTable() {
        if (currentReload !== undefined) {
          currentReload.resolve()
        }
        currentReload = $q.defer()

        let url = '/api/application/getPage'
        if (scope.userId !== undefined) {
          url += '/' + scope.userId
        }

        $http
          .post<{
            pageCount: number
            groups: ApplicationGroup[]
          }>(
            url,
            {
              page: scope.currentPage,
              perpage: scope.perPage,
              tableState: scope.tableState,
              statusTypeOrdering: ['1', '5', '8', '0', '3', '4', '9', '6', '2', '7'],
              searchString: scope.searchString,
              creditAnalysisFilterActionable: [
                ['CreditRating', scope.creditAnalysisFilter === 'CreditRating'],
                ['KYC', scope.creditAnalysisFilter === 'KYC'],
                ['DisposableIncome', scope.creditAnalysisFilter === 'DisposableIncome']
              ].filter((t) => t[1])
                .map((t) => t[0])
            },
            { timeout: currentReload.promise }
          )
          .then(function(res) {
            scope.pageCount = res.data.pageCount
            scope.pages = []
            for (let i = 1; i <= scope.pageCount; i++) {
              scope.pages.push(i)
            }
            scope.applications = res.data.groups
            scope.applicationsLoaded = true
            scope.emptyResult = scope.applications.length === 0
          })
      },

      applicationsLoaded: false,
      emptyResult: true,

      newsNotifications: [],
      newsNotificationHidden: {},
      hideNewsNotification(id) {
        $http.post('/api/news/hideActiveUserNotification/' + id, {})
        scope.newsNotificationHidden[id] = true
      },
      showNewsNotification(news) {
        return !scope.newsNotificationHidden[news.id]
      },

      tableState: {
        orderBy: 'product',
        reverse: false,
        hide: {},
        ...JSON.parse(scope.userInfo?.tableState ?? '{}')
      },

      persistTableState() {
        scope.reloadTable()
        ;(scope.userInfo as UserInfo).tableState = JSON.stringify(scope.tableState)
        $http.post('/api/setTableState', scope.tableState)
      },

      toggleNewsNotifications() {
        scope.tableState.hideNewsNotifications = !scope.tableState.hideNewsNotifications
        scope.persistTableState()
      },

      setOrder(field) {
        scope.tableState.reverse = scope.tableState.orderBy == field ? !scope.tableState.reverse : false
        scope.tableState.orderBy = field

        // Gå til den side den nuværende åbne starter, hvis vi er midt nede i en!
        const intervalFrom = scope.applications[0].intervalFrom
        if (intervalFrom) {
          const sub = Math.ceil((intervalFrom - 1) / scope.perPage)
          scope.currentPage -= sub
        }

        scope.persistTableState()
      },

      setHide(key: string) {
        if (scope.tableState.hide === undefined) {
          scope.tableState.hide = {}
        }
        scope.tableState.hide[key] = !scope.tableState.hide[key]
        if (scope.tableState.hide[key]) {
          // vi har netop skjult noget der blev vist før!
          for (const a of scope.applications) {
            const intervalFrom = a.intervalFrom
            if (intervalFrom) {
              const sub = Math.ceil((intervalFrom - 1) / scope.perPage)
              scope.currentPage -= sub
            }
          }
        }

        scope.persistTableState()
      },

      creditAnalysisFilter: 'All',
      creditAnalysisFilterOptions: [
        { value: 'All', option: 'Alle sager', canSee: true },
        {
          value: 'DisposableIncome',
          option: 'Afventer godkendelse (Rådighedsbeløb)',
          canSee: scope.userInfo?.creditAnalysis?.canApproveContract ?? false
        },
        {
          value: 'CreditRating',
          option: 'Afventer godkendelse (Kreditvurdering)',
          canSee: scope.userInfo?.creditAnalysis?.canApproveDisposableIncome ?? false
        },
        {
          value: 'KYC',
          option: 'Afventer godkendelse (Kundekendskab)',
          canSee: scope.userInfo?.creditAnalysis?.canApproveKyc ?? false
        }
      ],
      searchString: '',
      currentPage: 1,
      perPage: 20,
      pageCount: 1,
      pages: [1],

      setPage(p: number) {
        scope.currentPage = p
      },

      userLookup: {},
      users: [],

      onUserIdChange() {
        scope.creditAnalysisFilter = 'All'
        scope.reloadTable()
      },

      onCreditAnalysisFilterChange() {
        scope.userId = (scope.userInfo as UserInfo).id
        scope.reloadTable()
      },

      seeInitialsInfo(name, email, phone) {
        return [
          { text: `<b>${name}</b>`, href: '#', disabled: true },
          { text: `<b style="display: inline-block; width: 100px;">Mail:</b> ${email}`, href: `mailto:${email}` },
          { text: `<b style="display: inline-block; width: 100px;">Tlf:</b> ${phone}`, href: `tel:${phone}` }
        ]
      },

      delete(id) {
        const subscope = $rootScope.$new() as IScope & { deleteDo(): void }
        subscope.deleteDo = function() {
          $http.post('/api/application/delete/' + id, {}).then(function() {
            scope.reloadTable()
          })
        }
        $modal({ template: warningDeleteTemplate, show: true, scope: subscope })
      },
      condition(a) {
        const subscope = $rootScope.$new() as IScope & {
          a: Application
          getContractDropdown(a: Application, $hide: () => void): void
          closeModal(): void
          $hide(): void
        }
        subscope.a = a
        subscope.getContractDropdown = scope.getContractDropdown
        subscope.closeModal = function() {
          subscope.$hide()
        }
        $modal({ template: applicationConditionTemplate, show: true, scope: subscope })
      },

      reason(a) {
        const subscope = $rootScope.$new() as IScope & { a: Application }
        subscope.a = a
        $modal({ template: applicationReasonTemplate, show: true, scope: subscope })
      },

      creditAnalysisInfo(a) {
        function translateStatus(status: ApprovalState) {
          switch (status) {
            case 'draft':
              return 'Kladde'
            case 'requested':
              return 'Forespurgt'
            case 'approved':
              return 'Godkendt'
            case 'declined':
              return 'Afvist'

            default:
              return status
          }
        }

        return [
          {
            applicable: !a.kundetype,
            description: 'Rådighedsbeløb: ' + translateStatus(a.creditAnalysisDisposableIncomeStatus),
            status: a.creditAnalysisDisposableIncomeStatus
          },
          {
            applicable: true,
            description: 'Kreditvurdering: ' + translateStatus(a.creditAnalysisCreditRatingStatus),
            status: a.creditAnalysisCreditRatingStatus
          },
          {
            applicable: !a.leasingtype,
            description: 'Kundekendskab: ' + translateStatus(a.creditAnalysisKycStatus),
            status: a.creditAnalysisKycStatus
          }
        ]
      },

      creditAnalysisCount(a) {
        return scope.creditAnalysisInfo(a).filter((o) => o.applicable && o.status === 'approved').length
      },

      creditAnalysisTotal(a) {
        return scope.creditAnalysisInfo(a).filter((o) => o.applicable).length
      },

      creditAnalysisTooltip(a) {
        return scope
          .creditAnalysisInfo(a)
          .filter((o) => o.applicable)
          .map((o) => o.description)
          .join('<br />')
      },

      creditAnalysisColor(a) {
        const info = scope.creditAnalysisInfo(a)
        const allApproved = info.every((o) => o.status === 'approved') && 'label-success'
        const existsDeclined = info.some((o) => o.status === 'declined') && 'label-danger'
        const existsRequest = info.some((o) => o.status === 'requested') && 'label-warning'
        return allApproved || existsDeclined || existsRequest || 'label-default'
      },

      addMonthsToDate(date, months) {
        if (!date) {
          return null
        }

        const d = new Date(date)
        d.setMonth(d.getMonth() + months)
        return d
      },

      showDate(date) {
        if (!date) {
          return date
        }

        const d = new Date(date)
        return Intl.DateTimeFormat('da', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(d)
      },

      showDatetime(date) {
        if (!date) {
          return null
        }

        const d = new Date(date)
        return Intl.DateTimeFormat('da', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }).format(d)
      }
    }
  },
  link({ $http, $timeout, $rootScope }, { scope }) {
    $http.post<NewsNotification[]>('/api/news/getActiveUserNewsNotifications', {}).then(function(res) {
      scope.newsNotifications = res.data
    })

    scope.reloadTable()

    $rootScope.$watch('userInfo', function() {
      scope.userInfo = $rootScope.userInfo

      if (scope.userInfo?.tableState !== undefined) {
        scope.tableState = {
          orderBy: 'product',
          reverse: false,
          hide: {},
          ...JSON.parse(scope.userInfo.tableState)
        }
        scope.creditAnalysisFilterOptions = [
          { value: 'All', option: 'Alle sager', canSee: true },
          {
            value: 'DisposableIncome',
            option: 'Afventer godkendelse (Rådighedsbeløb)',
            canSee: scope.userInfo.creditAnalysis.canApproveContract
          },
          {
            value: 'CreditRating',
            option: 'Afventer godkendelse (Kreditvurdering)',
            canSee: scope.userInfo.creditAnalysis.canApproveDisposableIncome
          },
          {
            value: 'KYC',
            option: 'Afventer godkendelse (Kundekendskab)',
            canSee: scope.userInfo.creditAnalysis.canApproveKyc
          }
        ]
      }

      if (scope.userInfo !== undefined) {
        scope.userId = scope.userInfo.id
        scope.userLookup = {}
        scope.users = []

        $http.post<Company[]>('/api/company/getAll', {}).then(function(res) {
          // når denne er hentet laves
          const companyLookup: { [id: string]: Company } = {}
          for (const c of res.data) {
            companyLookup[String(c.id)] = c
          }

          $http
            .post<{ [companyId: number]: { [userId: number]: User } }>('/api/user/getAllByCompanyId', {})
            .then(function(res) {
              for (const companyId in res.data) {
                if (scope.userInfo?.isSuperAdmin || Number(companyId) === scope.userInfo?.companyId) {
                  for (const u of Object.values(res.data[companyId])) {
                    scope.userLookup[String(u.id)] = {
                      user: u,
                      company: companyLookup[companyId]
                    }

                    if (!u.deactivated) {
                      scope.users.push({
                        id: u.id,
                        name: u.name,
                        company: companyLookup[companyId].name
                      })
                    }
                  }
                }
              }
            })
        })
      }
    })

    scope.$watch('userId', function(p, p1) {
      if (p != p1) {
        scope.reloadTable()
      }
    })
    scope.$watch('currentPage', function(p, p1) {
      if (p != p1) {
        scope.reloadTable()
      }
    })
    scope.$watch('tableState', function(p, p1) {
      if (p != p1) {
        scope.reloadTable()
      }
    })

    let currentTimeout: IPromise<any> | undefined = undefined
    scope.$watch('searchString', function(p, p1) {
      if (p != p1) {
        if (currentTimeout !== undefined) {
          $timeout.cancel(currentTimeout)
        }

        currentTimeout = $timeout(function() {
          scope.setPage(1)
          scope.reloadTable()
        }, 150)
      }
    })
  }
})
