import '@/shared/scss/bootstrap5.scss'

import { datepickerConfig } from '@/shared/config/datepicker'
import { myHttpInterceptor, myHttpInterceptorConfig } from '@/shared/config/httpInterceptor'
import { showdownConfig } from '@/shared/config/showdown'
import { templateCacheConfig } from '@/shared/config/templatecache'

import { nl2brFilter } from '@/shared/filters/nl2br'
import { casehubFrame } from '@/shared/directives/casehubFrame'
import { codeMirror } from '@/shared/directives/codeMirror'
import { clickOn } from '@/shared/directives/clickOn'
import { compile } from '@/shared/directives/compile'
import { dkkapAnnualReports } from '@/shared/directives/dkkapAnnualReports'
import { dkkapCreditReport } from '@/shared/directives/dkkapCreditReport'
import { dkkapUltimateOwners } from '@/shared/directives/dkkapUltimateOwners'
import { infotextClick } from '@/shared/directives/infotextClick'
import { match } from '@/shared/directives/match'
import { ngAutofield } from '@/shared/directives/ngAutofield'
import { ngAutostuff } from '@/shared/directives/ngAutostuff'
import { ngBankKontonr } from '@/shared/directives/ngBankKontonr'
import { ngBankRegnr } from '@/shared/directives/ngBankRegnr'
import { ngCompileTest } from '@/shared/directives/ngCompileTest'
import { ngCpr } from '@/shared/directives/ngCpr'
import { ngCprCvr } from '@/shared/directives/ngCprCvr'
import { ngCvr } from '@/shared/directives/ngCvr'
import { ngDecimal } from '@/shared/directives/ngDecimal'
import { ngDigits } from '@/shared/directives/ngDigits'
import { ngEnter } from '@/shared/directives/ngEnter'
import { ngInteger } from '@/shared/directives/ngInteger'
import { ngLeasingkontraktnr } from '@/shared/directives/ngLeasingkontraktnr'
import { ngPercentage } from '@/shared/directives/ngPercentage'
import { ngPostnr } from '@/shared/directives/ngPostnr'
import { ngRegistreringsnummer } from '@/shared/directives/ngRegistreringsnummer'
import { ngStelnummer } from '@/shared/directives/ngStelnummer'
import { ngTelefon } from '@/shared/directives/ngTelefon'
import { ngUcwords } from '@/shared/directives/ngUcwords'
import { ngUcwordsBrand } from '@/shared/directives/ngUcwordsBrand'
import { ngUppercase } from '@/shared/directives/ngUppercase'
import { ngValidCondition } from '@/shared/directives/ngValidCondition'
import { ngYear } from '@/shared/directives/ngYear'
import { nrKontonr } from '@/shared/directives/nrKontonr'
import { pdflink } from '@/shared/directives/pdflink'
import { selectOnFocus } from '@/shared/directives/selectOnFocus'
import { disposableIncomeComputer } from '@/shared/directives/disposableIncomeComputer'
import { companyCtrl } from '@/shared/controllers/companyCtrl'
import { autoitConnectCtrl } from '@/shared/autoit/autoitConnectCtrl'
import { companyUserCtrl } from '@/shared/controllers/companyUserCtrl'
import { controlpanelArticleCtrl } from '@/shared/controllers/controlpanelArticleCtrl'
import { controlpanelCompanyCtrl } from '@/shared/controllers/controlpanelCompanyCtrl'
import { controlpanelCompanyGroupCtrl } from '@/shared/controllers/controlpanelCompanyGroupCtrl'
import { controlpanelFinancingPartnerCtrl } from '@/shared/controllers/controlpanelFinancingPartnerCtrl'
import { controlpanelProductCtrl } from '@/shared/controllers/controlpanelProductCtrl'
import { controlpanelDepreciationMatrixCtrl } from '@/shared/controllers/controlpanelDepreciationMatrixCtrl'
import { controlpanelDepreciationModelCtrl } from '@/shared/controllers/controlpanelDepreciationModelCtrl'
import { controlpanelGpsProviderCtrl } from '@/shared/controllers/controlpanelGpsProviderCtrl'
import { controlpanelInsuranceCompanyCtrl } from '@/shared/controllers/controlpanelInsuranceCompanyCtrl'
import { controlpanelNewsCtrl } from '@/shared/controllers/controlpanelNewsCtrl'
import { controlpanelNewsletterCtrl } from '@/shared/controllers/controlpanelNewsletterCtrl'
import { controlpanelServiceLevelCtrl } from '@/shared/controllers/controlpanelServiceLevelCtrl'
import { datepickerTestCtrl } from '@/shared/controllers/datepickerTestCtrl'
import { formArticleCategoryCtrl } from '@/shared/controllers/formArticleCategoryCtrl'
import { formArticleCtrl } from '@/shared/controllers/formArticleCtrl'
import { formPrtCtrl } from '@/shared/controllers/formPrtCtrl'
import { formSelvanmelderCtrl } from '@/shared/controllers/formSelvanmelderCtrl'
import { formCommonConfigCtrl } from '@/shared/controllers/formCommonConfigCtrl'
import { formCompanyCtrl } from '@/shared/controllers/formCompanyCtrl'
import { formCompanyGroupCtrl } from '@/shared/controllers/formCompanyGroupCtrl'
import { formFinancingPartnerCtrl } from '@/shared/controllers/formFinancingPartnerCtrl'
import { formProductCtrl } from '@/shared/controllers/formProductCtrl'
import { formDepreciationMatrixCtrl } from '@/shared/controllers/formDepreciationMatrixCtrl'
import { formDepreciationModelCtrl } from '@/shared/controllers/formDepreciationModelCtrl'
import { formGpsProviderCtrl } from '@/shared/controllers/formGpsProviderCtrl'
import { formInsuranceCompanyCtrl } from '@/shared/controllers/formInsuranceCompanyCtrl'
import { formInsuranceCostsCtrl } from '@/shared/controllers/formInsuranceCostsCtrl'
import { formMailTemplatesCtrl } from '@/shared/controllers/formMailTemplatesCtrl'
import { formNewsCtrl } from '@/shared/controllers/formNewsCtrl'
import { formNewsletterCtrl } from '@/shared/controllers/formNewsletterCtrl'
import { formServiceLevelCtrl } from '@/shared/controllers/formServiceLevelCtrl'
import { formPenneoCtrl } from '@/shared/controllers/formPenneoCtrl'
import { formRecalculationCtrl } from '@/shared/controllers/formRecalculationCtrl'
import { formUserCtrl } from '@/shared/controllers/formUserCtrl'
import { headerCtrl } from '@/shared/controllers/headerCtrl'
import { headerUserPasswordCtrl } from '@/shared/controllers/headerUserPasswordCtrl'
import { headerUserSettingsCtrl } from '@/shared/controllers/headerUserSettingsCtrl'
import { helpCtrl } from '@/shared/controllers/helpCtrl'
import { helpItemCtrl } from '@/shared/controllers/helpItemCtrl'
import { helpMenuCtrl } from '@/shared/controllers/helpMenuCtrl'
import { inspectCtrl } from '@/shared/controllers/inspectCtrl'
import { logonCtrl } from '@/shared/controllers/logonCtrl'
import { mainCtrl } from '@/shared/controllers/mainCtrl'
import { newsCtrl } from '@/shared/controllers/newsCtrl'
import { newsItemCtrl } from '@/shared/controllers/newsItemCtrl'
import { registerBilbogenCtrl } from '@/shared/controllers/registerBilbogenCtrl'
import { registerCvrCtrl } from '@/shared/controllers/registerCvrCtrl'
import { registerDmrCtrl } from '@/shared/controllers/registerDmrCtrl'
import { registerDowJonesCtrl } from '@/shared/controllers/registerDowJonesCtrl'
import { registerRkiCtrl } from '@/shared/controllers/registerRkiCtrl'
import { registerSynsrapportCtrl } from '@/shared/controllers/registerSynsrapportCtrl'
import { registerTinglysningCtrl } from '@/shared/controllers/registerTinglysningCtrl'
import { seasonAdminCtrl } from '@/shared/controllers/seasonAdminCtrl'
import { reportAdjustedFeesCtrl } from '@/shared/controllers/reportAdjustedFeesCtrl'
import { reportSummaryCtrl } from '@/shared/controllers/reportSummaryCtrl'
import { ptbCompany } from '@/shared/directives/digitalSignatureRecipients/company'
import { ptbPerson } from '@/shared/directives/digitalSignatureRecipients/person'
import { formInterestAdjustmentCtrl } from '@/shared/controllers/formInterestAdjustmentCtrl'
import { ptbModal } from '@/shared/directives/digitalSignatureRecipients/companyModal'
import { creditAnalysis } from '@/shared/creditAnalysis/creditAnalysis'
import { creditAnalysisSidebar } from '@/shared/creditAnalysis/creditAnalysisSidebar'
import { creditAnalysisSidebarHead } from '@/shared/creditAnalysis/creditAnalysisSidebarHead'
import { creditAnalysisHeader } from '@/shared/creditAnalysis/creditAnalysisHeader'
import { creditAnalysisApproval } from '@/shared/creditAnalysis/creditAnalysisApproval'
import { creditAnalysisAnnualReport } from '@/shared/creditAnalysis/creditAnalysisAnnualReport'
import { creditAnalysisContract } from '@/shared/creditAnalysis/creditAnalysisContract'
import { creditAnalysisMonthio } from '@/shared/creditAnalysis/creditAnalysisMonthio'
import { creditAnalysisOwners } from '@/shared/creditAnalysis/creditAnalysisOwners'
import { creditAnalysisRki } from '@/shared/creditAnalysis/creditAnalysisRki'
import { creditAnalysisTotalValue } from '@/shared/creditAnalysis/creditAnalysisTotalValue'
import { creditAnalysisCreditro } from '@/shared/creditAnalysis/creditAnalysisCreditro'
import { creditAnalysisNetIncome } from '@/shared/creditAnalysis/creditAnalysisNetIncome'
import { offer } from '@/shared/form/offer/offer'
import { offerForm } from '@/shared/form/offer/offerForm'
import { favForm } from '@/shared/form/offer/favForm'
import { showmodeToolbar } from '@/shared/form/offer/showmodeToolbar'
import { engineRoomForm } from '@/shared/form/offer/engineRoomForm'
import { attachments } from '@/shared/form/offer/attachments'
import { formDetails } from "@/shared/form/form.details";
import { asgBox } from "@/shared/form/asgBox";
import { noitsoBox } from '@/shared/form/noitsoBox'
import { holcoBox } from '@/shared/holco/holcoBox'
import { holcoTuple } from '@/shared/holco/holcoTuple'
import { indexDir } from '@/shared/index/index'
import { indexActions } from '@/shared/index/indexActions'

import angular from 'angular'

const app = angular.module('carleasing.shared', [
    'ui.router',
    'mgcrea.ngStrap',
    'ngSanitize',
    'angularLoad',
    'angularFileUpload',
    'ng-showdown',
    'duScroll',
])

app.config(datepickerConfig)
app.config(myHttpInterceptorConfig)
app.factory('myHttpInterceptor', myHttpInterceptor)
app.config(showdownConfig)
app.config(templateCacheConfig)

app.filter('nl2br', nl2brFilter)

app.directive('casehubFrame', casehubFrame)
app.directive('codeMirror', codeMirror)
app.directive('clickOn', clickOn)
app.directive('compile', compile)
app.directive('dkkapAnnualReports', dkkapAnnualReports)
app.directive('dkkapCreditReport', dkkapCreditReport)
app.directive('dkkapUltimateOwners', dkkapUltimateOwners)
app.directive('infotextClick', infotextClick)
app.directive('match', match)
app.directive('ngAutofield', ngAutofield)
app.directive('ngAutostuff', ngAutostuff)
app.directive('ngBankKontonr', ngBankKontonr)
app.directive('ngBankRegnr', ngBankRegnr)
app.directive('ngCompileTest', ngCompileTest)
app.directive('ngCpr', ngCpr)
app.directive('ngCprCvr', ngCprCvr)
app.directive('ngCvr', ngCvr)
app.directive('ngDecimal', ngDecimal)
app.directive('ngDigits', ngDigits)
app.directive('ngEnter', ngEnter)
app.directive('ngInteger', ngInteger)
app.directive('ngLeasingkontraktnr', ngLeasingkontraktnr)
app.directive('ngPercentage', ngPercentage)
app.directive('ngPostnr', ngPostnr)
app.directive('ngRegistreringsnummer', ngRegistreringsnummer)
app.directive('ngStelnummer', ngStelnummer)
app.directive('ngTelefon', ngTelefon)
app.directive('ngUcwords', ngUcwords)
app.directive('ngUcwordsBrand', ngUcwordsBrand)
app.directive('ngUppercase', ngUppercase)
app.directive('ngValidCondition', ngValidCondition)
app.directive('ngYear', ngYear)
app.directive('nrKontonr', nrKontonr)
app.directive('pdflink', pdflink)
app.directive('selectOnFocus', selectOnFocus)
app.directive('kreditvaerdighed', disposableIncomeComputer)
app.directive('ptbCompany', ptbCompany)
app.directive('ptbPerson', ptbPerson)
app.directive('ptbModal', ptbModal)
app.directive('creditAnalysis', creditAnalysis)
app.directive('creditAnalysisSidebar', creditAnalysisSidebar)
app.directive('creditAnalysisApproval', creditAnalysisApproval)
app.directive('creditAnalysisHeader', creditAnalysisHeader)
app.directive('creditAnalysisAnnualReport', creditAnalysisAnnualReport)
app.directive('creditAnalysisContract', creditAnalysisContract)
app.directive('creditAnalysisMonthio', creditAnalysisMonthio)
app.directive('creditAnalysisOwners', creditAnalysisOwners)
app.directive('creditAnalysisRki', creditAnalysisRki)
app.directive('creditAnalysisTotalValue', creditAnalysisTotalValue)
app.directive('creditAnalysisCreditro', creditAnalysisCreditro)
app.directive('creditAnalysisSidebarHead', creditAnalysisSidebarHead)
app.directive('creditAnalysisNetIncome', creditAnalysisNetIncome)
app.directive('offer', offer)
app.directive('offerForm', offerForm)
app.directive('favForm', favForm)
app.directive('showmodeToolbar', showmodeToolbar)
app.directive('engineRoomForm', engineRoomForm)
app.directive('attachments', attachments)
app.directive('formDetails', formDetails)
app.directive('asgBox', asgBox)
app.directive('noitsoBox', noitsoBox)
app.directive('holcoTuple', holcoTuple)
app.directive('holcoBox', holcoBox)
app.directive('indexDir', indexDir)
app.directive('indexActions', indexActions)

app.controller('autoitConnectCtrl', autoitConnectCtrl)
app.controller('companyCtrl', companyCtrl)
app.controller('companyUserCtrl', companyUserCtrl)
app.controller('controlpanelArticleCtrl', controlpanelArticleCtrl)
app.controller('controlpanelCompanyCtrl', controlpanelCompanyCtrl)
app.controller('controlpanelCompanyGroupCtrl', controlpanelCompanyGroupCtrl)
app.controller('controlpanelFinancingPartnerCtrl', controlpanelFinancingPartnerCtrl)
app.controller('controlpanelProductCtrl', controlpanelProductCtrl)
app.controller('controlpanelDepreciationMatrixCtrl', controlpanelDepreciationMatrixCtrl)
app.controller('controlpanelDepreciationModelCtrl', controlpanelDepreciationModelCtrl)
app.controller('controlpanelGpsProviderCtrl', controlpanelGpsProviderCtrl)
app.controller('controlpanelInsuranceCompanyCtrl', controlpanelInsuranceCompanyCtrl)
app.controller('controlpanelNewsCtrl', controlpanelNewsCtrl)
app.controller('controlpanelNewsletterCtrl', controlpanelNewsletterCtrl)
app.controller('controlpanelServiceLevelCtrl', controlpanelServiceLevelCtrl)
app.controller('datepickerTestCtrl', datepickerTestCtrl)
app.controller('formArticleCategoryCtrl', formArticleCategoryCtrl)
app.controller('formArticleCtrl', formArticleCtrl)
app.controller('formSelvanmelderCtrl', formSelvanmelderCtrl)
app.controller('formInterestAdjustmentCtrl', formInterestAdjustmentCtrl)
app.controller('formCommonConfigCtrl', formCommonConfigCtrl)
app.controller('formCompanyCtrl', formCompanyCtrl)
app.controller('formPrtCtrl', formPrtCtrl)
app.controller('formCompanyGroupCtrl', formCompanyGroupCtrl)
app.controller('formFinancingPartnerCtrl', formFinancingPartnerCtrl)
app.controller('formProductCtrl', formProductCtrl)
app.controller('formDepreciationMatrixCtrl', formDepreciationMatrixCtrl)
app.controller('formDepreciationModelCtrl', formDepreciationModelCtrl)
app.controller('formGpsProviderCtrl', formGpsProviderCtrl)
app.controller('formInsuranceCompanyCtrl', formInsuranceCompanyCtrl)
app.controller('formInsuranceCostsCtrl', formInsuranceCostsCtrl)
app.controller('formMailTemplatesCtrl', formMailTemplatesCtrl)
app.controller('formNewsCtrl', formNewsCtrl)
app.controller('formNewsletterCtrl', formNewsletterCtrl)
app.controller('formServiceLevelCtrl', formServiceLevelCtrl)
app.controller('formPenneoCtrl', formPenneoCtrl)
app.controller('formRecalculationCtrl', formRecalculationCtrl)
app.controller('formUserCtrl', formUserCtrl)
app.controller('headerCtrl', headerCtrl)
app.controller('headerUserPasswordCtrl', headerUserPasswordCtrl)
app.controller('headerUserSettingsCtrl', headerUserSettingsCtrl)
app.controller('helpCtrl', helpCtrl)
app.controller('helpItemCtrl', helpItemCtrl)
app.controller('helpMenuCtrl', helpMenuCtrl)
app.controller('inspectCtrl', inspectCtrl)
app.controller('logonCtrl', logonCtrl)
app.controller('mainCtrl', mainCtrl)
app.controller('newsCtrl', newsCtrl)
app.controller('newsItemCtrl', newsItemCtrl)
app.controller('registerBilbogenCtrl', registerBilbogenCtrl)
app.controller('registerCvrCtrl', registerCvrCtrl)
app.controller('registerDmrCtrl', registerDmrCtrl)
app.controller('registerDowJonesCtrl', registerDowJonesCtrl)
app.controller('registerRkiCtrl', registerRkiCtrl)
app.controller('registerSynsrapportCtrl', registerSynsrapportCtrl)
app.controller('registerTinglysningCtrl', registerTinglysningCtrl)
app.controller('seasonAdminCtrl', seasonAdminCtrl)
app.controller('reportAdjustedFeesCtrl', reportAdjustedFeesCtrl)
app.controller('reportSummaryCtrl', reportSummaryCtrl)
