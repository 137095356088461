import type { IFilterService } from 'angular'

export const nl2brFilter = [
  '$filter',
  function ($filter: IFilterService) {
    return function (data: string) {
      if (!data) return data
      return data.replace(/\n\r?/g, '<br />')
    }
  },
]
