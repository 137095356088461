export function ucwords(str: string) {
  return String(str).replace(/^([a-z\u00E0-\u00FC])|[\s\-]+([a-z\u00E0-\u00FC])/g, function ($1) {
    return $1.toUpperCase()
  })
}

export function ucwordsSpecialCases(x: string) {
  if (x.substring(0, 2) == 'V/') return 'v/' + ucwords(x.substring(2, x.length))
  if (x == 'A/s') return 'A/S'
  if (x == 'I/s') return 'I/S'
  if (x == 'Ivs') return 'IVS'
  if (x == 'Aps') return 'ApS'
  if (x == 'Tv.') return 'tv.'
  if (x == 'Th.') return 'th.'
  if (x == 'Mf.') return 'mf.'
  if (x == 'Hk') return 'HK'
  if (x == 'Dsg') return 'DSG'
  if (x == 'Tdi') return 'TDi'
  if (x == 'Dci') return 'dCi'
  if (x == 'Bmt') return 'BMT'
  if (x == 'Mpv') return 'DSG'
  if (x == 'Stationcar') return 'st.car.'
  return x.replace(/(\d+)v/, '$1V')
}

export function ucwordsImproved(str: string) {
  return str
    .split(' ')
    .map(function (x) {
      if (x.length <= 4 && x.toUpperCase() == x) {
        return x
      }
      return ucwordsSpecialCases(ucwords(x.toLowerCase()))
    })
    .join(' ')
}

export function ucwordsBrand(str: string) {
  return str
    .split(' ')
    .map(function (x) {
      if (x.length <= 4) {
        return x
      }
      return ucwordsSpecialCases(ucwords(x))
    })
    .join(' ')
}
