
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/holco/holcoTuple.html";
      const src = "<div ng-if=\"holcoBoxPrefix === 'Application' && !engine.eval('useDepreciationModelHolco')\">\n  <div ng-autofield=\"holcoApplicationBrandId\" ng-disabled=\"engine.eval('useHolco')\"></div>\n  <div ng-autofield=\"holcoApplicationModelId\" ng-disabled=\"engine.eval('useHolco')\"></div>\n  <div ng-autofield=\"holcoApplicationEngineId\" ng-disabled=\"engine.eval('useHolco')\"></div>\n</div>\n<div ng-show=\"holcoBoxPrefix === 'DepreciationModel' || engine.eval('useDepreciationModelHolco')\">\n  <div ng-autofield=\"holcoDepreciationModelBrandId\" ng-disabled=\"engine && engine.eval('useHolco')\"></div>\n  <div ng-autofield=\"holcoDepreciationModelModelId\" ng-disabled=\"engine && engine.eval('useHolco')\"></div>\n  <div ng-autofield=\"holcoDepreciationModelEngineId\" ng-disabled=\"engine && engine.eval('useHolco')\"></div>\n</div>";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      