import { dateToYMD } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'

export const formCompanyGroupCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $interop) {
    $scope.errorCount = errorCount
    if ($scope.common.productId === undefined) {
      $scope.common.productId = $interop.specific.formCompanyGroupCtrl.defaultProductId
    }

    $scope.options = {}
    $scope.options.variabeltEkspeditionsgebyr = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]
    $scope.options.isCampaign = [
      { value: false, option: 'Hovedprisgruppe' },
      { value: true, option: 'Kampagneprisgruppe' },
    ]
    $scope.options.dokumentgebyrOpkreavningsmetodeDefault = [
      { value: false, option: 'Medfinansieres' },
      { value: true, option: 'Opkræves' },
    ]
    $scope.options.interestRateDependsOn = [
      {
        value: 0,
        option: 'Alder'
      },
      {
        value: 1,
        option: 'Pris'
      }
    ]

    var watches = []

    var unregisterWatches = function () {
      for (var i in watches) {
        var temp = watches[i]
        temp()
      }
      watches = []
    }

    var registerWatches = function () {
      unregisterWatches()

      watches.push(
        $scope.$watch('vars.row.isCampaign', function (val, oldVal) {
          if (val != oldVal) {
            $scope.vars.row.start = null
            $scope.vars.row.end = null
          }
        }),
      )
    }

    $scope.variableDefs = {
      name: {
        friendly: 'Navn',
        datatype: 'ucwords',
        vartype: 'model',
        required: true,
        maxLength: 20,
      },

      isCampaign: {
        friendly: 'Prisgruppetype',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        default: false,
      },

      handleExternally: {
        friendly: 'Håndteres eksternt',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        default: false,
      },

      start: {
        friendly: 'Startdato',
        datatype: 'date',
        vartype: 'model',
        ngRequired: 'vars.row.isCampaign',
      },

      end: {
        friendly: 'Slutdato',
        datatype: 'date',
        vartype: 'model',
        ngRequired: 'vars.row.isCampaign',
      },

      finansielLeasingMaxLoebetid: {
        friendly: 'Max løbetid, finansiel leasing',
        datatype: 'count',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.finansielLeasingMaxLoebetid'],
        },
      },
      ekspeditionsgebyr: {
        friendly: 'Fast ekspeditionsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      variabeltEkspeditionsgebyr: {
        friendly: 'Variabelt ekspeditionsgebyr',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        default: false,
      },
      dokumentgebyrOpkreavningsmetodeDefault: {
        friendly: 'Dokumentgebyr Opkrævningsmetode',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        default: false,
        fieldConditions: {
          requiredFeature: ['companyGroup.dokumentgebyrOpkreavningsmetodeDefault'],
        },
      },
      deklarationForsikringOpkraevesSeparatStandard: {
        friendly: 'Deklaration forsikring opkræves separat standard',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        default: false,
        fieldConditions: {
          requiredFeature: ['companyGroup.deklarationForsikringOpkraevesSeparatStandard'],
        },
      },
      dokumentgebyrPct: {
        friendly: 'Dokumentgebyr pct',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.dokumentgebyrPct'],
        },
      },
      rentesatsRegFaktorNeg: {
        friendly: 'rentesatsRegFaktorNeg',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.rentesatsRegFaktorNeg'],
        },
      },
      rentesatsRegFaktorPos: {
        friendly: 'rentesatsRegFaktorPos',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.rentesatsRegFaktorPos'],
        },
      },
      sikringskontoBeloebMin: {
        friendly: 'sikringskontoBeloebMin',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.guaranteeAccount'],
        },
      },
      sikringskontoPct: {
        friendly: 'sikringskontoPct',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.guaranteeAccount'],
        },
      },
      variabeltEkspeditionsgebyrBeloebMin: {
        friendly: 'Variabelt ekspeditionsgebyr (min)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      variabeltEkspeditionsgebyrPct: {
        friendly: 'Variabelt ekspeditionsgebyr',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      variabeltEkspeditionsgebyrBeloebMax: {
        friendly: 'Variabelt ekspeditionsgebyr (max)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      dokumentgebyr: {
        friendly: 'Dokumentgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      gebyrAfgiftsvurdering: {
        friendly: 'Gebyr for afgiftsberegning af Krone Kapital',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.gebyrAfgiftsvurdering'],
        },
      },
      fastGebyrAfgiftsvurdering: {
        friendly: 'Fast gebyr for afgiftsberegning',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.fastGebyrAfgiftsvurdering'],
        },
      },
      dokumentgebyrTillaeg: {
        friendly: 'Dokumentgebyr tillæg ved ny bil',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.dokumentgebyrTillaeg'],
        },
      },
      administrationsgebyr: {
        friendly: $interop.terms.administrationFee,
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      administrationFeeVisible: {
        friendly: 'Administrationsgebyr (inkluderes ikke i ydelse)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.administrationFeeVisible'],
        },
      },
      opkraevningsgebyr: {
        friendly: 'Opkrævningsgebyr, leasing',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      dealerbonus: {
        friendly: 'Bonus til forhandler',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.dealerbonus'],
        },
      },
      shareholdercommision: {
        friendly: 'Provision til aktionærer',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.shareholdercommision'],
        },
      },
      minPrisForAfgiftsvurdering: {
        friendly: 'Min. stålpris for afgiftsvurderinggebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.minPrisForAfgiftsvurdering'],
        },
      },
      stiftelsesprovisionBeloebMin: {
        friendly: 'Min. stiftelsesprovision',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      stiftelsesprovisionPctMin: {
        friendly: 'Min. stiftelsesprovision',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      stiftelsesprovisionRegBeloebMax: {
        friendly: 'Max. reg. stiftelsesprovision',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      autoItStiftelsesprovisionBeloebMax: {
        friendly: 'Auto IT Max. reg. stiftelsesprovision',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.autoItStiftelsesprovisionBeloebMax']
        }
      },
      stiftelsesprovisionBeloebMax: {
        friendly: 'Max. stiftelsesprovision',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      rentesatsRegMin: {
        friendly: 'Min. reg. rentesats',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      rentesatsRegMax: {
        friendly: 'Max. reg. rentesats',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      udbetalingPctMin: {
        friendly: 'Min. udbetaling',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      udbetalingBeloebMin: {
        friendly: 'Min. udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      udbetalingAldersbetingelse: {
        friendly: 'Min. udbetaling (aldersgrænse, person)',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      udbetalingAldersbetingelsePctMin: {
        friendly: 'Min. udbetaling (v. aldersbetingelse)',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      udbetalingAldersbetingelseBeloebMin: {
        friendly: 'Min. udbetaling (v. aldersbetingelse)',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      foersteYdelsePrivatPctMin: {
        friendly: 'Min. første ydelse (privatleasing)',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      foersteYdelsePctForslag: {
        friendly: 'Forslag til første ydelse',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      restvaerdiExMomsBeloebMin: {
        friendly: 'Restværdi, minimumsbeløb',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      fastRenteTillaeg: {
        friendly: 'F-rente (tillæg til V-rente)',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      price: {
        friendly: 'Pris',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      maxLoebetid: {
        friendly: 'Max. løbetid',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      rentesats: {
        friendly: 'Rentesats',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      loebetidForslag: {
        friendly: 'Forslag til løbetid (bruges ved > 0)',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      overkoerselKmPrisForslag: {
        friendly: 'Forslag til pris pr. overkørt km.',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
      },
      autoItOverkoerselKmPris: {
        friendly: 'Forslag til pris pr. overkørt km. (Auto IT)',
        datatype: 'decimal',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.autoItOverkoerselKmPris']
        }
      },
      prisgruppeUdenEkstraMaanedRegAfgift: {
        friendly: 'Uden ekstra måned reg. afgift',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      prisgruppeMedDigitalUnderskrift: {
        friendly: 'Aktivér digital underskrift',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      tilladOperationelLeasing: {
        friendly: 'Aktivér operationel leasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.tilladOperationelLeasing'],
        },
      },
      renteHoejUdbetaling: {
        friendly: 'Rentereg., høj udbetaling',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.interestRateForHighDownPayment'],
        },
      },
      renteHoejUdbetalingGraensePct: {
        friendly: 'Rentereg., høj udbetaling',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.interestRateForHighDownPayment'],
        },
      },
      renteHoejUdbetalingGraenseBeloeb: {
        friendly: 'Rentereg., høj udbetaling',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.interestRateForHighDownPayment'],
        },
      },
      renteregForhandlerAnviserPct: {
        friendly: 'Renteregulering, forhandler anviser',
        datatype: 'percent',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.renteregForhandlerAnviserPct'],
        },
      },
      dokumentgebyrregForhandlerAnviserBeloeb: {
        friendly: 'Dokumentgebyrregulering, forhandler anviser',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.dokumentgebyrregForhandlerAnviserBeloeb'],
        },
      },
      nummerpladerDefaultCGP: {
        friendly: 'Nummerplader',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.licensePlates'],
        },
      },
      deklarationForsikringCGP: {
        friendly: 'Deklaration, forsikring',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.pledgeDeclarationFee'],
        },
      },
      useNummerpladerDefaultCGP: {
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.licensePlates'],
        },
      },
      useDeklarationForsikringCGP: {
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.pledgeDeclarationFee'],
        },
      },
      useDeklarationForsikringForlaengelseCGP: {
        datatype: 'boolean',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.pledgeDeclarationFee'],
        },
      },
      deklarationForsikringForlaengelseCGP: {
        friendly: 'Deklaration, forsikring',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.pledgeDeclarationFee'],
        },
      },
      contractCreationFeeCompanyGroup: {
        friendly: 'Oprettelsesgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        default: 0,
        fieldConditions: {
          requiredFeature: ['companyGroup.contractCreationFeeCompanyGroup'],
        },
      },
      interestRateDependsOn: {
        friendly: 'Rentesats og max løbetid afhænger af',
        align: 'left',
        vartype: 'model',
        datatype: 'count',
        fieldConditions: {
          requiredFeature: ['companyGroup.companyGroupProductPrice']
        }
      },
      vehicleImportCostProductGroup: {
        friendly: 'Standard hjemtagelsesomkostninger',
        datatype: 'amount',
        vartype: 'model',
        required: true,
        fieldConditions: {
          requiredFeature: ['companyGroup.vehicleImportCostProductGroup'],
        },
      }
    }

    function yearOrPriceEmpty() {
      return { maxLoebetid: 0, rentesats: 0, renteHoejUdbetaling: 0 }
    }

    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Prisgruppen blev gemt.')
      if (parseInt(res, 10) === $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/companyGroup/edit/' + res)
    }
    $scope.addYear = function (productId, idx) {
      $scope.vars.productAges[productId].splice(idx + 1, 0, yearOrPriceEmpty())
    }
    $scope.removeYear = function (productId, idx) {
      $scope.vars.productAges[productId].splice(idx, 1)
    }
    $scope.addPrice = function (productId, idx) {
      $scope.vars.productPrices[productId].splice(idx + 1, 0, yearOrPriceEmpty())
    }
    $scope.removePrice = function (productId, idx) {
      $scope.vars.productPrices[productId].splice(idx, 1)
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10)
    $scope.setActiveId($scope.id)

    var createMissingProductVariablesWhenProductsAreLoaded = function () {
      $scope.$watch('products', function () {
        for (var i in $scope.products) {
          if ($scope.vars.products[$scope.products[i].id] === undefined) {
            $scope.vars.products[$scope.products[i].id] = {}
            for (var field in $scope.variableDefs) {
              $scope.vars.products[$scope.products[i].id][field] =
                $scope.variableDefs[field].datatype === 'boolean' ? false : 0
            }
          }
          if ($scope.vars.productAges[$scope.products[i].id] === undefined) {
            $scope.vars.productAges[$scope.products[i].id] = []
          }
          if ($scope.vars.productPrices[$scope.products[i].id] === undefined) {
            $scope.vars.productPrices[$scope.products[i].id] = []
          }
        }
      })
    }

    if ($scope.id !== undefined) {
      $scope.delete = function () {
        $http.post('/api/companyGroup/delete/' + $stateParams.id).success(function () {
          $scope.loadAll()
          $scope.setGlobalAlert('Prisgruppen blev slettet.')
          $location.url('/controlpanel/companyGroup')
        })
      }

      $scope.save = function (saveAsNew) {
        var temp = angular.copy($scope.vars)
        angular.forEach(temp.row, function (elem, field) {
          if (elem instanceof Date) {
            temp.row[field] = dateToYMD(elem)
          }
        })

        if (saveAsNew) {
          $http.post('/api/companyGroup/add', temp).success(saveSuccess)
        } else {
          $http.post('/api/companyGroup/edit/' + $stateParams.id, temp).success(saveSuccess)
        }
      }

      $http.post('/api/companyGroup/get/' + $stateParams.id).success(function (res) {
        $scope.vars = res
        angular.forEach($scope.variableDefs, function (def, v) {
          if ($scope.vars.row[v] !== undefined && def.datatype == 'date') {
            $scope.vars.row[v] = new Date($scope.vars.row[v])
          }
        })

        createMissingProductVariablesWhenProductsAreLoaded()

        $scope.formIsLoaded = true
        registerWatches()
      })

      if ($interop.specific.formCompanyGroupCtrl.getCommonConfig) {
        $http.post('/api/commonConfig/get').success(function (res) {
          $scope.commonConfig = res
          $scope.formIsLoaded = true
        })
      }
    } else {
      $scope.vars = {
        row: {
          name: '',
          handleExternally: false,
          isCampaign: false,
        },
        products: {},
        productAges: {},
      }

      createMissingProductVariablesWhenProductsAreLoaded()

      $scope.save = function () {
        $http.post('/api/companyGroup/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
      registerWatches()
    }
  },
]
