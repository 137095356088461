
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/forms/mailTemplates.html";
      const src = "<h3>Mailskabeloner</h3>\n\n<form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n  <fieldset ng-repeat=\"(name, def) in variableDefs\" ng-if=\"!name.endsWith('Subject')\">\n    <legend ng-if=\"hasAllFieldConditions(def)\">\n      <a ng-href=\"/api/commonConfig/preview/{{name}}\" target=\"_blank\" class=\"btn btn-default btn-xs pull-right\"\n        ><i class=\"fa fa-eye\"></i> Vis eksempel (Gem først)</a\n      >\n      {{def.friendly}}\n    </legend>\n    <div ng-if=\"variableDefs[name + 'Subject']\" ng-autofield=\"{{name}}Subject\"></div>\n    <textarea ng-if=\"hasAllFieldConditions(def)\" ng-model=\"modelVars[name]\" class=\"form-control\" rows=\"8\"></textarea>\n    <hr ng-if=\"hasAllFieldConditions(def)\" />\n  </fieldset>\n\n  <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" ng-click=\"save()\"\n    ><i class=\"fa fa-save\"></i> &nbsp; Gem</a\n  >\n  <a class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\"\n    ><i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl</a\n  >\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      