import { IAttributes, IDirective, IScope } from 'angular'

import favFormTemplate from './favForm.html'

export const favForm = [
  function (): IDirective<IScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: favFormTemplate,
    }
  },
]
