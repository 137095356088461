import angular, {
  type IAttributes,
  IDirective,
  type INgModelController,
  type IScope
} from "angular";

interface MatchScope extends IScope {
  match: any
}

export function match(): IDirective<MatchScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      match: '=',
    },
    link(scope: MatchScope, elem: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      scope.$watch(
        function () {
          return (ctrl.$pristine && angular.isUndefined(ctrl.$modelValue)) || scope.match === ctrl.$modelValue
        },
        function (currentValue: any) {
          ctrl.$setValidity('match', currentValue)
        },
      )
    },
  }
}
