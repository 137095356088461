
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/prisskilt.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Prisskilt</h4>\n      </div>\n      <div class=\"modal-body\">\n        <div class=\"alert alert-success\" ng-show=\"successMsg\" ng-bind=\"successMsg\"></div>\n\n        <form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n          <div ng-autofield=\"maerkeModel\"></div>\n          <textarea\n            class=\"form-control\"\n            ng-model=\"modelVars.modelbeskrivelse\"\n            placeholder=\"Mulighed for øvrig modelbeskrivelse\"\n          ></textarea>\n          <hr />\n\n          <a class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\"\n            ><i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl</a\n          >\n          <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" pdflink=\"prisskilt\" params=\"\"\n            ><i class=\"fa fa-download\"></i> &nbsp; Hent PDF</a\n          >\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      