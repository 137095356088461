import {
  IAttributes,
  IDirective,
  IHttpResponse,
  IHttpService,
  IPromise,
  IQService,
  IRootScopeService,
  ISCEService,
  IScope,
} from 'angular'
import casehubFrameTemplate from '../templates/casehubFrame.html'

interface UserInfo {
  id: number
  kontraktSkaeringsnr: number
}

interface AppRootScope extends IRootScopeService {
  setGlobalAlert: (msg: string) => void
  setKeepAlert: (keepAlert: boolean) => void
  userInfo: undefined | UserInfo
}
interface ComputationEngine {
  eval: (field: string) => any
  getValues: () => { [field: string]: any }
}

interface AppRootScope extends IRootScopeService {
  setGlobalAlert: (msg: string) => void
  setKeepAlert: (keepAlert: boolean) => void
  userInfo: undefined | UserInfo
}
interface CasehubFrameScope extends IScope {
  caseError: boolean | undefined
  caseNotFound: boolean | undefined
  iframeUrl: string
  applicationId: number
  engine: ComputationEngine
  users: never
}

export const casehubFrame = [
  '$http',
  '$rootScope',
  '$q',
  '$sce',
  function (
    $http: IHttpService,
    $rootScope: AppRootScope,
    $q: IQService,
    $sce: ISCEService,
  ): IDirective<CasehubFrameScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: casehubFrameTemplate,
      scope: {
        applicationId: '=',
        engine: '=',
        users: '=',
        broadcaster: '=',
      },

      link: function ($scope: CasehubFrameScope, elm: JQLite, attrs: IAttributes) {
        function load() {
          return $http
            .get<any>('/api/casehub/case/' + $scope.applicationId)
            .then(function (data) {
              const res = data.data
              $scope.iframeUrl = $sce.trustAsResourceUrl(
                'https://vurdering.casehub.dk/admin/kronekapital/admin/' + res.caseId,
              )
            })
            .catch(function (data) {
              if (data.status === 404) {
                $scope.caseNotFound = true
              } else {
                $scope.caseError = true
              }
            })
        }

        load()
      },
    }
  },
]
