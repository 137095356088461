import { dateToYMD } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'
import { danishLocaleNumber } from '../functions/numbers'

export const formDepreciationModelCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount
    $scope.options = {}

    $scope.variableDefs = {
      depreciationMatrixId: {
        friendly: 'Type',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      standardEquipmentDepreciationMatrixId: {
        friendly: 'Type',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      extraEquipmentDepreciationMatrixId: {
        friendly: 'Type',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      baseValue: {
        friendly: 'Basisværdi',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      dbiNo: {
        friendly: 'DBI nr.',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
      holcoDepreciationModelBrandId: {
        friendly: 'Mærke',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
      holcoDepreciationModelModelId: {
        friendly: 'Model',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
      holcoDepreciationModelEngineId: {
        friendly: 'Motor',
        datatype: 'count',
        vartype: 'model',
        required: false,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Restværdimodellen blev gemt.')
      if (res == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/depreciationModel/edit/' + res.depreciationModelId)
      if ($scope.id !== undefined) {
        loadDepreciationModel()
      }
    }

    $scope.renderValue = function (value) {
      return danishLocaleNumber(Math.ceil(value).toString())
    }

    $scope.modelVars = {
      depreciationMatrixId: 0,
      standardEquipmentDepreciationMatrixId: 0,
      extraEquipmentDepreciationMatrixId: 0,
      dbiNo: '',
    }
    $scope.vars = {}
    $scope.id = $stateParams.id === undefined ? undefined : $stateParams.id
    $scope.setActiveId($scope.id)

    var loadDepreciationModel = function () {
      $http
        .post('/api/depreciationMatrix/getAllByAssetType')
        .success(function (xs) {
          $scope.depreciationMatrices = xs
        })
        .then(function (response) {
          $http.post('/api/depreciationModel/get/' + $scope.id).success(function (res) {
            $scope.vars = res
            for (var i in res.row) {
              $scope.modelVars[i] = res.row[i]
            }
            $scope.formIsLoaded = true
          })
        })
    }
    $scope.emptyItem = function (name) {
      return [
        {
          id: 0,
          name: name,
        },
      ]
    }

    if ($scope.id !== undefined) {
      $scope.save = function () {
        const req = {
          row: { ...$scope.modelVars },
        }
        $http.post('/api/depreciationModel/edit/' + $scope.id, req).success(saveSuccess)
      }
      $scope.delete = function () {
        $http.post('/api/depreciationModel/delete/' + $scope.id).success(function () {
          $scope.loadAll()
          $location.url('/controlpanel/depreciationModel')
        })
      }
      loadDepreciationModel()
    } else {
      $http.post('/api/depreciationMatrix/getAllByAssetType').success(function (xs) {
        $scope.depreciationMatrices = xs
      })
      $scope.save = function () {
        const req = {
          row: { ...$scope.modelVars },
        }
        $http.post('/api/depreciationModel/add', req).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
