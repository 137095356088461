import { assignScope, makeDirective, ScopeType } from '@/shared/util/directiveHelpers'
import asgBoxTemplate from './asgBox.html'
import { formatDate, formatNumber } from '@/shared/functions/intlFormaters'

interface AsgData {
  requestDate: string
  status: string
  charge: number | null
  newPrice: number | null
  dmrPrice: number | null
}

interface PresentAsgData {
  requestDate: string
  charge: number
  newPrice: number
  dmrPrice: number
}

interface AsgJobArgs {
  stelnummer: string
  kilometerstand: number
  varevogn: number
  nummerpladeFarve: number
}

export const asgBox = makeDirective({
  template: asgBoxTemplate,
  inject: {
    $http: '$http',
    $timeout: '$timeout',
    $interop: '$interop',
  },
  scope: {
    id: {
      binding: 'twoWay',
      type: Number,
    },
    modelVars: {
      binding: 'twoWay',
      type: Object as ScopeType<unknown> as ScopeType<
        {
          afgiftBeregnetAf: number
          vurderetRegAfgift: number
          status: number
          leveringsdatoFinal: Date
          varevogn: number
        } & AsgJobArgs
      >,
    },
    currentRow: {
      binding: 'twoWay',
      type: Object as ScopeType<unknown> as ScopeType<{
        asgJobId?: string | null
        asgJobArgs?: string | null
      }>,
    },
    userInfo: {
      binding: 'twoWay',
      type: Object,
    },
    engine: {
      binding: 'twoWay',
      type: Object as unknown as ScopeType<computationEngine.ComputationEngine<any>>,
    },
  },
  assignScope: assignScope<{
    formatNumber: (v: number) => string | null
    formatDate: (v: string) => string | null
    formatVarevogn: (v: number) => string | null
    formatLicensePlateColor: (v: number) => string | null

    asgStatus: 'notOrdered' | 'pending' | 'error' | 'completed' | 'unknown' | 'uninitialized'
    rawAsgStatus: string | null
    asgData: PresentAsgData | null
    asgJobArgs: AsgJobArgs | null
    isAsgUsed: () => boolean
    hasRequestDateWarning: () => boolean
    isOtherComputationUsed: () => boolean
    canOrder: () => boolean
    asgOrderStatus: 'done' | 'loading'

    asgColor: () => string
    danishAsgStatus: () => string

    orderAsg: () => void
    getAsgStatistics: () => void
    applyAsgData: () => void
  }>(),
  link({ $http, $timeout, $interop }, { scope }) {
    scope.formatNumber = formatNumber
    scope.formatDate = formatDate
    scope.formatVarevogn = (v) => {
      switch (v) {
        case 0:
          return 'Personbil'
        case 1:
          return 'Varevogn 50%'
        case 2:
          return 'Varevogn 30%'
        default:
          return null
      }
    }
    scope.formatLicensePlateColor = (v) => {
      switch (v) {
        case 0:
          return 'Hvid'
        case 1:
          return 'Gul'
        case 2:
          return 'Hvid/Gul'
        default:
          return null
      }
    }

    scope.asgStatus = 'uninitialized'
    scope.rawAsgStatus = null
    scope.asgData = null

    scope.asgJobArgs = null

    scope.$watch('currentRow.asgJobArgs', () => {
      scope.asgJobArgs = scope.currentRow.asgJobArgs ? JSON.parse(scope.currentRow.asgJobArgs) : null
    })

    function isAsgUsed() {
      return (
        scope.modelVars.afgiftBeregnetAf === $interop.specific.asgBox.afgiftBeregnetAfAsg &&
        scope.asgData?.charge === scope.modelVars.vurderetRegAfgift
      )
    }

    function hasRequestDateWarning(): boolean {
      let requestDate
      if (scope.asgData) {
        requestDate = new Date(scope.asgData.requestDate)
      } else {
        requestDate = new Date()
      }
      const deliveryDate = scope.engine.eval('leveringsdatoFinal') as Date

      requestDate.setDate(requestDate.getDate() + 30)

      return requestDate <= deliveryDate
    }
    scope.hasRequestDateWarning = hasRequestDateWarning

    function hasMismatchedValue<K extends keyof AsgJobArgs>(k: K) {
      return scope.asgJobArgs && scope.asgJobArgs[k] !== scope.modelVars[k]
    }

    function canOrder() {
      return (
        !scope.currentRow.asgJobId ||
        (['notOrdered', 'completed', 'error', 'unknown', 'uninitialized'].includes(scope.asgStatus) &&
          (hasMismatchedValue('stelnummer') ||
            hasMismatchedValue('kilometerstand') ||
            ($interop.module === 'krone' ? hasMismatchedValue('varevogn') : hasMismatchedValue('nummerpladeFarve')) ||
            hasRequestDateWarning()))
      )
    }
    scope.canOrder = canOrder

    function isOtherComputationUsed() {
      return (
        scope.modelVars.afgiftBeregnetAf !== $interop.specific.asgBox.afgiftBeregnetAfAsg &&
        scope.modelVars.afgiftBeregnetAf !== 0
      )
    }
    scope.isAsgUsed = isAsgUsed
    scope.isOtherComputationUsed = isOtherComputationUsed

    function parseAsgStatus(rawAsgStatus: string) {
      if (rawAsgStatus === null) {
        if (scope.currentRow.asgJobId !== null) {
          return 'pending'
        } else {
          return 'notOrdered'
        }
      } else if (rawAsgStatus === 'Completed') {
        return 'completed'
      } else if (rawAsgStatus.startsWith('InProgress')) {
        return 'pending'
      } else if (rawAsgStatus === 'IKKE-DMR') {
        return 'error'
      } else if (rawAsgStatus === 'New' || rawAsgStatus.endsWith('New')) {
        return 'pending'
      } else if (rawAsgStatus === 'IS-COMPLETED' || rawAsgStatus === 'IB-COMPLETED') {
        return 'error'
      } else {
        return 'unknown'
      }
    }

    function danishAsgStatus() {
      if (isOtherComputationUsed()) {
        return 'Anvendes ikke'
      } else {
        switch (scope.asgStatus) {
          case 'notOrdered':
            return 'Ej bestilt'
          case 'uninitialized':
            return 'Indlæser...'
          case 'pending':
            return 'Afventer beregning'
          case 'completed':
            if (isAsgUsed()) {
              return 'Anvendes på sagen'
            } else {
              return 'Klar (Ikke i brug)'
            }
          case 'error':
          case 'unknown':
            if (scope.rawAsgStatus === 'IKKE-DMR') {
              return 'Ikke kendt I DMR'
            } else if (scope.rawAsgStatus === 'IS-COMPLETED') {
              return 'Ingen sammenligningsbiler'
            } else if (scope.rawAsgStatus === 'IB-COMPLETED') {
              return 'Afvist'
            } else {
              return scope.rawAsgStatus ?? 'Ej bestilt'
            }
        }
      }
    }

    function asgColor() {
      switch (scope.asgStatus) {
        case 'notOrdered':
        case 'uninitialized':
          return 'text-muted'
        case 'completed':
          return isAsgUsed() ? 'text-success' : 'text-muted'
        case 'pending':
          return 'text-warning'
        case 'error':
          return 'text-danger'
        case 'unknown':
          return 'text-danger'
      }
    }

    scope.danishAsgStatus = danishAsgStatus
    scope.asgColor = asgColor
    scope.asgOrderStatus = 'done'

    async function getAsgStatistics() {
      if (typeof scope.id !== 'undefined') {
        const res = await $http.get<AsgData>(`/api/asg/statistics/${scope.id}`)
        await $timeout(() => {
          scope.rawAsgStatus = res.data.status
          scope.asgStatus = parseAsgStatus(res.data.status)
          if (res.data.charge !== null && res.data.newPrice !== null && res.data.dmrPrice !== null) {
            scope.asgData = {
              requestDate: res.data.requestDate,
              charge: res.data.charge,
              newPrice: res.data.newPrice,
              dmrPrice: res.data.dmrPrice,
            }
          }
        })
      }
    }
    scope.getAsgStatistics = getAsgStatistics

    scope.$watch('id', () => {
      if (scope.currentRow.asgJobId) {
        getAsgStatistics()
      } else {
        scope.asgData = null
        scope.rawAsgStatus = null
        scope.asgStatus = 'notOrdered'
      }
    })

    async function orderAsg() {
      if (typeof scope.id !== 'undefined') {
        scope.asgOrderStatus = 'loading'
        scope.asgData = null
        scope.rawAsgStatus = null
        scope.asgJobArgs = null
        const res = await $http.post<{ jobId: string; jobArgs: AsgJobArgs }>(
          `/api/asg/statistics/${scope.id}`,
          scope.modelVars,
        )
        await $timeout(() => {
          scope.asgJobArgs = res.data.jobArgs
          scope.rawAsgStatus = 'InProgress'
          scope.asgStatus = 'pending'
          scope.currentRow.asgJobId = res.data.jobId
          scope.asgOrderStatus = 'done'
        })
      }
    }
    scope.orderAsg = orderAsg

    function applyAsgData() {
      if (scope.asgData && scope.modelVars.status === 0) {
        scope.modelVars.afgiftBeregnetAf = $interop.specific.asgBox.afgiftBeregnetAfAsg
        scope.modelVars.vurderetRegAfgift = scope.asgData.charge
      }
    }

    scope.applyAsgData = applyAsgData
  },
})
