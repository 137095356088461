
      require('/home/soren/Programming/carleasing/applications/js/src/shared/templates/angularstrap/alternativeDropdown.html');
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/modals/applicationCondition.html";
      const src = "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" ng-click=\"$hide()\">&times;</button>\n        <h4 class=\"modal-title\">Ansøgningen er betinget godkendt</h4>\n      </div>\n      <div class=\"modal-body\">\n        <p ng-show=\"a.betingelseKunde\"><b>Betingelse til kunde:</b><br />{{a.betingelseKunde}}</p>\n        <p ng-show=\"a.betingelseSaelger\"><b>Betingelse til sælger:</b><br />{{a.betingelseSaelger}}</p>\n      </div>\n      <div class=\"modal-footer clearfix\">\n        <button type=\"button\" class=\"btn btn-default pull-left\" ng-click=\"$hide()\">Acceptér ikke</button>\n        <a\n          class=\"btn btn-success pull-right\"\n          bs-dropdown=\"getContractDropdown(a, $hide)\"\n          data-template=\"shared/templates/angularstrap/alternativeDropdown.html\"\n          >Acceptér og hent kontrakt</a\n        >\n      </div>\n    </div>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      