
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/controlpanel.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>Forhandlere og priser</h3>\n</div>\n\n<ul class=\"nav nav-tabs\">\n  <li ng-repeat=\"entry in controlPanelEntries\" ng-class=\"{'active' : $state.includes(entry.state)}\">\n    <a ng-href=\"{{entry.href}}\">{{entry.name}}</a>\n  </li>\n</ul>\n\n<div ui-view></div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      