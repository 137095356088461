import creditReportTemplate from '../templates/dkkapCreditReport.html'

import { dateToDanish, dateToYMD } from '../functions/dates'
import { danishLocaleNumber } from '../functions/numbers'
import angular, {
  IAttributes,
  IDirective,
  IHttpResponse,
  IHttpService,
  IPromise,
  IQService,
  IRootScopeService,
  IScope,
} from 'angular'
import { CarleasingInterop } from '@/shared/carleasingInterop'

function chunk<T>(arr: Array<T>, len: number) {
  const chunks = [],
    n = arr.length

  let i = 0
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

////

interface NextPrev<T> {
  next: T
  prev: null | T
}

interface LookupRequest {
  name: string
  identifier: string
  address: string
  zipcode: number | undefined
}

interface Stakeholder extends LookupRequest {
  roles: ReadonlyArray<string>
  number: string
}

type StakeholdersMap = { [identifier: string]: Stakeholder }

////

interface LookupResult {
  navKeyMetrics: undefined | NavKeyMetrics
  fastEjendom: undefined | FastEjendom
  annualReports: undefined | CompanyInfo
  rki: undefined | boolean
  dowJones: undefined | boolean
}

interface AnnualReport {
  start: number
  end: number
  summary: { [x: string]: number }
  pdfUrl: undefined | string
  xmlUrl: undefined | string
}

interface CompanyInfo {
  name: string
  roles: ReadonlyArray<string>
  reports: ReadonlyArray<AnnualReport>
}

type LookupResultMap = { [identifier: string]: LookupResult }

interface NavKeyMetrics {
  totalRestgaeld: number
  countMainContracts: number
}

interface FastEjendom {
  fastEjendom: boolean
  hvisJa: undefined | boolean
}

interface CreditReportStatus {
  saving: boolean
  dirty: boolean
  cernoTransferring: boolean
  errorCount?: () => number
}

interface ComputationEngine {
  eval: (field: string) => any
  getValues: () => { [field: string]: any }
}

interface AppRootScope extends IRootScopeService {
  setGlobalAlert: (msg: string) => void
  setKeepAlert: (keepAlert: boolean) => void
  userInfo: undefined | UserInfo
}

interface UserInfo {
  id: number
  kontraktSkaeringsnr: number
}

interface ValuesJson {
  status: 0 | 1 | 2
}

interface VariableDef {
  friendly?: string
  datatype: 'amount' | 'string' | 'count'
  vartype: 'model'
  required: boolean
}

interface CreditReportScope extends IScope {
  // from parent
  applicationId: number
  engine: ComputationEngine
  users: never
  stakeholders: StakeholdersMap
  showSaveButton: never
  products: never
  status: CreditReportStatus
  aftalenr: null | number
  aftalenr2: null | number
  valuesJson: ValuesJson

  companySections: ReadonlyArray<ReadonlyArray<Stakeholder>>
  personSections: ReadonlyArray<ReadonlyArray<Stakeholder>>

  modelVars: null | ModelVars
  lookups: LookupResultMap
  variableDefs: { [variable: string]: VariableDef }

  save: (useAlert: boolean, setReviewer: boolean) => IPromise<void>
  lookup: (lookups: NextPrev<LookupRequest>[]) => IPromise<IHttpResponse<LookupResultMap>>
  reactToLookups: () => void
  pdf: () => void
  pdfById: () => void
  cerno: () => void

  showWarnings: () => boolean
  blankoBeloebHasWarning: () => boolean
  foersteYdelseHasWarning: () => boolean
  afviklingsPctHasWarning: () => boolean
  indtjeningHasWarning: (i: Stakeholder) => boolean
  egenkapitalHasWarning: (i: Stakeholder) => boolean
  hovedstolHasWarning: () => boolean
  samletEngagementHasWarning: () => boolean
  udbytteHasWarning: (i: Stakeholder) => boolean
  balanceHasWarning: (i: Stakeholder) => boolean
  konkurserIndehaverhistorikHasWarning: (i: Stakeholder) => boolean
  koncernrelationerHasWarning: (i: Stakeholder) => boolean
  debitorskifteHasDanger: () => boolean
  passerUdstyretBranchenHasDanger: () => boolean
  udlaegVirksomhedHasDanger: (i: Stakeholder) => boolean
  udlaegPersonHasDanger: (i: Stakeholder) => boolean
  rigtigePersonHasDanger: (i: Stakeholder) => boolean

  leasingtypeErrorCount: () => number
  virksomhedErrorCount: () => number
  personErrorCount: () => number
  provisionErrorCount: () => number
  totalErrorCount: () => number

  virksomhedDangerMessages: () => ReadonlyArray<string>
  virksomhedKommentarHasDanger: () => boolean
  virksomhedDangerMessage: (stakeholder: Stakeholder) => ReadonlyArray<string>

  personDangerMessages: () => ReadonlyArray<string>
  personKommentarHasDanger: () => boolean
  personDangerMessage: (stakeholder: Stakeholder) => ReadonlyArray<string>

  renderAmount: (value: number) => string
  renderPct: (value: number) => string

  showKundetype: () => string
  indkoebPris: () => number
  scrapPris: () => number
  afviklingsPct: () => number
  foersteYdelse: () => number
  showGenleasingKontraktNr: () => string
  genleasing: () => boolean
  loebetid: () => number

  regnskabChanged: (identifier: string, obj: VirksomhedInfo) => void
  fastEjendomChanged: (identifier: string, obj: CommonInfo) => void
  renderTimestamp: (timestamp: number) => string

  options: OptionsType

  debug: () => void
  contractIsApproved: () => boolean

  lookupTimer: number | null
  toggleDJ: (cpr: number, setting: boolean) => void
}

type CreditRating = { applicationId: number } & ModelVars

interface ModelVars {
  blankoEtablering: number
  blankoUdloeb: number
  antalKontrakter: number
  totalRestgaeld: number
  personKommentar: string
  virksomhedKommentar: string
  phbKommentar: string
  personInfo: { [identifier: string]: PersonInfo }
  virksomhedInfo: { [identifier: string]: VirksomhedInfo }
  debitorskifte?: boolean
  gennemgaaetAf?: number
  passerUdstyretBranchen?: boolean
}

interface CreditRatingLoad {
  lookups: { [identifier: string]: LookupResult }
  creditRating?: CreditRating
}

interface CommonInfo {
  rkiAuto?: boolean
  rki?: boolean
  fastEjendomIndtastning?: boolean
  fastEjendom?: boolean
  hvisJa?: boolean
}

interface PersonInfo extends CommonInfo {
  dowJonesRigtigePerson?: boolean
  dowJones?: boolean // not on server type?
}

interface VirksomhedInfo extends CommonInfo {
  indtjening?: number
  positivEgenkapital?: number
  udbytte?: number
  balance?: number
  regnskab?: boolean // not on server type?
  koncernrelationer?: boolean
  konkurserIndehaverhistorik?: boolean
}

interface Choice<A> {
  value: A
  option: string
}

interface OptionsType {
  jaNej: ReadonlyArray<Choice<boolean>>
  jaNejVaelg: ReadonlyArray<Choice<undefined | boolean>>
  rkiIndtastning: ReadonlyArray<Choice<boolean>>
  regnskabIndtastning: (identifier: string) => ReadonlyArray<Choice<boolean>>
  fastEjendomIndtastning: (identifier: string) => ReadonlyArray<Choice<boolean>>
}

export const dkkapCreditReport = [
  '$http',
  '$rootScope',
  '$q',
  '$interop',
  function (
    $http: IHttpService,
    $rootScope: AppRootScope,
    $q: IQService,
    $interop: CarleasingInterop
  ): IDirective<CreditReportScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditReportTemplate,
      scope: {
        applicationId: '=',
        engine: '=',
        users: '=',
        stakeholders: '=',
        showSaveButton: '=',
        broadcaster: '=',
        products: '=',
        status: '=',
        aftalenr: '=',
        aftalenr2: '=',
        valuesJson: '=',
        contractIsApproved: '=',
      },

      link: function ($scope: CreditReportScope, elm: JQLite, attrs: IAttributes) {
        $scope.companySections = []
        $scope.personSections = []

        function lookupResponseHandler(adds: IHttpResponse<LookupResultMap>) {
          let temp: LookupResultMap = {}
          for (const i in adds.data) {
            temp[i] = {
              ...($scope.lookups[i] || {}),
              ...adds.data[i],
            }
          }

          $scope.lookups = { ...$scope.lookups, ...temp }

          $scope.reactToLookups()
        }

        // stakeholders is handled in an immutable manner -- therefore we can use $watch
        // it is only involved with data from the parent, i.e. will not be messed up on creditRating load.
        // thus it is placed here, instead of below in "registerWatches"
        $scope.$watch('stakeholders', function (m: StakeholdersMap, n: StakeholdersMap) {
          //if (m === n) return;
          $scope.companySections = chunk(
            Object.keys(m)
              .filter((x) => x.length === 8)
              .map((x) => m[x]),
            3,
          )
          $scope.personSections = chunk(
            Object.keys(m)
              .filter((x) => x.length === 10)
              .map((x) => m[x]),
            3,
          )

          if ($scope.valuesJson.status === 0) {
            // we make an angular.equals comparison on matching keys
            var lookupRequests: NextPrev<LookupRequest>[] = []

            for (var x in m) {
              if (!angular.equals(m[x], n[x])) {
                lookupRequests.push({ next: m[x], prev: n[x] })
              }
            }

            // Lav noget med setTimeout her.
            // Kald nedenstående i callback til setTimeout.
            $scope.lookupTimer = null
            if ($scope.lookupTimer) {
              clearTimeout($scope.lookupTimer)
            }
            // @ts-ignore
            $scope.lookupTimer = setTimeout(
              () => $scope.lookup(lookupRequests).then((adds) => lookupResponseHandler(adds)),
              1000,
            )
          }
        })

        $scope.modelVars = null
        $scope.lookups = {}

        // API HANDLERS:

        $scope.save = function (useAlert, setReviewer): IPromise<void> {
          if (setReviewer && $scope.modelVars && $scope.modelVars.gennemgaaetAf === undefined && $rootScope.userInfo) {
            $scope.modelVars.gennemgaaetAf = $rootScope.userInfo.id
          }
          $scope.status.saving = true
          return $http
            .post('/api/kreditvurdering/save', {
              applicationId: $scope.applicationId,
              ...$scope.modelVars,
            })
            .then(() => {
              $scope.status.saving = false
              $scope.status.dirty = false

              if (useAlert) {
                $rootScope.setGlobalAlert('Kreditvurderingen blev gemt!')
                $rootScope.setKeepAlert(false)
              }
            })
        }

        $scope.lookup = function (lookups: NextPrev<LookupRequest>[]) {
          var contractNumbers = []

          if ($scope.aftalenr) {
            contractNumbers.push($scope.aftalenr)
          }
          if ($scope.aftalenr2) {
            contractNumbers.push($scope.aftalenr2)
          }

          return $http.post('/api/creditrating/lookup', {
            lookups: lookups,
            contractNumbers: contractNumbers,
          })
        }

        function submitForm(method: string, action: string, json: any): void {
          const form = document.getElementById('creditRatingForm')
          if (form instanceof HTMLFormElement) {
            const jsonInput = form.elements.namedItem('json')
            if (jsonInput instanceof HTMLInputElement) {
              jsonInput.value = JSON.stringify(json)
              form.action = action
              form.method = method
              form.submit()
            }
          }
        }

        $scope.pdfById = function (): void {
          submitForm('GET', '/api/creditrating/pdf/' + $scope.applicationId, '')
        }

        $scope.pdf = function (): void {
          let temp = $scope.engine.getValues()
          for (var v in temp) {
            var d = temp[v]
            temp[v] = d instanceof Date ? dateToYMD(d) : d
          }

          const payload = {
            valuesJson: temp,
            creditRating: { ...$scope.modelVars, applicationId: $scope.applicationId },
            stakeholderHeads: Object.keys($scope.stakeholders).map((x) => $scope.stakeholders[x]),
            lookups: $scope.lookups,
            aftalenr: $scope.aftalenr,
            aftalenr2: $scope.aftalenr2,
          }

          submitForm('POST', '/api/creditrating/pdf', payload)
        }
        $scope.cerno = function (): void {
          $scope.status.cernoTransferring = true

          $scope.save(false, false).then(() => {
            transferToCerno()
          })
        }

        function transferToCerno() {
          return $http.get('/api/creditrating/cerno/' + $scope.applicationId).then(() => {
            $scope.status.cernoTransferring = false
          })
        }

        // $on/$broadcast/$emit

        $scope.$on('creditRating.save', function (event, argsOpt) {
          var args = argsOpt || {}

          var promise = $scope.save(args.alert, !!args.setReviewer)
          if (args.pdf) {
            promise.then(() => $scope.$broadcast('creditRating.pdf'))
          }

          if (!!$scope.aftalenr && $scope.valuesJson.status > 0) {
            promise.then(() => transferToCerno())
          }
        })

        $scope.$on('creditRating.pdf', function () {
          if ($scope.valuesJson.status > 0) {
            $scope.pdfById()
          } else {
            $scope.pdf()
          }
        })

        $scope.toggleDJ = function (cpr: number, setting: boolean) {
          if ($scope.modelVars) {
            $scope.modelVars.personInfo[cpr].dowJonesRigtigePerson = setting
            $scope.$broadcast('creditRating.parentToggleDJ', { alert: false, cpr: cpr, setting: setting })
          }
        }

        $scope.$on('creditRating.childToggleDJ', function (event, argsObj) {
          if ($scope.modelVars) {
            $scope.modelVars.personInfo[argsObj.cpr].dowJonesRigtigePerson = argsObj.setting
          }
        })

        // $watch

        var watches: (() => void)[] = []

        var unregisterWatches = function () {
          for (var i in watches) {
            var temp = watches[i]
            temp()
          }
          watches = []
        }

        var registerWatches = function () {
          unregisterWatches()
          var modelVarsInitial = true
          watches.push(
            $scope.$watch(
              'modelVars',
              function (x, y) {
                if (modelVarsInitial) {
                  modelVarsInitial = false
                  return
                }
                $scope.status.dirty = true
              },
              true,
            ),
          )
        }

        // TEMPLATE HELPERS
        $scope.showWarnings = function () {
          if (
            $scope.leasingtypeErrorCount() > 0 &&
            $rootScope.userInfo &&
            $scope.aftalenr != null &&
            $rootScope.userInfo.kontraktSkaeringsnr < $scope.aftalenr
          ) {
            return true
          } else {
            return false
          }
        }
        const doValidation = $interop.specific.dkkapCreditReport.doValidation

        $scope.afviklingsPctHasWarning = function () {
          if (doValidation) {
            return $scope.afviklingsPct() < $scope.engine.eval('afviklingMinPctVejl')
          }
          return false
        }
        $scope.indtjeningHasWarning = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]
            return info.indtjening == undefined || (info && info.indtjening !== undefined && info.indtjening <= 0)
          }
          return false
        }
        $scope.balanceHasWarning = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]
            return info.balance == undefined || (info && info.balance !== undefined && info.balance <= 0)
          }
          return false
        }
        $scope.egenkapitalHasWarning = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]
            return (
              info.positivEgenkapital == undefined ||
              (info && info.positivEgenkapital !== undefined && info.positivEgenkapital <= 0)
            )
          }
          return false
        }
        $scope.koncernrelationerHasWarning = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]
            return info.koncernrelationer == undefined
          }
          return false
        }
        $scope.konkurserIndehaverhistorikHasWarning = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]
            return info.konkurserIndehaverhistorik == undefined
          }
          return false
        }
        $scope.hovedstolHasWarning = function () {
          if (doValidation) {
            return $scope.engine.eval('blankoHovedstolDMR') > $scope.engine.eval('hovedstolBestyrelseGraense')
          }
          return false
        }
        $scope.debitorskifteHasDanger = function () {
          if (doValidation && $scope.modelVars) {
            return $scope.modelVars.debitorskifte === undefined && !$scope.genleasing()
          }
          return false
        }
        $scope.passerUdstyretBranchenHasDanger = function () {
          if (doValidation && $scope.modelVars) {
            return $scope.modelVars.passerUdstyretBranchen === undefined
          }
          return false
        }
        $scope.udlaegVirksomhedHasDanger = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[i.identifier]

            if (info) {
              const out = !!info.fastEjendom && info.hvisJa === undefined
              return out
            } else {
              return false
            }
          }
          return false
        }
        $scope.udlaegPersonHasDanger = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.personInfo[i.identifier]

            if (info) {
              const out = !!info.fastEjendom && info.hvisJa === undefined
              return out
            } else {
              return false
            }
          }
          return false
        }
        $scope.rigtigePersonHasDanger = function (i: Stakeholder) {
          if (doValidation && $scope.modelVars) {
            const info = $scope.modelVars.personInfo[i.identifier]
            if (info) {
              return info.dowJonesRigtigePerson === undefined && info.dowJones === true
            } else {
              return false
            }
          }
          return false
        }

        $scope.virksomhedDangerMessages = function () {
          return Object.keys($scope.stakeholders)
            .filter((x) => x.length === 8)
            .map((x) => $scope.virksomhedDangerMessage($scope.stakeholders[x]))
            .reduce((xss, xs) => [...xss, ...xs], [])
        }
        $scope.virksomhedKommentarHasDanger = function () {
          if (doValidation && $scope.modelVars) {
            return $scope.virksomhedDangerMessages().length > 0 && $scope.modelVars.virksomhedKommentar.length === 0
          }
          return false
        }
        $scope.virksomhedDangerMessage = function (stakeholder) {
          if ($scope.modelVars) {
            const info = $scope.modelVars.virksomhedInfo[stakeholder.identifier]
            return [
              ...(info && info.hvisJa && info.fastEjendom ? ['Udlæg i ejendom (' + stakeholder.name + ')'] : []),
              ...(info.indtjening == undefined || (info && (info.indtjening || 0) <= 0)
                ? ['Indtjening ≤ 0 (' + stakeholder.name + ')']
                : []),
              ...(info.positivEgenkapital == undefined || (info && (info.positivEgenkapital || 0) <= 0)
                ? ['Egenkapital ≤ 0 (' + stakeholder.name + ')']
                : []),
              ...(info.balance == undefined || (info && (info.balance || 0) <= 0)
                ? ['Balance ≤ 0 (' + stakeholder.name + ')']
                : []),
            ]
          }
          return []
        }

        $scope.personDangerMessages = function () {
          return Object.keys($scope.stakeholders)
            .filter((x) => x.length === 10)
            .map((x) => $scope.personDangerMessage($scope.stakeholders[x]))
            .reduce((xss, xs) => [...xss, ...xs], [])
        }
        $scope.personKommentarHasDanger = function () {
          if (doValidation && $scope.modelVars) {
            return $scope.personDangerMessages().length > 0 && $scope.modelVars.personKommentar.length === 0
          }
          return false
        }
        $scope.personDangerMessage = function (stakeholder) {
          if ($scope.modelVars) {
            const info = $scope.modelVars.personInfo[stakeholder.identifier]
            return [...(info && info.hvisJa && info.fastEjendom ? ['Udlæg (' + stakeholder.name + ')'] : [])]
          }
          return []
        }
        $scope.leasingtypeErrorCount = function () {
          return ($scope.debitorskifteHasDanger() ? 1 : 0) + ($scope.passerUdstyretBranchenHasDanger() ? 1 : 0)
        }
        $scope.virksomhedErrorCount = function () {
          const all = Object.keys($scope.stakeholders)
            .filter((x) => x.length === 8)
            .map((key) => $scope.stakeholders[key])

          return (
            all.filter($scope.udlaegVirksomhedHasDanger).length +
            all.filter($scope.koncernrelationerHasWarning).length +
            all.filter($scope.konkurserIndehaverhistorikHasWarning).length +
            (all.filter($scope.virksomhedKommentarHasDanger).length > 0 ? 1 : 0)
          )
        }
        $scope.personErrorCount = function () {
          const all = Object.keys($scope.stakeholders)
            .filter((x) => x.length === 10)
            .map((key) => $scope.stakeholders[key])
          return (
            all.filter($scope.udlaegPersonHasDanger).length +
            (all.filter($scope.personKommentarHasDanger).length > 0 ? 1 : 0) +
            all.filter($scope.rigtigePersonHasDanger).length
          )
        }
        $scope.provisionErrorCount = function () {
          return 0
        }
        $scope.totalErrorCount = function () {
          return (
            $scope.leasingtypeErrorCount() +
            $scope.virksomhedErrorCount() +
            $scope.personErrorCount() +
            $scope.provisionErrorCount()
          )
        }

        $scope.renderAmount = function (value: number) {
          return danishLocaleNumber(Math.ceil(value).toString())
        }

        $scope.renderPct = function (value) {
          return (value * 100).toFixed(2).replace('.', ',')
        }

        $scope.variableDefs = {
          virksomhedKommentar: {
            datatype: 'string',
            vartype: 'model',
            required: false,
          },
          personKommentar: {
            datatype: 'string',
            vartype: 'model',
            required: false,
          },
          phbKommentar: {
            datatype: 'string',
            vartype: 'model',
            required: false,
          },
          totalRestgaeld: {
            friendly: 'Samlet engagement, udover denne (total restgæld)',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          antalKontrakter: {
            friendly: 'Samlet engagement, udover denne (antal kontrakter)',
            datatype: 'count',
            vartype: 'model',
            required: true,
          },
          indtjening: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          blankoEtablering: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          blankoUdloeb: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          positivEgenkapital: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          udbytte: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
          balance: {
            friendly: '',
            datatype: 'amount',
            vartype: 'model',
            required: true,
          },
        }

        $scope.regnskabChanged = function (identifier, obj) {
          if (!obj.regnskab) {
            obj.indtjening = 0
            obj.udbytte = 0
            obj.positivEgenkapital = 0
            obj.balance = 0
          }
          if (obj.regnskab) {
            var currentLU = $scope.lookups[identifier]
            if (currentLU && currentLU.annualReports) {
              var reports = currentLU.annualReports.reports
              var latestAR = reports[reports.length - 1]

              obj.indtjening = latestAR.summary.ProfitLoss
              obj.udbytte = latestAR.summary.ProposedDividendRecognisedInEquity
              obj.positivEgenkapital = latestAR.summary.Equity
              obj.balance = latestAR.summary.Assets
            }
          }
        }

        $scope.fastEjendomChanged = function (identifier, obj) {
          if (!obj.fastEjendomIndtastning) {
            obj.fastEjendom = undefined
            obj.hvisJa = undefined
          }
          if (obj.fastEjendomIndtastning) {
            var currentLU = $scope.lookups[identifier]
            if (currentLU && currentLU.fastEjendom) {
              obj.fastEjendom = currentLU.fastEjendom.fastEjendom
              obj.hvisJa = currentLU.fastEjendom.hvisJa
            }
          }
        }

        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(timestamp))
        }

        $scope.options = {
          jaNej: [
            { value: true, option: 'Ja' },
            { value: false, option: 'Nej' },
          ],
          jaNejVaelg: [
            { value: undefined, option: 'Vælg' },
            { value: true, option: 'Ja' },
            { value: false, option: 'Nej' },
          ],
          rkiIndtastning: [
            { value: true, option: 'Automatisk indtastning' },
            { value: false, option: 'Manuel indtastning' },
          ],

          regnskabIndtastning: function (identifier) {
            var currentLU = $scope.lookups[identifier]
            if (currentLU && currentLU.annualReports) {
              var reports = currentLU.annualReports.reports
              var latestAR = reports[reports.length - 1]

              var start = $scope.renderTimestamp(latestAR.start)
              var end = $scope.renderTimestamp(latestAR.end)

              return [
                { value: true, option: 'Regnskab (' + start + '-' + end + ')' },
                { value: false, option: 'Manuel indtastning' },
              ]
            }

            return [{ value: false, option: 'Manuel indtastning' }]
          },

          fastEjendomIndtastning: function (identifier) {
            var currentLU = $scope.lookups[identifier]
            if (currentLU && currentLU.fastEjendom) {
              return [
                { value: true, option: 'Tinglysningsopslag' },
                { value: false, option: 'Manuel indtastning' },
              ]
            } else {
              return [{ value: false, option: 'Manuel indtastning' }]
            }
          },
        }

        $scope.showKundetype = function () {
          return $scope.engine.eval('kundetype') ? 'Erhvervskunde' : 'Privatkunde'
        }

        $scope.indkoebPris = function () {
          return $scope.engine.eval('fakturaprisExMoms')
        }
        $scope.scrapPris = function () {
          return $scope.engine.eval('restvaerdi')
        }
        $scope.afviklingsPct = function () {
          return $scope.engine.eval('afviklingsprocent')
        }
        $scope.foersteYdelse = function () {
          return $scope.engine.eval('foersteYdelseExMomsPct')
        }
        $scope.showGenleasingKontraktNr = function () {
          return $scope.engine.eval('genleasing')
            ? 'Ja, kontraktnr. ' + $scope.engine.eval('genleasingKontraktnr')
            : 'Nej'
        }
        $scope.genleasing = function () {
          return $scope.engine.eval('genleasing')
        }
        $scope.loebetid = function () {
          return $scope.engine.eval('loebetid')
        }

        $scope.reactToLookups = function () {
          if (!$scope.modelVars) return
          for (var i in $scope.lookups) {
            var currentLU = $scope.lookups[i]

            if (i.length === 8) {
              let currentCR: VirksomhedInfo = $scope.modelVars.virksomhedInfo[i] || {}

              var fastEjendomIsAvailable = !!currentLU.fastEjendom
              var fastEjendom = {}

              var autoFastEjendom =
                currentCR.fastEjendomIndtastning === undefined || currentCR.fastEjendomIndtastning === null
                  ? fastEjendomIsAvailable
                  : fastEjendomIsAvailable && currentCR.fastEjendomIndtastning
              if (autoFastEjendom && currentLU.fastEjendom) {
                fastEjendom = {
                  fastEjendom: currentLU.fastEjendom.fastEjendom,
                  hvisJa: currentLU.fastEjendom.hvisJa,
                }
              } else {
                fastEjendom = {
                  fastEjendom: currentCR.fastEjendom || false,
                  hvisJa: currentCR.hvisJa || undefined,
                }
              }

              var regnskabIsAvailable = !!currentLU.annualReports
              var regnskabstal = {}

              var autoRegnskab =
                currentCR.regnskab === undefined || currentCR.regnskab === null
                  ? regnskabIsAvailable
                  : regnskabIsAvailable && currentCR.regnskab
              if (autoRegnskab && currentLU.annualReports) {
                var reports = currentLU.annualReports.reports
                var latestAR = reports[reports.length - 1]

                regnskabstal = {
                  indtjening: latestAR.summary.ProfitLoss || 0,
                  positivEgenkapital: latestAR.summary.Equity || 0,
                  udbytte: latestAR.summary.ProposedDividendRecognisedInEquity || 0,
                  balance: latestAR.summary.Assets || 0,
                }
              } else {
                regnskabstal = {
                  indtjening: currentCR.indtjening || 0,
                  positivEgenkapital: currentCR.positivEgenkapital || 0,
                  udbytte: currentCR.udbytte || 0,
                  balance: currentCR.balance || 0,
                }
              }

              var rkiAuto = !(currentLU.rki !== true && currentLU.rki !== false)

              $scope.modelVars.virksomhedInfo[i] = {
                rkiAuto: rkiAuto,
                rki: rkiAuto ? currentLU.rki : undefined,
                fastEjendomIndtastning: autoFastEjendom,
                regnskab: autoRegnskab,
                koncernrelationer: currentCR.koncernrelationer,
                konkurserIndehaverhistorik: currentCR.konkurserIndehaverhistorik,
                ...fastEjendom,
                ...regnskabstal,
              }
            }
            if (i.length === 10) {
              let currentCR = $scope.modelVars.personInfo[i] || {}

              var fastEjendomIsAvailable = !!currentLU.fastEjendom
              var fastEjendom = {}

              var autoFastEjendom =
                currentCR.fastEjendomIndtastning === undefined || currentCR.fastEjendomIndtastning === null
                  ? fastEjendomIsAvailable
                  : fastEjendomIsAvailable && currentCR.fastEjendomIndtastning

              if (autoFastEjendom && currentLU.fastEjendom) {
                fastEjendom = {
                  fastEjendom: currentLU.fastEjendom.fastEjendom,
                  hvisJa: currentLU.fastEjendom.hvisJa,
                }
              } else {
                fastEjendom = {
                  fastEjendom: currentCR.fastEjendom || false,
                  hvisJa: currentCR.hvisJa || undefined,
                }
              }

              var rkiAuto = !(currentLU.rki !== true && currentLU.rki !== false)

              $scope.modelVars.personInfo[i] = {
                rkiAuto: rkiAuto,
                rki: rkiAuto ? currentLU.rki : undefined,
                dowJones: currentLU.dowJones,
                dowJonesRigtigePerson: currentCR.dowJonesRigtigePerson,
                fastEjendomIndtastning: autoFastEjendom,
                ...fastEjendom,
              }
            }
          }
        }

        function load() {
          if (!$scope.applicationId) return

          unregisterWatches()
          if ($interop.specific.dkkapCreditReport.makeCalls) {
            $http.get<CreditRatingLoad>('/api/creditrating/load/' + $scope.applicationId).then((data) => {
              const res = data.data
              const creditRating = res.creditRating || {}
              const lookups = res.lookups

              $scope.modelVars = {
                totalRestgaeld: 0,
                antalKontrakter: 0,
                personKommentar: '',
                virksomhedKommentar: '',
                phbKommentar: '',
                personInfo: {},
                virksomhedInfo: {},
                // @ts-ignore
                foersteYdelseBegrundelse: 0,
                blankoEtablering: 0,
                blankoUdloeb: 0,

                ...creditRating,
              }

              // To appease TypeScript
              if (!$scope.modelVars) {
                throw new Error('Impossible')
              }

              if ($scope.modelVars.debitorskifte === null) {
                $scope.modelVars.debitorskifte = undefined
              }
              if ($scope.modelVars.gennemgaaetAf === null) {
                $scope.modelVars.gennemgaaetAf = undefined
              }
              if ($scope.modelVars.passerUdstyretBranchen === null) {
                $scope.modelVars.passerUdstyretBranchen = undefined
              }

              $scope.lookups = { ...$scope.lookups, ...lookups }
              $scope.status.errorCount = () => {
                return $scope.totalErrorCount()
              }
              $scope.reactToLookups()
              registerWatches()
            })
          }
        }

        $scope.$watch('applicationId', function () {
          load()
        })
      },
    }
  },
]
