import { isEmpty } from '../functions/isEmpty'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import angular from 'angular'
import type { IAttributes, IDirective, INgModelController, IParseService, IScope } from 'angular'

interface NgAutostuffScope extends IScope {
  engine: computationEngine.ComputationEngine<any>
  formIsLoaded: boolean
  form: { [name: string]: INgModelController }
  currentRow?: any

  autostuffWatches: { [name: string]: <T>(newValue: T, oldValue: T, scope: IScope) => any }

  nameMappings: {[name: string]: string}
}

interface NgAutostuffAttr extends IAttributes {
  ngAutostuff: string
  ngModel: string
  bsDatepicker?: any
  name: string
}

export const ngAutostuff = [
  '$parse',
  function ($parse: IParseService): IDirective<NgAutostuffScope, JQLite, NgAutostuffAttr, INgModelController> {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope: NgAutostuffScope, elem: JQLite, attr: NgAutostuffAttr, ctrl?: INgModelController) {
        if (!ctrl) {
          throw new Error('Ctrl not defined')
        }

        elem.bind('focus', function () {
          const firstElem = elem[0]
          if (firstElem instanceof HTMLInputElement) {
            firstElem.select()
          }
          return false
        })
        // Controller
        const varName = attr.ngAutostuff.split('.')[1]
        const variableDef = scope.$eval(attr.ngAutostuff) as computationEngine.VariableDef
        const hasMinExpr = typeof variableDef.minExpr !== 'undefined'
        const hasMaxExpr = typeof variableDef.maxExpr !== 'undefined'

        function hasValues() {
          if (!scope.currentRow) {
            return false
          }
          return Boolean(scope.currentRow.valuesJson)
        }

        if (typeof variableDef.reset !== 'undefined') {
          if (typeof scope.autostuffWatches === 'undefined') {
            console.log('scope.autostuffWatches not defined - not adding reset watch for ', varName, variableDef, scope)
          } else {
            scope.autostuffWatches[varName] = function () {
              const newval = scope.engine.eval(`${varName}.default`)
              if (scope.$eval(attr.ngModel) !== newval && !hasValues()) {

                // kun opdatér hvis værdi har ændret sig (#248)
                $parse(attr.ngModel).assign(scope, newval) // This works well
                rerenderViewValue(newval, ctrl)
                angular.element(elem).addClass('repaint').removeClass('repaint') // repaint fix
              }
            }
          }
        }

        if (!scope.nameMappings) {
          scope.nameMappings = {}
        }
        if (ctrl.$name) {
          scope.nameMappings[ctrl.$name] = varName
        }

        if (typeof variableDef.inUseExpr !== 'undefined' && (hasMinExpr || hasMaxExpr)) {
          scope.$watch(
            function (scope) {
              return (scope as NgAutostuffScope).engine.eval(`${varName}.inUse`)
            },
            function () {
              if (!hasValues()) {
                ctrl.$setViewValue(ctrl.$viewValue)
              }
            },
          )
        }

        if (hasMinExpr) {
          scope.$watch(
            typeof attr.bsDatepicker !== 'undefined'
              ? function (scope) {
                  const temp = (scope as NgAutostuffScope).engine.eval(`${varName}.min`)
                  if (temp !== null && temp instanceof Date) {
                    return temp.getFullYear() * 10000 + (temp.getMonth() + 1) * 100 + temp.getDate()
                  }
                }
              : function () {
                  const temp = scope.engine.eval(`${varName}.min`)
                  if (temp !== temp) return undefined
                  return temp
                },
            function (val, oldVal) {
              if (val === oldVal) return
              if (!scope.formIsLoaded || hasValues()) return

              ctrl.$setViewValue(ctrl.$viewValue)
            },
          )

          const minValidator = (value: number) => {
            const variableDef = scope.$eval(attr.ngAutostuff) as computationEngine.VariableDef
            const formName = attr.name
            let inUse = true
            if (typeof variableDef.inUseExpr !== 'undefined') {
              inUse = scope.engine.eval(`${varName}.inUse`) as boolean
            }
            if (hasValues()) {
              inUse = false
            }

            const min = scope.engine.eval(`${varName}.min`) as number
            if (inUse && !isEmpty(value) && value < min) {
              ctrl.$setValidity('ngMin', false)
              scope.form[formName].$setValidity('ngMin', false)
              return undefined
            } else {
              ctrl.$setValidity('ngMin', true)
              scope.form[formName].$setValidity('ngMin', true)
              return value !== value ? undefined : value // NaN bug
            }
          }

          ctrl.$parsers.push(minValidator)
        }
        if (hasMaxExpr) {
          scope.$watch(
            typeof attr.bsDatepicker !== 'undefined'
              ? function (scope) {
                  const temp = (scope as NgAutostuffScope).engine.eval(`${varName}.max`)
                  if (temp !== null && temp instanceof Date) {
                    return temp.getFullYear() * 10000 + (temp.getMonth() + 1) * 100 + temp.getDate()
                  }
                }
              : function () {
                  const temp = scope.engine.eval(`${varName}.max`)
                  if (temp !== temp) return undefined
                  return temp
                },
            function (val, oldVal) {
              if (val === oldVal) return
              if (!scope.formIsLoaded || hasValues()) return

              ctrl.$setViewValue(ctrl.$viewValue)
            },
          )

          const maxValidator = (value: number) => {
            const variableDef = scope.$eval(attr.ngAutostuff) as computationEngine.VariableDef
            const formName = attr.name
            let inUse = true
            if (typeof variableDef.inUseExpr !== 'undefined') {
              inUse = scope.engine.eval(`${varName}.inUse`) as boolean
            }
            if (hasValues()) {
              inUse = false
            }

            const max = scope.engine.eval(`${varName}.max`) as number
            if (inUse && !isEmpty(value) && value > max) {
              ctrl.$setValidity('ngMax', false)
              scope.form[formName].$setValidity('ngMax', false)
              return undefined
            } else {
              ctrl.$setValidity('ngMax', true)
              scope.form[formName].$setValidity('ngMax', true)
              return value !== value ? undefined : value // NaN bug
            }
          }

          ctrl.$parsers.push(maxValidator)
        }

        // 150808 Fix til opg #247
        if (hasMinExpr || hasMaxExpr) {
          scope.$watch(
            function (scope) {
              const temp = scope.$eval(attr.ngModel)
              if (temp !== null && temp instanceof Date) {
                return temp.getFullYear() * 10000 + (temp.getMonth() + 1) * 100 + temp.getDate()
              }
              if (temp !== temp) return undefined
              return temp
            },
            function () {
              if (!hasValues()) {
                ctrl.$setViewValue(ctrl.$viewValue)
              }
            },
          )
        }
      },
    }
  },
]
