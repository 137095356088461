import type { IAttributes, ICompileService, IDirective, IScope } from "angular";

interface InfotextClickAttrs extends IAttributes {
  infotextClick: string
}

export const infotextClick = [
  '$compile',
  function ($compile: ICompileService): IDirective<IScope, JQLite, InfotextClickAttrs> {
    return {
      link(scope: IScope, elem: JQLite, attrs: InfotextClickAttrs) {
        const friendly = elem.text()
        const fieldname = attrs.infotextClick
        const template = `<span ng-show="fieldDescriptions['${fieldname}']" style="margin-left:-20px">
              <i style="width:14px" class="text-info fa fa-info-circle" bs-tooltip="fieldDescriptions['${fieldname}']" bs-placement="bottom"></i>
              <span style="display:inline-block; width:6px"></span>
           </span>
           <span ng-click="editInfotext('${fieldname}')">${friendly}</span>`
        elem.html(template)
        $compile(elem.contents())(scope)
      },
    }
  },
]
