import { REGISTRERINGSNUMMER_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngRegistreringsnummer(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = (viewValue + '').toUpperCase()
        if (REGISTRERINGSNUMMER_REGEXP.test(viewValue) || viewValue == '') {
          // it is valid
          ctrl.$setValidity('registreringsnummer', true)
          return viewValue
        } else {
          ctrl.$setValidity('registreringsnummer', false)
          return undefined
        }
      })
      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
