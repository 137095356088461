
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/creditAnalysis/creditAnalysisOwners.html";
      const src = "<div class=\"panel panel-default\">\n  <div class=\"panel-heading\">\n    <span class=\"pull-right\">\n      <a class=\"btn btn-xs btn-default\" ng-click=\"create()\">\n        <i ng-if=\"buttonState.create !== 'loading'\" class=\"fa fa-refresh\"></i>\n        <i ng-if=\"buttonState.create === 'loading'\" class=\"fa fa-spinner fa-spin\"></i>\n      </a>\n    </span>\n    <h3 class=\"panel-title\"><img src=\"/assets/images/thirdparty/virk.svg\" style=\"height: 16px\" /></h3>\n  </div>\n  <div class=\"panel-body\">\n    <div ng-if=\"buttonState.create === 'error'\" class=\"alert alert-info alert-dismissable\">\n      <button type=\"button\" class=\"close\" ng-click=\"buttonState.create = undefined\">&times;</button>\n\n      Fejl ved oprettelse. Kontrollér at alle påkrævede felter er udfyldt.\n    </div>\n    <div ng-if=\"buttonState.create === 'httperror'\" class=\"alert alert-warning\">\n      <button type=\"button\" class=\"close\" ng-click=\"buttonState.create = undefined\">&times;</button>\n      Der skete en fejl. Fejlen kan være relateret til forbindelse til eksterne services.\n    </div>\n\n    <table ng-if=\"data\" class=\"table table-bordered\">\n      <thead>\n        <tr>\n          <th>Navn</th>\n          <th>Ejerandel</th>\n          <th></th>\n        </tr>\n      </thead>\n      <tbody>\n        <tr ng-repeat=\"row in data track by $index\">\n          <td>{{ row.name }}</td>\n          <td>\n            {{ formatPercent(row.ownership.u.numer / row.ownership.u.denom) }} - {{' '}} {{\n            formatPercent(row.ownership.l.numer / row.ownership.l.denom) }}\n          </td>\n          <td>\n            <button class=\"btn btn-xs btn-default\" ng-click=\"addOwner(row.name)\">Tilføj</button>\n          </td>\n        </tr>\n      </tbody>\n    </table>\n    <span ng-if=\"!data\"> CVR-data ikke indlæst. Er der indtastet gyldigt CVR-nummer på sagen? </span>\n  </div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      