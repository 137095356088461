export const helpCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $http.post('/api/article/getActiveUserArticles').success(function (res) {
      // vi skal nu distribuere kategorierne så ligeligt som muligt
      var colCount = 3
      var total = 0
      for (var i in res.categories) {
        var categoryId = res.categories[i].id
        var weight = res.articles[categoryId].length + 1 // +1 pga. overskrift
        total += weight
      }
      $scope.cols = []
      var avg = total / colCount
      var currentTotal = 0
      var currentCol = []
      for (var i in res.categories) {
        var categoryId = res.categories[i].id
        var weight = res.articles[categoryId].length + 1 // +1 pga. overskrift
        if (currentTotal >= avg) {
          $scope.cols.push(currentCol)
          currentCol = []
          currentTotal = 0
        }
        currentCol.push(res.categories[i])
        currentTotal += weight
      }
      if (currentCol.length > 0) {
        $scope.cols.push(currentCol)
      }
      $scope.articles = res.articles
    })
  },
]
