export const controlpanelGpsProviderCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$location',
  function ($scope, $rootScope, $http, $state, $location) {
    $scope.addurl = '/controlpanel/gpsProvider/add'
    $scope.editurl = '/controlpanel/gpsProvider/edit'
    $scope.loadAll = function () {
      $http.post('/api/gpsProvider/getAll').success(function (res) {
        $scope.list = res
        //console.log($scope.list)
        if ($state.is('root.controlpanel.gpsProvider.index')) {
          if ($scope.list.length > 0) {
            $location.url('/controlpanel/gpsProvider/edit/' + $scope.list[0].id)
          } else {
            $location.url('/controlpanel/gpsProvider/add')
          }
        }
      })
    }

    $scope.loadAll()

    $scope.setActiveId = function (id) {
      $scope.activeId = id
    }
    $rootScope.common = {}
  },
]
