import annualReportsTemplate from '../templates/annual-reports.html'
import { dateToDanish } from '../functions/dates'
import { danishLocaleNumber } from '../functions/numbers'

export const dkkapAnnualReports = [
  '$http',
  '$rootScope',
  '$interop',
  function ($http, $rootScope, $interop) {
    return {
      restrict: 'A',
      template: annualReportsTemplate,
      scope: {
        applicationId: '=',
      },
      link: function ($scope, elm, attrs) {
        $scope.rows = [
          { name: 'GrossProfitLoss', caption: 'Bruttofortjeneste' },
          //{name: "ProfitLossFromOrdinaryPrimaryOperatingActivities", "caption": "Res. før fin. poster"},
          { name: 'ProfitLossFromOrdinaryActivitiesBeforeTax', caption: 'Resultat før skat' },
          { name: 'ProfitLoss', caption: 'Årets resultat' },
          { name: 'Equity', caption: 'Egenkapital' },
          { name: 'ProposedDividendRecognisedInEquity', caption: 'Foreslået udbytte' },
          { name: 'Assets', caption: 'Balance' },
        ]

        $scope.extractValue = function (x, summary) {
          var out = summary[x]
          if (out === undefined) {
            if (x === 'ProfitLossFromOrdinaryPrimaryOperatingActivities') {
              return summary['ProfitLossFromOrdinaryOperatingActivities']
            }
          }
          return out
        }

        $scope.result = {}
        $scope.resultCount = 0

        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(timestamp))
        }
        $scope.renderAmount = function (value) {
          return danishLocaleNumber(value ? Math.ceil(value) : 0)
        }

        function load() {
          if (!$scope.applicationId) return
          if ($interop.specific.dkkapAnnualReports.doCalls) {
            $http.get('/api/annualReports/' + $scope.applicationId).success(function (res) {
              $scope.result = res
              $scope.resultCount = Object.keys(res).length
            })
          }
        }

        $scope.$watch('applicationId', function () {
          load()
        })
      },
    }
  },
]
