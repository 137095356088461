import { IAttributes, IDirective, IScope } from 'angular'
import { formatDate, formatNumber } from '../../functions/intlFormaters'

// corresponds to offer.tex from LaTeX
import offerTemplate from './offer.html'

interface ComputationEngine {
  eval: (field: string) => any
}
type CE = ComputationEngine

// corresponds to contract.Util.scala
function getStr(e: CE) {
  return {
    // CUSTOMER
    debtorName: (): string => e.eval('navn'),
    debtorAtt: (): string => e.eval('contactName'),
    debtorUser: (): string => e.eval('assetUserName'),
    assetDeliverDate: (): string => formatDate(e.eval('leveringsdatoFinal')) || '',

    // VEHICLE
    assetMakeModel: (): string => e.eval('maerkeModel'),
    assetColour: (): string => e.eval('farve'),
    assetGear: (): string => e.eval('gear'),
    assetFuel: (): string => {
      switch (e.eval('braendstofstype')) {
        case 1:
          return 'Benzin'
        case 2:
          return 'Diesel'
        case 3:
          return 'El'
        case 4:
          return 'Hybrid'
        case 5:
          return 'Plug-in Hybrid (Benzin)'
        case 6:
          return 'Plug-in Hybrid (Diesel)'
        default:
          return ''
      }
    },
    assetKmPerLiter: (): string => formatNumber(e.eval('anslaaetKmPrLiterBraendstof'), 2) || '',
    assetEnvironmentCategory: (): string => e.eval('environmentCategory'),
    assetFirstRegDate: (): string => formatDate(e.eval('indregistreretFoersteGang')) || '',
    assetMileage: (): string => formatNumber(e.eval('kilometerstand'), 0) || '',
    taxableAmount: (): string => formatNumber(e.eval('beskatningsgrundlagBil'), 0) || '',
    taxableAmountEnvironment: (): string => formatNumber(e.eval('environmentTax'), 0) || '',
    taxableAmountPerMonth: (): string => formatNumber(e.eval('taxationMonthly'), 0) || '',
    assetCarbonDioxidPerKm: (): string => formatNumber(e.eval('co2Udslip'), 0) || '',
    excessMileageFeeExVat: (): string => formatNumber(e.eval('overkoerselKmPris'), 2) || '',
    lessMileageFeeExVat: (): string => formatNumber(e.eval('underkoerselKmPris'), 2) || '',
    excessMileageFeeInclVat: (): string => formatNumber(e.eval('overkoerselKmPris') * 1.25, 2) || '',
    lessMileageFeeInclVat: (): string => formatNumber(e.eval('underkoerselKmPris') * 1.25, 2) || '',
    standardEquipmentDescription: (): string => e.eval('udstyr'),
    extraEquipmentDescription: (): string => e.eval('eftermonteretUdstyrBeskrivelse'),

    leasingDownPaymentExVat: (): string => formatNumber(e.eval('foersteYdelseExMoms'), 2) || '',
    leasingDownPaymentInclVat: (): string => formatNumber(e.eval('foersteYdelseExMoms') * 1.25, 2) || '',

    chargedContractCreationFeeExVat: (): string => formatNumber(e.eval('contractCreationFee'), 2) || '',
    chargedContractCreationFeeInclVat: (): string => formatNumber(e.eval('contractCreationFee') * 1.25, 2) || '',

    chargedDocumentFeeExVat: (): string => formatNumber(e.eval('directDocumentFee'), 2) || '',
    chargedDocumentFeeInclVat: (): string => formatNumber(e.eval('directDocumentFee') * 1.25, 2) || '',
    chargedDeliveryExpensesExVat: (): string => formatNumber(e.eval('directDeliveryExpenses'), 2) || '',
    chargedDeliveryExpensesInclVat: (): string => formatNumber(e.eval('directDeliveryExpenses') * 1.25, 2) || '',
    chargedLicensePlates: (): string => formatNumber(e.eval('directLicensePlates'), 2) || '',
    chargedPledgeDeclarationExVat: (): string => formatNumber(e.eval('directPledgeDeclarationFee'), 2) || '',
    chargedPledgeDeclarationInclVat: (): string => formatNumber(e.eval('directPledgeDeclarationFee') * 1.25, 2) || '',

    // TODO ADD TO LATEX DOC
    chargedTotalVat: (): string =>
      formatNumber(
        e.eval('foersteYdelseExMoms') * 0.25 +
          e.eval('contractCreationFee') * 0.25 +
          e.eval('directDocumentFee') * 0.25 +
          e.eval('directDeliveryExpenses') * 0.25 +
          e.eval('directPledgeDeclarationFee') * 0.25,
        2,
      ) || '',
    // TODO ADD TO LATEX DOC
    chargedTotalInclVat: (): string =>
      formatNumber(
        e.eval('foersteYdelseExMoms') * 1.25 +
          e.eval('contractCreationFee') * 1.25 +
          e.eval('directDocumentFee') * 1.25 +
          e.eval('directDeliveryExpenses') * 1.25 +
          e.eval('directLicensePlates') +
          e.eval('directPledgeDeclarationFee') * 1.25,
        2,
      ) || '',

    periodicVehicleTaxHalfYearly: (): string => formatNumber(e.eval('periodicTax'), 0) || '',
    leasingPeriods: (): string => formatNumber(e.eval('loebetid'), 0) || '',
    totalMileage: (): string => formatNumber(e.eval('maxKoerselTotal'), 0) || '',

    leasingPeriodPaymentExVat: (): string => formatNumber(e.eval('ydelse'), 0) || '',
    leasingPeriodPaymentInclVat: (): string => formatNumber(e.eval('ydelse') * 1.25, 0) || '',

    addonText: (): string => 'Forsikring',
    addonAmount: (): string => formatNumber(e.eval('forsikringMaanedligPraemieSamlet'), 0) || '',

    periodicVehicleTaxMonthly: (): string => formatNumber(e.eval('periodicTax') / 6, 0) || '',

    monthlyPaymentInclFixedCosts: (): string => formatNumber(e.eval('monthlyPaymentInclFixedCosts'), 0) || '',
    monthlyPaymentVat: (): string => formatNumber(e.eval('primaerYdelseMoms'), 0) || '',

    vatDeductionSigned: (): string => formatNumber(e.eval('vatDeductionSigned'), 0) || '',

    monthlyPaymentInclVatNet: (): string => formatNumber(e.eval('monthlyPaymentInclFixedCostsInclVatNet'), 0) || '',
    monthlyPaymentInclVat: (): string => formatNumber(e.eval('primaerYdelseInclMoms'), 0) || '',

    estimatedFuelConsumption: (): string => formatNumber(e.eval('anslaaetBraendstofPrMaaned'), 0) || '',

    monthlyPaymentInclVariableCosts: (): string => formatNumber(e.eval('monthlyPaymentInclVariableCosts'), 0) || '',

    roadsideAssistancePrice: (): string => formatNumber(e.eval('roadsideAssistancePriceFinal'), 0) || '',

    leasingRepurchaseAmountExVat: (): string => formatNumber(e.eval('repurchaseAmount'), 0) || '',
    leasingRepurchaseAmountInclVat: (): string =>
      formatNumber(e.eval('repurchaseAmount') * (e.eval('brugtmoms') ? 1 : 1.25), 0) || '',
  }
}

// corresponds to contract.Util.scala
function getBool(e: CE) {
  return {
    company: (): boolean => e.eval('kundetype') == true,
    person: (): boolean => e.eval('kundetype') == false,

    fixed: (): boolean => e.eval('fastRente') == true,
    variable: (): boolean => e.eval('fastRente') == false,

    hasAddon: (): boolean => true,

    hasPeriodicVehicleTax: (): boolean => e.eval('periodicTax') > 0,

    financial: (): boolean => e.eval('leasingtype') == false,
    operating: (): boolean => e.eval('leasingtype') == true,

    vehicleTypeCar: (): boolean => e.eval('varevogn') == false,

    boolNyBil: (): boolean => e.eval('nyBil') == true,

    hasExcessMileageFee: (): boolean => e.eval('overkoerselKmPris') > 0,
    hasLessMileageFee: (): boolean => e.eval('underkoerselKmPris') > 0,

    chargedDeliveryExpenses: (): boolean => e.eval('directDeliveryExpenses') != 0,
    chargedDocumentFee: (): boolean => e.eval('directDocumentFee') != 0,
    chargedContractCreationFee: (): boolean => e.eval('contractCreationFee') != 0,
    chargedLicensePlates: (): boolean => e.eval('directLicensePlates') != 0,
    chargedPledgeDeclaration: (): boolean => e.eval('directPledgeDeclarationFee') != 0,

    service: (): boolean => e.eval('service') != 0,
    roadsideAssistance: (): boolean => e.eval('enableRoadsideAssistance'),
    replacementVehicle: (): boolean => e.eval('useReplacementVehicle') && e.eval('faellespolice'),
    repurchaseRight: (): boolean => e.eval('repurchaseRight'),

    vehiclesIncludingTax: (): boolean => e.eval('regTax') == 1,
    secondHandVat: (): boolean => e.eval('brugtmoms'),
  }
}

// corresponds to lang-da.tex from LaTeX
function getLoc(e: CE, str: ReturnType<typeof getStr>, bool: ReturnType<typeof getBool>) {
  function repurchaseAmount() {
    const amountPart = bool.company() ? str.leasingRepurchaseAmountExVat() : str.leasingRepurchaseAmountInclVat()
    const taxPart = bool.vehiclesIncludingTax() ? 'inkl. afgift' : 'ekskl. afgift'
    const vatPart = bool.secondHandVat() ? 'uden tillæg af moms' : bool.company() ? 'ekskl. moms' : 'inkl. moms'
    return `${amountPart} ${taxPart} og ${vatPart}`
  }
  return {
    // CUSTOMER
    // title
    leaseOffer: 'Leasingforslag',
    operating: 'Operationelt',
    financial: 'Finansielt',

    // fields
    customer: 'Kunde',
    att: 'Att.',
    user: 'Bruger',
    expectedTimeOfDelivery: 'Forventet leveringsdato',

    // VEHICLE
    // title
    descriptionOfVehicle: 'Bilbeskrivelse',

    // fields
    vehicle: 'Bil',
    colour: 'Farve',
    gear: 'Gear',
    fuel: 'Brændstof',
    kmPerLiter: 'Km/liter',
    environmentCategory: 'Miljøkategori',
    firstRegDate: '1. reg. dato',
    mileage: 'KM/start',
    taxableAmount: 'Anbefalet beskatningsgrundlag',
    taxableAmountEnvironment: 'Miljøtillæg (skatteværdi)',
    taxableAmountPerMonth: 'Beskatning pr. måned',
    carbonDioxidPerKm: 'CO2 pr. kilometer i gram',
    excessMileageIsNotCharged: 'Der afregnes ikke for overkilometer',
    excessMileageFeeExVat: 'Overkilometer ekskl. moms',
    lessMileageFeeExVat: 'Underkilometer ekskl. moms',
    excessMileageFeeInclVat: 'Overkilometer inkl. moms',
    lessMileageFeeInclVat: 'Underkilometer inkl. moms',

    // EQUIPMENT
    // title
    equipment: 'Udstyr',

    // fields
    standardEquipment: 'Fabriksmonteret udstyr',
    extraEquipment: 'Eftermonteret udstyr',

    // CHARGED
    // title
    charged: 'Følgende opkræves ifm. levering af bil',

    // fields
    downPaymentExVat: '1. gangs ydelse ekskl. moms',
    downPaymentInclVat: '1. gangs ydelse inkl. moms',
    documentFee: 'Etableringsomkostninger',
    deliveryCost: 'Leveringsomkostninger',
    contractCreationFee: 'Kontraktoprettelse',
    licensePlates: 'Nummerplader',
    pledgeDeclarationFee: 'Panthavergebyr, forsikring',
    vat: 'Moms',
    total: 'Total',

    // CALCULATION
    // title
    leasingCalculation: 'Leasingberegning',

    // fields
    period: 'Leasingperiode',
    monthsAbbr: 'mdr.',
    kmIncl: 'Kilometer i alt',
    leasingFeeFixedExVat: 'Leasingafgift ekskl. moms (fast)',
    leasingFeeVariableExVat: 'Leasingafgift ekskl. moms (variabel)',
    leasingFeeFixedInclVat: 'Leasingafgift inkl. moms (fast)',
    leasingFeeVariableInclVat: 'Leasingafgift inkl. moms (variabel)',
    periodicVehicleTax: 'Grøn ejerafgift',
    periodicVehicleTaxChargedByMotorstyrelsen: () =>
      'Grøn ejerafgift opkræves af Motorstyrelsen med kr. ' + str.periodicVehicleTaxHalfYearly() + ' pr. halvår',
    totalPerMonthExVat: 'Total pr. måned ekskl. moms',
    vatDeductableValidUntil:
      'Momsfradrag er gældende indtil bilens reg. afgift bliver genberegnet senest 3 mdr. efter indregistrering',
    vatDeductable: 'Momsfradrag',
    totalPerMonthNet: 'Månedlig nettoudgift',
    totalPerMonth: 'Total pr. måned',
    estimatedFuelConsumption: 'Estimeret drivmiddel forbrug',
    tco: 'TCO',

    // fields
    leasingFeeContains: 'Leasingafgift indeholder',
    serviceIncluded: 'Landsdækkende service jf. serviceaftale',
    serviceNotIncluded: 'ikke service og vedligeholdelse',
    tyresNotIncluded: 'Ikke sommer og vinterdæk',
    tyresStorageIncluded: 'Opbevaring og ombytning af dæk',
    roadsideAssistanceIncluded:
      'Falck-abonnement (Vejhjælp, Transport, Døroplukning, Sygekørsel, Erstatningsbil i DK + Europa)',
    replacementVehicleIncluded: 'Inkl. Erstatningsbil i én dag ved foreskrevne serviceintervaller',
    otherConditionsReference: 'Øvrige vilkår jf. "Generelle Leasingvilkår"',
    repurchaseObligationLessor: 'First Lease indestår for restværdi',

    repurchaseAmount: repurchaseAmount,
    leasingOfferDisclaimer:
      'Leasingforslaget er med forbehold for rente, pris, afgiftsændringer og evt. fejl og mangler.',
    leasingOfferValidPeriod: 'Tilbuddet er gældende 8 dage fra dags dato, med forbehold for positiv kreditvurdering.',
    creditRatingProcedure:
      'Lovpligtig kreditværdighedsvurdering. Ved etablering af leasingaftaler til private SKAL First Lease lave en vurdering af de aktuelle økonomiske forhold. Det gælder både for nye og for nuværende kunder.',
    amlProcedure:
      'Endelig aftale vil være betinget af, at vi kan opfylde Hvidvaskningslovens bestemmelser. Vi kan derfor have brug for, at få besvaret en række spørgsmål om leasingtager, ligesom vi er forpligtet til at kunne bekræfte vores kunders identitet ved indhentelse af gyldig legitimation.',
  }
}

interface OfferScope extends IScope {
  engine: CE
  loc: ReturnType<typeof getLoc>
  str: ReturnType<typeof getStr>
  bool: ReturnType<typeof getBool>
}

export const offer = [
  function (): IDirective<OfferScope> {
    return {
      restrict: 'A',
      template: offerTemplate,
      scope: { engine: '=' },
      link: function ($scope: OfferScope) {
        $scope.$watch('engine', (e: CE) => {
          $scope.str = getStr(e)
          $scope.bool = getBool(e)
          $scope.loc = getLoc(e, $scope.str, $scope.bool)
        })

        $scope.str = getStr($scope.engine)
        $scope.bool = getBool($scope.engine)
        $scope.loc = getLoc($scope.engine, $scope.str, $scope.bool)
      },
    }
  },
]
