import { dateToYMD } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'

export const formProductCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount
    $scope.options = {}
    $scope.options.jaNej = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]

    $scope.variableDefs = {
      shortName: {
        friendly: 'Kort navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      isLeasing: {
        friendly: 'Leasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      selvanmelderProdukt: {
        friendly: 'Selvanmelder',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      isSplitleasing: {
        friendly: 'Splitleasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      leasingTypeFinancial: {
        friendly: 'Finansiel',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      leasingTypeOperating: {
        friendly: 'Operationel',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      customerTypeCompany: {
        friendly: 'Erhverv',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      customerTypePerson: {
        friendly: 'Privat',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      regTaxProportionate: {
        friendly: 'Forholdsmæssig',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      regTaxFull: {
        friendly: 'Fuld',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      regTaxNone: {
        friendly: 'Ingen',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      purchasedAsNew: {
        friendly: 'Ny',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      purchasedAsUsed: {
        friendly: 'Brugt',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      licensePlateWhite: {
        friendly: 'Hvid',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      licensePlateYellow: {
        friendly: 'Gul',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      licensePlateWhiteYellow: {
        friendly: 'Hvid og gul',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      isSeasonLeasing: {
        friendly: 'Sæsonleasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      isEquipment: {
        friendly: 'Udstyr',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      useExtraEquipment: {
        friendly: 'Bruger ekstraudstyr',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      renewalTypeNew: {
        friendly: 'Ny',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      renewalTypeRenewal: {
        friendly: 'Genleasing',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      sort: {
        friendly: 'Sort',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      foqusContractProfile: {
        friendly: 'Foqus kontraktprofil',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      repurchaseRightNo: {
        friendly: 'Nej',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      },
      repurchaseRightYes: {
        friendly: 'Ja',
        datatype: 'boolean',
        vartype: 'model',
        required: true,
      }
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Produktet blev gemt.')
      if (res == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/product/edit/' + res.id)
      if ($scope.id !== undefined) {
        loadProduct()
      }
    }
    $scope.addEntry = function () {
      $scope.vars.productFinancingPartners.push({ sort: 0, foqusContractProfile: '' })
    }
    $scope.removeEntry = function () {
      $scope.vars.productFinancingPartners.pop()
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : $stateParams.id
    $scope.setActiveId($scope.id)

    var loadProduct = function () {
      $http.post('/api/product/get/' + $scope.id).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }
        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.save = function (saveAsNew) {
        $http.post('/api/product/edit/' + $scope.id, $scope.vars).success(saveSuccess)
      }
      $scope.delete = function () {
        $http.post('/api/product/delete/' + $scope.id).success(function () {
          $scope.loadAll()
          $location.url('/controlpanel/product')
        })
      }
      loadProduct()
    } else {
      $scope.vars = {}
      $scope.vars.productFinancingPartners = []
      $scope.vars.row = {
        shortName: '',
        name: '',
        isLeasing: false,
        selvanmelderProdukt: false,
        isSplitleasing: false,
        leasingTypeFinancial: false,
        leasingTypeOperating: false,
        customerTypeCompany: false,
        customerTypePerson: false,
        regTaxProportionate: false,
        regTaxFull: false,
        regTaxNone: false,
        purchasedAsNew: false,
        purchasedAsUsed: false,
        licensePlateWhite: false,
        licensePlateYellow: false,
        licensePlateWhiteYellow: false,
        isSeasonLeasing: false,
        isEquipment: false,
        useExtraEquipment: false,
        renewalTypeNew: false,
        renewalTypeRenewal: false,
        repurchaseRightNo: true,
        repurchaseRightYes: false,
        useRepurchaseObligationDealer: false,
        useRepurchaseObligationOther: false,
        useRepurchaseObligationSecondary: false
      }

      $scope.save = function () {
        $http.post('/api/product/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
