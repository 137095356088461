import formDetailsTemplate from './form.details.html'
import { assignScope, makeDirective, type ScopeType } from '@/shared/util/directiveHelpers'

export const formDetails = makeDirective({
  restrict: ['attribute'],
  template: formDetailsTemplate,
  scope: {
    engine: {
      type: Object as unknown as ScopeType<computationEngine.ComputationEngine<any>>,
      binding: 'twoWay',
    },
    variableDefs: {
      type: Object,
      binding: 'twoWay',
    },
    renderStaticAmount: {
      type: Function as unknown as ScopeType<(n: number) => string>,
      binding: 'twoWay',
    },
    renderStaticPct: {
      type: Function as unknown as ScopeType<(n: number) => string>,
      binding: 'twoWay',
    },
    leasingPriceBasis: {
      type: Object,
      binding: 'twoWay',
    },
    leasingPriceExtras: {
      type: Object,
      binding: 'twoWay',
    },
    leasingPv: {
      type: Object,
      binding: 'twoWay',
    },
    leasingAddons: {
      type: Object,
      binding: 'twoWay',
    },
    leasingFixedCosts: {
      type: Object,
      binding: 'twoWay',
    },
    leasingVariableCosts: {
      type: Object,
      binding: 'twoWay',
    },
    leasingVat: {
      type: Object,
      binding: 'twoWay',
    },
    leasingRate: {
      type: Object,
      binding: 'twoWay',
    },
    leasingDirect: {
      type: Object,
      binding: 'twoWay',
    },
  },
  assignScope: assignScope<{ objectKeys: (o: Object) => string[] }>(),
  link(i, { scope }) {
    scope.objectKeys = function (obj) {
      return Object.keys(obj)
    }
  },
})
