import angular from 'angular'

export const logonCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  function ($scope, $rootScope, $http) {
    $scope.resetPassword = window.location.href.split('/')[3] == 'resetPassword'
    $scope.resetPasswordUserId = window.location.href.split('/')[4]
    $scope.resetPasswordKey = window.location.href.split('/')[5]
    $scope.newpassword1 = ''
    $scope.newpassword2 = ''

    $scope.action = function ($event) {
      if ($scope.resetPassword) {
        $http
          .post('/api/resetPassword', {
            id: parseInt($scope.resetPasswordUserId, 10),
            password: $scope.newpassword1,
            key: $scope.resetPasswordKey,
          })
          .success(function () {
            $scope.resetPassword = false
            $scope.warningMsg = ''
            $scope.successMsg = 'Dit kodeord er nu nulstillet. Log på igen.'
          })
          .error(function () {
            $scope.resetPassword = true
            $scope.warningMsg = 'Der skete en fejl. Prøv igen, og kontakt os hvis problemet fortsætter'
            $scope.successMsg = ''
          })
      } else {
        if (!$scope.forgotpassword) {
          angular.forEach(angular.element($event.currentTarget).parent().parent().find('input'), function (elem) {
            angular.element(elem).triggerHandler('change')
          })

          $http
            .post('/api/logon', {
              mail: $scope.mail,
              password: $scope.password,
            })
            .success(function () {
              $scope.$broadcast('loginSuccessful')
              //window.location.replace('/');
            })
            .error(function () {
              $scope.warningMsg = 'Forkert brugernavn eller adgangskode.'
              $scope.successMsg = ''
            })
        } else {
          $http
            .post('/api/forgotPassword', {
              mail: $scope.mail,
            })
            .success(function () {
              $scope.forgotpassword = false
              $scope.warningMsg = ''
              $scope.successMsg = 'Der er afsendt e-mail med nærmere information.'
            })
        }
      }
    }
  },
]
