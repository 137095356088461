import { IAttributes, IDirective, IScope } from 'angular'

import showmodeToolbarTemplate from './showmodeToolbar.html'

export const showmodeToolbar = [
  function (): IDirective<IScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: showmodeToolbarTemplate,
    }
  },
]
