import type {
  IController,
  IHttpResponse,
  IHttpService,
  ILocationService,
  IRootScopeService,
  IScope,
  ITimeoutService
} from 'angular'

interface Dealer {
  name: string
  cvr: string
}

interface Salesperson {
  name: string
  email: string
}

interface LinkedCompany {
  name: string
  cvr: string
}

interface AutoitConnectCtrl extends IScope {
  loadingData: boolean
  doLink: () => Promise<void>
  switchToCorrectUser: (id: string) => void
  dealer?: Dealer
  salesperson?: Salesperson
  linkedCompany?: LinkedCompany | null
}

async function getInitialData(
  $scope: AutoitConnectCtrl,
  $rootScope: IRootScopeService & {
    userInfo?: {
      alternateAccounts: { [userId: string]: string }
      forhandlerNavn: string
    }
  },
  $http: IHttpService,
  $timeout: ITimeoutService,
  $location: ILocationService,
  externalId: string,
) {
  let res: IHttpResponse<{
    applicationId: number | null
    dealer: Dealer
    salesperson: Salesperson
    linkedCompany: LinkedCompany | null
  }>
  try {
    res = await $http.get<{
      applicationId: number | null
      dealer: Dealer
      salesperson: Salesperson
      linkedCompany: LinkedCompany | null
    }>(`/api/autoit/${externalId}`)
  } catch (res) {
    if ((res as { status: number }).status === 404) {
      $timeout(() => $location.url('/'))
      return
    }
  }

  while (typeof $rootScope.userInfo === 'undefined') {
    await $timeout(() => {}, 100)
  }

  await $timeout(() => {
    if (res.status === 200) {
      if (
        res.data.applicationId &&
        (res.data.linkedCompany === null || res.data.linkedCompany.name === $rootScope.userInfo?.forhandlerNavn)
      ) {
        $location.url(`/application/${res.data.applicationId}`)
      } else {
        $scope.dealer = res.data.dealer
        $scope.salesperson = res.data.salesperson
        $scope.linkedCompany = res.data.linkedCompany
        $scope.loadingData = false
      }
    } else if (res.status === 404) {
      console.log('Not found')
      $scope.loadingData = false
    }
  })
}

export const autoitConnectCtrl = [
  '$location',
  '$http',
  '$scope',
  '$rootScope',
  '$stateParams',
  '$timeout',
  function (
    $location: ILocationService,
    $http: IHttpService,
    $scope: AutoitConnectCtrl,
    $rootScope: IRootScopeService & {
      userInfo?: {
        alternateAccounts: { [userId: string]: string }
        forhandlerNavn: string
      }
    },
    $stateParams: { externalId: string },
    $timeout: ITimeoutService,
  ): IController {
    $scope.loadingData = true
    getInitialData($scope, $rootScope, $http, $timeout, $location, $stateParams.externalId)

    $scope.doLink = async function () {
      $scope.loadingData = true
      const res = await $http.post<{
        applicationId: number
      }>(`/api/autoit/${$stateParams.externalId}/connect`, {})
      if (res.status === 200) {
        await $timeout(() => $location.url(`/application/${res.data.applicationId}`))
      } else {
        await $timeout(() => ($scope.loadingData = false))
      }
    }

    $scope.switchToCorrectUser = function () {
      const correctUserIdTuple = Object.entries($rootScope.userInfo?.alternateAccounts ?? {}).find(
        (e) => e[1] === $scope.linkedCompany?.name,
      )
      if (correctUserIdTuple) {
        window.location.replace(`/logon/impersonate/${correctUserIdTuple[0]}?redirect=${$location.url()}`)
      }
    }

    return {}
  },
]
