
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/register.dowjones.html";
      const src = "<form name=\"form\" action=\"/dowjones/pdf\" method=\"POST\" target=\"_blank\">\n  <div class=\"form-group clearfix\">\n    <div class=\"col-xs-3\">\n      <input\n        type=\"text\"\n        name=\"dateOfBirth\"\n        ng-model=\"modelVars.dateOfBirth\"\n        placeholder=\"Fødselsdato\"\n        class=\"form-control\"\n      />\n    </div>\n    <div class=\"col-xs-6\">\n      <input type=\"text\" name=\"name\" ng-model=\"modelVars.name\" placeholder=\"Navn\" class=\"form-control\" required />\n    </div>\n    <div class=\"col-xs-3\">\n      <button class=\"btn btn-default pull-right\" type=\"button\" ng-disabled=\"form.name.$invalid\" ng-click=\"query()\">\n        Kør opslag\n      </button>\n    </div>\n  </div>\n\n  <hr />\n\n  <div>\n    <div ng-show=\"result.type == 'success'\">\n      <input type=\"submit\" class=\"btn btn-default pull-right\" value=\"Hent som PDF\" />\n\n      <div ng-repeat=\"section in result.sections\">\n        <p><strong>{{section.title}}</strong></p>\n        <dl class=\"dl-horizontal\">\n          <div ng-repeat=\"line in section.elements\">\n            <dt>{{line.overrideText ? line.overrideText : line.key}}</dt>\n            <dd ng-repeat=\"v in line.value track by $index\">{{line.value[$index]}}&nbsp;</dd>\n          </div>\n        </dl>\n      </div>\n    </div>\n\n    <div ng-show=\"result.type == 'error'\" class=\"alert alert-warning\">{{result.message}}</div>\n    <div ng-show=\"result.type == 'wait'\" class=\"well\"><i class=\"fa fa-spinner fa-spin\"></i> Forespørger...</div>\n  </div>\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      