import interestAdjustmentTemplate from '../templates/forms/interestAdjustment.html'
import prtTemplate from '../templates/forms/prt.html'
import formtestTemplate from '../templates/formtest.html'
import seasonAdminCtrlTemplate from '../templates/season-admin-ctrl.html'
import formsRecalculationTemplate from '../templates/forms/recalculation.html'
import inspectTemplate from '../templates/inspect.html'
import controlpanelCompanyTemplate from '../templates/controlpanel.company.html'
import formsCompanyTemplate from '../templates/forms/company.html'
import formsUserTemplate from '../templates/forms/user.html'
import controlpanelCompanyGroupTemplate from '../templates/controlpanel.companyGroup.html'
import controlpanelFinancingPartnerTemplate from '../templates/controlpanel.financingPartner.html'
import controlpanelProductTemplate from '../templates/controlpanel.product.html'

import controlpanelDepreciationMatrixTemplate from '../templates/controlpanel.depreciationMatrix.html'
import controlpanelDepreciationModelTemplate from '../templates/controlpanel.depreciationModel.html'
import formsFinancingPartnerTemplate from '../templates/forms/financingPartner.html'
import formsProductTemplate from '../templates/forms/product.html'
import formsDepreciationMatrixTemplate from '../templates/forms/depreciationMatrix.html'
import formsDepreciationModelTemplate from '../templates/forms/depreciationModel.html'
import controlpanelGpsProviderTemplate from '../templates/controlpanel.gpsProvider.html'
import formsGpsProviderTemplate from '../templates/forms/gpsProvider.html'
import controlpanelInsuranceCompanyTemplate from '../templates/controlpanel.insuranceCompany.html'
import formsInsuranceCompanyTemplate from '../templates/forms/insuranceCompany.html'
import controlpanelNewsTemplate from '../templates/controlpanel.news.html'
import formsNewsTemplate from '../templates/forms/news.html'
import controlpanelNewsletterTemplate from '../templates/controlpanel.newsletter.html'
import formsNewsletterTemplate from '../templates/forms/newsletter.html'
import controlpanelArticleTemplate from '../templates/controlpanel.article.html'
import controlpanelReportsTemplate from '../templates/controlpanel.reports.html'
import formsArticleCategoryTemplate from '../templates/forms/articleCategory.html'
import formsArticleTemplate from '../templates/forms/article.html'
import formsSelvanmelderTemplate from '../templates/forms/selvanmelder.html'
import controlpanelCommonConfigTemplate from '../templates/controlpanel.commonConfig.html'
import formsCommonConfigTemplate from '../templates/forms/commonConfig.html'
import controlpanelMailTemplatesTemplate from '../templates/controlpanel.mailTemplates.html'
import formsMailTemplatesTemplate from '../templates/forms/mailTemplates.html'
import controlpanelPenneoTemplate from '../templates/controlpanel.penneo.html'
import formsPenneoTemplate from '../templates/forms/penneo.html'
import controlpanelInsuranceCostsTemplate from '../templates/controlpanel.insuranceCosts.html'
import formsInsuranceCostsTemplate from '../templates/forms/insuranceCosts.html'
import companyTemplate from '../templates/company.html'
import companyUserTemplate from '../templates/company.user.html'
import companyEditTemplate from '../templates/company.edit.html'
import registerTemplate from '../templates/register.html'
import registerRkiTemplate from '../templates/register.rki.html'
import registerDowjonesTemplate from '../templates/register.dowjones.html'
import registerCvrTemplate from '../templates/register.cvr.html'
import registerDmrTemplate from '../templates/register.dmr.html'
import registerBilbogenTemplate from '../templates/register.bilbogen.html'
import registerTinglysningTemplate from '../templates/register.tinglysning.html'
import registerSynsrapportTemplate from '../templates/register.synsrapport.html'
import newsTemplate from '../templates/news.html'
import newsitemTemplate from '../templates/newsitem.html'
import helpTemplate from '../templates/help.html'
import helpmenuTemplate from '../templates/helpmenu.html'
import helpitemTemplate from '../templates/helpitem.html'
import reportSummaryTemplate from '../templates/report.summary.html'
import autoitConnectCtrlTemplate from '../autoit/autoItConnectCtrl.html'
import controlpanelServiceLevelTemplate from '../templates/controlpanel.serviceLevel.html'
import formServiceLevelTemplate from '../templates/serviceLevel.html'
import controlPanelTemplate from '../templates/controlpanel.html'

import { IStateProvider, IStateService } from 'ui-router'

import type { IHttpService, ILocationProvider, ILocationService, IQService, IScope } from 'angular'
import { CarleasingInterop } from '@/shared/carleasingInterop'

type LoadAllScope = IScope & { loadAll: () => void }
type StateScope = IScope & { $state: IStateService }

export function autoItState(config: IStateProvider) {
  config
    .state('root.autoit', {
      abstract: true,
    })
    .state('root.autoit.index', {
      url: '/autoit/:externalId',
      views: {
        'content@': {
          controller: 'autoitConnectCtrl',
          template: autoitConnectCtrlTemplate,
        },
      },
    })
}

export function recalculationState(config: IStateProvider) {
  config
    .state('root.recalculation', {
      abstract: true,
      template: '<ui-view />',
    })
    .state('root.recalculation.edit', {
      url: '/recalculation/:applicationId/:id',
      views: {
        'content@': {
          controller: 'formRecalculationCtrl', // the report controller handles redirection to the default report
          template: formsRecalculationTemplate,
        },
      },
    })
    .state('root.recalculation.add', {
      url: '/recalculation/:applicationId',
      views: {
        'content@': {
          controller: 'formRecalculationCtrl', // the report controller handles redirection to the default report
          template: formsRecalculationTemplate,
        },
      },
    })
}

export function controlpanelFinancingPartnerState(config: IStateProvider) {
  config
    .state('root.controlpanel.financingPartner', {
      abstract: true,
      controller: 'controlpanelFinancingPartnerCtrl', // the report controller handles redirection to the default report
      template: controlpanelFinancingPartnerTemplate,
    })
    .state('root.controlpanel.financingPartner.index', {
      url: '/controlpanel/financingPartner',
      controller: [
        '$scope',
        function ($scope: LoadAllScope) {
          $scope.loadAll()
        },
      ],
    })
    .state('root.controlpanel.financingPartner.edit', {
      url: '/controlpanel/financingPartner/edit/:id',
      template: formsFinancingPartnerTemplate,
      controller: 'formFinancingPartnerCtrl',
    })
    .state('root.controlpanel.financingPartner.add', {
      url: '/controlpanel/financingPartner/add',
      template: formsFinancingPartnerTemplate,
      controller: 'formFinancingPartnerCtrl',
    })
}

export function controlpanelProductState(config: IStateProvider) {
  config
    .state('root.controlpanel.product', {
      abstract: true,
      controller: 'controlpanelProductCtrl', // the report controller handles redirection to the default report
      template: controlpanelProductTemplate,
    })
    .state('root.controlpanel.product.index', {
      url: '/controlpanel/product',
      controller: [
        '$scope',
        function ($scope: LoadAllScope) {
          $scope.loadAll()
        },
      ],
    })
    .state('root.controlpanel.product.edit', {
      url: '/controlpanel/product/edit/:id',
      template: formsProductTemplate,
      controller: 'formProductCtrl',
    })
    .state('root.controlpanel.product.add', {
      url: '/controlpanel/product/add',
      template: formsProductTemplate,
      controller: 'formProductCtrl',
    })
}

export function controlpanelDepreciationMatrixState(config: IStateProvider) {
  config
    .state('root.controlpanel.depreciationMatrix', {
      abstract: true,
      controller: 'controlpanelDepreciationMatrixCtrl', // the report controller handles redirection to the default report
      template: controlpanelDepreciationMatrixTemplate,
    })
    .state('root.controlpanel.depreciationMatrix.index', {
      url: '/controlpanel/depreciationMatrix',
      controller: [
        '$scope',
        function ($scope: LoadAllScope) {
          $scope.loadAll()
        },
      ],
    })
    .state('root.controlpanel.depreciationMatrix.edit', {
      url: '/controlpanel/depreciationMatrix/edit/:id',
      template: formsDepreciationMatrixTemplate,
      controller: 'formDepreciationMatrixCtrl',
    })
    .state('root.controlpanel.depreciationMatrix.add', {
      url: '/controlpanel/depreciationMatrix/add',
      template: formsDepreciationMatrixTemplate,
      controller: 'formDepreciationMatrixCtrl',
    })
}

export function controlpanelDepreceiationModelState(config: IStateProvider) {
  config
    .state('root.controlpanel.depreciationModel', {
      abstract: true,
      controller: 'controlpanelDepreciationModelCtrl', // the report controller handles redirection to the default report
      template: controlpanelDepreciationModelTemplate,
    })
    .state('root.controlpanel.depreciationModel.index', {
      url: '/controlpanel/depreciationModel',
      controller: [
        '$scope',
        function ($scope: LoadAllScope) {
          $scope.loadAll()
        },
      ],
    })
    .state('root.controlpanel.depreciationModel.edit', {
      url: '/controlpanel/depreciationModel/edit/:id',
      template: formsDepreciationModelTemplate,
      controller: 'formDepreciationModelCtrl',
    })
    .state('root.controlpanel.depreciationModel.add', {
      url: '/controlpanel/depreciationModel/add',
      template: formsDepreciationModelTemplate,
      controller: 'formDepreciationModelCtrl',
    })
}

export function makeFormState(formTemplate: string) {
  return function formState(config: IStateProvider) {
    config
      .state('root.application.add', {
        url: '/application',
        resolve: {
          dkkapVarDefs: [
            '$http',
            '$q',
            function ($http: IHttpService, $q: IQService) {
              var deferred = $q.defer()

              $http.post('/api/varDefs', {}).then(function (res) {
                deferred.resolve(res.data)
              })

              return deferred.promise
            },
          ],
        },
        views: {
          'content@': {
            controller: 'formCtrl', // the report controller handles redirection to the default report
            template: formTemplate,
          },
        },
      })
      .state('root.application.edit', {
        url: '/application/:id',
        resolve: {
          dkkapVarDefs: [
            '$http',
            '$q',
            function ($http: IHttpService, $q: IQService) {
              var deferred = $q.defer()

              $http.post('/api/varDefs', {}).then(function (res) {
                deferred.resolve(res.data)
              })

              return deferred.promise
            },
          ],
        },
        views: {
          'content@': {
            controller: 'formCtrl', // the report controller handles redirection to the default report
            template: formTemplate,
          },
        },
      })
  }
}

export function makeIndexState(indexTemplate: string | null) {
  return function indexState(config: IStateProvider) {
    config.state('root.application.index', {
      url: '/',
      views: {
        'content@': {
          template: indexTemplate ?? '<div index-dir></div>',
          controller: indexTemplate ? 'indexCtrl' : undefined,
        },
      },
    })
  }
}

export function reportSummaryState(config: IStateProvider) {
  config
    .state('root.controlpanel.report.index', {
      url: '/controlpanel/report',
      controller: [
        '$scope',
        '$location',
        function ($scope: IScope, $location: ILocationService) {
          $location.path('/controlpanel/report/summary')
        },
      ],
    })

    .state('root.controlpanel.report.summary', {
      url: '/controlpanel/report/summary',
      controller: 'reportSummaryCtrl',
      template: reportSummaryTemplate,
    })
}

export function companyGroupState(formsCompanyGroupTemplate: string) {
  return function (config: IStateProvider) {
    config
      .state('root.controlpanel.companyGroup.edit', {
        url: '/controlpanel/companyGroup/edit/:id',
        template: formsCompanyGroupTemplate,
        controller: 'formCompanyGroupCtrl',
      })
      .state('root.controlpanel.companyGroup.add', {
        url: '/controlpanel/companyGroup/add',
        template: formsCompanyGroupTemplate,
        controller: 'formCompanyGroupCtrl',
      })
  }
}

export const makeStateConfig = (extra: ((st: IStateProvider) => void)[]) => [
  '$stateProvider',
  '$locationProvider',
  function ($stateProvider: IStateProvider, $locationProvider: ILocationProvider) {
    interface ControlPanelEntry {
      state: ControlPanelState
      href: string
      name: string
    }

    const commonState = $stateProvider
      .state('root', {
        //url : '',
        abstract: true,
      })
      .state('root.application', {
        //url : '',
        abstract: true,
        template: '<ui-view />',
      })
      .state('root.application.dptest', {
        url: '/dptest',
        views: {
          'content@': {
            controller: 'datepickerTestCtrl', // the report controller handles redirection to the default report
            template: formtestTemplate,
          },
        },
      })
      .state('root.application.seasons', {
        url: '/application/:id/seasons',
        views: {
          'content@': {
            controller: 'seasonAdminCtrl', // the report controller handles redirection to the default report
            template: seasonAdminCtrlTemplate,
          },
        },
      })
      .state('root.inspect', {
        url: '/inspect/:id',
        views: {
          'content@': {
            controller: 'inspectCtrl', // the report controller handles redirection to the default report
            template: inspectTemplate,
          },
        },
      })

      .state('root.controlpanel', {
        abstract: true,
        views: {
          'content@': {
            controller: [
              '$scope',
              '$state',
              '$interop',
              function ($scope: StateScope & { controlPanelEntries: ControlPanelEntry[] }, $state: IStateService, $interop: CarleasingInterop) {
                const controlPanelEntries: ControlPanelEntry[] = [
                  {
                    state: 'root.controlpanel.company',
                    href: '/controlpanel/company',
                    name: 'Forhandlere',
                  },
                  {
                    state: 'root.controlpanel.companyGroup',
                    href: '/controlpanel/companyGroup',
                    name: 'Prisgrupper',
                  },
                  {
                    state: 'root.controlpanel.commonConfig',
                    href: '/controlpanel/commonConfig',
                    name: 'Fælles priser og stamdata',
                  },
                  {
                    state: 'root.controlpanel.interestAdjustment',
                    href: '/controlpanel/interestAdjustment',
                    name: 'Renteændring',
                  },
                  {
                    state: 'root.controlpanel.prt',
                    href: '/controlpanel/prt',
                    name: 'Registreringsafgift',
                  },
                  {
                    state: 'root.controlpanel.selvanmelder',
                    href: '/controlpanel/selvanmelder',
                    name: 'Selvanmelder',
                  },
                  {
                    state: 'root.controlpanel.insuranceCompany',
                    href: '/controlpanel/insuranceCompany',
                    name: 'Forsikringsselskaber',
                  },
                  {
                    state: 'root.controlpanel.insuranceCosts',
                    href: '/controlpanel/insuranceCosts',
                    name: 'Forsikringstrin',
                  },
                  {
                    state: 'root.controlpanel.gpsProvider',
                    href: '/controlpanel/gpsProvider',
                    name: 'GPS-leverandører',
                  },
                  {
                    state: 'root.controlpanel.article',
                    href: '/controlpanel/article',
                    name: 'Artikler',
                  },
                  {
                    state: 'root.controlpanel.news',
                    href: '/controlpanel/news',
                    name: 'Nyheder',
                  },
                  {
                    state: 'root.controlpanel.newsletter',
                    href: '/controlpanel/newsletter',
                    name: 'Nyhedsbreve',
                  },
                  {
                    state: 'root.controlpanel.mailTemplates',
                    href: '/controlpanel/mailTemplates',
                    name: 'Mailskabeloner',
                  },
                  {
                    state: 'root.controlpanel.penneo',
                    href: '/controlpanel/penneo',
                    name: 'Penneo',
                  },
                  {
                    state: 'root.controlpanel.report',
                    href: '/controlpanel/report',
                    name: 'Rapporter',
                  },
                  {
                    state: 'root.controlpanel.gdpr',
                    href: '/controlpanel/gdpr',
                    name: 'GDPR',
                  },
                  {
                    state: 'root.controlpanel.financingPartner',
                    href: '/controlpanel/financingPartner',
                    name: 'Finansieringspartnere',
                  },
                  {
                    state: 'root.controlpanel.product',
                    href: '/controlpanel/product',
                    name: 'Produkter',
                  },
                  {
                    state: 'root.controlpanel.depreciationModel',
                    href: '/controlpanel/depreciationModel',
                    name: 'Restværdimodel',
                  },
                  {
                    state: 'root.controlpanel.depreciationMatrix',
                    href: '/controlpanel/depreciationMatrix',
                    name: 'Restværdimatrix',
                  },
                  {
                    state: 'root.controlpanel.serviceLevel',
                    href: '/controlpanel/serviceLevel',
                    name: 'Serviceaftale',
                  },
                ]

                $scope.controlPanelEntries = controlPanelEntries.filter(e => $interop.controlPanelStates.includes(e.state))
                $scope.$state = $state
              },
            ],
            template: controlPanelTemplate,
          },
        },
      }) // REDIRECT
      .state('root.controlpanel.index', {
        url: '/controlpanel',
        controller: [
          '$scope',
          '$location',
          function ($scope: IScope, $location: ILocationService) {
            $location.path('/controlpanel/company')
          },
        ],
      })

      // COMPANY
      .state('root.controlpanel.company', {
        abstract: true,
        controller: 'controlpanelCompanyCtrl', // the report controller handles redirection to the default report
        template: controlpanelCompanyTemplate,
      })
      .state('root.controlpanel.company.index', {
        url: '/controlpanel/company',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.company.edit', {
        url: '/controlpanel/company/edit/:id',
        template: formsCompanyTemplate,
        controller: 'formCompanyCtrl',
      })
      .state('root.controlpanel.company.add', {
        url: '/controlpanel/company/add',
        template: formsCompanyTemplate,
        controller: 'formCompanyCtrl',
      })
      .state('root.controlpanel.company.addUser', {
        url: '/controlpanel/company/addUser/:id',
        template: formsUserTemplate,
        controller: 'formUserCtrl',
      })
      .state('root.controlpanel.company.editUser', {
        url: '/controlpanel/company/edit/:id/:userId',
        template: formsUserTemplate,
        controller: 'formUserCtrl',
      })

      // COMPANY GROUP
      .state('root.controlpanel.companyGroup', {
        abstract: true,
        controller: 'controlpanelCompanyGroupCtrl', // the report controller handles redirection to the default report
        template: controlpanelCompanyGroupTemplate,
      })
      .state('root.controlpanel.companyGroup.index', {
        url: '/controlpanel/companyGroup',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })

      // GPS PROVIDER
      .state('root.controlpanel.gpsProvider', {
        abstract: true,
        controller: 'controlpanelGpsProviderCtrl', // the report controller handles redirection to the default report
        template: controlpanelGpsProviderTemplate,
      })
      .state('root.controlpanel.gpsProvider.index', {
        url: '/controlpanel/gpsProvider',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.gpsProvider.edit', {
        url: '/controlpanel/gpsProvider/edit/:id',
        template: formsGpsProviderTemplate,
        controller: 'formGpsProviderCtrl',
      })
      .state('root.controlpanel.gpsProvider.add', {
        url: '/controlpanel/gpsProvider/add',
        template: formsGpsProviderTemplate,
        controller: 'formGpsProviderCtrl',
      })

      // INTEREST ADJUSTMENT
      .state('root.controlpanel.interestAdjustment', {
        abstract: true,
        controller: 'formInterestAdjustmentCtrl', // the report controller handles redirection to the default report
        template: interestAdjustmentTemplate,
      })
      .state('root.controlpanel.interestAdjustment.index', {
        url: '/controlpanel/interestAdjustment',
        controller: 'formInterestAdjustmentCtrl', // the report controller handles redirection to the default report
        template: interestAdjustmentTemplate,
      })

      // TODO: Split of prt into a separate function
      .state('root.controlpanel.prt', {
        abstract: true,
        controller: 'formPrtCtrl', // the report controller handles redirection to the default report
        template: prtTemplate,
      })
      .state('root.controlpanel.prt.index', {
        url: '/controlpanel/prt',
        controller: 'formPrtCtrl', // the report controller handles redirection to the default report
        template: prtTemplate,
      })

      // INSURANCE COMPANY
      .state('root.controlpanel.insuranceCompany', {
        abstract: true,
        controller: 'controlpanelInsuranceCompanyCtrl', // the report controller handles redirection to the default report
        template: controlpanelInsuranceCompanyTemplate,
      })
      .state('root.controlpanel.insuranceCompany.index', {
        url: '/controlpanel/insuranceCompany',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.insuranceCompany.edit', {
        url: '/controlpanel/insuranceCompany/edit/:id',
        template: formsInsuranceCompanyTemplate,
        controller: 'formInsuranceCompanyCtrl',
      })
      .state('root.controlpanel.insuranceCompany.add', {
        url: '/controlpanel/insuranceCompany/add',
        template: formsInsuranceCompanyTemplate,
        controller: 'formInsuranceCompanyCtrl',
      })

      // NEWS
      .state('root.controlpanel.news', {
        abstract: true,
        controller: 'controlpanelNewsCtrl', // the report controller handles redirection to the default report
        template: controlpanelNewsTemplate,
      })
      .state('root.controlpanel.news.index', {
        url: '/controlpanel/news',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.news.edit', {
        url: '/controlpanel/news/edit/:id',
        template: formsNewsTemplate,
        controller: 'formNewsCtrl',
      })
      .state('root.controlpanel.news.add', {
        url: '/controlpanel/news/add',
        template: formsNewsTemplate,
        controller: 'formNewsCtrl',
      })

      // NEWSLETTER
      .state('root.controlpanel.newsletter', {
        abstract: true,
        controller: 'controlpanelNewsletterCtrl', // the report controller handles redirection to the default report
        template: controlpanelNewsletterTemplate,
      })
      .state('root.controlpanel.newsletter.index', {
        url: '/controlpanel/newsletter',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.newsletter.edit', {
        url: '/controlpanel/newsletter/edit/:id',
        template: formsNewsletterTemplate,
        controller: 'formNewsletterCtrl',
      })
      .state('root.controlpanel.newsletter.add', {
        url: '/controlpanel/newsletter/add',
        template: formsNewsletterTemplate,
        controller: 'formNewsletterCtrl',
      })

      // ARTICLE
      .state('root.controlpanel.article', {
        abstract: true,
        controller: 'controlpanelArticleCtrl', // the report controller handles redirection to the default report
        template: controlpanelArticleTemplate,
      })
      .state('root.controlpanel.article.index', {
        url: '/controlpanel/article',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.article.edit', {
        url: '/controlpanel/article/edit/:id',
        template: formsArticleCategoryTemplate,
        controller: 'formArticleCategoryCtrl',
      })
      .state('root.controlpanel.article.add', {
        url: '/controlpanel/article/add',
        template: formsArticleCategoryTemplate,
        controller: 'formArticleCategoryCtrl',
      })
      .state('root.controlpanel.article.addArticle', {
        url: '/controlpanel/article/addArticle/:id',
        template: formsArticleTemplate,
        controller: 'formArticleCtrl',
      })
      .state('root.controlpanel.article.editArticle', {
        url: '/controlpanel/article/edit/:id/:articleId',
        template: formsArticleTemplate,
        controller: 'formArticleCtrl',
      })

      // COMMON CONFIG
      .state('root.controlpanel.selvanmelder', {
        abstract: true,
        template: controlpanelCommonConfigTemplate,
      })
      .state('root.controlpanel.selvanmelder.index', {
        url: '/controlpanel/selvanmelder',
        controller: 'formSelvanmelderCtrl', // the report controller handles redirection to the default report
        template: formsSelvanmelderTemplate,
      })

      // COMMON CONFIG
      .state('root.controlpanel.commonConfig', {
        abstract: true,
        template: controlpanelCommonConfigTemplate,
      })
      .state('root.controlpanel.commonConfig.index', {
        url: '/controlpanel/commonConfig',
        controller: 'formCommonConfigCtrl', // the report controller handles redirection to the default report
        template: formsCommonConfigTemplate,
      })

      // COMMON CONFIG
      .state('root.controlpanel.mailTemplates', {
        abstract: true,
        template: controlpanelMailTemplatesTemplate,
      })
      .state('root.controlpanel.mailTemplates.index', {
        url: '/controlpanel/mailTemplates',
        controller: 'formMailTemplatesCtrl', // the report controller handles redirection to the default report
        template: formsMailTemplatesTemplate,
      })

      // COMMON CONFIG
      .state('root.controlpanel.penneo', {
        abstract: true,
        template: controlpanelPenneoTemplate,
      })
      .state('root.controlpanel.penneo.index', {
        url: '/controlpanel/penneo',
        controller: 'formPenneoCtrl', // the report controller handles redirection to the default report
        template: formsPenneoTemplate,
      })

      // REPORTS
      // TODO: Factor out, and allow for adjusting summary/adjustedFees
      .state('root.controlpanel.report', {
        abstract: true,
        template: controlpanelReportsTemplate,
        /*views: {'content@': {
                    controller: ['$scope', '$state', function($scope,$state) { $scope.$state = $state; }],

                }}*/
      }) // REDIRECT

      // COMMON CONFIG
      .state('root.controlpanel.insuranceCosts', {
        abstract: true,
        template: controlpanelInsuranceCostsTemplate,
      })
      .state('root.controlpanel.insuranceCosts.index', {
        url: '/controlpanel/insuranceCosts',
        controller: 'formInsuranceCostsCtrl', // the report controller handles redirection to the default report
        template: formsInsuranceCostsTemplate,
      })

      .state('root.company', {
        abstract: true,
        views: {
          'content@': {
            controller: [
              '$scope',
              '$state',
              function ($scope: StateScope, $state: IStateService) {
                $scope.$state = $state
              },
            ],
            template: companyTemplate,
          },
        },
      }) // REDIRECT
      .state('root.company.index', {
        url: '/company',
        controller: [
          '$scope',
          '$location',
          function ($scope: IScope, $location: ILocationService) {
            $location.path('/company/user')
          },
        ],
      })

      // USER
      .state('root.company.user', {
        abstract: true,
        controller: 'companyUserCtrl', // the report controller handles redirection to the default report
        template: companyUserTemplate,
      })
      .state('root.company.user.index', {
        url: '/company/user',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.company.user.edit', {
        url: '/company/user/edit/:userId',
        template: formsUserTemplate,
        controller: 'formUserCtrl',
      })
      .state('root.company.user.add', {
        url: '/company/user/add',
        template: formsUserTemplate,
        controller: 'formUserCtrl',
      })

      // STAMDATA

      .state('root.company.edit', {
        abstract: true,
        controller: 'companyCtrl',
        template: companyEditTemplate,
      })
      .state('root.company.edit.index', {
        url: '/company/edit',
        controller: 'formCompanyCtrl', // the report controller handles redirection to the default report
        template: formsCompanyTemplate,
      })

      .state('root.register', {
        abstract: true,
        views: {
          'content@': {
            controller: [
              '$scope',
              '$state',
              function ($scope: StateScope, $state: IStateService) {
                $scope.$state = $state
              },
            ],
            template: registerTemplate,
          },
        },
      }) // REDIRECT
      .state('root.register.index', {
        url: '/register',
        controller: [
          '$scope',
          '$location',
          function ($scope: IScope, $location: ILocationService) {
            $location.path('/register/rki')
          },
        ],
      })

      // SERVICE LEVEL
      .state('root.controlpanel.serviceLevel', {
        abstract: true,
        controller: 'controlpanelServiceLevelCtrl',
        template: controlpanelServiceLevelTemplate,
      })
      .state('root.controlpanel.serviceLevel.index', {
        url: '/controlpanel/serviceLevel',
        controller: [
          '$scope',
          function ($scope: LoadAllScope) {
            $scope.loadAll()
          },
        ],
      })
      .state('root.controlpanel.serviceLevel.edit', {
        url: '/controlpanel/serviceLevel/edit/:id',
        template: formServiceLevelTemplate,
        controller: 'formServiceLevelCtrl',
      })
      .state('root.controlpanel.serviceLevel.add', {
        url: '/controlpanel/serviceLevel/add',
        template: formServiceLevelTemplate,
        controller: 'formServiceLevelCtrl',
      })

      .state('root.register.rki', {
        url: '/register/rki',
        controller: 'registerRkiCtrl',
        template: registerRkiTemplate,
      })
      .state('root.register.dowjones', {
        url: '/register/dowjones',
        controller: 'registerDowJonesCtrl',
        template: registerDowjonesTemplate,
      })
      .state('root.register.cvr', {
        url: '/register/cvr',
        controller: 'registerCvrCtrl',
        template: registerCvrTemplate,
      })
      .state('root.register.dmr', {
        url: '/register/dmr',
        controller: 'registerDmrCtrl',
        template: registerDmrTemplate,
      })
      .state('root.register.bilbogen', {
        url: '/register/bilbogen',
        controller: 'registerBilbogenCtrl',
        template: registerBilbogenTemplate,
      })
      .state('root.register.tinglysning', {
        url: '/register/tinglysning',
        controller: 'registerTinglysningCtrl',
        template: registerTinglysningTemplate,
      })
      .state('root.register.synsrapport', {
        url: '/register/synsrapport',
        controller: 'registerSynsrapportCtrl',
        template: registerSynsrapportTemplate,
      })

      // NEWS
      .state('root.news', {
        abstract: true,
        views: {
          'content@': {
            controller: 'newsCtrl',
            template: newsTemplate,
          },
        },
      })
      .state('root.news.index', {
        url: '/news',
      })

      .state('root.news.show', {
        url: '/news/show/:month/:id',
        template: newsitemTemplate,
        controller: 'newsItemCtrl',
      })

      // HELP
      .state('root.help', {
        abstract: true,
      })

      .state('root.help.index', {
        url: '/help',
        views: {
          'content@': {
            controller: 'helpCtrl',
            template: helpTemplate,
          },
        },
      })

      .state('root.help.menu', {
        abstract: true,
        views: {
          'content@': {
            controller: 'helpMenuCtrl',
            template: helpmenuTemplate,
          },
        },
      })

      .state('root.help.menu.show', {
        url: '/help/show/:categoryId/:id',
        template: helpitemTemplate,
        controller: 'helpItemCtrl',
      })

    extra.forEach((f) => f(commonState))

    $locationProvider.html5Mode(true)
  },
]
