
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/controlpanel.newsletter.html";
      const src = "<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <h3></h3>\n    <p class=\"btn-group-sm\">\n      <a href=\"{{addurl}}\" class=\"btn-default btn-sm\"><i class=\"fa fa-plus-circle\"></i> Nyt nyhedsbrev</a>\n    </p>\n    <ul class=\"nav nav-pills nav-stacked\">\n      <li\n        ng-repeat=\"item in list | filter:showNewsletter\"\n        ng-class=\"{'active' : activeId == item.id,  'deactivated': item.deactivated}\"\n      >\n        <a ng-href=\"{{editurl}}/{{item.id}}\">{{item.subject}}</a>\n      </li>\n    </ul>\n    <hr />\n    <p class=\"btn-group-sm\">\n      <a href=\"{{addurl}}\" class=\"btn-default btn-sm\"><i class=\"fa fa-plus-circle\"></i> Nyt nyhedsbrev</a>\n    </p>\n    <p class=\"btn-group-sm\">\n      <a ng-click=\"showDeactivated = !showDeactivated\" class=\"btn-default btn-sm\"\n        ><i class=\"fa fa-plus-circle\"></i> {{showDeactivated ? 'Skjul' : 'Vis'}} deaktiverede</a\n      >\n    </p>\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      