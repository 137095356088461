
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/register.synsrapport.html";
      const src = "<form name=\"form\">\n  <div class=\"form-group clearfix\">\n    <div class=\"col-xs-12\">\n      <div class=\"input-group\" ng-class=\"\">\n        <input\n          type=\"text\"\n          class=\"form-control\"\n          ng-model=\"modelVars.value\"\n          placeholder=\"Skriv reg. nr/stelnr.\"\n          required\n        />\n        <span class=\"input-group-btn\">\n          <button class=\"btn btn-default\" type=\"button\" ng-click=\"query('registreringsnummer')\">Reg. nr. opslag</button>\n        </span>\n        <span class=\"input-group-btn\">\n          <button class=\"btn btn-default\" type=\"button\" ng-click=\"query('stelnummer')\">Stelnr. opslag</button>\n        </span>\n      </div>\n    </div>\n  </div>\n</form>\n\n<hr />\n\n<div>\n  <div ng-show=\"result.type == 'success'\">\n    <a\n      class=\"btn btn-default pull-right\"\n      target=\"_blank\"\n      ng-href=\"/api/synsrapportLookup/pdf/{{result.queryType}}/{{result.query}}\"\n      >Hent som PDF</a\n    >\n\n    <div ng-repeat=\"section in result.sections\">\n      <p><strong>{{section.title}}</strong></p>\n      <dl class=\"dl-horizontal\">\n        <div ng-repeat=\"line in section.elements\">\n          <dt>{{line.overrideText ? line.overrideText : line.key}}</dt>\n          <dd ng-repeat=\"v in line.value track by $index\">{{line.value[$index]}}&nbsp;</dd>\n        </div>\n      </dl>\n    </div>\n  </div>\n\n  <div ng-show=\"result.type == 'error'\" class=\"alert alert-warning\">{{result.message}}</div>\n  <div ng-show=\"result.type == 'wait'\" class=\"well\"><i class=\"fa fa-spinner fa-spin\"></i> Forespørger...</div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      