import { PERCENTAGE_REGEXP } from '../regexes'
import { rerenderViewValue } from '../functions/rerenderViewValue'
import { IAttributes } from 'angular'
import { attrs, makeDirective } from '@/shared/util/directiveHelpers'

interface PercentageAttrs extends IAttributes {
  ngPercentage: number
}

export const ngPercentage = makeDirective({
  inject: { $interop: '$interop' },
  require: 'ngModel',
  attrs: attrs<PercentageAttrs>(),
  link({ $interop }, { ctrl, elem, attrs }) {
    ctrl.$parsers.unshift(function (viewValue) {
      if (PERCENTAGE_REGEXP.test(viewValue) || viewValue === '') {
        ctrl.$setValidity('percentage', true)
        return parseFloat(viewValue.replace(',', '.')) / 100
      } else {
        ctrl.$setValidity('percentage', false)
        return undefined
      }
    })
    ctrl.$formatters.push(function (modelValue) {
      if (typeof modelValue === 'undefined') {
        return ''
      }
      if (modelValue === null || modelValue * 1 === modelValue) {
        ctrl.$setValidity('percentage', true)
      } else {
        ctrl.$setValidity('percentage', false)
      }

      return (modelValue * 100).toFixed($interop.specific.ngPercentage.toFixedArgs(attrs)).replace('.', ',')
    })

    elem.bind('blur', function () {
      rerenderViewValue(ctrl.$modelValue, ctrl)
    })
  },
})
