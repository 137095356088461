
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/formtest.html";
      const src = "<form name=\"form\" class=\"form-horizontal\" novalidate>\n  <div class=\"row\">\n    <div class=\"col-sm-6\">\n      <pre>\n                leveringsdato = {{modelVars.leveringsdato}}\n\n            </pre\n      >\n      <div class=\"form-group\">\n        <label class=\"col-xs-4 control-label\"\n          ><input\n            ng-disabled=\"formIsDisabled\"\n            type=\"checkbox\"\n            ng-model=\"modelVars.useLeveringsdato\"\n            ng-click=\"modelVars.leveringsdato = engine.eval('leveringsdatoDefault')\"\n          />\n          Forventet leveringsdato</label\n        >\n        <div class=\"col-xs-8\">\n          <div ng-autofield=\"leveringsdatoDefault\" ng-show=\"!modelVars.useLeveringsdato\" no-form-group></div>\n          <div ng-autofield=\"leveringsdato\" ng-show=\"modelVars.useLeveringsdato\" no-form-group future-date></div>\n        </div>\n      </div>\n    </div>\n  </div>\n\n  <input bs-datepicker type=\"text\" ng-model=\"modelVars.leveringsdato\" />\n\n  <input\n    name=\"leveringsdato\"\n    type=\"text\"\n    ng-model=\"modelVars.leveringsdato\"\n    class=\"form-control text-right\"\n    placeholder=\"\"\n    ng-disabled=\"formIsDisabled\"\n    required=\"\"\n    bs-datepicker=\"\"\n    data-placement=\"bottom-right\"\n    data-date-format=\"dd.MM.yyyy\"\n    data-start-week=\"1\"\n    data-lang=\"da\"\n    data-start-view=\"2\"\n    data-autoclose=\"true\"\n    data-min-date=\"today\"\n    ng-autostuff=\"variableDefs.leveringsdato\"\n  />\n\n  <div ng-compile-test></div>\n\n  {{modelVars.leveringsdato}}\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      