import creditAnalysisHeaderTemplate from './creditAnalysisHeader.html'
import { element, IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import angular from 'angular'
import { dateToDanish } from '../functions/dates'

interface CreditAnalysisHeaderScope extends IScope {
  positionFixed: boolean | undefined
  lastModified: null | {
    userId: number
    name: string
    timestamp: number
  }
  userInputDirty: boolean
  saving: boolean
  save: () => void
  saveUserInput: () => Promise<void>
  renderTimestamp: (timestamp: string) => string
}

const SCROLL_OFFSET = 235

export const creditAnalysisHeader = [
  '$window',
  '$timeout',
  function ($window: Window, $timeout: ITimeoutService): IDirective<CreditAnalysisHeaderScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisHeaderTemplate,
      scope: {
        lastModified: '=',
        userInputDirty: '=',
        saveUserInput: '=',
        contract: '=',
      },

      link: function ($scope: CreditAnalysisHeaderScope, elm: JQLite, attrs: IAttributes) {
        $scope.saving = false
        $scope.save = function () {
          $scope.saving = true
          $scope.saveUserInput().then(function () {
            $timeout(function () {
              $scope.saving = false
            })
          })
        }

        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(Date.parse(timestamp)), true)
        }

        const onWindowEvent = function () {
          $timeout(function () {
            const belowScrollOffset =
              (document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset) >= SCROLL_OFFSET

            const elmHeightLessThanWindowHeight =
              elm[0].getElementsByClassName('credit-analysis-header')[0].clientHeight < $window.innerHeight

            $scope.positionFixed = belowScrollOffset && elmHeightLessThanWindowHeight
          })
        }
        angular.element($window).bind('scroll', onWindowEvent)
        angular.element($window).bind('resize', onWindowEvent)
      },
    }
  },
]
