
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/ultimate-owners.html";
      const src = "<div class=\"panel panel-default panel-ownership\">\n  <div class=\"panel-heading\">\n    <p class=\"pull-right text-muted\" ng-if=\"modelVars.cvr\">{{modelVars.cvr}}: {{modelVars.navn}}</p>\n    <p class=\"pull-right text-muted\" ng-if=\"!modelVars.cvr\">CVR-nummer mangler</p>\n    <h3 class=\"panel-title\"><i class=\"fa fa-id-card-o\"></i> Ultimative ejere</h3>\n  </div>\n  <table class=\"table\" ng-if=\"currentCvr\">\n    <thead>\n      <tr>\n        <th ng-if=\"applicationId\">ID-krav</th>\n        <th>CPR</th>\n        <th>Navn</th>\n        <th style=\"width: 100px\">Ejerandel</th>\n      </tr>\n    </thead>\n    <tbody ng-repeat=\"table in [ownerships, typedOwners]\">\n      <tr\n        ng-if=\"ownership.isUltimateOwner && modelVars.cvr\"\n        ng-repeat=\"ownership in table\"\n        ng-class=\"{\n            'warning' : ((ownership.cprCvr.length == 0 && !knownCPRs[ownership.name]) || ownership.isCompany) && ownershipRequireId[getOwnershipKey(ownership)],\n            'text-muted' : !ownershipRequireId[getOwnershipKey(ownership)]\n          }\"\n      >\n        <td ng-if=\"applicationId\">\n          <input\n            type=\"checkbox\"\n            ng-model=\"ownershipRequireId[getOwnershipKey(ownership)]\"\n            ng-change=\"updateRequireIdOverride(ownership.name, ownershipRequireId[getOwnershipKey(ownership)])\"\n          />\n          <i class=\"fa fa-asterisk\" ng-show=\"ownership.requireId != ownershipRequireId[getOwnershipKey(ownership)]\"></i>\n        </td>\n        <td>{{knownCPRs[ownership.name] ? knownCPRs[ownership.name] : (ownership.cprCvr ? ownership.cprCvr : '')}}</td>\n        <td>{{ownership.name}}</td>\n        <td>{{ownership.ownership}}</td>\n      </tr>\n    </tbody>\n  </table>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      