import angular, { type IFormController, type IScope } from 'angular'

export function errorList(form: IFormController, scope?: IScope & { nameMappings?: { [name: string]: string } }) {
  const errors: string[] = []
  if (form !== undefined && form.$error !== undefined) {
    angular.forEach(form.$error, function (arr) {
      angular.forEach(arr, function (err) {
        if (err.$name) {
          if (scope?.nameMappings && scope.nameMappings[err.$name]) {
            errors.push(scope.nameMappings[err.$name])
          } else {
            errors.push(err.$name)
          }
        }
      })
    })
  }

  return [...new Set(errors)]
}

export function errorCount(form: IFormController, scope: IScope) {
  return errorList(form, scope).length
}
