export const Formula = {
  FV(rate: any, periods: number, payment: number, value: number, type?: number) {
    // Credits: algorithm inspired by Apache OpenOffice

    // Initialize type
    type = typeof type === 'undefined' ? 0 : type

    // Evaluate rate (TODO: replace with secure expression evaluator)
    rate = eval(rate)

    // Return future value
    var result
    if (rate === 0) {
      result = value + payment * periods
    } else {
      var term = Math.pow(1 + rate, periods)
      if (type === 1) {
        result = value * term + (payment * (1 + rate) * (term - 1.0)) / rate
      } else {
        result = value * term + (payment * (term - 1)) / rate
      }
    }
    return -result
  },

  PMT(rate: any, periods: any, present: number, future: number, type?: number) {
    // Credits: algorithm inspired by Apache OpenOffice

    // Initialize type
    type = typeof type === 'undefined' ? 0 : type

    // Evaluate rate and periods (TODO: replace with secure expression evaluator)
    rate = eval(rate)
    periods = eval(periods)

    // Return payment
    var result
    if (rate === 0) {
      result = (present + future) / periods
    } else {
      var term = Math.pow(1 + rate, periods)
      if (type === 1) {
        result = ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) / (1 + rate)
      } else {
        result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)
      }
    }
    return -result
  },

  PV(rate: any, periods: any, payment: number, future: number, type?: number) {
    // Initialize type
    type = typeof type === 'undefined' ? 0 : type

    // Evaluate rate and periods (TODO: replace with secure expression evaluator)
    rate = eval(rate)
    periods = eval(periods)

    // Return present value
    if (rate === 0) {
      return -payment * periods - future
    } else {
      return (
          (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods)
      )
    }
  },
  NPER(rate: any, payment: number, present: number, future?: number, type?: number) {
    // Initialize type
    type = typeof type === 'undefined' ? 0 : type

    // Initialize future value
    future = typeof future === 'undefined' ? 0 : future

    // Evaluate rate and periods (TODO: replace with secure expression evaluator)
    rate = eval(rate)

    // Return number of periods
    var num = payment * (1 + rate * type) - future * rate
    var den = present * rate + payment * (1 + rate * type)
    return Math.log(num / den) / Math.log(1 + rate)
  },

  RATE(periods: any, payment: number, present: number, future?: number, type?: number, guess?: number) {
    // Credits: rabugento

    // Initialize guess
    guess = typeof guess === 'undefined' ? 0.01 : guess

    // Initialize future
    future = typeof future === 'undefined' ? 0 : future

    // Initialize type
    type = typeof type === 'undefined' ? 0 : type

    // Evaluate periods (TODO: replace with secure expression evaluator)
    periods = eval(periods)

    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10

    // Set maximum number of iterations
    var iterMax = 50

    // Implement Newton's method
    var y,
        y0,
        y1,
        x0,
        x1 = 0,
        f = 0,
        i = 0
    var rate = guess
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future
    } else {
      f = Math.exp(periods * Math.log(1 + rate))
      y = present * f + payment * (1 / rate + type) * (f - 1) + future
    }
    y0 = present + payment * periods + future
    y1 = present * f + payment * (1 / rate + type) * (f - 1) + future
    i = x0 = 0
    x1 = rate
    while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0)
      x0 = x1
      x1 = rate
      if (Math.abs(rate) < epsMax) {
        y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future
      } else {
        f = Math.exp(periods * Math.log(1 + rate))
        y = present * f + payment * (1 / rate + type) * (f - 1) + future
      }
      y0 = y1
      y1 = y
      ++i
    }
    return rate
  },

  CUMIPMT(rate: any, periods: any, value: number, start: number, end: number, type?: number) {
    // Credits: algorithm inspired by Apache OpenOffice
    // Credits: Hannes Stiebitzhofer for the translations of function and variable names
    // Requires Formula.FV() and Formula.PMT() from Formula.js [http://stoic.com/formula/]

    // Evaluate rate and periods (TODO: replace with secure expression evaluator)
    rate = eval(rate)
    periods = eval(periods)

    // Return error if either rate, periods, or value are lower than or equal to zero
    if (rate <= 0 || periods <= 0 || value <= 0) {
      return undefined //'#NUM!';
    }

    // Return error if start < 1, end < 1, or start > end
    if (start < 1 || end < 1 || start > end) {
      return undefined // '#NUM!';
    }

    // Return error if type is neither 0 nor 1
    if (type !== 0 && type !== 1) {
      return undefined //'#NUM!';
    }

    // Compute cumulative interest
    var payment = Formula.PMT(rate, periods, value, 0, type)
    var interest = 0

    if (start === 1) {
      if (type === 0) {
        interest = -value
        start++
      }
    }

    for (var i = start; i <= end; i++) {
      if (type === 1) {
        interest += Formula.FV(rate, i - 2, payment, value, 1) - payment
      } else {
        interest += Formula.FV(rate, i - 1, payment, value, 0)
      }
    }
    interest *= rate

    // Return cumulative interest
    return interest
  },

  EFFECT(rate: number, periods: any) {
    // Return error if any of the parameters is not a number
    if (isNaN(rate) || isNaN(periods)) {
      return undefined //'#VALUE!';
    }

    // Return error if rate <=0 or periods < 1
    if (rate <= 0 || periods < 1) {
      return undefined //'#NUM!';
    }

    // Truncate periods if it is not an integer
    periods = parseInt(periods, 10)

    // Return effective annual interest rate
    return Math.pow(1 + rate / periods, periods) - 1
  }
}
