import type { IAttributes, ICompileService, IDirective, IDocumentService, IParseService, IScope } from 'angular'
import { CarleasingInterop } from '@/shared/carleasingInterop'

interface NgAutofieldScope<VariableDefs extends { [name: string]: computationEngine.VariableDef }> extends IScope {
  variableDefs?: VariableDefs
  options?: Partial<{ [Property in keyof VariableDefs]: { value: computationEngine.Scalar; option: string }[] }>
  noneOptions?: Partial<{ [Property in keyof VariableDefs]: string }>
  modelVars?: { [Property in keyof VariableDefs]: computationEngine.Scalar }
  engine: computationEngine.ComputationEngine<VariableDefs>
}

interface NgAutofieldAttrs extends IAttributes {
  ngAutofield: string
  ngModel?: string
  ngOptions?: string
  noneOption?: string
  name?: string
  config?: any
  placeholder?: string
  noInputAddon?: string
  futureDate?: string
  anyDate?: string
  ngAutofieldErrors?: string
  ngClick?: string

  ngDisabled?: string
  class?: string
  ngAutofieldLock?: string
  noFormGroup?: string

  ngEnableWriteable?: string
}

type HTMLElementEdits<TagName extends keyof HTMLElementTagNameMap> = Partial<
  Omit<HTMLElementTagNameMap[TagName], 'style'>
> & {
  style?: Partial<CSSStyleDeclaration>
  extra?: { [name: string]: string | undefined }
}

function makeElem<TagName extends keyof HTMLElementTagNameMap>(doc: Document, tagName: TagName) {
  return function (properties?: HTMLElementEdits<TagName>, children?: (string | Node)[]) {
    const elem = doc.createElement(tagName)

    if (properties) {
      for (const untypedPropKey in properties) {
        const propKey = untypedPropKey as Extract<keyof HTMLElementTagNameMap[TagName], string> | 'style' | 'extra'

        if (properties.hasOwnProperty(propKey)) {
          if (propKey === 'style') {
            const prop = properties['style'] ?? {}
            for (const stylePropKey in prop) {
              if (prop.hasOwnProperty(stylePropKey)) {
                const styleProp = prop[stylePropKey]
                if (typeof styleProp !== 'undefined') {
                  elem.style[stylePropKey] = styleProp
                }
              }
            }
          } else if (propKey === 'extra') {
            Object.entries(properties['extra'] ?? {}).forEach(([attribKey, attrib]) => {
              if (typeof attrib !== 'undefined') {
                // https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149
                const kebabKey = attribKey
                  .replace(/([a-z])([A-Z])/g, '$1-$2')
                  .replace(/[\s_]+/g, '-')
                  .toLowerCase()

                elem.setAttribute(kebabKey, attrib)
              }
            })
          } else {
            const prop = (properties as Partial<HTMLElementTagNameMap[TagName]>)[propKey]
            if (prop !== undefined && prop !== null) {
              elem[propKey] = prop
            }
          }
        }
      }
    }

    if (children) {
      elem.append(...children)
    }

    return elem
  }
}

export const ngAutofield = [
  '$compile',
  '$document',
  '$parse',
  '$interop',
  function (
    $compile: ICompileService,
    $document: IDocumentService,
    $parse: IParseService,
    $interop: CarleasingInterop,
  ): IDirective<NgAutofieldScope<any>, JQLite, NgAutofieldAttrs> {
    return {
      restrict: 'A',
      replace: true,
      scope: false, // SKAL være false -- Datepicker skal opdatere med det samme når man laver ændring!
      link: function <VariableDefs extends { [name: string]: computationEngine.VariableDef }>(
        scope: NgAutofieldScope<VariableDefs>,
        elem: JQLite,
        attr: NgAutofieldAttrs,
      ) {
        const doc = $document[0] as Document

        scope.$watch('variableDefs', () =>
          onChange($compile, doc, $parse, $interop, scope, elem, attr, $interop.specific.ngAutofield.ngPercentageValueStr, $interop.specific.ngAutofield.makeDescription),
        )
      },
    }
  },
]

function onChange<VariableDefs extends { [name: string]: computationEngine.VariableDef }>(
  $compile: ICompileService,
  doc: Document,
  $parse: IParseService,
  $interop: CarleasingInterop,
  scope: NgAutofieldScope<VariableDefs>,
  elem: JQLite,
  attr: NgAutofieldAttrs,
  ngPercentageValueStr: string,
  makeDescription: (varDef: computationEngine.VariableDef, name: string) => string,
) {
  const span = makeElem(doc, 'span')
  const i = makeElem(doc, 'i')
  const div = makeElem(doc, 'div')
  const label = makeElem(doc, 'label')
  const input = makeElem(doc, 'input')

  const varName = attr.ngAutofield
  const fieldname = attr.name ?? varName
  const ngEnableWriteable = attr.ngEnableWriteable
  const formGroup = typeof attr.noFormGroup === 'undefined'
  const useInputAddon = typeof attr.noInputAddon === 'undefined'
  const attrClass = attr.class ?? 'col-xs-8'

  if (scope.variableDefs === undefined) return // vi venter til den *er* defineret!

  const varDef = scope.variableDefs[varName]

  const makeLabel = (): HTMLElement => {
    const description =
      typeof varDef.friendlyExpr !== 'undefined' ? makeDescription(varDef, varName) : varDef.friendly ?? varName

    function isNotUndefined<A>(v: A | undefined): v is A {
      return v !== undefined
    }

    const toggleWriteable: HTMLElement | undefined =
      ngEnableWriteable !== undefined
        ? input({
            type: 'checkbox',
            extra: { ngDisabled: 'formIsDisabled', ngModel: `modelVars.${ngEnableWriteable}` },
          })
        : undefined

    return label({ className: 'col-xs-4 control-label' }, [
      span({ extra: { ngShow: `fieldDescriptions['${fieldname}']` }, style: { marginLeft: '-20px' } }, [
        i({
          style: { width: '14px' },
          className: 'text-info fa fa-info-circle',
          extra: {
            bsTooltip: `fieldDescriptions['${fieldname}']`,
            bsPlacement: 'bottom',
          },
        }),
        span({
          style: { display: 'inline-block', width: '6px' },
        }),
      ]),
      span(
        { extra: { ngClick: ngEnableWriteable ? undefined : `editInfotext('${varName}')` } },
        [toggleWriteable, toggleWriteable && ' ', description].filter(isNotUndefined<HTMLElement | string>),
      ),
    ])
  }

  const [inputOuts, addonPart] = makeControl(doc, $parse, $interop, scope, attr, ngPercentageValueStr)
  if (inputOuts.length === 0 && addonPart === undefined) {
    return
  }

  const elemWithAddon: HTMLElement[] =
    addonPart && useInputAddon
      ? [div({ className: 'input-group' }, [span({ className: 'input-group-addon' }, [addonPart]), ...inputOuts])]
      : inputOuts

  let res: HTMLElement[]
  if (formGroup) {
    res = [
      div(
        {
          className: 'form-group',
          extra: { ngClass: `{'has-error': form.${fieldname}.$invalid && formIsLoaded && !formIsDisabled }` },
        },
        [makeLabel(), div({ className: attrClass, style: { position: 'relative' } }, elemWithAddon)].filter(
          (v): v is HTMLElement => Boolean(v),
        ),
      ),
    ]
  } else {
    res = elemWithAddon
  }

  elem.html(res.map((e) => e.outerHTML).join(''))
  $compile(elem.contents())(scope)
}

function makeControl<VariableDefs extends { [name: string]: computationEngine.VariableDef }>(
  doc: Document,
  $parse: IParseService,
  $interop: CarleasingInterop,
  scope: NgAutofieldScope<VariableDefs>,
  attr: NgAutofieldAttrs,
  ngPercentageValueStr: string,
): [inputOuts: HTMLElement[], addonPart: HTMLElement | string | undefined] {
  const ucwordsBrandMinLength = 7
  if (scope.variableDefs === undefined) return [[], undefined] // vi venter til den *er* defineret!

  const varName = attr.ngAutofield
  const ngModel = attr.ngModel ?? `modelVars.${varName}`
  const ngOptions =
    attr.ngOptions ?? (scope.options && scope.options[varName] && `o.value as o.option for o in options.${varName}`)
  const noneOption =
    attr.noneOption ?? (scope.noneOptions && scope.noneOptions[varName] && `{{noneOptions.${varName}}}`)
  const fieldname = attr.name ?? varName
  const config = attr.config
  const placeholder = attr.placeholder ?? ''
  const useInputAddon = typeof attr.noInputAddon === 'undefined'
  const futureDate = typeof attr.futureDate !== 'undefined'
  const anyDate = typeof attr.anyDate !== 'undefined'
  const attrClass = attr.class ?? 'col-xs-8'
  const asTextArea = typeof attr.textarea !== 'undefined'

  const validateOrError: [boolean, string][] = [
    [scope.modelVars === undefined, "Variable 'modelVars' should be defined in outer scope of autofields"],
    [
      (() => {
        return (
          scope.engine === undefined &&
          Object.values(scope.variableDefs).some(
            (v) => v.vartype === 'expression' || Object.keys(v).some((k) => k.match(/Expr$/)),
          )
        )
      })(),
      "Variable 'engine' should be defined in outer scope of autofields",
    ],
    [
      scope.variableDefs[varName] === undefined,
      `Variable definition with name '${varName}' could not be found in scope.variableDefs`,
    ],
  ]

  for (const [condition, error] of validateOrError) {
    if (condition) {
      throw new Error(error)
    }
  }

  const span = makeElem(doc, 'span')
  const i = makeElem(doc, 'i')
  const div = makeElem(doc, 'div')
  const input = makeElem(doc, 'input')
  const button = makeElem(doc, 'button')
  const select = makeElem(doc, 'select')
  const option = makeElem(doc, 'option')
  const textarea = makeElem(doc, 'textarea')

  const addonParts: Partial<{ [k in computationEngine.VarDefDataType]: string | HTMLElement }> = {
    amount: 'kr',
    percent: '%',
    date: i({ className: 'fa fa-calendar' }),
  }

  const varDef = scope.variableDefs[varName]
  if (varDef.fieldConditions) {
    const isSupportedModule = varDef.fieldConditions.requiredModule?.includes($interop.module) ?? true
    const hasRequiredModule =
      varDef.fieldConditions.requiredFeature?.every((requiredFeature) => $interop.features.includes(requiredFeature)) ??
      true

    if (!isSupportedModule || !hasRequiredModule) {
      return [[], undefined]
    }
  }

  const {
    required: attrsRequired,
    disabled: attrsDisabled,
    extra: attrsExtra,
  } = determineAttributes(varName, varDef, attr.ngDisabled, ucwordsBrandMinLength, config, ngPercentageValueStr)

  const classes = [
    (varDef.datatype !== 'boolean' || ngOptions) ? 'form-control' : undefined,
    (varDef.align ?? 'right') === 'right' &&
    varDef.datatype &&
    ['percent', 'amount', 'count', 'decimal', 'singledecimal', 'date'].includes(varDef.datatype)
      ? 'text-right'
      : undefined,
  ].filter((v): v is string => Boolean(v))

  let inputOuts: HTMLElement[]
  let addonPart: HTMLElement | string | undefined
  switch (varDef.vartype) {
    case 'model':
      const ngAutofieldErrors = attr.ngAutofieldErrors
        ? ($parse(attr.ngAutofieldErrors)(scope) as { error: string; content: string; isWarning?: boolean }[])
        : []

      let errorSpans: HTMLElement[] = []
      if (!attrClass.split(' ').includes('no-warning')) {
        let previousErrors: string[] = []

        let horizontalOffset: Partial<CSSStyleDeclaration> | null = null

        if (varDef.align === 'right' || !varDef.align) {
          if (varDef.datatype === 'count' || varDef.datatype === 'decimal' || varDef.datatype === 'singledecimal') {
            horizontalOffset = { left: '25px' }
          } else if (varDef.datatype === 'percent' || varDef.datatype === 'amount' || varDef.datatype === 'date') {
            horizontalOffset = { left: '60px' }
          } else if (varDef.align) {
            throw new Error(`Unknown right align for datatype ${varDef.datatype}`)
          }
        }

        if (varDef.align === 'left' || !varDef.align && !horizontalOffset) {
          horizontalOffset = { right: '25px' }
        }

        if (!horizontalOffset) {
          throw new Error(`Unknown align ${varDef.align}`)
        }

        const makeError = (errorCond: string, content: string, isWarning?: boolean) => {
          const makeErrorCond = (errorCond: string) => `form.${fieldname}.$error.${errorCond}`

          let nots: string = previousErrors.map((e) => `!${makeErrorCond(e)}`).join(' && ')
          const cond = previousErrors.length ? `${nots} && ${makeErrorCond(errorCond)}` : makeErrorCond(errorCond)

          previousErrors.push(errorCond)
          return span(
            {
              extra: {
                ngShow: `${cond} && formIsLoaded && !formIsDisabled`,
              },
              className: `form-error-label label ${isWarning ? 'label-warning' : 'label-danger'}`,
              style: {
                position: 'absolute',
                top: '10px',
                ...horizontalOffset,
                zIndex: '3',
                display: 'block',
              },
            },
            [content],
          )
        }

        for (const w of [...ngAutofieldErrors].reverse()) {
          errorSpans.unshift(makeError(w.error, w.content, w.isWarning))
        }
        if (varDef.datatype === 'ucwords-brand') {
          errorSpans.unshift(makeError('ucwordsBrand', `Mindst ${ucwordsBrandMinLength} tegn`))
        }
        if (varDef.maxExpr) {
          errorSpans.unshift(makeError('ngMax', `Max {{getMax("${varName}")}}`))
        }
        if (varDef.minExpr) {
          errorSpans.unshift(makeError('ngMin', `Min {{getMin("${varName}")}}`))
        }

        errorSpans.unshift(makeError('required', 'Skal udfyldes'))
      }

      let inputElem
      if (ngOptions) {
        inputElem = select(
          {
            name: fieldname,
            className: classes.join(' '),
            required: attrsRequired,
            disabled: attrsDisabled,
            extra: {
              ngModel: ngModel,
              ngOptions: ngOptions,
              ...attrsExtra,
              ngAutostuff: `variableDefs.${varName}`,
              ngBlur: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock}=true` : undefined,
              ngKeydown: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock}=true` : undefined,
            },
          },
          noneOption ? [option({ value: '' }, [noneOption])] : [],
        )
      } else if (varDef.datatype === 'date') {
        inputElem = input({
          name: fieldname,
          type: 'text',
          className: classes.join(' '),
          placeholder: placeholder,
          required: attrsRequired,
          disabled: attrsDisabled,
          extra: {
            ngModel: ngModel,
            ...attrsExtra,
            ngAutostuff: `variableDefs.${varName}`,
            bsDatepicker: '',
            dataPlacement: 'bottom-right',
            dataDateFormat: 'dd.MM.yyyy',
            dataStartWeek: '1',
            dataLang: 'da',
            dataStartView: '2',
            dataAutoclose: 'true',
            minDate: !anyDate && futureDate ? 'today' : undefined,
            maxDate: !anyDate && !futureDate ? 'today' : undefined,
          },
        })
      } else if (asTextArea) {
        inputElem = textarea({
          name: fieldname,
          rows: $parse(attr.textarea)(scope) ?? 3,
          className: classes.join(' '),
          placeholder: placeholder,
          required: attrsRequired,
          disabled: attrsDisabled,
          extra: {
            ngModel: ngModel,
            ...attrsExtra,
            ngAutostuff: `variableDefs.${varName}`,
            ngBlur: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock} = true` : undefined,
            ngKeydown: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock} = true` : undefined,
          },
        })
      } else {
        inputElem = input({
          name: fieldname,
          type: varDef.datatype == 'boolean' ? 'checkbox' : 'text',
          className: classes.join(' '),
          placeholder: placeholder,
          required: attrsRequired,
          disabled: attrsDisabled,
          extra: {
            ngModel: ngModel,
            ...attrsExtra,
            ngAutostuff: `variableDefs.${varName}`,
            ngBlur: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock} = true` : undefined,
            ngKeydown: attr.ngAutofieldLock ? `modelVars.${attr.ngAutofieldLock} = true` : undefined,
          },
        })
      }

      const addonElem = varDef.datatype && addonParts[varDef.datatype]

      const lockField: HTMLElement | undefined = attr.ngAutofieldLock
        ? span({ className: 'input-group-btn' }, [
            button(
              {
                className: 'btn btn-default',
                type: 'button',
                extra: {
                  ngClick: `modelVars.${attr.ngAutofieldLock} = !modelVars.${attr.ngAutofieldLock}`,
                },
                style: { width: '40px' },
              },
              [
                i({
                  className: 'fa',
                  extra: {
                    ngClass: `{'fa-lock': modelVars.${attr.ngAutofieldLock}, 'fa-unlock': !modelVars.${attr.ngAutofieldLock}}`,
                  },
                }),
              ],
            ),
          ])
        : undefined

      const inputGroupParts = [
        useInputAddon && addonElem ? span({ className: 'input-group-addon' }, [addonElem]) : undefined,
        inputElem,
        lockField,
      ].filter((e): e is HTMLElement => Boolean(e))

      inputOuts = [
        ...errorSpans,
        ...(inputGroupParts.length > 1 ? [div({ className: 'input-group' }, inputGroupParts)] : inputGroupParts),
      ]

      break
    case 'server':
    case 'expression':
      if (!varDef.datatype) {
        throw new Error(`Found ${varDef.vartype} ngAutofield with no datatype in ${varName}`)
      }

      const engineEval = `engine.eval('${varName}')`

      const ngValues: Partial<{ [k in computationEngine.VarDefDataType]: string }> = {
        enum: (() => {
          const noneOptionWithoutBraces =
            noneOption?.startsWith('{{') && noneOption?.endsWith('}}')
              ? noneOption?.substring(2, noneOption?.length - 2)
              : noneOption
          return `${engineEval} === null ? (${noneOptionWithoutBraces} || '') : renderOption('${varName}', ${engineEval}) `
        })(),
        amount: `renderAmount(${engineEval})`,
        percent: `renderPct(${engineEval})`,
        date: `renderDate(${engineEval})`,
      }

      inputOuts = [
        input({
          type: 'text',
          disabled: true,
          className: classes.join(' '),
          placeholder: placeholder,
          extra: {
            ngValue: ngValues[varDef.datatype] ?? engineEval,
            ngClick: attr.ngClick,
          },
        }),
      ]
      addonPart = addonParts[varDef.datatype]
      break
    default:
      console.log(varName, varDef)
      throw new Error(`(datatype,vartype) = (${varDef.datatype},${varDef.vartype}) not handled`)
  }
  return [inputOuts, addonPart]
}

function determineAttributes(
  varName: string,
  varDef: computationEngine.VariableDef,
  ngDisabled: string | undefined,
  ucwordsBrandMinLength: number,
  cvrCprConfig: any,
  ngPercentageValueStr: string,
): { required?: boolean; disabled?: boolean; extra: { [name: string]: string } } {
  const attrs: { required?: boolean; disabled?: boolean; extra: { [name: string]: string } } = { extra: {} }
  if (varDef.disabled === true) {
    attrs.disabled = true
  } else if (typeof ngDisabled !== 'undefined') {
    attrs.extra.ngDisabled = `(!(forceEnabled && forceEnabled.${varName} === true) && formIsDisabled) || (${ngDisabled})`
  } else {
    attrs.extra.ngDisabled = `(!(forceEnabled && forceEnabled.${varName} === true) && formIsDisabled)`
  }

  switch (varDef.datatype) {
    case 'percent':
      attrs.extra.ngPercentage = ngPercentageValueStr
      break
    case 'amount':
    case 'count':
      attrs.extra.ngInteger = ''
      break
    case 'postnr':
      attrs.extra.ngPostnr = `${varName}`
      break
    case 'singledecimal':
      attrs.extra.ngDecimal = '1'
      break
    case 'decimal':
      attrs.extra.ngDecimal = '2'
      break
    case 'ucwords-brand':
      attrs.extra.ngUcwordsBrand = `${ucwordsBrandMinLength}`
      if (varDef.maxLength !== undefined) {
        attrs.extra.ngMaxlength = `${varDef.maxLength}`
      }
      break
    case 'bank-regnr':
    case 'bank-kontonr':
    case 'leasingkontraktnr':
    case 'digits':
    case 'telefon':
    case 'cpr':
    case 'cvr':
    case 'cpr-cvr':
    case 'registreringsnummer':
    case 'stelnummer':
    case 'ucwords':
    case 'year':
    case 'uppercase':
      attrs.extra[`ng-${varDef.datatype}`] = ''
      if (varDef.maxLength !== undefined) {
        attrs.extra.ngMaxlength = `${varDef.maxLength}`
      }
      if ((varDef.datatype == 'cvr' || varDef.datatype == 'cpr') && !!cvrCprConfig) {
        attrs.extra.config = cvrCprConfig
      }
      break
    case 'enum':
    default:
      break
  }

  if (varDef.validConditionExpr !== undefined) {
    attrs.extra.ngValidCondition = `engine.eval('${varName}.validCondition', true)`
  }

  const requireArr = []
  if (varDef.inUseExpr !== undefined) {
    requireArr.push(`engine.eval('${varName}.inUse')`)
  }
  if (varDef.requireExpr !== undefined) {
    requireArr.push(`engine.eval('${varName}.require')`)
  }
  if (varDef.ngRequired !== undefined) {
    requireArr.push(`(${varDef.ngRequired})`)
  }

  if (isVarDefRequired(varDef)) {
    attrs.required = true
  } else if (requireArr.length > 0) {
    attrs.extra.ngRequired = requireArr.join(' && ')
  }

  return attrs
}

function isVarDefRequired(varDef: computationEngine.VariableDef) {
  switch (varDef.datatype) {
    case 'percent':
    case 'amount':
    case 'count':
      return varDef.required !== false
    case 'singledecimal':
    case 'decimal':
      return true
    default:
      return Boolean(varDef.required)
  }
}
