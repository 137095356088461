import { dateToYMD } from '../functions/dates'

import angular from 'angular'

export const inspectCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  'angularLoad',
  '$q',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, angularLoad, $q, $interop) {
    angularLoad.loadScript('/assets/javascripts/vendor/modules/diff_match_patch_uncompressed.js').then(function () {
      angularLoad.loadScript('/assets/javascripts/vendor/modules/jsondiffpatch.min.js').then(function () {
        angularLoad.loadScript('/assets/javascripts/vendor/modules/jsondiffpatch-formatters.min.js')
      })
    })
    angularLoad.loadCSS('/assets/stylesheets/jsondiffpatch.annotated.css')
    angularLoad.loadCSS('/assets/stylesheets/jsondiffpatch.html.css')
    /* <script type="text/javascript" src="build/bundle.min.js"></script>
          <script type="text/javascript" src="build/formatters.min.js"></script>
          <link rel="stylesheet" href="src/formatters/html.css" type="text/css" />
          <link rel="stylesheet" href="src/formatters/annotated.css" type="text/css" />*/
    $scope.options = {}
    $scope.options.left = []
    $scope.options.right = []
    $scope.id = $stateParams.id
    $scope.comparisonActive = false
    $http.post('/api/application/get', { id: parseInt($scope.id, 10) }).success(function (application) {
      $scope.application = application
      $scope.options.left = []
      $scope.options.right = []
      if (application.values !== undefined) {
        $scope.left = 'locked'
        var obj = { value: 'locked', option: 'Beregning ved låsning' }
        $scope.options.left.push(obj)
        $scope.options.right.push(obj)
        $http.post('/api/application/get', {}).success(function (application2) {
          $scope.serverVars = application2.serverVars
          $scope.defs = new $interop.ComputationEngine({}, $scope.serverVars).getVariableDefs()
        })
      } else {
        $scope.left = 'current'
        $scope.serverVars = application.serverVars
      }
      var obj = { value: 'current', option: 'Aktiv beregningsmotor' }
      $scope.options.left.push(obj)
      $scope.options.right.push(obj)

      $http.post('/api/util/getAllCommits').success(function (res) {
        $scope.right = res[0].sha
        for (var i in res) {
          var obj = {
            value: res[i].sha,
            option: res[i].commit.committer.date + ' - ' + res[i].commit.message,
          }
          $scope.options.left.push(obj)
          $scope.options.right.push(obj)
        }
      })
    })

    var getVariableDefs = function (CE) {
      var modelVars = {}
      var serverVars = $scope.serverVars
      var engine = new CE(modelVars, serverVars)
      return engine.getVariableDefs()
    }

    var prepareValues = function (defs, values) {
      var out = {}
      for (var field in values) {
        var temp = field.split('.')
        if (values[field] != '' && defs[temp[0]].datatype == 'date') {
          out[field] = dateToYMD(new Date(values[field]))
        } else if (values[field] != '' && defs[temp[0]].datatype == 'year') {
          out[field] = dateToYMD(new Date(values[field])).substring(0, 4)
        } else if (values[field] != '' && defs[temp[0]].datatype == 'amount') {
          out[field] = (0 + values[field]).toFixed(0)
        } else if (values[field] != '' && defs[temp[0]].datatype == 'percent') {
          out[field] = (0 + values[field]).toFixed(4)
        } else {
          out[field] = values[field]
        }
      }
      return out
    }

    var computeValues = function (CE) {
      var modelVars = {}
      var serverVars = $scope.serverVars
      var engine = new CE(modelVars, serverVars)
      var defs = engine.getVariableDefs()
      engine.resetComputation()

      var values = $scope.application.modelVars
      for (var field in values) {
        if (defs[field] !== undefined) {
          if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
            if (values[field] === null) {
              modelVars[field] = null
            } else {
              modelVars[field] = new Date(values[field])
            }
          } else {
            modelVars[field] = values[field] // håndtér datoer etc
          }
          if (field == 'insuranceCompanyId' && modelVars[field] == 0) {
            modelVars[field] = null
          }
        }
      }
      engine.clearExpressionCache()
      return prepareValues(defs, engine.getValues())
    }

    $scope.doComparison = function () {
      var dleft = $q.defer()
      if ($scope.left == 'locked') {
        $scope.leftValues = prepareValues($scope.defs, $scope.application.values)
        $scope.leftDefs = {}
        dleft.resolve(true)
      } else if ($scope.left == 'current') {
        $scope.leftValues = computeValues(ComputationEngine)
        $scope.leftDefs = getVariableDefs(ComputationEngine)
        dleft.resolve(true)
      } else {
        angularLoad.loadScript('/api/util/getComputationEngine/' + $scope.left).then(function () {
          $scope.leftValues = computeValues(computationEngines[$scope.left])
          $scope.leftDefs = getVariableDefs(computationEngines[$scope.left])
          dleft.resolve(true)
        })
      }

      var dright = $q.defer()
      if ($scope.right == 'locked') {
        $scope.rightValues = prepareValues($scope.defs, $scope.application.values)
        dright.resolve(true)
      } else if ($scope.right == 'current') {
        $scope.rightValues = computeValues(ComputationEngine)
        $scope.rightDefs = getVariableDefs(ComputationEngine)
        dright.resolve(true)
      } else {
        angularLoad.loadScript('/api/util/getComputationEngine/' + $scope.right).then(function () {
          $scope.rightValues = computeValues(computationEngines[$scope.right])
          $scope.rightDefs = getVariableDefs(computationEngines[$scope.right])
          dright.resolve(true)
        })
      }

      $q.all([dleft.promise, dright.promise]).then(function (data) {
        jsondiffpatch.formatters.html.hideUnchanged()

        $scope.delta = jsondiffpatch.diff($scope.leftValues, $scope.rightValues)
        //console.log(jsondiffpatch);
        $scope.visual = jsondiffpatch.formatters.html.format($scope.delta, $scope.leftValues)
        $scope.delta2 = jsondiffpatch.diff($scope.leftDefs, $scope.rightDefs)
        //console.log($scope.leftDefs)
        //console.log($scope.rightDefs)
        //console.log(jsondiffpatch);
        $scope.visual2 = jsondiffpatch.formatters.html.format($scope.delta2, $scope.leftDefs)
      })

      $scope.comparisonActive = true
    }
  },
]
