import { CVR_REGEXP } from '../regexes'
import { cvrLookupMappings, companyCallback } from '../functions/cvrcprLookupMappings'
import {
  IAttributes,
  IDirective,
  IHttpService,
  INgModelController,
  IScope
} from "angular";

interface CvrAttrs extends IAttributes {
  config: string
}

export const ngCvr = [
  '$http',
  function ($http: IHttpService): IDirective<IScope, JQLite, CvrAttrs, INgModelController> {
    return {
      require: 'ngModel',

      link(scope: IScope, elm: JQLite, attrs: CvrAttrs, ctrl?: INgModelController) {
        if (!ctrl) {
          throw new Error("Controller not present")
        }

        const config = scope.$eval(attrs.config)
        ctrl.$parsers.unshift(function (viewValue) {
          viewValue = viewValue + ''

          var valid = CVR_REGEXP.test(viewValue)
          if (valid) {
            ctrl.$setValidity('cvr', true)
            if (config) {
              $http.post(`/api/registration/companyAddress/${viewValue}`, {}).then(function (res) {
                companyCallback(scope, res.data, config)
              })
            }
            return viewValue
          } else if (viewValue == '') {
            ctrl.$setValidity('cvr', true)
            return null
          } else {
            ctrl.$setValidity('cvr', false)
            return undefined
          }
        })
      },
    }
  },
]
