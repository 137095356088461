
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/season-admin-ctrl.html";
      const src = "<a ng-href=\"/application/{{applicationRow.modelVars.id}}\" class=\"btn btn-default pull-right\"\n  ><i class=\"fa fa-arrow-left\"></i> Gå til ansøgning</a\n>\n\n<h3>Sæsonadministration</h3>\n<hr />\n\n<div class=\"row\">\n  <div class=\"col-md-6\">\n    <dl class=\"dl-horizontal\">\n      <dt>Leasingtager</dt>\n      <dd>{{applicationRow.values.navn}}</dd>\n      <dt>Mærke/model</dt>\n      <dd>{{applicationRow.values.maerkeModel}}</dd>\n      <dt>Produkt</dt>\n      <dd>{{products[applicationRow.values.productId].name}}</dd>\n      <dt>Betalingstype</dt>\n      <dd>\n        {{applicationRow.values.betalingstype}} ({{applicationRow.values.betalingstype == 0 ? 'Ved månedens afslutning'\n        : 'Ved månedens start'}})\n      </dd>\n    </dl>\n  </div>\n\n  <div class=\"col-md-6\">\n    <p>\n      <b>Intern note til sæsonaftale</b>\n    </p>\n    <p>\n      <textarea\n        class=\"form-control\"\n        rows=\"5\"\n        ng-model=\"self.seasonNote\"\n        ng-change=\"self.seasonNoteDirty = true\"\n        ng-disabled=\"self.seasonNoteLoading && !self.seasonNoteDirty\"\n      ></textarea>\n    </p>\n    <p>\n      <span ng-if=\"self.seasonNoteDirty\"> Husk at gemme! </span>\n      <button class=\"btn btn-default pull-right\" ng-click=\"saveNote()\">\n        <i class=\"fa fa-sticky-note\"></i> Gem note\n      </button>\n    </p>\n  </div>\n</div>\n\n<hr />\n\n<div\n  ng-if=\"applicationRow && contractRes\"\n  dkkap-season-admin\n  application-row=\"applicationRow\"\n  contract-res=\"contractRes\"\n  reload-data=\"reloadData\"\n  create=\"create\"\n  update=\"update\"\n></div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      