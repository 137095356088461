export const helpItemCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  function ($scope, $rootScope, $http, $stateParams, $location, $state) {
    $scope.setActiveCategoryId(
      $stateParams.categoryId === undefined ? undefined : parseInt($stateParams.categoryId, 10),
    )
    $scope.setActiveId($stateParams.id === undefined ? undefined : parseInt($stateParams.id, 10))

    $http.post('/api/article/get/' + $scope.id).success(function (res) {
      $scope.row = res.row
    })
  },
]
