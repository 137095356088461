import { dateToYMD } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'

export const formFinancingPartnerCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount
    $scope.options = {}
    $scope.options.jaNej = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]

    $scope.variableDefs = {
      financingPartnerCode: {
        friendly: 'Finansieringspartnerkode',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      baseRate: {
        friendly: 'Basisrente',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      administrationFee: {
        friendly: 'Administrationsgebyr',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      handlingFeeAllMonths: {
        friendly: 'Håndteringsgebyr der tillægges ydelse',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      handlingFeePurchase: {
        friendly: 'Håndteringsgebyr der medfinansieres',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      service: {
        friendly: 'Service',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      summerTires: {
        friendly: 'Sommerdæk',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      winterTires: {
        friendly: 'Vinterdæk',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      replacementVehicle: {
        friendly: 'Erstatningskøretøj',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      recomputationFeeCar: {
        friendly: 'Genberegningsgebyr, bil',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      recomputationFeeVan: {
        friendly: 'Genberegningsgebyr, varevogn',
        datatype: 'amount',
        vartype: 'model',
        required: true,
      },
      serviceRegPct: {
        friendly: 'Service reg.',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      tiresRegPct: {
        friendly: 'Dæk reg.',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      defaultFinancingPartnerCvr: {
        friendly: 'Std. finansieringspartner CVR',
        vartype: 'model',
      },
      months: {
        friendly: 'Løbetid',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      fixedRateMargin: {
        friendly: 'Dæk reg.',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
      cvr: {
        friendly: 'CVR',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      externalCreditorNo: {
        friendly: 'Eksternt kreditornummer',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      externalCreditorNoPrt: {
        friendly: 'Eksternt kreditornummer, FRA',
        datatype: 'text',
        vartype: 'model',
        required: false,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Finansieringspartneren blev gemt.')
      if (res == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/financingPartner/edit/' + res.financingPartnerCode)
      if ($scope.id !== undefined) {
        loadFinancingPartner()
      }
    }
    $scope.addEntry = function () {
      $scope.vars.financingPartnerMonths.push({ months: 0, fixedRateMargin: 0 })
    }
    $scope.removeEntry = function () {
      $scope.vars.financingPartnerMonths.pop()
    }

    $scope.addFinancingPartnerCompanyEntry = function () {
      $scope.vars.financingPartnerCompany.push({ cvr: '', name: '', externalCreditorNo: '', externalCreditorNoPrt: '' })
    }
    $scope.removeFinancingPartnerCompanyEntry = function (key) {
      $scope.vars.financingPartnerCompany.splice(key, 1)
    }

    $scope.modelVars = {} // dummy
    $scope.id = $stateParams.id === undefined ? undefined : $stateParams.id
    $scope.setActiveId($scope.id)

    var loadFinancingPartner = function () {
      $http.post('/api/financingPartner/get/' + $scope.id).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }
        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.save = function (saveAsNew) {
        $http.post('/api/financingPartner/edit/' + $scope.id, $scope.vars).success(saveSuccess)
      }
      $scope.delete = function () {
        $http.post('/api/financingPartner/delete/' + $scope.id).success(function () {
          $scope.loadAll()
          $location.url('/controlpanel/financingPartner')
        })
      }
      loadFinancingPartner()
    } else {
      $scope.vars = {}
      $scope.vars.financingPartnerMonths = []
      $scope.vars.financingPartnerCompany = []
      $scope.vars.row = {
        name: '',
        financingPartnerCode: '',
        purchasedAsNew: false,
        purchasedAsUsed: false,
        usePvCorrection: false
      }

      $scope.save = function () {
        $http.post('/api/financingPartner/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
