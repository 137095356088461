import creditAnalysisAnnualReportTemplate from './creditAnalysisAnnualReport.html'
import { IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import { formatNumber, formatDate } from '../functions/intlFormaters'

interface CreditAnalysisAnnualReportScope extends IScope {
  data: any
  cvr: any
  annualReports: any[]
  ownershipPercentageStr: (x: string) => string
  formatNumber: typeof formatNumber
  formatDate: typeof formatDate
  errors: any
  errorsStrs: string | null
}

export const creditAnalysisAnnualReport = [
  function (): IDirective<CreditAnalysisAnnualReportScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisAnnualReportTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        buttonState: '=',
      },

      link: function ($scope: CreditAnalysisAnnualReportScope, elm: JQLite, attrs: IAttributes) {
        function update() {
          const dataBase = $scope.data?.data?.creditlinkCaseByExternalId?.other?.at(0)

          $scope.cvr = dataBase?.cvrDataStatus?.cvrData

          $scope.annualReports = dataBase
            ? dataBase.annualReports.slice(-5, dataBase.annualReports.length).reverse()
            : []
          $scope.errors = $scope.data?.errors
          $scope.errorsStrs = $scope.errors?.map((x: any) => x.message)?.join(', ')
        }

        $scope.ownershipPercentageStr = (x) => {
          switch (x) {
            case 'OWNERSHIP_0_05':
              return 'Op til 5,00%'
            case 'OWNERSHIP_0_1':
              return '5,01% - 10,00%'
            case 'OWNERSHIP_0_15':
              return '10,01% - 15,00%'
            case 'OWNERSHIP_0_2':
              return '15,01% - 20,00%'
            case 'OWNERSHIP_0_25':
              return '20,01% - 25,00%'
            case 'OWNERSHIP_0_3333':
              return '25,01% - 33,33%'
            case 'OWNERSHIP_0_5':
              return '33,34% - 50,00%'
            case 'OWNERSHIP_0_6667':
              return '50,01% - 66,67%'
            case 'OWNERSHIP_0_9':
              return '66,68% - 90,00%'
            case 'OWNERSHIP_1_0':
              return '90,01% - 100,00%'
          }

          return x
        }

        $scope.formatNumber = formatNumber
        $scope.formatDate = formatDate

        $scope.$watch('data', update)
        update()
      },
    }
  },
]
