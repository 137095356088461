import creditAnalysisRkiTemplate from './creditAnalysisRki.html'
import { IAttributes, IDirective, IScope, ITimeoutService } from 'angular'
import { formatDate, formatNumber } from '../functions/intlFormaters'

interface CreditAnalysisRkiScope extends IScope {
  formatDate: typeof formatDate
  formatNumber: typeof formatNumber
  unixTime: (n: number) => string | null
  data: any
  rkiData: any
  errors: any
  errorsStrs: string | null
}

export const creditAnalysisRki = [
  function (): IDirective<CreditAnalysisRkiScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisRkiTemplate,
      scope: {
        create: '&',
        refresh: '&',
        createAndRefresh: '&',
        data: '=',
        buttonState: '=',
      },

      link: function ($scope: CreditAnalysisRkiScope, elm: JQLite, attrs: IAttributes) {
        $scope.formatDate = formatDate
        $scope.formatNumber = formatNumber

        function listIfNotNull<A>(x: A | null): A[] | null {
          if (typeof x !== 'undefined' && x !== null) {
            return [x]
          } else {
            return null
          }
        }

        function update() {
          $scope.rkiData = $scope.data?.data?.creditlinkCaseByExternalId?.other?.at(0)?.badDebtStatus
          $scope.errors = $scope.data?.errors ?? listIfNotNull($scope.rkiData?.error)
          $scope.errorsStrs = $scope.errors?.map((x: any) => x.message)?.join(', ')
        }

        update()
        $scope.$watch('data', update)
        $scope.unixTime = function (num) {
          const intl = Intl.DateTimeFormat('da', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })

          return intl.format(new Date(num))
        }
      },
    }
  },
]
