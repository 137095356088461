import { errorCount } from '../functions/validationErrors'
export const formArticleCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $state, $interop) {
    $scope.selectedProducts = {}

    $scope.errorCount = errorCount
    $scope.modelVars = {}
    var tempId = parseInt($stateParams.id, 10)
    var tempArticleId = parseInt($stateParams.articleId, 10)

    // NaN håndtering
    if (tempId !== tempId) tempId = undefined
    if (tempArticleId !== tempArticleId) tempArticleId = undefined

    $scope.setActiveId(tempId)
    $scope.setActiveArticleId(tempArticleId)
    $scope.variableDefs = {
      title: {
        friendly: 'Titel',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      content: {
        friendly: 'Indhold',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      sort: {
        friendly: 'Sorteringstal',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      icon: {
        friendly: 'Ikon',
        datatype: 'text',
        vartype: 'model',
        required: false,
        fieldConditions: {
          requiredFeature: ['article.icon']
        },
      },
      showCustomerApp: {
        friendly: 'Vis i vejledning på kunde side',
        datatype: 'boolean',
        vartype: 'mode',
        required: false,
        default: false,
      },
    }
    $http.post('/api/articleCategory/getAll').success(function (res) {
      $scope.articleCategories = res
      if (
        $scope.vars !== undefined &&
        $scope.vars.row !== undefined &&
        $scope.vars.row.articleCategoryId === undefined
      ) {
        $scope.vars.row.articleCategoryId = res[0].id
      }
    })

    $http.post('/api/product/getAll').success(function (res) {
      $scope.products = res
    })
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Artiklen blev gemt.')
      if (parseInt(res, 10) == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/article/edit/' + $scope.id + '/' + res)
      if ($scope.articleId !== undefined) {
        loadArticle()
      }
    }

    $scope.modelVars = {} // dummy

    var loadArticle = function () {
      $http.post('/api/article/get/' + $scope.articleId).success(function (res) {
        $scope.vars = res
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }

        $scope.selectedProducts = res.products

        $scope.formIsLoaded = true
      })
    }

    if ($scope.articleId !== undefined) {
      $scope.deactivate = function () {
        $http.post('/api/article/deactivate/' + $scope.articleId).success(function (res) {
          $scope.setGlobalAlert('Artiklen blev deaktiveret.')
          loadArticle()
          $scope.loadAll()
        })
      }
      $scope.activate = function () {
        $http.post('/api/article/activate/' + $scope.articleId).success(function (res) {
          $scope.setGlobalAlert('Artiklen blev genaktiveret.')
          loadArticle()
          $scope.loadAll()
        })
      }
      $scope.save = function (saveAsNew) {
        if (saveAsNew) {
          $http.post('/api/article/add', $scope.vars).success(saveSuccess)
        } else {
          $http.post('/api/article/edit/' + $stateParams.articleId, $scope.vars).success(saveSuccess)
        }
      }
      loadArticle()
    } else {
      $scope.vars = {
        products: $scope.selectedProducts,
      }
      $scope.vars.row = {
        title: '',
        content: '',
        sort: 0,
      }
      if ($scope.articleCategories !== undefined) {
        $scope.vars.row.articleCategoryId = $scope.articleCategories[0].id
      }

      $scope.save = function () {
        if ($interop.module !== 'perb' && $scope.vars.showCustomerApp === undefined) {
          $scope.vars.row.showCustomerApp = false
        }
        $http.post('/api/article/add', $scope.vars).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
