export const registerRkiCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  '$state',
  '$interop',
  function ($scope, $rootScope, $http, $stateParams, $location, $state, $interop) {
    $scope.options = {}
    $scope.modelVars = { kundetype: false, cvr: '', cpr: '' }
    $scope.options.type = [
      { value: false, option: 'Privatkunde' },
      { value: true, option: 'Erhvervskunde' },
    ]
    $scope.showRkiRegistrations = false
    $scope.rkiRegistrations = {}
    $scope.query = function () {
      $scope.showRkiRegistrations = false
      $scope.rkiRegistrations = {}
      if ($interop.specific.registerRkiCtrl.makeCalls) {
        if ($scope.modelVars.kundetype === false) {
          $http.post('/api/registration/personRegistrationForce/' + $scope.modelVars.cpr).success(function (obj) {
            $scope.rkiRegistrations[$scope.modelVars.cpr] = obj
            $scope.showRkiRegistrations = true
          })
        } else {
          $http.post('/api/registration/companyRegistrationForce/' + $scope.modelVars.cvr).success(function (obj) {
            $scope.rkiRegistrations[$scope.modelVars.cvr] = obj
            $scope.showRkiRegistrations = true
          })
        }
      }
    }
  },
]
