import { rerenderViewValue } from '../functions/rerenderViewValue'
import { ucwordsImproved } from '../functions/ucwords'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngUcwords(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        return ucwordsImproved(viewValue === undefined ? '' : viewValue + '')
      })
      elm.bind('blur', function () {
        rerenderViewValue(ctrl.$modelValue, ctrl)
      })
    },
  }
}
