import { dateToYMD } from '../functions/dates'
import { errorCount } from '../functions/validationErrors'
import { danishLocaleNumber } from '../functions/numbers'

export const formDepreciationMatrixCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$stateParams',
  '$location',
  function ($scope, $rootScope, $http, $stateParams, $location) {
    $scope.errorCount = errorCount
    $scope.options = {}
    $scope.options.assetType = [
      { value: 0, option: 'Bil' },
      { value: 1, option: 'Fabriksmonteret udstyr' },
      { value: 2, option: 'Eftermonteret udstyr' },
    ]

    $scope.variableDefs = {
      assetType: {
        friendly: 'Type',
        datatype: 'count',
        vartype: 'model',
        required: true,
      },
      name: {
        friendly: 'Navn',
        datatype: 'text',
        vartype: 'model',
        required: true,
      },
      pct: {
        friendly: 'Afskrivningsprocent',
        datatype: 'percent',
        vartype: 'model',
        required: true,
      },
    }
    var saveSuccess = function (res) {
      $scope.loadAll()
      $scope.setGlobalAlert('Restværdimatricen blev gemt.')
      if (res == $scope.id) {
        $scope.setKeepAlert(false)
      }
      $location.url('/controlpanel/depreciationMatrix/edit/' + res.depreciationMatrixId)
      if ($scope.id !== undefined) {
        loadDepreciationMatrix()
      }
    }

    $scope.renderValue = function (value) {
      return danishLocaleNumber(Math.ceil(value).toString())
    }

    $scope.modelVars = {}
    $scope.vars = {}
    $scope.id = $stateParams.id === undefined ? undefined : $stateParams.id
    $scope.setActiveId($scope.id)
    $scope.monthColumns = [12, 24, 36, 48, 60, 72]
    $scope.annualMileages = [15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000]
    $scope.monthColumnsIndex = {}
    $scope.annualMileagesIndex = {}

    for (const i in $scope.monthColumns) {
      $scope.monthColumnsIndex[$scope.monthColumns[i]] = i
    }
    for (const i in $scope.annualMileages) {
      $scope.annualMileagesIndex[$scope.annualMileages[i]] = i
    }
    var loadDepreciationMatrix = function () {
      $http.post('/api/depreciationMatrix/get/' + $scope.id).success(function (res) {
        for (var i in res.row) {
          $scope.modelVars[i] = res.row[i]
        }
        $scope.vars.depreciationMatrixItem = [[], [], [], [], [], [], [], [], [], [], []]

        for (const monthColumn in $scope.monthColumns) {
          for (const annualMileage in $scope.annualMileages) {
            $scope.vars.depreciationMatrixItem[annualMileage][monthColumn] = null
          }
        }

        for (var i of res.depreciationMatrixItems) {
          $scope.vars.depreciationMatrixItem[$scope.annualMileagesIndex[i.annualMileage]][
            $scope.monthColumnsIndex[i.months]
          ] = i.pct
        }
        $scope.formIsLoaded = true
      })
    }

    if ($scope.id !== undefined) {
      $scope.save = function () {
        var dbDepreciationMatrixItem = []
        for (let i = 0; i < $scope.monthColumns.length; i++) {
          for (let j = 0; j < $scope.annualMileages.length; j++) {
            dbDepreciationMatrixItem.push({
              months: $scope.monthColumns[i],
              annualMileage: $scope.annualMileages[j],
              pct: $scope.vars.depreciationMatrixItem[j][i],
            })
          }
        }
        const req = {
          row: {
            assetType: $scope.modelVars.assetType,
            name: $scope.modelVars.name,
          },
          depreciationMatrixItem: dbDepreciationMatrixItem,
        }
        $http.post('/api/depreciationMatrix/edit/' + $scope.id, req).success(saveSuccess)
      }
      $scope.delete = function () {
        $http.post('/api/depreciationMatrix/delete/' + $scope.id).success(function () {
          $scope.loadAll()
          $location.url('/controlpanel/depreciationMatrix')
        })
      }
      loadDepreciationMatrix()
    } else {
      $scope.vars = {}

      $scope.vars.depreciationMatrixItem = [[], [], [], [], [], [], [], [], [], [], []]
      for (const monthColumn in $scope.monthColumns) {
        for (const annualMileage in $scope.annualMileages) {
          $scope.vars.depreciationMatrixItem[annualMileage][monthColumn] = 0
        }
      }
      $scope.vars.row = {
        assetType: 0,
        name: '',
      }

      $scope.save = function () {
        var dbDepreciationMatrixItem = []
        for (let i = 0; i < $scope.monthColumns.length; i++) {
          for (let j = 0; j < $scope.annualMileages.length; j++) {
            dbDepreciationMatrixItem.push({
              months: $scope.monthColumns[i],
              annualMileage: $scope.annualMileages[j],
              pct: $scope.vars.depreciationMatrixItem[j][i],
            })
          }
        }
        const req = {
          row: {
            assetType: $scope.modelVars.assetType,
            name: $scope.modelVars.name,
          },
          depreciationMatrixItem: dbDepreciationMatrixItem,
        }
        $http.post('/api/depreciationMatrix/add', req).success(saveSuccess)
      }
      $scope.formIsLoaded = true
    }
  },
]
