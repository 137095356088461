
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/news.html";
      const src = "<div class=\"row\" ng-show=\"globalAlert\">\n  <div class=\"col-xs-12 center-alert\"><span>{{globalAlert}}</span></div>\n</div>\n\n<div class=\"page-header\">\n  <h3>Nyheder</h3>\n</div>\n\n<div class=\"row\">\n  <div class=\"col-sm-4\">\n    <h3></h3>\n\n    <ul class=\"nav nav-pills nav-stacked\">\n      <li ng-repeat=\"item in list\" ng-class=\"{'active' : activeMonth == item.id,  'deactivated': item.deactivated}\">\n        <a ng-href=\"/news/show/{{item.id}}/{{newsList[item.id][0].id}}\">{{item.name}}</a>\n        <ul ng-show=\"activeMonth == item.id && newsList[item.id] !== undefined && (newsList[item.id]).length\">\n          <li\n            ng-repeat=\"newsItem in newsList[item.id]\"\n            ng-class=\"{'active' : activeId == newsItem.id,  'deactivated': newsItem.deactivated}\"\n          >\n            <a href=\"/news/show/{{item.id}}/{{newsItem.id}}\"\n              >{{renderTimestamp(newsItem.published)}}: {{newsItem.title}}</a\n            >\n          </li>\n        </ul>\n      </li>\n    </ul>\n    <hr />\n  </div>\n  <div class=\"col-sm-8\" ui-view></div>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      