import { dateToYMD, dateToDanish } from '../functions/dates'
import { danishLocaleNumber } from '../functions/numbers'

export const formRecalculationCtrl = [
  '$scope',
  '$stateParams',
  '$http',
  '$location',
  '$q',
  '$interop',
  function ($scope, $stateParams, $http, $location, $q, $interop) {
    $scope.options = {}

    $scope.save = function () {
      var temp = $scope.engine.getValues()
      for (var v in temp) {
        var d = temp[v]
        temp[v] = d instanceof Date ? dateToYMD(d) : d
      }
      temp.applicationId = $scope.applicationId
      var json = JSON.stringify(temp)

      if ($scope.id === undefined) {
        $http.post('/api/recalculation/add', json).success(function (recalculationId) {
          $location.url('/recalculation/' + $scope.applicationId + '/' + recalculationId)
        })
      } else {
        $http.post('/api/recalculation/edit/' + $scope.id, json).success(function () {
          reload()
        })
      }
    }

    $scope.options.fastPeriode = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]

    var getDefaultVariableDefs = function () {
      var tempModelVars = {}
      var tempServerVars = {}
      var defaultEngine = new $interop.ComputationEngine(
        tempModelVars,
        tempServerVars,
        function () {
          return $scope.userInfo.id
        },
        function () {
          return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
        },
        function () {
          return 0
        },
      )

      return defaultEngine.getVariableDefs()
    }

    var getFail = function (msg) {
      $scope.setGlobalAlert(msg)
      $location.url('/')
    }

    var getPreparedValues = function (defs, res) {
      if (res.values === undefined) {
        getFail('Genberegning kan kun foretages på godkendte sager')
      }

      var values = res.values
      var preparedValues = {}
      for (var field in values) {
        if (defs[field] !== undefined) {
          if (defs[field].datatype == 'date' || defs[field].datatype == 'year') {
            preparedValues[field] = new Date(values[field])
          } else {
            preparedValues[field] = values[field] // håndtér datoer etc
          }
          if (field == 'insuranceCompanyId' && preparedValues[field] == 0) {
            preparedValues[field] = null
          }
        }
      }

      return preparedValues
    }

    var id = undefined

    if ($stateParams.id !== undefined) {
      id = parseInt($stateParams.id, 10)
    }

    var applicationId = parseInt($stateParams.applicationId, 10)
    if (applicationId === undefined) {
      getFail('Ansøgningsnummer ikke angivet')
    }
    var applicationParam = { id: applicationId }
    $scope.applicationId = applicationId
    $scope.id = id

    var serverVars = {}
    var modelVars = {}

    var defaultDefs = getDefaultVariableDefs()

    $scope.options = {}
    $scope.options.fastPeriode = [
      { value: false, option: 'Nej' },
      { value: true, option: 'Ja' },
    ]

    var variableDefs = {
      handelspris: {
        vartype: 'model',
        datatype: 'amount',
        defaultValue: 0,
      },
      nypris: {
        vartype: 'model',
        datatype: 'amount',
        defaultValue: 0,
      },
      fastPeriode: {
        vartype: 'model',
        defaultValue: false,
      },
      forsikring: {
        vartype: 'model',
        datatype: 'text',
        defaultValue: '',
      },

      glKronekontraktnr: {
        vartype: 'model',
        datatype: 'text',
        defaultValue: '',
      },
      nyKronekontraktnr: {
        vartype: 'model',
        datatype: 'text',
        defaultValue: '',
      },

      restgaeld: {
        friendly: 'Restgæld jf. NAV, brutto',
        vartype: 'model',
        datatype: 'amount',
        defaultValue: 0,
      },
      godtgoerelseSkat: {
        friendly: 'Godtgørelse Skat',
        vartype: 'model',
        datatype: 'amount',
        defaultValue: 0,
      },
      restgaeldNetto: {
        friendly: 'Restgæld NAV, netto',
        vartype: 'expression',
        datatype: 'amount',
        expression: 'restgaeld - godtgoerelseSkat',
      },
      dokumentgebyrReg: { vartype: 'model', datatype: 'amount', defaultValue: 0 },
      dokumentgebyrFinal: { vartype: 'expression', datatype: 'amount', expression: 'dokumentgebyr + dokumentgebyrReg' },
      deklarationForsikringReg: { vartype: 'model', datatype: 'amount', defaultValue: 0 },
      deklarationForsikringFinal: {
        vartype: 'expression',
        datatype: 'amount',
        expression: 'deklarationForsikring + deklarationForsikringReg',
      },
      gebyrKroneGenberegningReg: { vartype: 'model', datatype: 'amount', defaultValue: 0 },
      gebyrKroneGenberegningFinal: {
        vartype: 'expression',
        datatype: 'amount',
        expression: 'gebyrKroneGenberegning + gebyrKroneGenberegningReg',
      },
      gebyrOmregNummerpladerReg: { vartype: 'model', datatype: 'amount', defaultValue: 0 },
      gebyrOmregNummerpladerFinal: {
        vartype: 'expression',
        datatype: 'amount',
        expression: 'gebyrOmregNummerplader + gebyrOmregNummerpladerReg',
      },
      restvaerdiReg: { vartype: 'model', datatype: 'amount', defaultValue: 0 },
      restvaerdiFinal: { vartype: 'expression', datatype: 'amount', expression: 'restvaerdi + restvaerdiReg' },
      renteReg: { vartype: 'model', datatype: 'percent', defaultValue: 0 },
      renteFinal: { vartype: 'expression', datatype: 'percent', expression: 'rente + renteReg' },
      renteRegAfgiftPctReg: { vartype: 'model', datatype: 'percent', defaultValue: 0 },
      renteRegAfgiftPctFinal: {
        vartype: 'expression',
        datatype: 'percent',
        expression: 'renteRegAfgiftPct + renteRegAfgiftPctReg',
      },

      restloebetid: {
        friendly: 'Restløbetid, md.',
        vartype: 'model',
        datatype: 'count',
        defaultExpr: 'loebetid - mdrSidenLevering',
      },
      nyleveringsdato: {
        friendly: 'Ny leveringsdato',
        vartype: 'model',
        datatype: 'date',
        required: true,
        defaultExpr: 'addDays(addMonths(leveringsdato, mdrSidenLevering),1)',
      },
      nyIndregistreretFoersteGang: {
        friendly: 'Ny indregistreret første gang',
        vartype: 'model',
        datatype: 'date',
        required: true,
        defaultExpr: 'indregistreretFoersteGangBeregnet',
      },
      udloebsdato: {
        friendly: 'Udløbsdato',
        vartype: 'model',
        datatype: 'date',
        required: true,
        defaultExpr: 'addDays(addMonths(leveringsdato, loebetid),1)',
      },
      nyVurderetRegAfgift: {
        friendly: 'Ny registreringsafgift',
        vartype: 'model',
        datatype: 'amount',
        defaultValue: 0,
      },
      nyhovedstol: {
        friendly: 'Ny hovedstol',
        vartype: 'expression',
        datatype: 'amount',
        expression:
          'restgaeldNetto + dokumentgebyrFinal + deklarationForsikringFinal + gebyrKroneGenberegningFinal + gebyrOmregNummerpladerFinal + forudbetalingRegAfgift',
      },

      nyydelse: {
        friendly: 'Ny ydelse',
        vartype: 'expression',
        datatype: 'amount',
        expression:
          'ceil(-pmt(renteFinal/12, restloebetid, nyhovedstol, if(productId >= 4,-restvaerdiFinal,0), betalingstype)) + administrationsgebyr',
      },

      nyydelseInclMoms: {
        friendly: 'Ny ydelse',
        vartype: 'expression',
        datatype: 'amount',
        expression: 'nyydelse * 1.25',
      },

      // start på udregning af forslag til restløbetid osv.

      mdrSidenLevering: {
        friendly: 'Tid siden levering (mdr.)',
        datatype: 'count',
        vartype: 'expression',
        expression: 'monthsSince(leveringsdato, getToday()) + 1',
      },
      indregistreretFoersteGangBeregnet: {
        datatype: 'date',
        vartype: 'expression',
        expression:
          'if(nyBil, leveringsdato, if(tidligereIndregistreret, indregistreretFoersteGang, ejIndregistreretAargang))',
      },
      nyAlderMdr: {
        datatype: 'count',
        friendly: 'Alder ved ny leveringsdato (mdr.)',
        vartype: 'expression',
        expression: 'monthsSince(nyIndregistreretFoersteGang,nyleveringsdato)',
      },

      // slut på udr..

      forudbetalingRegAfgift: {
        friendly: 'Ny forudbetaling Skat',
        vartype: 'expression',
        expression: 'forbrugtRegAfgift + renteRegAfgift',
        datatype: 'amount',
      },
      forbrugtRegAfgift: {
        vartype: 'expression',
        expression: 'udskudtRegAfgiftPct * nyVurderetRegAfgift',
      },
      udskudtRegAfgiftPct: {
        vartype: 'expression',
        expression: 'udskudtRegAfgiftPct(nyAlderMdr, restloebetid)',
      },
      renteRegAfgift: {
        vartype: 'expression',
        expression: 'renteRegAfgiftPctFinal * restRegAfgift * restloebetid / 12',
      },
      restRegAfgift: {
        vartype: 'expression',
        expression: 'nyVurderetRegAfgift - forbrugtRegAfgift',
      },

      nystelnummer: {
        friendly: 'Stelnummer',
        vartype: 'model',
        datatype: 'stelnummer',
        defaultExpr: 'stelnummer',
      },
      nyregistreringsnummer: {
        friendly: 'Registreringsnr.',
        vartype: 'model',
        datatype: 'registreringsnummer',
        defaultExpr: 'registreringsnummer',
      },

      // manuelt overført fra konkret sag:
      aftalenr: { vartype: 'server' },
      aftalenr2: { vartype: 'server' },
      productId: { vartype: 'server' },
      companyGroupIdFinal: { vartype: 'server' },
      restvaerdi: { vartype: 'server', datatype: 'amount' },
      rente: { vartype: 'server', datatype: 'percent' },
      leveringsdato: { vartype: 'server', datatype: 'date' },
      loebetid: { vartype: 'server', datatype: 'count' },
      nyBil: { vartype: 'server' },
      tidligereIndregistreret: { vartype: 'server' },
      indregistreretFoersteGang: { vartype: 'server', datatype: 'date' },
      ejIndregistreretAargang: { vartype: 'server', datatype: 'date' },
      ydelse: { vartype: 'server', datatype: 'amount' },
      vurderetRegAfgift: { vartype: 'server', datatype: 'amount' },
      navn: { vartype: 'server' },
      navn2: { vartype: 'server' },
      adresse: { vartype: 'server' },
      adresse2: { vartype: 'server' },
      postnr: { vartype: 'server' },
      postnr2: { vartype: 'server' },
      cvr: { vartype: 'server' },
      cpr: { vartype: 'server' },
      cpr2: { vartype: 'server' },
      kundetype: { vartype: 'server' },
      maerkeModel: { vartype: 'server' },
      registreringsnummer: { vartype: 'server' },
      stelnummer: { vartype: 'server' },
    }

    var transferredDefs = {
      dokumentgebyr: true,
      deklarationForsikring: true,
      gebyrKroneGenberegning: true,
      gebyrOmregNummerplader: true,
      betalingstype: true,
      administrationsgebyr: true,
      renteRegAfgiftPct: true,
    }
    for (var varName in transferredDefs) {
      variableDefs[varName] = defaultDefs[varName] // transfer definition from default defs!
    }

    $scope.modelVars = modelVars
    $scope.variableDefs = variableDefs

    var engine = new ComputationEngine(
      modelVars,
      serverVars,
      function () {
        return $scope.userInfo.id
      },
      function () {
        return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
      },
      function () {
        return 0
      },
      variableDefs,
    )

    $scope.engine = engine

    $scope.formIsLoaded = false
    $scope.formIsDisabled = false
    $scope.justInitialized = {}

    $scope.autostuffWatches = {}

    $scope.renderStaticAmount = function (value) {
      return 'kr ' + danishLocaleNumber(Math.ceil(value))
    }
    $scope.renderStaticPct = function (value) {
      return (value * 100).toFixed(2).replace('.', ',') + ' %'
    }
    $scope.renderStaticDecimal = function (value) {
      return value.toFixed(1).replace('.', ',') + ''
    }
    $scope.renderAmount = function (value) {
      return danishLocaleNumber(Math.ceil(value))
    }
    $scope.renderPct = function (value) {
      return (value * 100).toFixed(2).replace('.', ',')
    }
    $scope.renderDecimal = function (value, decimals) {
      return value.toFixed(decimals).replace('.', ',')
    }
    $scope.renderDate = dateToDanish
    var justSaved = false

    var watches = []

    var unregisterWatches = function () {
      for (var i in watches) {
        var temp = watches[i]
        temp()
      }
      watches = []
    }

    var registerWatches = function () {
      unregisterWatches()

      watches.push(
        $scope.$watchCollection('modelVars', function (newVar, oldVar) {
          if (!$scope.formIsLoaded) return

          //console.log('collectionWatch', 'before', oldVar.loebetidWrite, 'after',newVar.loebetidWrite)
          engine.clearExpressionCache()

          if ($scope.form.$dirty) {
            //} && !justSaved) {
            $scope.setGlobalAlert('')
          }
          if (justSaved) {
            justSaved = false
          }
          //}
        }),
      )

      for (var varName in $scope.autostuffWatches) {
        var rs = $scope.variableDefs[varName].reset
        for (var i in rs) {
          watches.push($scope.$watch('modelVars.' + rs[i], $scope.autostuffWatches[varName]))
        }
      }
    }

    var loadForm = function (res, existing) {
      //
      $scope.engine = engine
      $scope.formIsLoaded = false
      $scope.formIsDisabled = false
      unregisterWatches()
      $scope.isOpen = true
      //

      for (var field in res.serverVars) {
        if (transferredDefs[field] === true) {
          serverVars[field] = res.serverVars[field]
        }
      }

      if (existing !== undefined && existing.valuesJson !== null) {
        // vi skal bruge en static compEngine. global variabel engine ændres ikke!
        $scope.isOpen = false
        var DummyEngine = function (variableDefs, values) {
          for (var i in values) {
            if (variableDefs[i] !== undefined && variableDefs[i].datatype === 'date') {
              if (values[i] !== null) {
                values[i] = new Date(values[i])
              } else {
                values[i] = null
              }
            }
          }

          this.eval = function (identifier, fallback) {
            if (values[identifier] === undefined) {
              return fallback
            }
            return values[identifier]
          }
          this.getValues = function () {
            return values
          }
          this.resetComputation = function () {}
          this.clearExpressionCache = function () {}
          this.getVariableDefs = function () {
            return variableDefs
          }
        }

        var values = JSON.parse(existing.valuesJson)
        $scope.formIsDisabled = true
        $scope.engine = new DummyEngine(variableDefs, values)

        for (var field in values) {
          if (variableDefs[field] !== undefined) {
            if (variableDefs[field].datatype == 'date' || variableDefs[field].datatype == 'year') {
              if (values[field] !== null) {
                $scope.modelVars[field] = new Date(values[field])
              } else {
                $scope.modelVars[field] = null
              }
            } else {
              $scope.modelVars[field] = values[field] // håndtér datoer etc
            }
          }
        }
      } else {
        // transfer application values to serverVars

        var preparedValues = getPreparedValues(defaultDefs, res)
        serverVars.aftalenr = res.modelVars.aftalenr
        serverVars.aftalenr2 = res.modelVars.aftalenr2
        serverVars.productId = preparedValues.productId
        serverVars.companyGroupIdFinal = preparedValues.companyGroupIdFinal
        serverVars.restvaerdi = preparedValues.restvaerdi
        serverVars.rente = preparedValues.rentesatsEfterReg
        serverVars.leveringsdato = preparedValues.leveringsdatoFinal
        serverVars.loebetid = preparedValues.loebetid
        serverVars.nyBil = preparedValues.nyBil
        serverVars.tidligereIndregistreret = preparedValues.tidligereIndregistreret
        serverVars.indregistreretFoersteGang = preparedValues.indregistreretFoersteGang
        serverVars.ejIndregistreretAargang = preparedValues.ejIndregistreretAargang
        serverVars.ydelse = preparedValues.ydelse
        serverVars.vurderetRegAfgift = preparedValues.vurderetRegAfgift
        serverVars.navn = preparedValues.navn
        serverVars.navn2 = preparedValues.navn2
        serverVars.adresse = preparedValues.adresse
        serverVars.adresse2 = preparedValues.adresse2
        serverVars.postnr = preparedValues.postnr
        serverVars.postnr2 = preparedValues.postnr2
        serverVars.cpr = preparedValues.cpr
        serverVars.cvr = preparedValues.cvr
        serverVars.cpr2 = preparedValues.cpr2
        serverVars.kundetype = preparedValues.kundetype
        serverVars.maerkeModel = preparedValues.maerkeModel
        serverVars.registreringsnummer = preparedValues.registreringsnummer
        serverVars.stelnummer = preparedValues.stelnummer

        if (existing !== undefined) {
          for (var field in existing) {
            if (variableDefs[field] !== undefined) {
              if (variableDefs[field].datatype == 'date' || variableDefs[field].datatype == 'year') {
                if (existing[field] !== null) {
                  $scope.modelVars[field] = new Date(existing[field])
                } else {
                  $scope.modelVars[field] = null
                }
              } else {
                $scope.modelVars[field] = existing[field] // håndtér datoer etc
              }
            }
          }
        } else {
          engine.resetComputation($scope.justInitialized) // Kun indlæs default-værdier når den er ved at blive redigeret!
        }

        engine.clearExpressionCache()
        $scope.formIsLoaded = true
        registerWatches()
      }

      //
    }

    $scope.reopen = function () {
      $http.post('/api/recalculation/reopen/' + id).success(reload)
    }

    var reload = function () {
      var requests = [$http.post('/api/application/get', applicationParam)]
      if (id !== undefined) {
        requests.push($http.post('/api/recalculation/get/' + id))
      }

      $q.all(requests).then(function (data) {
        if (data[0].status !== 200) {
          getFail()
        } else {
          if (data[1] !== undefined) {
            loadForm(data[0].data, data[1].data)
          } else {
            loadForm(data[0].data)
          }
        }
      })
    }

    reload()
  },
]
