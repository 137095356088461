function tryOrNull<A>(f: () => A): A | null {
  try {
    return f()
  } catch (e) {
    return null
  }
}

export function formatDate(x: string | null) {
  return x === null
    ? null
    : tryOrNull(() =>
        Intl.DateTimeFormat('da', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(Date.parse(x)),
      )
}
export function formatNumber(x: number | null, digits: number = 2) {
  return x === null
    ? null
    : isNaN(x)
    ? 'N/A'
    : tryOrNull(() =>
        Intl.NumberFormat('da', { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(x),
      )
}

export function formatPercent(x: number | null) {
  return x === null
    ? null
    : isNaN(x)
    ? x
    : tryOrNull(() => Intl.NumberFormat('da', { style: 'percent', minimumFractionDigits: 2 }).format(x))
}
