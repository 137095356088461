import creditAnalysisApprovalTemplate from './creditAnalysisApproval.html'
import { IAttributes, IDirective, IScope } from 'angular'
import { ApprovalInfo, ApprovalState } from './creditAnalysisTypes'
import { dateToDanish } from '../functions/dates'

interface CreditAnalysisApprovalScope extends IScope {
  setApprovalDirty: () => void
  approval: ApprovalInfo
  save: (state: ApprovalState) => void
  saveApproval: () => (state: ApprovalState) => Promise<void>
  validateApproval: () => boolean
  renderTimestamp: (timestamp: string) => string
  hasPermsToApprove: boolean
}

export const creditAnalysisApproval = [
  function (): IDirective<CreditAnalysisApprovalScope, JQLite, IAttributes> {
    return {
      restrict: 'A',
      template: creditAnalysisApprovalTemplate,
      scope: {
        title: '@',
        approval: '=',
        userInputDirty: '=',
        saveApproval: '&',
        validateApproval: '&',
        hasPermsToApprove: '=',
      },

      link: function ($scope: CreditAnalysisApprovalScope, elm: JQLite, attrs: IAttributes) {
        $scope.renderTimestamp = function (timestamp) {
          return dateToDanish(new Date(Date.parse(timestamp)), true)
        }
        $scope.setApprovalDirty = function () {
          $scope.approval.dirty = true
        }
        $scope.save = function (state: ApprovalState) {
          $scope.approval.dirty = true
          if ($scope.validateApproval()) {
            $scope
              .saveApproval()(state)
              .then(function () {
                $scope.approval.dirty = false
              })
          }
        }
      },
    }
  },
]
