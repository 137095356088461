export const datepickerTestCtrl = [
  '$scope',
  '$interop',
  function ($scope, $interop) {
    $scope.formIsDisabled = false
    var serverVars = {}
    var modelVars = {}

    var engine = new $interop.ComputationEngine(
      modelVars,
      serverVars,
      function () {
        return $scope.userInfo.id
      },
      function () {
        return $scope.userInfo !== undefined && $scope.userInfo.isSuperAdmin
      },
      function () {
        if ($scope.insuranceCompanyLookupReverse !== undefined) {
          return $scope.insuranceCompanyLookupReverse['Købstædernes Forsikring']
        } else {
          return 0
        }
      },
    ) // hent senere serverVars ind...

    $scope.variableDefs = engine.getVariableDefs()
    $scope.modelVars = modelVars
    $scope.ening
  },
]
