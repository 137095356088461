export const controlpanelArticleCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$location',
  function ($scope, $rootScope, $http, $state, $location) {
    $scope.state = $state
    $scope.addurl = '/controlpanel/article/add'
    $scope.addArticleUrl = '/controlpanel/article/addArticle'
    $scope.editurl = '/controlpanel/article/edit'

    $scope.loadAll = function () {
      $http.post('/api/article/getAllByArticleCategoryId').success(function (res) {
        $scope.articleList = res
      })
      $http.post('/api/articleCategory/getAll').success(function (res) {
        $scope.list = res
        //console.log($scope.list)
        if ($state.is('root.controlpanel.article.index')) {
          if ($scope.list.length > 0) {
            $location.url('/controlpanel/article/edit/' + $scope.list[0].id)
          } else {
            $location.url('/controlpanel/article/add')
          }
        }
      })
    }

    $scope.loadAll()

    $scope.setActiveId = function (id) {
      $scope.activeId = id
      $scope.id = id
    }
    $scope.setActiveArticleId = function (id) {
      $scope.activeArticleId = id
      $scope.articleId = id
    }
    $scope.showArticleCategory = function (item) {
      return !item.deactivated || $scope.showDeactivated
    }
    $scope.showArticle = function (item) {
      return !item.deactivated || $scope.showDeactivated
    }
  },
]
