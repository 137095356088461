
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/creditAnalysis/creditAnalysisNetIncome.html";
      const src = "<div>\n  <h4>Indtjening</h4>\n  <table class=\"table table-striped\">\n    <tbody>\n      <tr>\n        <td>Finansieringsindtægt</td>\n        <td class=\"text-right\">{{renderStaticAmount(netIncomeInterest)}}</td>\n      </tr>\n      <tr>\n        <td>Forsikring</td>\n        <td class=\"text-right\">{{renderStaticAmount(netIncomeInsurance)}}</td>\n      </tr>\n      <tr>\n        <td>Administrationsgebyr</td>\n        <td class=\"text-right\">{{renderStaticAmount(netIncomeAdministrationFee)}}</td>\n      </tr>\n      <tr>\n        <td>Etableringsgebyr</td>\n        <td class=\"text-right\">{{renderStaticAmount(netIncomePVFees)}}</td>\n      </tr>\n      <tr>\n        <th>Indtjening</th>\n        <th class=\"text-right\">{{renderStaticAmount(netIncomeTotal)}}</th>\n      </tr>\n      <tr>\n        <th>Indtjening pr. måned</th>\n        <th class=\"text-right\">{{renderStaticAmount(netIncomeTotalPerMonth)}}</th>\n      </tr>\n    </tbody>\n  </table>\n</div>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      