
      
      const angular = require('angular');
      let ngModule;
      try {
        ngModule = angular.module(["ng"]);
      }
      catch(e) {
        ngModule = angular.module("ng",[]);
      }
      
      const inj = angular.element(window.document).injector();
      const path = "shared/templates/forms/penneo.html";
      const src = "<h3>Penneo</h3>\n\n<form name=\"form\" class=\"form-horizontal clearfix\" novalidate>\n  <fieldset>\n    <legend>Generelt</legend>\n    <div ng-autofield=\"penneoReminderInterval\"></div>\n  </fieldset>\n\n  <fieldset>\n    <legend>{{variableDefs.penneoEmailText.friendly}}</legend>\n    <div ng-autofield=\"penneoEmailSubject\"></div>\n    <textarea ng-model=\"modelVars.penneoEmailText\" class=\"form-control\" rows=\"8\"></textarea>\n  </fieldset>\n  <fieldset>\n    <legend>{{variableDefs.penneoReminderEmailText.friendly}}</legend>\n    <div ng-autofield=\"penneoReminderEmailSubject\"></div>\n    <textarea ng-model=\"modelVars.penneoReminderEmailText\" class=\"form-control\" rows=\"8\"></textarea>\n  </fieldset>\n  <fieldset>\n    <legend>{{variableDefs.penneoCompletedEmailText.friendly}}</legend>\n    <div ng-autofield=\"penneoCompletedEmailSubject\"></div>\n    <textarea ng-model=\"modelVars.penneoCompletedEmailText\" class=\"form-control\" rows=\"8\"></textarea>\n  </fieldset>\n  <hr />\n  <a class=\"btn btn-primary pull-right\" ng-show=\"!form.$invalid || !formIsLoaded\" ng-click=\"save()\"\n    ><i class=\"fa fa-save\"></i> &nbsp; Gem</a\n  >\n  <a class=\"btn btn-danger pull-right\" ng-show=\"form.$invalid && formIsLoaded\"\n    ><i class=\"fa fa-exclamation\"></i> &nbsp; {{errorCount(form, this)}} fejl</a\n  >\n</form>\n";
      if (inj){
        inj.get("$templateCache").put(path,src);
      }
      else{
        ngModule.run(["$templateCache",function(c){c.put(path,src)}]);
      }
      
      
      
      module.exports = src;
      