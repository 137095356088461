export const controlpanelCompanyCtrl = [
  '$scope',
  '$rootScope',
  '$http',
  '$state',
  '$location',
  function ($scope, $rootScope, $http, $state, $location) {
    $scope.self = $scope
    $scope.state = $state
    $scope.addurl = '/controlpanel/company/add'
    $scope.addUserUrl = '/controlpanel/company/addUser'
    $scope.editurl = '/controlpanel/company/edit'

    $scope.showOnlyActive = true

    $scope.loadAll = function () {
      $http.post('/api/user/getAllByCompanyId').success(function (res) {
        $scope.userList = res
      })
      $http.post('/api/company/getAll').success(function (res) {
        $scope.list = res
        //console.log($scope.list)
        if ($state.is('root.controlpanel.company.index')) {
          if ($scope.list.length > 0) {
            $location.url('/controlpanel/company/edit/' + $scope.list[0].id)
          } else {
            $location.url('/controlpanel/company/add')
          }
        }
      })
    }

    $scope.loadAll()

    $scope.setActiveId = function (id) {
      $scope.activeId = id
      $scope.id = id
    }
    $scope.setActiveUserId = function (id) {
      $scope.activeUserId = id
      $scope.userId = id
    }
    $scope.showCompany = function (item) {
      return (!item.deactivated || $scope.showDeactivated) && $scope.showOnlyActive == item.isActive
    }
    $scope.showUser = function (item) {
      return !item.deactivated || $scope.showDeactivated
    }
  },
]
