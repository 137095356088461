import { YEAR_REGEXP } from '../regexes'
import { IAttributes, IDirective, INgModelController, IScope } from "angular";

export function ngYear(): IDirective<IScope, JQLite, IAttributes, INgModelController> {
  return {
    require: 'ngModel',
    link(scope: IScope, elm: JQLite, attrs: IAttributes, ctrl?: INgModelController) {
      if (!ctrl) {
        throw new Error("Controller not present")
      }

      ctrl.$parsers.unshift(function (viewValue) {
        viewValue = viewValue + ''
        let valid = YEAR_REGEXP.test(viewValue)
        let date
        if (valid) {
          const year = parseInt(viewValue, 10)
          date = new Date()
          date.setFullYear(year, 0, 1)
          date.setHours(0)
          date.setMinutes(0)
          date.setSeconds(0)
          date.setMilliseconds(0)
          valid = year === date.getFullYear()

          if (valid) {
            valid = year <= new Date().getFullYear()
          }
        }
        if (valid) {
          // it is valid
          ctrl.$setValidity('year', true)
          return date
        } else if (viewValue == '') {
          ctrl.$setValidity('year', true)
          return null
        } else {
          ctrl.$setValidity('year', false)
          return undefined
        }
      })

      ctrl.$formatters.push(function (modelValue) {
        if (modelValue instanceof Date) return modelValue.getFullYear()
      })
    },
  }
}
