import companyTemplate from '@/shared/templates/digitalSignatureRecipients/company.html'
import { CvrMetadata, Person } from './companyModal'
import { assignScope, makeDirective, ScopeType } from '@/shared/util/directiveHelpers'

export const ptbCompany = makeDirective({
  restrict: ['attribute'],
  template: companyTemplate,
  scope: {
    cvr: {
      type: String,
      binding: 'twoWay',
    },
    persons: {
      type: Array as ScopeType<Array<Person>>,
      binding: 'twoWay',
    },
    cvrMetadata: {
      type: Object as unknown as ScopeType<CvrMetadata>,
      binding: 'twoWay',
    },
    contractPersons: {
      type: Array as ScopeType<Array<Person>>,
      binding: 'twoWay',
    },
  },
  assignScope: assignScope<{
    addPerson(name: string, cprCvr: string, mail: string): void
    removePerson(i: number): void
    registerButtonDropdown(): void
  }>(),
  linkAssign({}, { scope }) {
    return {
      addPerson(name: string, cprCvr: string, mail: string) {
        scope.persons.push({ name: name, cprCvr: cprCvr, mail: mail })
      },
      removePerson(i: number) {
        scope.persons.splice(i, 1)
      },
      registerButtonDropdown() {
        const isSeen: { [cpr: string]: true } = {}

        return scope.contractPersons
          .filter((person) => {
            const out = !isSeen[person.cprCvr]
            isSeen[person.cprCvr] = true
            return out
          })
          .map((person) => {
            return {
              text: person.name,
              click: () => scope.addPerson(person.name, person.cprCvr, person.mail),
            }
          })
      },
    }
  },
})
